import React from "react";
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  RadioGroup,
  Radio,
  Textarea,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Select,
  FormErrorMessage,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field } from "formik";
import { MdNavigateNext } from "react-icons/md";
import { validateField, validateOnSubmit } from "../../../utils/Validaciones";

const PrimerPasoRegistroFijo = (props) => {
  const {
    loading,
    setLoading,
    activeStep,
    setActiveStep,
    handleChange,
    handleBlur,
    values,
    setImgUpload,
    type,
    errors,
    touched,
  } = props;
  const toast = useToast();

  return (
    <Stack w="100%" pb={3}>
      <HStack justifyContent="space-around" pb={3}>
        {(type === "crear" || values.locacionRegistroEvento) && (
          <FormControl w="2xs" placeSelf={"start"} isRequired>
            <FormLabel fontWeight="semibold">Locación del evento</FormLabel>

            <HStack spacing={0}>
              <Select
                placeSelf={"start"}
                placeholder="Seleccionar locación"
                name="locacionRegistroEvento"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.locacionRegistroEvento}
              >
                <option value={"baseAnelo"}>Base Añelo</option>
                <option value={"oficinaNqn"}>Oficina Neuquen</option>
                <option value={"oficinaBSAS"}>Oficina Bs. As</option>
                <option value={"otro"}>Otro</option>
              </Select>
              {values.locacionRegistroEvento === "otro" && (
                <Stack placeSelf={"start"}>
                  <Field
                    name="locacionRegistroEventoOtro"
                    validate={(value) => validateField("text", value, "nombre")}
                  >
                    {({ field }) => (
                      <FormControl
                        isInvalid={
                          errors.locacionRegistroEventoOtro &&
                          touched.locacionRegistroEventoOtro
                        }
                      >
                        <Input
                          {...field}
                          placeholder="Ubicación"
                          name="locacionRegistroEventoOtro"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.locacionRegistroEventoOtro}
                        />
                        <FormErrorMessage>
                          {errors.locacionRegistroEventoOtro}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              )}
            </HStack>
          </FormControl>
        )}

        <FormControl w="2xs" placeSelf={"start"} isRequired>
          <FormLabel fontWeight="semibold">Evento</FormLabel>
          <RadioGroup
            name="tipoRegistroEventos"
            value={values.tipoRegistroEventos}
          >
            <HStack>
              <Radio
                onChange={handleChange}
                onBlur={handleBlur}
                value="Calidad"
              >
                Calidad
              </Radio>
              <Radio
                onChange={handleChange}
                onBlur={handleBlur}
                value="Mantenimiento"
              >
                Mantenimiento
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" placeSelf={"start"} isRequired>
          <FormLabel fontWeight="semibold">Valoracion de Evento</FormLabel>
          <RadioGroup
            name="valoracionRegistroEventos"
            value={values.valoracionRegistroEventos}
          >
            <HStack>
              <Radio onChange={handleChange} onBlur={handleBlur} value="Leve">
                Leve
              </Radio>
              <Radio onChange={handleChange} onBlur={handleBlur} value="Grave">
                Grave
              </Radio>
              <Radio
                onChange={handleChange}
                onBlur={handleBlur}
                value="Muy Grave"
              >
                Muy Grave
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del evento</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaRegistroEventos}
          />
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Equipo involucrado</FormLabel>
          <RadioGroup
            name="equipoRegistroEventos"
            value={values.equipoRegistroEventos}
          >
            <HStack justifyContent="start">
              <Stack justifyContent="left">
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sander"
                >
                  Sander
                </Radio>
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandcubes"
                >
                  Sandcubes
                </Radio>
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandVan"
                >
                  SandVan
                </Radio>
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="dosificadora"
                >
                  Dosificadora
                </Radio>
              </Stack>
              <Stack placeSelf="start">
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="generadores"
                >
                  Generadores
                </Radio>
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="autoelevador"
                >
                  Autoelevador
                </Radio>
                <Radio
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="minipala"
                >
                  Mini Pala
                </Radio>
                <Radio onChange={handleChange} onBlur={handleBlur} value="otro">
                  Ninguno
                </Radio>
              </Stack>
            </HStack>
          </RadioGroup>
        </FormControl>
        <Stack w="lg" placeSelf={"start"}>
          <Field
            name="descripcionRegistroEventos"
            validate={(value) =>
              validateField("textArea", value, "Observaciones")
            }
            w={"100%"}
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors?.descripcionRegistroEventos &&
                  touched?.descripcionRegistroEventos
                }
                isRequired
              >
                <HStack spacing={-1}>
                  <FormLabel> Descripcion de Evento</FormLabel>

                  <Text fontSize={12} color={"gray"}>
                    ¿Qué pasó?¿Cómo pasó?¿Quiénes estuvieron involucrados?
                  </Text>
                </HStack>
                <Card variant="unstyled" bg="brand.fondos_secundarios">
                  <CardBody>
                    <Textarea
                      {...field}
                      placeholder="Descripcion"
                      name="descripcionRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descripcionRegistroEventos}
                      maxLength="1500"
                      resize="none"
                    />
                  </CardBody>
                  <CardFooter
                    placeSelf="end"
                    color="brand.gris_medio"
                    pr={2}
                    m={"unset"}
                  >
                    {values.descripcionRegistroEventos.length}/1500
                  </CardFooter>
                </Card>
                <FormErrorMessage>
                  {errors?.descripcionRegistroEventos}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <FormControl w="2xs"></FormControl>
      </HStack>
      <HStack px={7} pb={3}>
        <FormControl w="xs" placeSelf="start">
          <FormLabel fontWeight="semibold">Foto</FormLabel>
          <Input
            variant="filled"
            type="file"
            onChange={(e) => setImgUpload(e.target.files[0])}
            accept=".jpeg, .jpg, .png"
            multiple={false}
            _hover="none"
            bg="brand.fondo_secundario"
          />
        </FormControl>
      </HStack>
      <HStack>
        <FormLabel w={"sm"}></FormLabel>
        <FormLabel w={"sm"}></FormLabel>
        <FormLabel w={"sm"}>A quién se comunicó?</FormLabel>
      </HStack>
      <HStack>
        <Stack placeSelf={"start"} px={5}>
          <Checkbox
            name="masFotosRegistroEventos"
            onChange={handleChange}
            isChecked={values.masFotosRegistroEventos}
          >
            Se disponen más fotos?
          </Checkbox>
          <HStack w={"sm"}>
            <Checkbox
              w={"xs"}
              name="otroRegistroEventos"
              onChange={handleChange}
              isChecked={values.otroRegistroEventos}
            >
              Se registró evento en otro registro?
            </Checkbox>
            <Stack m={"unset"} w={"sm"}>
              <Field
                name="otroDondeRegistroEventos"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={
                      errors.otroDondeRegistroEventos &&
                      touched.otroDondeRegistroEventos
                    }
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="¿Donde?"
                      size="sm"
                      name="otroDondeRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otroDondeRegistroEventos}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.otroDondeRegistroEventos}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
        </Stack>
        <Stack placeSelf={"start"}>
          <HStack w={"xs"} placeSelf={"start"}>
            <Checkbox
              w={"sm"}
              name="actoresRegistroEventos"
              onChange={handleChange}
              isChecked={values.actoresRegistroEventos}
            >
              Involucró otros actores/empresas?
            </Checkbox>
            <Stack m={"unset"} placeSelf={"start"} w={"2xs"}>
              <Field
                name="actoresQuienRegistroEventos"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={
                      errors.actoresQuienRegistroEventos &&
                      touched.actoresQuienRegistroEventos
                    }
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="¿Quien?"
                      size="sm"
                      name="actoresQuienRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actoresQuienRegistroEventos}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.actoresQuienRegistroEventos}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
          <HStack w={"xs"} placeSelf={"start"}>
            <Checkbox
              w={"sm"}
              name="informoRegistroEventos"
              onChange={handleChange}
              isChecked={values.informoRegistroEventos}
            >
              Se informó al cliente?
            </Checkbox>
            <Stack placeSelf={"start"} m={"unset"} w={"2xs"}>
              <Field
                name="rtaObtenidaCl"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.rtaObtenidaCl && touched.rtaObtenidaCl}
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="Respuesta obtenida"
                      size="sm"
                      name="rtaObtenidaCl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rtaObtenidaCl}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.rtaObtenidaCl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
        </Stack>
        <Stack placeSelf={"start"} spacing={6}>
          <Stack w={"2xs"}>
            <Field
              name="aQuienComunico"
              validate={(value) => validateField("text", value, "texto")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.aQuienComunico && touched.aQuienComunico}
                >
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    size="sm"
                    name="aQuienComunico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.aQuienComunico}
                    w={"2xs"}
                  />
                  <FormErrorMessage fontSize={"10px"} m={"unset"}>
                    {errors.aQuienComunico}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          <Stack w={"xs"}>
            <Field
              name="rtaObtenida"
              validate={(value) => validateField("text", value, "texto")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.rtaObtenida && touched.rtaObtenida}
                >
                  <Input
                    {...field}
                    type="text"
                    placeholder="Respuesta obtenida"
                    size="sm"
                    name="rtaObtenida"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rtaObtenida}
                    w={"2xs"}
                  />
                  <FormErrorMessage fontSize={"10px"}>
                    {errors.rtaObtenida}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Stack>
      </HStack>
      <HStack px={5} justifyContent="end">
        <Box>
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            variant="solid"
            onClick={() => {
              setLoading(true);

              if (
                validateOnSubmit("text", values.descripcionRegistroEventos) ||
                (values.otroDondeRegistroEventos &&
                  validateOnSubmit("text", values.otroDondeRegistroEventos)) ||
                (values.actoresQuienRegistroEventos &&
                  validateOnSubmit(
                    "text",
                    values.actoresQuienRegistroEventos
                  )) ||
                (values.rtaObtenidaCl &&
                  validateOnSubmit("text", values.rtaObtenidaCl)) ||
                (values.aQuienComunico &&
                  validateOnSubmit("text", values.aQuienComunico)) ||
                (values.rtaObtenida &&
                  validateOnSubmit("text", values.rtaObtenida))
              ) {
                setLoading(false);
                return toast({
                  title: "Error",
                  description: "Datos invalidos",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
              setLoading(false);
              setActiveStep(activeStep + 1);
            }}
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
        </Box>
      </HStack>
    </Stack>
  );
};

export default PrimerPasoRegistroFijo;
