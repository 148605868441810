import React from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";

const FinalizacionManual = (props) => {
  const {
    isOpen,
    onClose,
    editable,
    setFiltroCamiones,
    filtroCamiones,
    camiones,
    camionFin,
    setCamionFin,
    setFechaFin,
    handleColorEstado,
    handleTextoEstado,
    handleCierreManual,
    fechaFin,
  } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isLazy>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reporte N° {editable.idReporteViaje}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody border={"1px solid gray"} borderRadius={"10px"} mx={3}>
          <Stack>
            <FormControl>
              <HStack justifyContent={"space-between"}>
                <FormLabel>Camion inicio del viaje</FormLabel>
                <Text>{editable.camionRV}</Text>
              </HStack>
            </FormControl>
            <FormControl isRequired>
              <HStack justifyContent={"space-between"}>
                <FormLabel>Camion fin del viaje</FormLabel>
                <Stack>
                  <Input
                    w="xs"
                    variant="flushed"
                    type="text"
                    placeholder="Camion"
                    style={{ fontWeight: "bold", color: "black" }}
                    onChange={(e) => {
                      const value = e.target.value ?? "";
                      setFiltroCamiones(value.toLowerCase());
                    }}
                    value={camionFin?.dominio}
                  />
                  {filtroCamiones && (
                    <List
                      w={"2xs"}
                      position={"absolute"}
                      top={39}
                      bg={"white"}
                      h={40}
                      overflowY={"scroll"}
                      zIndex={10}
                      cursor={"pointer"}
                    >
                      {camiones
                        .filter((element) =>
                          element.dominio.toLowerCase().includes(filtroCamiones)
                        )
                        .map((element, index) => (
                          <ListItem
                            key={index}
                            w={"100%"}
                            onClick={() => {
                              setCamionFin(element);
                              setFiltroCamiones();
                            }}
                          >
                            {element.dominio}
                          </ListItem>
                        ))}
                    </List>
                  )}
                </Stack>
              </HStack>
            </FormControl>
            <FormControl isRequired>
              <HStack justifyContent={"space-between"}>
                <FormLabel>Hora fin del viaje</FormLabel>
                <Input
                  w={"2xs"}
                  type="datetime-local"
                  min={editable.tsPozoOut}
                  onChange={(e) => setFechaFin(e.target.value)}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack justifyContent={"space-between"}>
                <FormLabel>Estado</FormLabel>
                <HStack>
                  <Text color={handleColorEstado("En curso")}>
                    {handleTextoEstado("En curso")}
                  </Text>
                  <Text fontWeight={"bold"}>{">>"}</Text>
                  <Text color={handleColorEstado("Finalizado")}>
                    {handleTextoEstado("Finalizado")}
                  </Text>
                </HStack>
              </HStack>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            bg={"brand.naranja"}
            color={"white"}
            onClick={() => handleCierreManual(editable, fechaFin, camionFin)}
          >
            Finalizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FinalizacionManual;
