export const TYPENAMEREGEX = /^[a-zA-Z0-9\- ]*$/;
export const TYPEEMAILREGEX =
  /^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;
export const TYPENUMBERREGEX = /^(0|[1-9]\d*)$|^$/;
export const TYPETEXTAREAREGEX = /^[a-zA-Z0-9.,\s]*$/;
export const TYPEONLYLETTERSREGEX = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/;
export const TYPELETTERSNUMBERSREGEX = /^[a-zA-Z0-9\s]*$/;
export const TYPEDECIMALREGEX = /^(0|[1-9]\d*)(\.\d+)?$|^$/;
export const TYPELATLNGREGEX =
  /^[-+]?(?:(?:[0-8]\d|90)\.\d{5}|(?:1[0-7]\d|0\d{2}|180)\.\d{5})$/;
