import ajax from "../../../utils/axios";
import axios from "axios";
import { LogLevel } from "@azure/msal-browser";
const baseURL = process.env.REACT_APP_URL;

export const postLogin = async (username, password) => {
  let res;
  try {
    res = await ajax.post(
      "/endpoint/login",
      { username, password },
      { timeout: 3000 }
    );

    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - postLogin() - Error", { error });
    return error.response;
  }
};

export const postLogout = async () => {
  try {
    let response = await ajax.post("/endpoint/logout");
    return response.data;
  } catch (error) {
    console.error("Error en: api.helper.js - postLogout() - Error", error);
    return error.response.data;
  }
};

export const changePassword = async (params) => {
  try {
    let response = await ajax.put("/endpoint/new-password", params);
    return response.data;
  } catch (error) {
    console.error("Error en: api.helper.js - changePassword() - Error", error);
    return error.response.data;
  }
};
export const recuperar = async (params) => {
  try {
    let response = await ajax.put("/endpoint/forgot-password", params);
    return response.data;
  } catch (error) {
    console.error("Error en: api.helper.js - recuperar() - Error", error);
    return error.response.data;
  }
};

/**
 *
 *
 *  Integración con Azure AD
 *
 *
 */

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AZURE_AD_LOGIN_CALLBACK,
    clientSecret: process.env.REACT_APP_AZURE_AD_APP_SECRET,
    extraQueryParameters: {
      resource: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    },
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [`api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/User.Login`],
};

export const loginValidateAD = async (ad) => {
  let res;
  try {
    res = await axios.post(
      `${baseURL}/endpoint/loginAD`,
      { timeout: 5000 },
      { headers: { Authorization: `Bearer ${ad.accessToken}` } }
    );

    sessionStorage.setItem("token", res.data.data.token);
    return res;
  } catch (error) {
    console.error("Error en: api.helper.js - loginAD() - Error", { error });
    return error.response;
  }
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * Uso esta función para limpiar los intentos de inicio de sesión con AD
 * que hayan quedado abiertos sin handlear, porque provocan un error en
 * la llamada a loginPopup y no permiten iniciar otro proceso de login.
 *
 * @param {*} instance
 */
export const clearCachedTokens = async (instance) => {
  try {
    /**
     * Se dio el caso que quedaba la cookie 'msal.interaction.status' con el valor
     * 'undefined' y eso provocaba una excepción en la llamada a loginPopup.
     * Se puede reproducir el error creando la cookie desde la consola de js del
     * browser de la siguiente forma:
     *
     * > document.cookie="msal.interaction.status=undefined"
     */
    deleteCookie("msal.interaction.status");
    const currentAccounts = instance.getAllAccounts();
    if (currentAccounts) {
      const tokenRemovalPromises = currentAccounts.map((account) => {
        return instance.acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account,
        });
      });

      await Promise.all(tokenRemovalPromises);
    }
  } catch (error) {
    console.error("Error al borrar tokens almacenados en caché:", error);
  }
};
