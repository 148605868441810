import React, { useEffect, useState } from "react";
import {
  FormControl,
  HStack,
  Input,
  FormLabel,
  Button,
  Stack,
  Heading,
  IconButton,
  useToast,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import {
  MdFileDownloadDone,
  MdArrowBack,
  MdAdd,
  MdOutlineRemove,
} from "react-icons/md";
import { FieldArray, Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { crearCamiones, getTransportista } from "../../helpers/api.helper";

const FormCrearCamiones = (props) => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [cantidadInputs, setCantidadInputs] = useState(1);
  const [transportista, setTransportista] = useState([]);

  const nombre = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const initData = async () => {
    let res = await getTransportista({ nombre: nombre.nombreTransportista });

    if (res.status === 200) {
      setTransportista(res.data[0]);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  const initialValues = {
    rowCount: cantidadInputs,
    camiones: [
      {
        nombreCamion: "",
        dominio: "",
        idRSV: "",
        tipoContratoCamion: "",
        geotab: false,
        rsv: false,
        microtrack: false,
      },
    ],
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w={"100%"} p={3} spacing={5}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Agregar Camiones
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (
              values.camiones.some(
                (element) => !element.nombreCamion && !element.dominio
              )
            ) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere una identificacion para el camión",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (
              values.camiones.some((element) => !element.tipoContratoCamion)
            ) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Tipo de contrato requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let parserdCamiones = values.camiones.map((camion) => {
              let tempProv = [];

              if (camion.rsv) {
                tempProv.push({
                  idProveedor: 1,
                  activada: camion.rsv ? 1 : 0,
                });
              }
              if (camion.microtrack) {
                tempProv.push({
                  idProveedor: 2,
                  activada: camion.microtrack ? 1 : 0,
                });
              }
              if (camion.geotab) {
                tempProv.push({
                  idProveedor: 3,
                  activada: camion.geotab ? 1 : 0,
                });
              }

              return {
                nombreCamion: camion.nombreCamion,
                dominio: camion.dominio,
                tipoContratoCamion: camion.tipoContratoCamion,
                idEmpresaT: transportista.idEmpresaT,
                proveedores: tempProv,
              };
            });

            let res = await crearCamiones({ camiones: parserdCamiones });

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: "Camiones creados correctamente",
                duration: 3000,
              });
            } else if (res.status === 404) {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                description: res.data.message,
                title: "Error",
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al crear los camiones`,
                description: res.message || "Error al crear camiones",
              });
            }
            navigate(-1);
            setLoading(false);
          }}
        >
          {({ values, handleBlur, handleChange, handleSubmit }) => (
            <FieldArray name="camiones">
              {({ push, remove }) => (
                <Form id="formAgregarCamiones">
                  <Stack w={"100%"}>
                    <HStack w={"100%"} justifyContent={"space-between"} px={3}>
                      <HStack>
                        <Button
                          leftIcon={<MdAdd />}
                          variant={"link"}
                          onClick={() =>
                            push({
                              nombreCamion: "",
                              dominio: "",
                              tipoContratoCamion: "",
                              rsv: false,
                              microtrack: false,
                              geotab: false,
                              proveedorDef: "",
                            })
                          }
                        >
                          Agregar
                        </Button>
                        <Button
                          leftIcon={<MdOutlineRemove />}
                          variant={"link"}
                          isDisabled={values.camiones.length <= 1}
                          onClick={() => remove(values.camiones.length - 1)}
                        >
                          Quitar
                        </Button>
                      </HStack>
                      <Button
                        placeSelf="end"
                        rightIcon={<MdFileDownloadDone />}
                        bg="brand.naranja"
                        form="formSandvan"
                        color="white"
                        isLoading={loading}
                        isDisabled={user.idRol === "usuarioIT"}
                        size="md"
                        onClick={handleSubmit}
                      >
                        Finalizar
                      </Button>
                    </HStack>
                    <HStack w={"100%"} justifyContent={"space-between"}>
                      <FormControl>
                        <FormLabel>ID Interno</FormLabel>
                        <Stack>
                          {values.camiones.map((camion, index) => (
                            <Input
                              key={camion.idCamion}
                              w={"150px"}
                              placeholder="ID Interno"
                              name={`camiones[${index}].nombreCamion`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.camiones[index].nombreCamion}
                              isRequired
                            />
                          ))}
                        </Stack>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Dominio</FormLabel>
                        <Stack>
                          {values.camiones.map((camion, index) => (
                            <Input
                              key={camion.idCamion}
                              w={"150px"}
                              placeholder="Dominio"
                              name={`camiones[${index}].dominio`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.camiones[index].dominio}
                              isRequired
                            />
                          ))}
                        </Stack>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Tipo contrato</FormLabel>
                        <Stack>
                          {values.camiones.map((camion, index) => (
                            <Select
                              key={camion.idCamion}
                              placeholder="- Tipo contrato -"
                              name={`camiones[${index}].tipoContratoCamion`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.camiones[index].tipoContratoCamion}
                            >
                              <option>{transportista.tipoContrato}</option>
                              {transportista.tipoContratoB && (
                                <option>{transportista.tipoContratoB}</option>
                              )}
                            </Select>
                          ))}
                        </Stack>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Proveedores</FormLabel>
                        <Stack>
                          {values.camiones.map((camion, index) => (
                            <HStack py={2} key={camion.idCamion}>
                              <Checkbox
                                onChange={handleChange}
                                isChecked={values.camiones[index].rsv}
                                name={`camiones[${index}].rsv`}
                              >
                                RSV
                              </Checkbox>
                              <Checkbox
                                onChange={handleChange}
                                isChecked={values.camiones[index].microtrack}
                                name={`camiones[${index}].microtrack`}
                              >
                                Microtrack
                              </Checkbox>
                              <Checkbox
                                onChange={handleChange}
                                isChecked={values.camiones[index].geotab}
                                name={`camiones[${index}].geotab`}
                              >
                                Geotab
                              </Checkbox>
                            </HStack>
                          ))}
                        </Stack>
                      </FormControl>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </FieldArray>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearCamiones;
