import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  Box,
  IconButton,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Select,
  useSteps,
  InputGroup,
  InputLeftElement,
  Checkbox,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  MdArrowBack,
  MdFileDownloadDone,
  MdAddCircleOutline,
  MdNavigateBefore,
  MdOutlineAdd,
  MdOutlineAttachMoney,
  MdOutlineRemove,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import {
  getTransportista,
  editarTransportista,
  crearTransportista,
  crearCamiones,
} from "../../helpers/api.helper";
import {
  validateField,
  validateOnSubmit,
} from "../../../../utils/Validaciones";

const FormCrearEditarTransportista = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState(false);
  const [transportista, setTransportista] = useState([]);
  const [temp, setTemp] = useState([]);
  const [formValues, setFormValues] = useState({
    initialValues:
      type === "editar"
        ? {
            nombreEmpresaT: transportista.nombreEmpresaT,
            cuitCuilEmpresaT: transportista.cuitCuilEmpresaT,
            razonSocialEmpresaT: transportista.razonSocialEmpresaT,
            ciudadEmpresaT: transportista.ciudadEmpresaT,
            direccionEmpresaT: transportista.direccionEmpresaT,
            provinciaEmpresaT: transportista.provinciaEmpresaT,
            telefonoEmpresaT: transportista.telefonoEmpresaT,
            emaillEmpresaT: transportista.emaillEmpresaT,
            costoADemanda12: transportista.costoADemanda12,
            costoCompleto: transportista.costoCompleto,
            costoPenalizado: transportista.costoPenalizado,
            tipoContrato: transportista.tipoContrato,
            tipoContratoB: transportista.tipoContratoB,
            costoCompletoB: transportista.costoCompletoB,
            costoPenalizadoB: transportista.costoPenalizadoB,
            costoADemanda12B: transportista.costoADemanda12B,
          }
        : {
            nombreEmpresaT: "",
            cuitCuilEmpresaT: "",
            razonSocialEmpresaT: "",
            ciudadEmpresaT: "",
            direccionEmpresaT: "",
            provinciaEmpresaT: "",
            telefonoEmpresaT: "",
            emaillEmpresaT: "",
            costoADemanda12: "",
            costoCompleto: "",
            costoPenalizado: "",
            tipoContrato: "",
            tipoContratoB: "",
            costoCompletoB: "",
            costoPenalizadoB: "",
            costoADemanda12B: "",
          },
  });
  const [cantidadInputs, setCantidadInputs] = useState();
  const [showTipoContratoB, setShowTipoContratoB] = useState(false);
  const steps = [
    { title: "Empresa", description: "Información" },
    { title: "Camiones", description: "Agregar camiones" },
  ];
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const initData = async () => {
    setLoading(true);
    let res = await getTransportista(id);

    if (res.status === 200) {
      if (type === "editar") {
        setFormValues({
          initialValues: {
            nombreEmpresaT: res.data[0].nombreEmpresaT,
            cuitCuilEmpresaT: res.data[0].cuitCuilEmpresaT,
            razonSocialEmpresaT: res.data[0].razonSocialEmpresaT,
            ciudadEmpresaT: res.data[0].ciudadEmpresaT,
            direccionEmpresaT: res.data[0].direccionEmpresaT,
            provinciaEmpresaT: res.data[0].provinciaEmpresaT,
            telefonoEmpresaT: res.data[0].telefonoEmpresaT,
            emaillEmpresaT: res.data[0].emaillEmpresaT,
            costoADemanda12: res.data[0].costoADemanda12,
            costoCompleto: res.data[0].costoCompleto,
            costoPenalizado: res.data[0].costoPenalizado,
            tipoContrato: res.data[0].tipoContrato,
            tipoContratoB: res.data[0].tipoContratoB,
            costoCompletoB: res.data[0].costoCompletoB,
            costoPenalizadoB: res.data[0].costoPenalizadoB,
            costoADemanda12B: res.data[0].costoADemanda12B,
          },
        });
      }
      setTransportista(res.data[0]);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" overflow={"auto"}>
      <HStack px={5}>
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <HStack w="100%" justifyContent="space-between" pr={5} pb={5}>
          <HStack>
            <Heading
              fontWeight="medium"
              size="xl"
              fontFamily="fonts.title"
              color="brand.gris_primario"
            >
              {type === "editar" ? "Editar" : "Nueva"} empresa transportista
            </Heading>
          </HStack>
        </HStack>
      </HStack>
      {type === "crear" && (
        <Stepper w={"lg"} colorScheme={"orange"} index={activeStep} px={3}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      )}

      {!loading && activeStep === 0 && (
        <Formik
          initialValues={formValues.initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.nombreEmpresaT) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre de la empresa requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.cuitCuilEmpresaT) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "CUIT/CUIL requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarTransportista({
                nombreEmpresaT: values.nombreEmpresaT,
                cuitCuilEmpresaT: values.cuitCuilEmpresaT,
                razonSocialEmpresaT: values.razonSocialEmpresaT,
                ciudadEmpresaT: values.ciudadEmpresaT,
                direccionEmpresaT: values.direccionEmpresaT,
                provinciaEmpresaT: values.provinciaEmpresaT,
                telefonoEmpresaT: values.telefonoEmpresaT,
                emaillEmpresaT: values.emaillEmpresaT,
                costoADemanda12: values.costoADemanda12 || 0,
                costoCompleto: values.costoCompleto || 0,
                costoPenalizado: values.costoPenalizado || 0,
                tipoContrato: values.tipoContrato,
                tipoContratoB: values.tipoContratoB
                  ? values.tipoContratoB
                  : "ninguno",
                costoCompletoB: values.costoCompletoB
                  ? values.costoCompletoB
                  : 0,
                costoPenalizadoB: values.costoPenalizadoB
                  ? values.costoPenalizadoB
                  : 0,
                costoADemanda12B: values.costoADemanda12B
                  ? values.costoADemanda12B
                  : 0,
                idEmpresaT: transportista.idEmpresaT,
              });
            }
            if (type === "crear") {
              res = await crearTransportista({
                nombreEmpresaT: values.nombreEmpresaT,
                cuitCuilEmpresaT: values.cuitCuilEmpresaT,
                razonSocialEmpresaT: values.razonSocialEmpresaT,
                ciudadEmpresaT: values.ciudadEmpresaT,
                direccionEmpresaT: values.direccionEmpresaT,
                provinciaEmpresaT: values.provinciaEmpresaT,
                telefonoEmpresaT: values.telefonoEmpresaT,
                emaillEmpresaT: values.emaillEmpresaT,
                costoADemanda12: values.costoADemanda12 || 0,
                costoCompleto: values.costoCompleto || 0,
                costoPenalizado: values.costoPenalizado || 0,
                tipoContrato: values.tipoContrato,
                tipoContratoB: values.tipoContratoB,
                costoCompletoB: values.costoCompletoB || 0,
                costoPenalizadoB: values.costoPenalizadoB || 0,
                costoADemanda12B: values.costoADemanda12B || 0,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Transportista ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } transportista`,
                description:
                  res.data.error.message || "Error al editar transportista",
              });
            }

            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="formTransportista">
              <Stack px={3} spacing={3} w="100%">
                <HStack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="nombreEmpresaT"
                      validate={(value) =>
                        validateField("letras", value, "Nombre de la empresa")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.nombreEmpresaT && touched.nombreEmpresaT
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel>Empresa</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Nombre de la empresa"
                            type="text"
                            name="nombreEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nombreEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.nombreEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="cuitCuilEmpresaT"
                      validate={(value) =>
                        validateField("number", value, "CUIT/CUIL")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.cuitCuilEmpresaT && touched.cuitCuilEmpresaT
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel>CUIT/CUIL</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="CUIT/CUIL"
                            type="text"
                            name="cuitCuilEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cuitCuilEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.cuitCuilEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="razonSocialEmpresaT"
                      validate={(value) =>
                        validateField("letras", value, "Razón social")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.razonSocialEmpresaT &&
                            touched.razonSocialEmpresaT
                          }
                          w={"xs"}
                        >
                          <FormLabel>Razón social</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Razón social"
                            type="text"
                            name="razonSocialEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.razonSocialEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.razonSocialEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </HStack>
                <HStack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="telefonoEmpresaT"
                      validate={(value) =>
                        validateField("number", value, "Teléfono")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.telefonoEmpresaT && touched.telefonoEmpresaT
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel>Teléfono</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Nro. Teléfono"
                            type={"tel"}
                            name="telefonoEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telefonoEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.telefonoEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="emaillEmpresaT"
                      validate={(value) =>
                        validateField("email", value, "Email")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.emaillEmpresaT && touched.emaillEmpresaT
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel>Email</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Email"
                            type="text"
                            name="emaillEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emaillEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.emaillEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <FormControl w={"xs"}></FormControl>
                </HStack>
                <HStack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="direccionEmpresaT"
                      validate={(value) =>
                        validateField("letras", value, "Calle")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.direccionEmpresaT &&
                            touched.direccionEmpresaT
                          }
                          w={"xs"}
                        >
                          <FormLabel>Calle</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Dirección"
                            type="text"
                            name="direccionEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.direccionEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.direccionEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="ciudadEmpresaT"
                      validate={(value) =>
                        validateField("letras", value, "Cuidad")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.ciudadEmpresaT && touched.ciudadEmpresaT
                          }
                          w={"xs"}
                        >
                          <FormLabel>Cuidad</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Cuidad"
                            type="text"
                            name="ciudadEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ciudadEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.ciudadEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="provinciaEmpresaT"
                      validate={(value) =>
                        validateField("letras", value, "Provincia")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.provinciaEmpresaT &&
                            touched.provinciaEmpresaT
                          }
                          w={"xs"}
                        >
                          <FormLabel>Provincia</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Provincia"
                            type="text"
                            name="provinciaEmpresaT"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.provinciaEmpresaT}
                          />
                          <FormErrorMessage>
                            {errors.provinciaEmpresaT}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </HStack>
                <HStack>
                  <FormControl w={"xs"} placeSelf={"start"}>
                    <FormLabel>Tipo de contrato</FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="- Selecciona un tipo -"
                      type="text"
                      name="tipoContrato"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoContrato}
                    >
                      {values.tipoContratoB !== "MENSUAL" && (
                        <option value={"MENSUAL"}>Mensual</option>
                      )}
                      {values.tipoContratoB !== "A DEMANDA" && (
                        <option value={"A DEMANDA"}>A Demanda</option>
                      )}
                      {values.tipoContratoB !== "FLOTA PROPIA" &&
                        !showTipoContratoB && (
                          <option value={"FLOTA PROPIA"}>Flota Propia</option>
                        )}
                    </Select>
                  </FormControl>

                  {values.tipoContrato === "MENSUAL" && (
                    <>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoCompleto"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoCompleto && touched.costoCompleto
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Completo</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="Mensual"
                                  type={"number"}
                                  name="costoCompleto"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoCompleto}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoCompleto}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoPenalizado"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoPenalizado &&
                                touched.costoPenalizado
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Penalizado</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="Penalizado mensual"
                                  type={"number"}
                                  name="costoPenalizado"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoPenalizado}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoPenalizado}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoStandBy"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoStandBy && touched.costoStandBy
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Standby</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="StandBy mensual"
                                  type={"number"}
                                  name="costoStandBy"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoStandBy}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoStandBy}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                    </>
                  )}

                  {values.tipoContrato === "A DEMANDA" && (
                    <>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoCompleto"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoCompleto && touched.costoCompleto
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Completo</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="A demanda 24"
                                  type={"number"}
                                  name="costoCompleto"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoCompleto}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoCompleto}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoPenalizado"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoPenalizado &&
                                touched.costoPenalizado
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Penalizado</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="Penalidad a demanda 24"
                                  type={"number"}
                                  name="costoPenalizado"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoPenalizado}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoPenalizado}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoADemanda12"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoADemanda12 &&
                                touched.costoADemanda12
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo A demanda 12</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="A demanda 12"
                                  type={"number"}
                                  name="costoADemanda12"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoADemanda12}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoADemanda12}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                    </>
                  )}

                  {values.tipoContrato === "FLOTA PROPIA" && (
                    <>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoCompleto"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoCompleto && touched.costoCompleto
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Completo</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="Flota propia"
                                  type={"number"}
                                  name="costoCompleto"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoCompleto}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoCompleto}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Stack placeSelf={"start"}>
                        <Field
                          name="costoPenalizado"
                          validate={(value) =>
                            validateField("number", value, "Costo")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              alignSelf="start"
                              isInvalid={
                                errors.costoPenalizado &&
                                touched.costoPenalizado
                              }
                              w={"3xs"}
                            >
                              <FormLabel>Costo Penalizado</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <MdOutlineAttachMoney color="gray.300" />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  placeholder="Penalizado Flota propia"
                                  type={"number"}
                                  name="costoPenalizado"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.costoPenalizado}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {errors.costoPenalizado}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                    </>
                  )}
                </HStack>
                {showTipoContratoB && (
                  <HStack>
                    <FormControl w={"xs"} placeSelf={"start"}>
                      <FormLabel>Tipo de contrato</FormLabel>
                      <Select
                        w={"xs"}
                        placeholder="- Selecciona un tipo -"
                        type="text"
                        name="tipoContratoB"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tipoContratoB}
                      >
                        {values.tipoContrato !== "MENSUAL" && (
                          <option value={"MENSUAL"}>Mensual</option>
                        )}
                        {values.tipoContrato !== "A DEMANDA" && (
                          <option value={"A DEMANDA"}>A Demanda</option>
                        )}
                      </Select>
                    </FormControl>
                    {values.tipoContratoB === "MENSUAL" && (
                      <>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoCompletoB"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoCompletoB &&
                                  touched.costoCompletoB
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo Completo</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="Mensual"
                                    type={"number"}
                                    name="costoCompletoB"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoCompletoB}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoCompletoB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoPenalizadoB"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoPenalizadoB &&
                                  touched.costoPenalizadoB
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo Penalizado</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="Penalizado mensual"
                                    type={"number"}
                                    name="costoPenalizadoB"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoPenalizadoB}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoPenalizadoB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoStandByB"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoStandByB && touched.costoStandByB
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo Standby</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="StandBy mensual"
                                    type={"number"}
                                    name="costoStandByB"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoStandByB}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoStandByB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </>
                    )}
                    {values.tipoContratoB === "A DEMANDA" && (
                      <>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoCompletoB"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoCompletoB &&
                                  touched.costoCompletoB
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo Completo</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="A demanda 24"
                                    type={"number"}
                                    name="costoCompletoB"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoCompletoB}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoCompletoB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoPenalizadoB"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoPenalizadoB &&
                                  touched.costoPenalizadoB
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo Penalizado</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="Penalidad a demanda 24"
                                    type={"number"}
                                    name="costoPenalizadoB"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoPenalizadoB}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoPenalizadoB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="costoADemanda12B"
                            validate={(value) =>
                              validateField("number", value, "Costo")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                alignSelf="start"
                                isInvalid={
                                  errors.costoADemanda12B &&
                                  touched.costoADemanda12B
                                }
                                w={"3xs"}
                              >
                                <FormLabel>Costo A demanda 12</FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none">
                                    <MdOutlineAttachMoney color="gray.300" />
                                  </InputLeftElement>
                                  <Input
                                    {...field}
                                    placeholder="A demanda 12"
                                    type={"number"}
                                    name="costoADemanda12B"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.costoADemanda12B}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.costoADemanda12B}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </>
                    )}
                  </HStack>
                )}
                <HStack
                  w={"100%"}
                  justifyContent={
                    values.tipoContrato !== "FLOTA PROPIA"
                      ? "space-between"
                      : "end"
                  }
                >
                  {values.tipoContrato !== "FLOTA PROPIA" && (
                    <Button
                      color="brand.naranja"
                      fontSize="12px"
                      variant="link"
                      size="lg"
                      leftIcon={
                        showTipoContratoB === false ? (
                          <MdOutlineAdd size={18} color="#FF8200" />
                        ) : (
                          <MdOutlineRemove size={18} color="#FF8200" />
                        )
                      }
                      onClick={() => setShowTipoContratoB(!showTipoContratoB)}
                      textColor="black"
                    >
                      {showTipoContratoB === false ? "Agregar" : "Quitar"} Tipo
                      de contrato
                    </Button>
                  )}
                  <HStack>
                    {type === "crear" && (
                      <Button
                        size={"md"}
                        variant="outline"
                        border="1px solid #FF8200"
                        leftIcon={<MdAddCircleOutline fontSize="20px" />}
                        color="brand.naranja"
                        onClick={() => {
                          if (
                            !values.nombreEmpresaT ||
                            validateOnSubmit("letras", values.nombreEmpresaT)
                          ) {
                            setLoading(false);
                            return toast({
                              title: "Error",
                              description: "Nombre de la empresa requerido",
                              status: "error",
                              isClosable: true,
                              duration: 3000,
                            });
                          }
                          if (
                            !values.cuitCuilEmpresaT ||
                            validateOnSubmit("number", values.cuitCuilEmpresaT)
                          ) {
                            setLoading(false);
                            return toast({
                              title: "Error",
                              description: "CUIT/CUIL requerido",
                              status: "error",
                              isClosable: true,
                              duration: 3000,
                            });
                          }

                          if (
                            (values.razonSocialEmpresaT &&
                              validateOnSubmit(
                                "letras",
                                values.razonSocialEmpresaT
                              )) ||
                            (values.telefonoEmpresaT &&
                              validateOnSubmit(
                                "number",
                                values.telefonoEmpresaT
                              )) ||
                            (values.emaillEmpresaT &&
                              validateOnSubmit(
                                "email",
                                values.emaillEmpresaT
                              )) ||
                            (values.direccionEmpresaT &&
                              validateOnSubmit(
                                "letras",
                                values.direccionEmpresaT
                              )) ||
                            (values.ciudadEmpresaT &&
                              validateOnSubmit(
                                "letras",
                                values.ciudadEmpresaT
                              )) ||
                            (values.direccionEmpresaT &&
                              validateOnSubmit(
                                "letras",
                                values.direccionEmpresaT
                              )) ||
                            (values.provinciaEmpresaT &&
                              validateOnSubmit(
                                "letras",
                                values.provinciaEmpresaT
                              )) ||
                            (values.costoCompleto &&
                              validateOnSubmit(
                                "number",
                                values.costoCompleto
                              )) ||
                            (values.costoPenalizado &&
                              validateOnSubmit(
                                "number",
                                values.costoPenalizado
                              )) ||
                            (values.costoStandBy &&
                              validateOnSubmit(
                                "number",
                                values.costoStandBy
                              )) ||
                            (values.costoADemanda12 &&
                              validateOnSubmit(
                                "number",
                                values.costoADemanda12
                              )) ||
                            (values.costoCompletoB &&
                              validateOnSubmit(
                                "number",
                                values.costoCompletoB
                              )) ||
                            (values.costoPenalizadoB &&
                              validateOnSubmit(
                                "number",
                                values.costoPenalizadoB
                              )) ||
                            (values.costoStandByB &&
                              validateOnSubmit(
                                "number",
                                values.costoStandByB
                              )) ||
                            (values.costoADemanda12B &&
                              validateOnSubmit(
                                "number",
                                values.costoADemanda12B
                              ))
                          ) {
                            setLoading(false);
                            return toast({
                              title: "Error",
                              description: "Datos incorrectos",
                              status: "error",
                              isClosable: true,
                              duration: 3000,
                            });
                          }

                          setTemp(values);
                          setActiveStep(activeStep + 1);
                        }}
                        _hover={{ bg: "brand.naranja", color: "white" }}
                      >
                        Agregar camiones
                      </Button>
                    )}
                    <Button
                      rightIcon={<MdFileDownloadDone fontSize="20px" />}
                      form="formTransportista"
                      onClick={handleSubmit}
                      isLoading={loading}
                      isDisabled={user.idRol === "usuarioIT"}
                      _hover={{ bg: "white", color: "brand.naranja" }}
                      bg={"brand.naranja"}
                      color={"white"}
                      size={"md"}
                    >
                      Finalizar
                    </Button>
                  </HStack>
                </HStack>
              </Stack>
            </Form>
          )}
        </Formik>
      )}

      {!loading && activeStep === 1 && (
        <Formik
          initialValues={{
            rowCount: cantidadInputs,
            camiones: [
              {
                nombreCamion: "",
                dominio: "",
                idRSV: "",
              },
            ],
          }}
          onSubmit={async (values) => {
            setLoading(true);
            let res;
            let response;

            res = await crearTransportista({
              nombreEmpresaT: temp.nombreEmpresaT,
              cuitCuilEmpresaT: temp.cuitCuilEmpresaT,
              razonSocialEmpresaT: temp.razonSocialEmpresaT,
              ciudadEmpresaT: temp.ciudadEmpresaT,
              direccionEmpresaT: temp.direccionEmpresaT,
              provinciaEmpresaT: temp.provinciaEmpresaT,
              telefonoEmpresaT: temp.telefonoEmpresaT,
              emaillEmpresaT: temp.emaillEmpresaT,
              costoADemanda12: temp.costoADemanda12 || 0,
              costoCompleto: temp.costoCompleto || 0,
              costoPenalizado: temp.costoPenalizado || 0,
              costoStandBy: temp.costoStandBy || 0,
              tipoContrato: temp.tipoContrato,
              tipoContratoB: temp.tipoContratoB,
              costoCompletoB: temp.costoCompletoB || 0,
              costoPenalizadoB: temp.costoPenalizadoB || 0,
              costoADemanda12B: temp.costoADemanda12B || 0,
            });

            if (res.status === 200) {
              response = await getTransportista({
                nombre: temp.nombreEmpresaT,
              });
            }
            let parserdCamiones = values.camiones.map((camion) => {
              let tempProv = [];

              if (camion.rsv) {
                tempProv.push({
                  idProveedor: 1,
                  activada: camion.rsv ? 1 : 0,
                });
              }
              if (camion.microtrack) {
                tempProv.push({
                  idProveedor: 2,
                  activada: camion.microtrack ? 1 : 0,
                });
              }
              if (camion.geotab) {
                tempProv.push({
                  idProveedor: 3,
                  activada: camion.geotab ? 1 : 0,
                });
              }

              return {
                nombreCamion: camion.nombreCamion,
                dominio: camion.dominio,
                tipoContratoCamion: camion.tipoContratoCamion,
                idEmpresaT: response.data[0].idEmpresaT,
                proveedores: tempProv,
              };
            });

            res = await crearCamiones({ camiones: parserdCamiones });

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: "Empresa y Camiones creados correctamente",
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: "Error al crear",
                description:
                  res.data.error.message || "Error al crear camiones",
              });
            }
            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form id="formAgregarCamiones">
              <HStack w={"100%"} justifyContent={"space-between"} px={3}>
                <FormControl w={"xs"}>
                  <FormLabel>Cantidad</FormLabel>
                  <Input
                    name="rowCount"
                    type="number"
                    value={values.rowCount || 1}
                    onChange={(e) => {
                      const rowCount = parseInt(e.target.value) || 1;

                      const existingCamiones = values.camiones || [];
                      const newCamiones = [...existingCamiones];

                      if (rowCount < existingCamiones.length) {
                        newCamiones.splice(rowCount);
                      } else {
                        for (
                          let i = existingCamiones.length;
                          i < rowCount;
                          i++
                        ) {
                          newCamiones.push({
                            camiones: {
                              idCamion: "",
                              dominio: "",
                              tipoContratoCamion: "",
                              tipoContrato: "",
                            },
                            proveedores: {
                              proveedores: [false, false, false],
                              proveedorDef: "",
                            },
                          });
                        }
                      }
                      setFieldValue("rowCount", rowCount);
                      setFieldValue("camiones", newCamiones);
                    }}
                  />
                </FormControl>
                <HStack>
                  <Button
                    leftIcon={<MdNavigateBefore />}
                    color="brand.naranja"
                    bg="brand.fondos_secundarios"
                    border={"1px solid "}
                    isLoading={loading}
                    size="md"
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Atras
                  </Button>
                  <Button
                    placeSelf="end"
                    rightIcon={<MdFileDownloadDone />}
                    bg="brand.naranja"
                    form="formAgregarCamiones"
                    color="white"
                    isLoading={loading}
                    isDisabled={
                      !values.camiones[0].nombreCamion ||
                      user.idRol === "usuarioIT"
                    }
                    size="md"
                    onClick={handleSubmit}
                  >
                    Finalizar
                  </Button>
                </HStack>
              </HStack>
              <FieldArray name="camiones">
                {() => (
                  <HStack p={3}>
                    <Stack placeSelf={"start"} m={"unset"}>
                      <FormLabel fontWeight="semibold">ID Interno</FormLabel>
                      {values.camiones.map((camion, index) => (
                        <Field
                          key={index}
                          name={`camiones[${index}].nombreCamion`}
                          validate={(value) =>
                            validateField(
                              "numerosLetras",
                              value,
                              `Camión ${index + 1}`
                            )
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              isInvalid={
                                touched.camiones &&
                                touched.camiones[index]?.nombreCamion &&
                                errors.camiones &&
                                errors.camiones[index]?.nombreCamion
                              }
                              w={"xs"}
                              isRequired
                            >
                              <Input
                                {...field}
                                w={"150px"}
                                placeholder="ID Interno"
                                name={`camiones[${index}].nombreCamion`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.camiones[index].nombreCamion}
                              />

                              <FormErrorMessage>
                                {touched.camiones &&
                                  touched.camiones[index]?.nombreCamion &&
                                  errors.camiones &&
                                  errors.camiones[index]?.nombreCamion}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      ))}
                    </Stack>
                    <Stack placeSelf={"start"} m={"unset"}>
                      <FormLabel>Dominio</FormLabel>
                      {values.camiones.map((camion, index) => (
                        <Field
                          key={index}
                          name={`camiones[${index}].dominio`}
                          validate={(value) =>
                            validateField(
                              "numerosLetras",
                              value,
                              `Dominio ${index + 1}`
                            )
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              isInvalid={
                                touched.camiones &&
                                touched.camiones[index]?.dominio &&
                                errors.camiones &&
                                errors.camiones[index]?.dominio
                              }
                              w={"xs"}
                              isRequired
                            >
                              <Input
                                {...field}
                                w={"150px"}
                                placeholder="Dominio"
                                name={`camiones[${index}].dominio`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.camiones[index].dominio}
                              />

                              <FormErrorMessage>
                                {touched.camiones &&
                                  touched.camiones[index]?.dominio &&
                                  errors.camiones &&
                                  errors.camiones[index]?.dominio}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      ))}
                    </Stack>
                    <FormControl placeSelf={"start"}>
                      <Stack>
                        <FormLabel>Tipo contrato</FormLabel>
                        {values.camiones.map((camion, index) => (
                          <Select
                            key={camion.idCamion}
                            w={"3xs"}
                            placeholder="- Tipo contrato -"
                            name={`camiones[${index}].tipoContratoCamion`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.camiones[index].tipoContratoCamion}
                          >
                            <option>{temp.tipoContrato}</option>
                            {temp.tipoContratoB && (
                              <option>{temp.tipoContratoB}</option>
                            )}
                          </Select>
                        ))}
                      </Stack>
                    </FormControl>
                    <FormControl placeSelf={"start"}>
                      <Stack>
                        <FormLabel>Proveedores</FormLabel>
                        {values.camiones.map((_, index) => (
                          <HStack py={2} key={index}>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].rsv}
                              name={`camiones[${index}].rsv`}
                            >
                              RSV
                            </Checkbox>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].microtrack}
                              name={`camiones[${index}].microtrack`}
                            >
                              Microtrack
                            </Checkbox>
                            <Checkbox
                              onChange={handleChange}
                              isChecked={values.camiones[index].geotab}
                              name={`camiones[${index}].geotab`}
                            >
                              Geotab
                            </Checkbox>
                          </HStack>
                        ))}
                      </Stack>
                    </FormControl>
                  </HStack>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarTransportista;
