import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Stack,
  HStack,
  Input,
  Select,
  Box,
  useToast,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineCreate, MdOutlineSave, MdRecycling } from "react-icons/md";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { Form, Formik } from "formik";
import { editarGeocercas } from "../helpers/api.helper";

const EditarGeocerca = (props) => {
  const {
    geocerca,
    initData,
    planificaciones,
    plantas,
    seleccionado,
    setSeleccionado,
    user,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [map, setMap] = useState(null);
  const [puntos, setPuntos] = useState(geocerca.puntos || []);
  const [inCenter, setInCenter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({
    lat: geocerca.latitudCentro,
    lng: geocerca.longitudCentro,
  });
  const mapRef = useRef();
  const polygonRef = useRef(null);
  const toast = useToast();

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: "#FF0000",
    fillOpacity: 0.5,
  };

  const handleClose = () => {
    onClose();
  };

  const handleMapClick = (e, setFieldValue) => {
    const { latLng } = e;
    const newLat = latLng.lat();
    const newLng = latLng.lng();
    let factor = 100000;
    let latitudEntera = Math.round(newLat * factor);
    let longitudEntera = Math.round(newLng * factor);
    let latitudDeNuevo = latitudEntera / factor;
    let longitudDeNuevo = longitudEntera / factor;
    // let newPoint = {
    //   lat: latitudDeNuevo,
    //   lng: longitudDeNuevo,
    // };

    if (!inCenter) {
      setCenter({ lat: newLat, lng: newLng });
      setInCenter(true);
      setFieldValue("latitudCentro", latitudDeNuevo);
      setFieldValue("longitudCentro", longitudDeNuevo);
    } else {
      const path = [...puntos, { lat: latitudDeNuevo, lng: longitudDeNuevo }];
      setPuntos(path);
    }
  };

  const handleArrastre = (e) => {
    const { latLng } = e;
    const newLat = latLng.lat();
    const newLng = latLng.lng();
    let factor = 100000;
    let latitudEntera = Math.round(newLat * factor);
    let longitudEntera = Math.round(newLng * factor);
    let latitudDeNuevo = latitudEntera / factor;
    let longitudDeNuevo = longitudEntera / factor;
    let newPoint = {
      lat: latitudDeNuevo,
      lng: longitudDeNuevo,
    };
    let puntosActualizados = [...puntos]; // Hacemos una copia del array 'puntos'

    if (e.vertex >= 0 && e.vertex < puntosActualizados.length) {
      puntosActualizados[e.vertex] = newPoint; // Reemplazamos el punto en la posición 'e.vertex'
    } else {
      console.error(`Índice ${e.vertex} fuera de rango.`);
    }

    setPuntos(puntosActualizados);
  };

  const handleClean = () => {
    setPuntos([]);
    setInCenter(false);
  };

  let initialValues = {
    nombreGeo: geocerca.nombreGeo,
    longitudCentro: geocerca.longitudCentro,
    latitudCentro: geocerca.latitudCentro,
    tipoGeo: geocerca.tipoGeo,
    puntos: puntos,
    planificacion: geocerca.planificacion,
    idPlanta: geocerca.idPlanta,
  };

  return (
    <>
      <Tooltip label="Editar Geocerca">
        <IconButton
          leftIcon={<MdOutlineCreate fontSize="20px" />}
          variant="link"
          onClick={() => onOpen()}
          isDisabled={user.idRol === "usuarioIT"}
          color={
            seleccionado && seleccionado.idGeocerca === geocerca.idGeocerca
              ? "brand.fondos_secundarios"
              : "gray.500"
          }
          _hover={{ color: "brand.naranja" }}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={handleClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar {geocerca.nombreGeo}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);

                if (!values.nombreGeo) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Nombre de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.tipoGeo) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Tipo de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.latitudCentro) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Latitud de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!values.longitudCentro) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "Longitud de la geocerca requerido",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }
                if (!puntos[2]) {
                  setLoading(false);
                  return toast({
                    title: "Error",
                    description: "El área de la geocerca debe estar definida",
                    status: "error",
                    isClosable: true,
                    duration: 3000,
                  });
                }

                let puntosEditar =
                  puntos.length > 0 &&
                  puntos.map((element) => ({
                    idGeocerca: geocerca.idGeocerca,
                    lat: element.lat,
                    lng: element.lng,
                  }));

                let data =
                  !values.planificacion && values.idPlanta
                    ? {
                        id: geocerca.idGeocerca,
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                        idPlanta: parseInt(values.idPlanta),
                      }
                    : !values.idPlanta && values.planificacion
                    ? {
                        id: geocerca.idGeocerca,
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                        planificacion: values.planificacion,
                        idPlanificacion: geocerca.idPlanificacion,
                      }
                    : {
                        id: geocerca.idGeocerca,
                        nombreGeo: values.nombreGeo,
                        longitudCentro: values.longitudCentro,
                        latitudCentro: values.latitudCentro,
                        tipoGeo: values.tipoGeo,
                      };

                let res;
                res = await editarGeocercas(data);
                if (puntosEditar.length > 0) {
                  res = await editarGeocercas({
                    puntosGeoCreate: puntosEditar,
                  });
                }

                if (res.status === 200) {
                  toast({
                    status: "success",
                    isClosable: true,
                    title: "Geocerca editada correctamente",
                    duration: 3000,
                  });
                  setSeleccionado([]);
                  resetForm();
                  initData();
                  onClose();
                } else {
                  setLoading(false);
                  toast({
                    status: "error",
                    isClosable: true,
                    title: "Error al editar geocerca",
                    description: res.resMessage || "Error al editar geocerca",
                  });
                }
                setLoading(false);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <Stack w={"100%"} pt={1} pb={2}>
                    <Box w={"100%"} h={"sm"} bg={"black"}>
                      <GoogleMap
                        center={center}
                        zoom={14}
                        map={mapRef}
                        onLoad={(map) => setMap(map)}
                        mapContainerStyle={{
                          width: "100%",
                          height: "100%",
                        }}
                        options={{
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                          mapTypeId: "satellite",
                        }}
                        onClick={(e) => {
                          handleMapClick(e, setFieldValue);
                          setInCenter(true);
                        }}
                      >
                        {center && (
                          <Marker
                            map={map}
                            position={center}
                            draggable={false}
                          />
                        )}

                        <Polygon
                          ref={polygonRef}
                          options={options}
                          map={map}
                          paths={puntos}
                          editable
                          draggable
                          onMouseUp={(e) => handleArrastre(e, setFieldValue)}
                        ></Polygon>
                      </GoogleMap>
                    </Box>
                    <HStack>
                      <Input
                        size={"sm"}
                        placeholder={"Nombre"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"nombreGeo"}
                        value={values.nombreGeo}
                        isRequired
                      />
                      <Select
                        size={"sm"}
                        placeholder={"Tipo"}
                        value={values.tipoGeo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"tipoGeo"}
                        isRequired
                      >
                        <option value="Base">Base</option>
                        <option value="PAD">PAD</option>
                        <option value="Planta">Planta</option>
                        <option value="Ref">Punto de referencia</option>
                        <option value="baseTransportista">
                          Base Transportista
                        </option>
                        <option value="estacionServicio">
                          Estación Servicio
                        </option>
                        <option value="barrera">Barrera</option>
                        <option value="cercaniaPlanta">Cercanía planta</option>
                      </Select>
                      {values.tipoGeo === "PAD" && (
                        <Select
                          size={"sm"}
                          placeholder={"Seleccione Planificación"}
                          value={values.planificacion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"planificacion"}
                        >
                          {planificaciones.map((planificacion) => (
                            <option key={planificacion.idPlanificacion}>
                              {planificacion.idPadlocacion}
                            </option>
                          ))}
                        </Select>
                      )}
                      {values.tipoGeo === "Planta" && (
                        <Select
                          size={"sm"}
                          placeholder={"Seleccione Planta"}
                          value={values.idPlanta}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={"idPlanta"}
                        >
                          {plantas
                            .filter((e) => !e.geocerca)
                            .map((planta) => (
                              <option
                                key={planta.idPlanta}
                                value={planta.idPlanta}
                              >
                                {planta.nombrePlanta}
                              </option>
                            ))}
                        </Select>
                      )}
                      <Input
                        size={"sm"}
                        placeholder={"Latitud"}
                        defaultValue={values.latitudCentro}
                        type={"number"}
                        name={"latitudCentro"}
                        isRequired
                      />
                      <Input
                        size={"sm"}
                        placeholder={"Longitud"}
                        defaultValue={values.longitudCentro}
                        type={"number"}
                        name={"longitudCentro"}
                        isRequired
                      />
                    </HStack>
                    <HStack justifyContent={"space-between"}>
                      <Tooltip label={"Limpiar marcas"}>
                        <IconButton
                          size="sm"
                          bg={"red"}
                          color={"white"}
                          icon={<MdRecycling fontSize={"20px"} />}
                          onClick={handleClean}
                        />
                      </Tooltip>
                      <Button
                        size="sm"
                        bg={"brand.naranja"}
                        color={"white"}
                        leftIcon={<MdOutlineSave fontSize={"20px"} />}
                        onClick={handleSubmit}
                        isDisabled={loading}
                      >
                        Guardar
                      </Button>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarGeocerca;
