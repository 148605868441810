import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  IconButton,
  Heading,
  useToast,
  Text,
  Button,
  Tooltip,
  CircularProgress,
  Select,
} from "@chakra-ui/react";
import {
  MdArrowBack,
  MdAddCircleOutline,
  MdOutlineCreate,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import EliminarCamion from "./EliminarCamion";
import { getCamiones } from "../../../operaciones/helpers/api.helper";
import { getTransportista } from "../../helpers/api.helper";

const TablaCamiones = (props) => {
  const { user } = props;
  const [camiones, setCamiones] = useState([]);
  const [transportista, setTransportista] = useState([]);
  const [editarOn, setEditarOn] = useState(Array(camiones.length).fill(false));
  const [loading, setLoading] = useState(false);
  const { nombreTransportista } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getCamiones({ empresa: nombreTransportista });

    if (res.status === 200) {
      setCamiones(res.data);
    } else if (res.status === 404) {
      toast({
        title: "Exito",
        description: "No existen camiones para esta empresa",
        duration: 3000,
        isClosable: true,
        status: "info",
      });
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los camiones",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }

    res = await getTransportista({ nombre: nombreTransportista });

    if (res.status === 200) {
      setTransportista(res.data[0]);
    }

    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="xl">
      <HStack w="100%" justifyContent="space-between" px={5}>
        <HStack>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />

          <HStack>
            <Heading
              fontWeight="medium"
              size="xl"
              fontFamily="fonts.title"
              color="brand.gris_primario"
            >
              Camiones
            </Heading>
            <Text placeSelf={"end"}>de {nombreTransportista}</Text>
          </HStack>
        </HStack>
        <Button
          size="sm"
          variant="outline"
          border="1px solid #FF8200"
          leftIcon={<MdAddCircleOutline fontSize="20px" />}
          color="brand.naranja"
          onClick={() =>
            navigate(
              `/inventario/transportista/tablaCamiones/${nombreTransportista}/agregarCamiones`
            )
          }
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Agregar camiones
        </Button>
      </HStack>
      {loading && loadingtable()}
      {!loading && camiones.length > 0 && (
        <>
          <TableContainer maxH={"100%"} overflowY="auto">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>ID Camion</Th>
                  <Th>Proveedor de Seguimiento</Th>
                  <Th>Dominio</Th>
                  <Th>Tipo contrato</Th>
                  <Th>Costo Completo</Th>
                  <Th>Costo Penalizado</Th>
                  <Th>Ubicacion</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>

              <Tbody>
                {camiones.map((camion, index) => (
                  <Tr justifyContent={"space-between"} key={camion.idCamion}>
                    <Td>{camion.nombreCamion}</Td>
                    <Td>
                      <HStack>
                        {!editarOn[index] && camion.proveedores && (
                          <Text>
                            {camion.proveedores
                              .filter((e) => e.activada === true)
                              .map((e) => e.nombreProveedor)
                              .join(" - ")}
                          </Text>
                        )}
                        {editarOn[index] && camion.proveedores && (
                          <Select variant={"flushed"}>
                            {camion.proveedores.map((proveedor, index) => (
                              <option key={index}>
                                {proveedor.nombreProveedor}
                              </option>
                            ))}
                          </Select>
                        )}
                      </HStack>
                    </Td>
                    <Td>{camion.dominio}</Td>
                    <Td>{camion.tipoContratoCamion}</Td>
                    <Td textAlign={"center"}>
                      {camion.tipoContratoCamion ===
                        camion.empresaTransportista.tipoContrato &&
                        camion.empresaTransportista.costoCompleto}
                      {camion.tipoContratoCamion ===
                        camion.empresaTransportista.tipoContratoB &&
                        camion.empresaTransportista.costoCompletoB}
                    </Td>
                    <Td textAlign={"center"}>
                      {camion.tipoContratoCamion ===
                        transportista.tipoContrato &&
                        camion.empresaTransportista.costoPenalizado}
                      {camion.tipoContratoCamion ===
                        camion.empresaTransportista.costoPenalizadoB &&
                        camion.empresaTransportista.costoCompletoB}
                    </Td>
                    <Td>{camion.ubicacionCamion || "-"}</Td>
                    <Td>
                      <Tooltip label="Editar Camión">
                        <IconButton
                          variant="link"
                          icon={<MdOutlineCreate fontSize="20px" />}
                          isDisabled={user.idRol === "usuarioIT"}
                          onClick={() =>
                            navigate(
                              `/inventario/transportista/tablaCamiones/${nombreTransportista}/editarCamion/${camion.idCamion}`
                            )
                          }
                          _hover={{ color: "brand.naranja" }}
                        />
                      </Tooltip>
                      <EliminarCamion
                        camion={camion}
                        initData={initData}
                        user={user}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      {!loading && camiones.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default TablaCamiones;
