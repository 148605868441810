import React, { useState, useEffect } from "react";
import {
  HStack,
  FormControl,
  Text,
  Stack,
  Button,
  Select,
  useToast,
  FormLabel,
  Input,
  Heading,
  Switch,
  Card,
  CardBody,
  CardFooter,
  Textarea,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { MdNavigateNext, MdArrowDropDown, MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionGeneradores,
  editarRevisionGeneradores,
  getPlanificaciones,
  getRevisionGeneradores,
} from "../../../helpers/api.helper";
import { validateField } from "../../../../../utils/Validaciones";
import moment from "moment";

const RevisionGeneradores = (props) => {
  const { tipo, user } = props;
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [editable, setEditable] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { padLocacion } = useParams();
  const { id } = useParams();

  const initUsarios = async () => {
    let res;

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getPlanificaciones({ padLocacion: padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    }

    res = await getRevisionGeneradores();

    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }

      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.find((e) => e.idRevisionGenerador === parseInt(id));

          return a;
        });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    initUsarios();
  }, []);

  let initialValues =
    tipo === "editar"
      ? {
          nivelRefrigeranteRG: editable.nivelRefrigeranteRG,
          nivelAceiteRG: editable.nivelAceiteRG,
          nivelCombustibleRG: editable.nivelCombustibleRG,
          filtroAireRG: editable.filtroAireRG,
          horometroRG: editable.horometroRG,
          voltajeRG: editable.voltajeRG,
          frecuenciaRG: editable.frecuenciaRG,
          presionAceiteRG: editable.presionAceiteRG,
          temperaturaRG: editable.temperaturaRG,
          fallaDisplayRG: editable.fallaDisplayRG,
          correaRG: editable.correaRG,
          manguerasRG: editable.manguerasRG,
          cargadorBateriasRG: editable.cargadorBateriasRG,
          bornesRG: editable.bornesRG,
          disyuntorRG: editable.disyuntorRG,
          observacionesRG: editable.observacionesRG,
          responsable: editable.responsable,
          fechaRevision: editable.fechaRevision,
        }
      : {
          nivelRefrigeranteRG: false,
          nivelAceiteRG: false,
          nivelCombustibleRG: false,
          filtroAireRG: false,
          horometroRG: false,
          voltajeRG: false,
          frecuenciaRG: false,
          presionAceiteRG: false,
          temperaturaRG: false,
          fallaDisplayRG: false,
          correaRG: false,
          manguerasRG: false,
          cargadorBateriasRG: false,
          bornesRG: false,
          disyuntorRG: false,
          observacionesRG: "",
          responsable: "",
          fechaRevision: "",
        };

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading px={1} size={"sm"}>
          Revision Generador
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.responsable) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Responsable requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let data =
              tipo === "editar"
                ? {
                    responsable: values.responsable,
                    id: editable.idRevisionGenerador,
                    idPlanificacion: planificacion.idPlanificacion,
                    nivelRefrigeranteRG: values.nivelRefrigeranteRG,
                    nivelAceiteRG: values.nivelAceiteRG,
                    nivelCombustibleRG: values.nivelCombustibleRG,
                    filtroAireRG: values.filtroAireRG,
                    horometroRG: values.horometroRG,
                    voltajeRG: values.voltajeRG,
                    frecuenciaRG: values.frecuenciaRG,
                    presionAceiteRG: values.presionAceiteRG,
                    temperaturaRG: values.temperaturaRG,
                    fallaDisplayRG: values.fallaDisplayRG,
                    correaRG: values.correaRG,
                    manguerasRG: values.manguerasRG,
                    cargadorBateriasRG: values.cargadorBateriasRG,
                    bornesRG: values.bornesRG,
                    disyuntorRG: values.disyuntorRG,
                    equipoRevision: "Generador",
                    observacionesRG: values.observacionesRG,
                  }
                : {
                    responsable: values.responsable,
                    idPlanificacion: planificacion.idPlanificacion,
                    nivelRefrigeranteRG: values.nivelRefrigeranteRG,
                    nivelAceiteRG: values.nivelAceiteRG,
                    nivelCombustibleRG: values.nivelCombustibleRG,
                    filtroAireRG: values.filtroAireRG,
                    horometroRG: values.horometroRG,
                    voltajeRG: values.voltajeRG,
                    frecuenciaRG: values.frecuenciaRG,
                    presionAceiteRG: values.presionAceiteRG,
                    temperaturaRG: values.temperaturaRG,
                    fallaDisplayRG: values.fallaDisplayRG,
                    correaRG: values.correaRG,
                    manguerasRG: values.manguerasRG,
                    cargadorBateriasRG: values.cargadorBateriasRG,
                    bornesRG: values.bornesRG,
                    disyuntorRG: values.disyuntorRG,
                    equipoRevision: "Generador",
                    observacionesRG: values.observacionesRG,
                  };

            let res;
            if (tipo === "editar") {
              res = await editarRevisionGeneradores(data);
            } else {
              res = await crearRevisionGeneradores(data);
            }
            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de generador ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de generador ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de generador`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="generador">
              <Stack w="100%" spacing={5}>
                <HStack px={5}>
                  <FormControl isRequired>
                    <FormLabel>Responsable</FormLabel>
                    <Select
                      w="2xs"
                      size="sm"
                      placeholder="Seleccione responsable"
                      icon={<MdArrowDropDown />}
                      onChange={handleChange}
                      value={values.responsable}
                      name="responsable"
                    >
                      {usuarios
                        .sort((a, b) => {
                          // Ordenar alfabéticamente por nombre y apellido
                          const nameA = `${a.nombre}`.toUpperCase();
                          const nameB = `${b.nombre}`.toUpperCase();
                          if (nameA < nameB) return -1;
                          if (nameA > nameB) return 1;
                          return 0;
                        })
                        .map((usuario) => (
                          <option
                            key={usuario.idUsuario}
                            value={usuario.idUsuario}
                          >
                            {usuario.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Fecha y Hora</FormLabel>
                    <Input
                      w="2xs"
                      size="sm"
                      type="datetime-local"
                      readOnly
                      value={
                        tipo === "editar"
                          ? moment(values.fechaRevision).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                          : moment().format("YYYY-MM-DDTHH:mm")
                      }
                    />
                  </FormControl>
                </HStack>
                <HStack justifyContent="space-around" pb={3}>
                  <FormControl></FormControl>
                  <FormControl>
                    <Text fontWeight="bold">Parametros de funcionamiento</Text>
                  </FormControl>
                  <FormControl></FormControl>
                </HStack>
                <HStack justifyContent="space-around" pb={3}>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelRefrigeranteRG"
                        isChecked={values.nivelRefrigeranteRG}
                      />
                      <Text>Nivel de Refrigerante</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelAceiteRG"
                        isChecked={values.nivelAceiteRG}
                      />
                      <Text>Nivel de Aceite de motor</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="nivelCombustibleRG"
                        isChecked={values.nivelCombustibleRG}
                      />
                      <Text>Nivel de combustible</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="filtroAireRG"
                        isChecked={values.filtroAireRG}
                      />
                      <Text>Condicion de filtro de aire</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="fallaDisplayRG"
                        isChecked={values.fallaDisplayRG}
                      />
                      <Text>Display muestra fallas</Text>
                    </HStack>
                  </Stack>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="horometroRG"
                        isChecked={values.horometroRG}
                      />
                      <Text>Horometro</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="voltajeRG"
                        isChecked={values.voltajeRG}
                      />
                      <Text>Voltaje</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="frecuenciaRG"
                        isChecked={values.frecuenciaRG}
                      />
                      <Text>Frecuencia</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="presionAceiteRG"
                        isChecked={values.presionAceiteRG}
                      />
                      <Text>Presión de aceite</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="temperaturaRG"
                        isChecked={values.temperaturaRG}
                      />
                      <Text>Temperatura de motor</Text>
                    </HStack>
                  </Stack>
                  <Stack w="xs">
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="correaRG"
                        isChecked={values.correaRG}
                      />
                      <Text>Condición de la correa</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="manguerasRG"
                        isChecked={values.manguerasRG}
                      />
                      <Text>Perdidas en Manguera</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="cargadorBateriasRG"
                        isChecked={values.cargadorBateriasRG}
                      />
                      <Text>Cargador de baterías (si aplica)</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="bornesRG"
                        isChecked={values.bornesRG}
                      />
                      <Text>Observa bornes sulfatados/flojos</Text>
                    </HStack>
                    <HStack>
                      <Switch
                        onChange={handleChange}
                        name="disyuntorRG"
                        isChecked={values.disyuntorRG}
                      />
                      <Text>Prueba de disyuntor</Text>
                    </HStack>
                  </Stack>
                </HStack>
                <HStack w="100%" justifyContent="space-between" p={3}>
                  <Stack placeSelf={"start"} w={"100%"}>
                    <Field
                      name="observacionesRG"
                      validate={(value) =>
                        validateField("textArea", value, "Observaciones")
                      }
                      w={"100%"}
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors?.observacionesRG && touched?.observacionesRG
                          }
                        >
                          <FormLabel>Observaciones</FormLabel>
                          <Card
                            variant="unstyled"
                            bg="brand.fondos_secundarios"
                          >
                            <CardBody>
                              <Textarea
                                {...field}
                                placeholder="Descripcion"
                                name="observacionesRG"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength="1500"
                                resize="none"
                              />
                            </CardBody>
                            <CardFooter
                              placeSelf="end"
                              color="brand.gris_medio"
                              pr={2}
                              m={"unset"}
                            >
                              {values.observacionesRG.length}/1500
                            </CardFooter>
                          </Card>
                          <FormErrorMessage>
                            {errors?.observacionesRG}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Button
                    placeSelf={"center"}
                    fontSize={12}
                    fontWeight="semibold"
                    bg="brand.naranja"
                    color="white"
                    rightIcon={<MdNavigateNext fontSize="20px" />}
                    variant="solid"
                    form="generador"
                    isDisabled={loading || user.idRol === "usuarioIT"}
                    onClick={handleSubmit}
                    _hover={{ bg: "white", color: "brand.naranja" }}
                  >
                    Finalizar
                  </Button>
                </HStack>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionGeneradores;
