import {
  TYPENAMEREGEX,
  TYPEEMAILREGEX,
  TYPENUMBERREGEX,
  TYPETEXTAREAREGEX,
  TYPEONLYLETTERSREGEX,
  TYPELETTERSNUMBERSREGEX,
  TYPEDECIMALREGEX,
  TYPELATLNGREGEX,
} from "./Regex";

export const validateField = (type, value, name) => {
  if (type === "text") {
    if (!TYPENAMEREGEX.test(value)) {
      return `El ${name} es inválido, solo se admiten letras, espacios y '-'`;
    }
  } else if (type === "email") {
    if (!TYPEEMAILREGEX.test(value)) {
      return `El ${name} es inválido`;
    }
  } else if (type === "number") {
    if (value === "0" || value === "") {
      return `El ${name} es inválido, no puede ser 0 ni estar vacio`;
    } else if (!TYPENUMBERREGEX.test(value)) {
      return `El ${name} es inválido, solo se permiten numeros`;
    }
  } else if (type === "textArea") {
    if (!TYPETEXTAREAREGEX.test(value)) {
      return `El texto no es valido, no se aceptan caracteres especiales ($%&'()*+-/:;<=>?@[]^_{|}~)`;
    }
  } else if (type === "letras") {
    if (!TYPEONLYLETTERSREGEX.test(value)) {
      return `El texto no es valido, solo se aceptan letras`;
    }
  } else if (type === "numerosLetras") {
    if (!TYPELETTERSNUMBERSREGEX.test(value)) {
      return `El texto no es valido, solo letras y numeros`;
    }
  } else if (type === "decimal") {
    if (!TYPEDECIMALREGEX.test(value)) {
      return `${name} no es valido`;
    }
  } else if (type === "numberWnull") {
    if (!TYPENUMBERREGEX.test(value)) {
      return `El ${name} es inválido, solo se permiten numeros`;
    }
  } else if (type === "latLng") {
    if (!TYPELATLNGREGEX.test(value)) {
      return `Coordenadas inválidas`;
    }
  }
};

export const validateOnSubmit = (type, value) => {
  if (type === "text") {
    if (!TYPENAMEREGEX.test(value)) {
      return true;
    }
  } else if (type === "email") {
    if (!TYPEEMAILREGEX.test(value)) {
      return true;
    }
  } else if (type === "number") {
    if (value === "0" || value === "") {
      return true;
    } else if (!TYPENUMBERREGEX.test(value)) {
      return true;
    }
  } else if (type === "textArea") {
    if (!TYPETEXTAREAREGEX.test(value)) {
      return true;
    }
  } else if (type === "letras") {
    if (!TYPEONLYLETTERSREGEX.test(value)) {
      return true;
    }
  } else if (type === "numerosLetras") {
    if (!TYPELETTERSNUMBERSREGEX.test(value)) {
      return true;
    }
  } else if (type === "decimal") {
    if (!TYPEDECIMALREGEX.test(value)) {
      return true;
    }
  } else if (type === "numberWnull") {
    if (!TYPENUMBERREGEX.test(value)) {
      return true;
    }
  } else if (type === "latLng") {
    if (!TYPELATLNGREGEX.test(value)) {
      return true;
    }
  }
};
