import React, { useState, useEffect } from "react";
import { HStack, Stack, useToast, Text, Center } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  getEtapas,
  getPlanificaciones,
} from "../operaciones/helpers/api.helper";
import { useSelector } from "react-redux";
import moment from "moment";
import DescargaReporteEtapas from "../operaciones/components/curso/etapas/DescargaReporteEtapas";
import DetalleEtapa from "./components/DetalleEtapa";
import TablaEtapas from "./components/TablaEtapas";
import HeaderReporteEtapas from "./components/HeaderReporteEtapas";
import FiltrosReporteEtapas from "./components/FiltrosReporteEtapas";

const ReporteEtapas = () => {
  const [etapas, setEtapas] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seleccionado, setSeleccionado] = useState();
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { padLocacion } = useParams();
  const [filterBy, setFilterBy] = useState({
    padDes: [],
    numTotalEtapaSC: [],
    numPozoEtapaSC: [],
    idPozo: [],
    etapaWeTSanD: [],
    fechaFinalEtapaSC: [],
    sandCubes: [],
    startDate: null,
    endDate: null,
  });
  const initData = async () => {
    setLoading(true);
    let res;
    res = await getEtapas({ padLocacion });

    if (res.status === 200) {
      const etapasProcesadas = res.data.map((etapa) => ({
        ...etapa,
        sandCubes: etapa.sandCubes || [], // Asegúrate de que `sandCubes` sea siempre un array
      }));
      setEtapas(etapasProcesadas);
      toast({
        status: "success",
        isClosable: true,
        title: "Etapas cargadas correctamente",
        duration: 3000,
      });
    } else if (res.status === 204) {
      toast({
        status: "info",
        isClosable: true,
        title: "No hay etapas cargadas",
        duration: 3000,
      });
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar etapas",
        duration: 3000,
      });
    }

    res = await getPlanificaciones({ padLocacion });

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar planificacion",
        duration: 3000,
      });
    }
    setSeleccionado();
    setLoading(false);
  };

  const sortData = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    const sortedData = [...etapas].sort((a, b) => {
      let aValue, bValue;
      if (field.includes(".")) {
        const [first, second] = field.split(".");
        aValue = a[first] ? a[first][second] : "";
        bValue = b[first] ? b[first][second] : "";
      } else if (field === "sandCubes.length") {
        aValue = a.sandCubes ? a.sandCubes.length : 0;
        bValue = b.sandCubes ? b.sandCubes.length : 0;
      } else {
        aValue = a[field];
        bValue = b[field];
      }

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setEtapas(sortedData);
    setSortField(field);
    setSortDirection(direction);
  };

  const filteredEtapas = etapas.filter((etapa) => {
    // const search = searchTerm.toLowerCase();

    const etapaFecha = moment(etapa.fechaFinalEtapaSC).format("YYYY-MM-DD");
    const startDate = filterBy.startDate
      ? moment(filterBy.startDate).format("YYYY-MM-DD")
      : null;
    const endDate = filterBy.endDate
      ? moment(filterBy.endDate).format("YYYY-MM-DD")
      : null;

    // Verificación de los filtros seleccionados
    const matchesFilters =
      (filterBy.padDes.length === 0 ||
        filterBy.padDes.includes(etapa.padLocacion)) &&
      (filterBy.numTotalEtapaSC.length === 0 ||
        filterBy.numTotalEtapaSC.includes(etapa.numTotalEtapaSC)) &&
      (filterBy.numPozoEtapaSC.length === 0 ||
        filterBy.numPozoEtapaSC.includes(etapa.numPozoEtapaSC)) &&
      (filterBy.idPozo.length === 0 ||
        filterBy.idPozo.includes(etapa.pozo?.nombrePozo)) &&
      (filterBy.etapaWeTSanD.length === 0 ||
        filterBy.etapaWeTSanD.includes(etapa.etapaWeTSanD.toString())) &&
      (filterBy.fechaFinalEtapaSC.length === 0 ||
        moment(etapa.fechaFinalEtapaSC).format("YYYY-MM-DD") ===
          filterBy.fechaFinalEtapaSC) &&
      (filterBy.sandCubes.length === 0 ||
        (etapa.sandCubes &&
          filterBy.sandCubes.includes(etapa.sandCubes.length.toString())));

    const matchesDateRange =
      (!startDate || etapaFecha >= startDate) &&
      (!endDate || etapaFecha <= endDate);
    // Combina los filtros
    return matchesFilters && matchesDateRange;
  });

  const handleDateChange = (dates) => {
    console.log("handleDateChange", dates);
    const [start, end] = dates;
    setFilterBy((prevState) => ({
      ...prevState,
      startDate: start,
      endDate: end,
    }));
  };

  const handleTnsNominales = (seleccionado) => {
    let sc = seleccionado.sandCubes ? seleccionado.sandCubes.length : 0;
    let cantidad = planificacion?.mallas[0]?.tnsArenaSc || 0;

    let resultado = (sc * cantidad).toFixed(2);

    return parseFloat(resultado);
  };

  useEffect(() => {
    initData();
  }, []);

  const handleFilterSelection = (filterName, value) => {
    setFilterBy((prevState) => {
      const currentFilter = prevState[filterName] || []; // Asegúrate de que siempre sea un array

      const updatedState = {
        ...prevState,
        [filterName]: currentFilter.includes(value)
          ? currentFilter.filter((val) => val !== value)
          : [...currentFilter, value],
      };

      return updatedState;
    });
  };

  const cleanFilter = () => {
    setFilterBy({
      padDes: [],
      numTotalEtapaSC: [],
      numPozoEtapaSC: [],
      idPozo: [],
      etapaWeTSanD: [],
      fechaFinalEtapaSC: [],
      sandCubes: [],
    });
    initData(); // Recargar datos después de limpiar los filtros
  };

  return (
    <Stack w="100%" h={"100vh"}>
      <HStack
        w="100%"
        bg="brand.fondos_secundario"
        justifyContent="space-between"
        alignItems={"center"}
        p={3}
      >
        <HeaderReporteEtapas />
        <HStack spacing={2} alignItems="center">
          {(user.idRol !== "usuarioBrent" || user.idRol !== "operaciones") && (
            <DescargaReporteEtapas
              planificacion={planificacion}
              etapas={filteredEtapas}
            />
          )}
          <FiltrosReporteEtapas
            etapas={etapas}
            filterBy={filterBy}
            handleFilterSelection={handleFilterSelection}
            cleanFilter={cleanFilter}
            handleDateChange={handleDateChange}
          />
        </HStack>
      </HStack>
      <HStack
        px={3}
        h={"calc(100vh - 160px)"}
        bg="brand.fondos_secundario"
        borderRadius={5}
        alignItems="start"
        spacing={4}
      >
        <TablaEtapas
          loading={loading}
          sortData={sortData}
          sortDirection={sortDirection}
          sortField={sortField}
          seleccionado={seleccionado}
          user={user}
          filteredEtapas={filteredEtapas}
          setSeleccionado={setSeleccionado}
          initData={initData}
        />

        {seleccionado ? (
          <DetalleEtapa
            seleccionado={seleccionado}
            handleTnsNominales={handleTnsNominales}
          />
        ) : (
          <Center
            w="15%"
            justifyContent="center"
            alignItems={"center"}
            height={"100%"}
          >
            <Text
              fontSize="14px"
              color="gray.400"
              fontWeight="bold"
              textAlign="center"
            >
              La información se verá una vez <br /> que se seleccione una etapa
            </Text>
          </Center>
        )}
      </HStack>
    </Stack>
  );
};

export default ReporteEtapas;
