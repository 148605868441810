import React, { useState } from "react";
import { Button, Box, useToast, Spinner } from "@chakra-ui/react";
import { editarPlanificacion } from "../../helpers/api.helper";

const TempButton = (props) => {
  const { seleccionado, initData, planificaciones, user } = props;
  const [isActive, setIsActive] = useState(false);
  const toast = useToast();

  const handleClick = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 3000);
  };

  const postDTM = async () => {
    if (
      planificaciones
        .filter((e) => e.idSET === seleccionado.idSET)
        .some((plan) => plan.estado === "activo")
    ) {
      return toast({
        title: "Error",
        description: "Ya existe una planificacion activa en ese SET",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    let res;

    if (!seleccionado.pozos) {
      res = await editarPlanificacion({
        id: seleccionado.idPlanificacion,
        desMontTransp: "1",
        estado: "proyectado",
      });
    } else {
      res = await editarPlanificacion({
        id: seleccionado.idPlanificacion,
        desMontTransp: "1",
        estado: "activo",
      });
    }

    if (res.status === 200) {
      initData();
      return toast({
        title: "Exito",
        description: "DTM aprobado",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      return toast({
        title: "Error",
        description: " Error aprobar DTM",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {!isActive && (
        <Button
          variant="outline"
          border={"1px solid #FF8200"}
          size="sm"
          onClick={() => {
            handleClick();
            postDTM();
          }}
          isDisabled={seleccionado.desMontTransp || user.idRol === "usuarioIT"}
          color="brand.naranja"
        >
          DTM Completo
        </Button>
      )}
      {isActive && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.naranja"
          size="sm"
        />
      )}
    </Box>
  );
};

export default TempButton;
