import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  HStack,
  VStack,
  Select,
  useToast,
  IconButton,
  InputGroup,
  InputRightAddon,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Heading,
  CircularProgress,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  MdOutlineAdd,
  MdNavigateBefore,
  MdFileDownloadDone,
  MdNavigateNext,
  MdArrowRight,
  MdArrowLeft,
} from "react-icons/md";
import {
  getCamiones,
  editarPlanificacion4,
  getAutoelevadores,
  getSander,
  getSanvan,
  getDosificadora,
  crearPlanificacion4,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";
import { handleUsuarioIT } from "../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../utils/Validaciones";
import { Field } from "formik";

const CuartoPaso = (props) => {
  const {
    activeStep,
    setActiveStep,
    id,
    type,
    initPlanificacion,
    onClose,
    values,
    handleChange,
    handleBlur,
    expanded,
    initDetallePlanta,
    formValues,
    user,
    errors,
    touched,
    setFieldValue,
  } = props;
  const [camiones, setCamiones] = useState(
    type === "editar" ? values.camiones : []
  );
  const [camionesSeleccionados, setCamionesSeleccionados] = useState([]);
  const [autoelevadores, setAutoelevadores] = useState([]);
  const [sander, setSander] = useState([]);
  const [sandvan, setSanvan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dosificadora, setDosificadora] = useState(
    values.dosificadora ? true : false
  );
  const [dosificadoras, setDosificadoras] = useState([]);
  const [filterBy, setFilterBy] = useState({
    dominio: "",
    empresa: "",
  });
  const {
    onOpen: openModal,
    onClose: closeModal,
    isOpen: isOpenModal,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const initCamiones = async () => {
    let res;
    res = await getCamiones();
    if (res.status === 200) {
      let camionesFiltrados = res.data.filter(
        (e) => e.empresaTransportista.habilitado
      );
      setCamiones(camionesFiltrados);
    } else {
      toast({
        description: "Error al cargar Camiones",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    res = await getAutoelevadores();
    if (res.status === 200) {
      setAutoelevadores(res.data);
    } else {
      toast({
        description: "Error al cargar Autoelevadores",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    res = await getSander();
    if (res.status === 200) {
      setSander(res.data);
    } else {
      toast({
        description: "Error al cargar Sander",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    res = await getSanvan();
    if (res.status === 200) {
      setSanvan(res.data);
    } else {
      toast({
        description: "Error al cargar SandVan",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getDosificadora();
    if (res.status === 200) {
      setDosificadoras(res.data);
    } else {
      toast({
        description: "Error al cargar Dosificadoras",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filtrocamiones = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return camiones;
    }
    return camiones.filter((camion) => {
      const { dominio, empresa } = filterBy;

      return (
        (!dominio ||
          camion.dominio.toLowerCase().includes(dominio) ||
          camion.nombreCamion?.toLowerCase().includes(dominio)) &&
        (!empresa ||
          camion.empresaTransportista.nombreEmpresaT
            .toLowerCase()
            .includes(empresa))
      );
    });
  };

  const handleSeleccionados = (camion) => {
    setCamiones(camiones.filter((item) => item.idCamion !== camion.idCamion));
    setCamionesSeleccionados([...camionesSeleccionados, camion]);
  };
  const handleLista = (camion) => {
    setCamionesSeleccionados(
      camionesSeleccionados.filter(
        (items) => items.idCamion !== camion.idCamion
      )
    );
    setCamiones([...camiones, camion]);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const handleChangeAutoE = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    // Si el primer select cambia, resetea el segundo
    if (name === "idAutoelevador1" && !value) {
      setFieldValue("idAutoelevador2", "");
    }
  };
  const handleSubmit = async (values, event) => {
    setLoading(true);
    if (!values.sanderPrincipal) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "ID de sander principal requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.idSandVan) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "SandVan requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.SandCubes || validateOnSubmit("number", values.SandCubes)) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Numero de sandcubes requerido o incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    let data;

    if (formValues.desMontTransp === true) {
      data = {
        sanderPrincipal: values.sanderPrincipal,
        sanderBackup: values.sanderBackup,
        idSandVan: values.idSandVan,
        SandCubes: values.SandCubes,
        camion: camionesSeleccionados,
        estado: "activo",
      };
    } else {
      data = {
        sanderPrincipal: values.sanderPrincipal,
        sanderBackup: values.sanderBackup,
        idSandVan: values.idSandVan,
        SandCubes: values.SandCubes,
        camion: camionesSeleccionados,
        estado: "proyectado",
      };
    }

    if (values.idAutoelevador1) {
      data = {
        ...data,
        idAutoelevador1: values.idAutoelevador1,
      };
    }

    if (values.idAutoelevador2) {
      data = {
        ...data,
        idAutoelevador2: values.idAutoelevador2,
      };
    }

    if (dosificadora === true) {
      data = {
        ...data,
        idDosificadora: values.dosificadora,
      };
    }

    let res;
    if (type === "editar") {
      res = await editarPlanificacion4({
        ...data,
        id: formValues.idPlanificacion,
      });
    } else if (type === "continuar") {
      res = await crearPlanificacion4({
        ...data,
        id: formValues.idPlanificacion,
      });
    } else {
      res = res = await crearPlanificacion4({ ...data, id: id });
    }

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: `Cuarto paso ${
          type === "editar" ? "editado" : "creado"
        } correctamente`,
        duration: 3000,
      });
      if (event === "next") {
        setActiveStep(activeStep + 1);
      } else {
        navigate("/operaciones/planificacion");
        if (type === "continuar") {
          initPlanificacion();
        }
      }
    } else {
      setLoading(false);
      return toast({
        status: "error",
        isClosable: true,
        title: `Error al ${
          type === "editar" ? "editado" : "creado"
        } el cuarto paso`,
        description: res.message,
      });
    }

    if (type === "continuar" && event === "finalizar") {
      onClose();
    }

    setLoading(false);
  };

  useEffect(() => {
    initCamiones();
  }, []);

  return (
    <Stack px={5}>
      <VStack
        w="100%"
        bg="brand.fondo"
        justifyContent="space-around"
        borderRadius={5}
        spacing={7}
      >
        <HStack
          placeSelf="start"
          spacing={10}
          pt={2}
          pb={2}
          w="100%"
          justifyContent={!expanded ? "space-between" : "unset"}
        >
          <FormControl isRequired w="xs">
            <FormLabel fontWeight="semibold">Sander</FormLabel>
            <Stack>
              <Select
                w="2xs"
                placeholder="ID Sander Principal"
                name="sanderPrincipal"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sanderPrincipal}
              >
                {sander.map((elemento) => (
                  <option key={elemento.idSander} value={elemento.idSander}>
                    {elemento.codigoSander}
                  </option>
                ))}
              </Select>
              <Select
                w="2xs"
                placeholder="ID Sander Back up"
                name="sanderBackup"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sanderBackup}
                isDisabled={!values.sanderPrincipal}
              >
                {sander
                  .filter(
                    (e) => e.idSander !== parseInt(values.sanderPrincipal)
                  )
                  .map((elemento) => (
                    <option key={elemento.idSander} value={elemento.idSander}>
                      {elemento.codigoSander}
                    </option>
                  ))}
              </Select>
            </Stack>
          </FormControl>
          <FormControl isRequired alignSelf="start" w="xs">
            <FormLabel fontWeight="semibold">SandVan</FormLabel>
            <Select
              w="2xs"
              placeholder="Cabina"
              name="idSandVan"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.idSandVan}
            >
              {sandvan.map((elem) => (
                <option key={elem.idSandVan} value={elem.idSandVan}>
                  {elem.codigoSandVan}
                </option>
              ))}
            </Select>
          </FormControl>
          <Stack placeSelf={"start"}>
            <Field
              name="SandCubes"
              validate={(value) => validateField("number", value, "Sandcubes")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.SandCubes && touched.SandCubes}
                  w={"xs"}
                  isRequired
                >
                  <FormLabel fontWeight="semibold">Sandcubes</FormLabel>
                  <InputGroup w="2xs">
                    <Input
                      {...field}
                      placeholder="Cantidad"
                      name="SandCubes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.SandCubes}
                    />
                    <InputRightAddon bg="brand.gris_primario" color="white">
                      Cant.
                    </InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage>{errors.SandCubes}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </HStack>
        <HStack w={"100%"} px={2} pb={4}>
          <FormControl placeSelf="start" w="xs">
            <FormLabel>Dosificadora</FormLabel>
            <HStack>
              <Checkbox
                isChecked={dosificadora}
                onChange={() => setDosificadora(!dosificadora)}
                border="1px solid orange"
                borderRadius="5px"
              />
              {dosificadora && (
                <Select
                  w="2xs"
                  placeholder="Seleccione una dosificadora"
                  name="dosificadora"
                  value={values.dosificadora}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {dosificadoras.map((dosi) => (
                    <option
                      key={dosi.idDosificadora}
                      value={dosi.idDosificadora}
                    >
                      {dosi.nombreDosificadora}
                    </option>
                  ))}
                </Select>
              )}
            </HStack>
          </FormControl>
          <FormControl w="xl">
            <FormLabel fontWeight="semibold" w="xs">
              Autoelevadores
            </FormLabel>
            <HStack
              justifyContent={!expanded ? "space-between" : "unset"}
              ml={-5}
              spacing={10}
            >
              <Select
                w="2xs"
                placeholder="ID autoelevador primero"
                name="idAutoelevador1"
                onChange={handleChangeAutoE}
                onBlur={handleBlur}
                value={values.idAutoelevador1}
              >
                {autoelevadores.map((autoelevador) => (
                  <option
                    key={autoelevador.idAutoE}
                    value={autoelevador.idAutoE}
                  >
                    {autoelevador.nombreAutoE}
                  </option>
                ))}
              </Select>
              <Select
                w="2xs"
                type="text"
                placeholder="ID autoelevador segundo"
                name="idAutoelevador2"
                onChange={handleChangeAutoE}
                onBlur={handleBlur}
                value={values.idAutoelevador1 ? values.idAutoelevador2 : ""}
                isDisabled={!values.idAutoelevador1}
              >
                {autoelevadores
                  .filter((e) => e.idAutoE !== parseInt(values.idAutoelevador1))
                  .map((autoelevador) => (
                    <option
                      key={autoelevador.idAutoE}
                      value={autoelevador.idAutoE}
                    >
                      {autoelevador.nombreAutoE}
                    </option>
                  ))}
              </Select>
            </HStack>
          </FormControl>
        </HStack>
        <HStack w={"100%"} placeContent={"start"}>
          <Button
            placeSelf="self-start"
            fontSize="12px"
            variant="link"
            size="lg"
            leftIcon={<MdOutlineAdd size={18} color="#FF8200" />}
            onClick={openModal}
            textColor="black"
          >
            Agregar Camion
          </Button>
          <Text fontSize="12px" color="brand.azul_text">
            {camionesSeleccionados.length} camiones seleccionados
          </Text>
        </HStack>
        <Modal isOpen={isOpenModal} onClose={closeModal} size={"5xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Camiones</ModalHeader>
            <ModalBody>
              {loading && loadingtable()}
              {!loading && camiones.length >= 1 && (
                <Stack w={"100%"}>
                  <HStack>
                    <Input
                      w="3xs"
                      type="text"
                      value={filterBy.dominio}
                      placeholder={`Buscar camion`}
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          dominio: e.target.value,
                        }));
                        filtrocamiones();
                      }}
                      variant="flushed"
                    />
                    <Input
                      w="3xs"
                      type="text"
                      value={filterBy.empresa}
                      placeholder={`Buscar por empresa`}
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          empresa: e.target.value,
                        }));
                        filtrocamiones();
                      }}
                      variant="flushed"
                    />
                  </HStack>
                  <HStack>
                    <Stack w={"50%"}>
                      <Heading size="md">Lista de Camiones</Heading>
                      <TableContainer
                        h="xs"
                        overflowY="scroll"
                        overflowX={"hidden"}
                      >
                        <Table size="sm">
                          <Thead>
                            <Tr>
                              <Th>Dominio</Th>
                              <Th>Interno</Th>
                              <Th>Empresa</Th>
                              <Th></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {filtrocamiones().map((camion) => (
                              <Tr key={camion.idCamion}>
                                <Td>{camion.dominio}</Td>
                                <Td>{camion.nombreCamion}</Td>
                                <Td>
                                  {camion.empresaTransportista.nombreEmpresaT}
                                </Td>
                                <Td>
                                  <IconButton
                                    variant="link"
                                    icon={<MdArrowRight />}
                                    onClick={() => {
                                      handleSeleccionados(camion);
                                    }}
                                    _hover={{ color: "brand.naranja" }}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Stack>
                    <Stack w={"50%"}>
                      <Heading size="md">Seleccionados</Heading>
                      <TableContainer h="xs" overflowY="scroll">
                        <Table size="sm">
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th>Dominio</Th>
                              <Th>Interno</Th>
                              <Th>Empresa</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {camionesSeleccionados.map((camion) => (
                              <Tr key={camion.idCamion}>
                                <Td>
                                  <IconButton
                                    variant="link"
                                    icon={<MdArrowLeft />}
                                    onClick={() => {
                                      handleLista(camion);
                                    }}
                                    _hover={{ color: "brand.naranja" }}
                                  />
                                </Td>
                                <Td>{camion.dominio}</Td>
                                <Td>{camion.nombreCamion}</Td>
                                <Td>
                                  {camion.empresaTransportista.nombreEmpresaT}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </HStack>
                </Stack>
              )}
              {!loading && camiones.length < 1 && (
                <Heading textAlign={"center"} size={"md"}>
                  No hay datos para mostrar
                </Heading>
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={closeModal}>
                Cerrar
              </Button>
              <Button variant="ghost" onClick={closeModal}>
                Guardar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>

      <HStack
        w="100%"
        justifyContent={
          user.idRol !== "userOperaciones" && user.puesto !== "supervisor"
            ? "space-between"
            : "end"
        }
        py={2}
        pr={2}
      >
        {user.idRol !== "userOperaciones" && user.puesto !== "supervisor" && (
          <Button
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            fontWeight="semibold"
            variant="outline"
            color="brand.naranja"
            leftIcon={<MdNavigateBefore fontSize="20px" />}
            onClick={() => {
              initDetallePlanta();
              setActiveStep(activeStep - 1);
            }}
            alignSelf="end"
            _hover={{ bg: "brand.naranja", color: "white" }}
          >
            Atras
          </Button>
        )}

        <VStack>
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            onClick={() =>
              user.idRol === "usuarioIT"
                ? handleUsuarioIT(setActiveStep, activeStep)
                : handleSubmit(values, "next")
            }
            variant="solid"
            form="FormularioPlanificacion"
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
          <Button
            color="brand.naranja"
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            variant="outline"
            leftIcon={<MdFileDownloadDone fontSize="20px" />}
            textColor="brand.naranja"
            form="FormularioPlanificacion"
            onClick={() => handleSubmit(values, "finalizar")}
            isDisabled={loading || user.idRol === "usuarioIT"}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Finalizar
          </Button>
        </VStack>
      </HStack>
    </Stack>
  );
};

export default CuartoPaso;
