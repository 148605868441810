import React, { useEffect, useState } from "react";
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Select,
  Button,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Textarea,
  InputGroup,
  InputRightAddon,
  Card,
  CardBody,
  CardFooter,
  useToast,
  Heading,
  CheckboxGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  CSSReset,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdArrowDropDown,
  MdFileDownloadDone,
  MdOutlineQrCode2,
  MdArrowBack,
} from "react-icons/md";
import { Form, Formik, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import {
  crearRevisionSanders,
  editarRevisionSanders,
  getPlanificaciones,
  getRevisionSanders,
} from "../../../helpers/api.helper";
import HandleQrReader from "./QrReader";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { handleUsuarioIT } from "../../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../../utils/Validaciones";

const RevisionSander = (props) => {
  const { tipo, user } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const { padLocacion } = useParams();
  const { id } = useParams();
  const [sanderBackUp, setSanderBackUp] = useState("SanderA");
  const [usuarios, setUsuarios] = useState([]);
  const [planificacion, setPlanificacion] = useState();
  const [editable, setEditable] = useState([]);
  const [sanders, setSanders] = useState([]);
  const [scannedData, setScannedData] = useState();
  let steps = [
    { title: "Power Pack" },
    { title: "Sistema transporte" },
    { title: "Sistema hidráulico" },
    { title: "Sistema eléctrico" },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hoy = new Date();
  const anio = hoy.getFullYear();
  const mes = String(hoy.getMonth() + 1).padStart(2, "0");
  const dia = hoy.getDate();
  const minutos = hoy.getMinutes();
  const horas = hoy.getHours();
  const hoyFormat = dia + "/" + mes + "/" + anio;
  const horaActual = horas + ":" + minutos;

  const initUsarios = async () => {
    let res;

    res = await getPlanificaciones({ padLocacion: padLocacion });
    if (res.status === 200) {
      setPlanificacion(res.data[0]);
      setSanders([res.data[0].sanderPrincipal, res.data[0].sanderBackup]);
    }

    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    }

    res = await getRevisionSanders();
    if (res.status === 200) {
      if (tipo === "crear") {
        setEditable(res.data);
      }
      if (tipo === "editar") {
        setEditable(() => {
          let a = res.data.filter((e) => e.idRevisionSander === parseInt(id));
          return a[0];
        });
      }

      setLoading(false);
    } else {
      setLoading(false);
      return hoyFormat + horaActual;
    }
  };

  let initialValues =
    tipo === "editar"
      ? {
          sanderA: editable.codigoSanderA,
          responsable: editable.responsable,
          qrCodeA1: editable.qrCodeA1,
          powerPackA: editable.powerPackA,
          horasMotorA: editable.horasMotorA,
          nivelCombustibleA: editable.nivelCombustibleA,
          perdidasCombustibleA: editable.perdidasCombustibleA,
          presionAceiteA: editable.presionAceiteA,
          nivelAceiteA: editable.nivelAceiteA,
          tempRefrigeranteA: editable.tempRefrigeranteA,
          perdidasRefrigeranteA: editable.perdidasRefrigeranteA,
          nivelRefrigeranteA: editable.nivelRefrigeranteA,
          refrigerantePerdidaA: editable.refrigerantePerdidaA,
          estadoBorneasA: editable.estadoBorneasA,
          limpiezaFiltroAireA: editable.limpiezaFiltroAireA,
          limpizaA: editable.limpizaA,
          qrCodeA2A: editable.qrCodeA2A,
          bandaTransportadoraA: editable.bandaTransportadoraA,
          alineacionBandaA: editable.alineacionBandaA,
          rolosRetornoA: editable.rolosRetornoA,
          rolosVentiladosA: editable.rolosVentiladosA,
          rolosSdriveA: editable.rolosSdriveA,
          unionBandaA: editable.unionBandaA,
          ctcpA: editable.ctcpA,
          acoplamientoCFA: editable.acoplamientoCFA,
          anclajePlacaCeldasA: editable.anclajePlacaCeldasA,
          lubricacionRodamientosA: editable.lubricacionRodamientosA,
          lubricacionCadenaA: editable.lubricacionCadenaA,
          qrCodeA3A: editable.qrCodeA3A,
          hsCircuitoHidraA: editable.hsCircuitoHidraA,
          tempAceiteHidraA: editable.tempAceiteHidraA,
          nivelAceiteHidraA: editable.nivelAceiteHidraA,
          aceiteHidraPerdidaA: editable.aceiteHidraPerdidaA,
          movimientoCarrosA: editable.movimientoCarrosA,
          presionRetonoBEA: editable.presionRetonoBEA,
          presionSantByBPA: editable.presionSantByBPA,
          presionTrabajoBPA: editable.presionTrabajoBPA,
          tablerosCerradosA: editable.tablerosCerradosA,
          cableComandoA: editable.cableComandoA,
          calibracionBalanzaA: editable.calibracionBalanzaA,
          semaforoLucesA: editable.semaforoLucesA,
          observacionesSA: editable.observacionesSA || "",
          sanderB: editable.codigoSanderB,
          qrCodeB1: editable.qrCodeB1,
          powerPackB: editable.powerPackB,
          horasMotorB: editable.horasMotorB,
          nivelCombustibleB: editable.nivelCombustibleB,
          perdidasCombustibleB: editable.perdidasCombustibleB,
          presionAceiteB: editable.presionAceiteB,
          nivelAceiteB: editable.nivelAceiteB,
          tempRefrigeranteB: editable.tempRefrigeranteB,
          perdidasRefrigeranteB: editable.perdidasRefrigeranteB,
          nivelRefrigeranteB: editable.nivelRefrigeranteB,
          refrigerantePerdidaB: editable.refrigerantePerdidaB,
          estadoBorneasB: editable.estadoBorneasB,
          limpiezaFiltroAireB: editable.limpiezaFiltroAireB,
          limpizaB: editable.limpizaB,
          qrCodeB2: editable.qrCodeB2,
          bandaTransportadoraB: editable.bandaTransportadoraB,
          alineacionBandaB: editable.alineacionBandaB,
          rolosRetornoB: editable.rolosRetornoB,
          rolosVentiladosB: editable.rolosVentiladosB,
          rolosSdriveB: editable.rolosSdriveB,
          unionBandaB: editable.unionBandaB,
          ctcpB: editable.ctcpB,
          acoplamientoCFB: editable.acoplamientoCFB,
          anclajePlacaCeldasB: editable.anclajePlacaCeldasB,
          lubricacionRodamientosB: editable.lubricacionRodamientosB,
          lubricacionCadenaB: editable.lubricacionCadenaB,
          qrCodeA3B: editable.qrCodeA3B,
          hsCircuitoHidraB: editable.hsCircuitoHidraB,
          tempAceiteHidraB: editable.tempAceiteHidraB,
          nivelAceiteHidraB: editable.nivelAceiteHidraB,
          aceiteHidraPerdidaB: editable.aceiteHidraPerdidaB,
          movimientoCarrosB: editable.movimientoCarrosB,
          presionRetonoBEB: editable.presionRetonoBEB,
          presionSantByBPB: editable.presionSantByBPB,
          presionTrabajoBPB: editable.presionTrabajoBPB,
          tablerosCerradosB: editable.tablerosCerradosB,
          cableComandoB: editable.cableComandoB,
          calibracionBalanzaB: editable.calibracionBalanzaB,
          semaforoLucesB: editable.semaforoLucesB,
          observacionesSB: editable.observacionesSB || "",
          observacionesGral: editable.observacionesGral || "",
          verResortesP1A: editable.verResortesP1A || false,
          verResortesP2A: editable.verResortesP2A || false,
          verResortesP3A: editable.verResortesP3A || false,
          verResortesP4A: editable.verResortesP4A || false,
          verContactoresP1A: editable.verContactoresP1A || false,
          verContactoresP2A: editable.verContactoresP2A || false,
          verContactoresP3A: editable.verContactoresP3A || false,
          verContactoresP4A: editable.verContactoresP4A || false,
          verCableadoP1A: editable.verCableadoP1A || false,
          verCableadoP2A: editable.verCableadoP2A || false,
          verCableadoP3A: editable.verCableadoP3A || false,
          verCableadoP4A: editable.verCableadoP4A || false,
          verConMangueraP1A: editable.verConMangueraP1A || false,
          verConMangueraP2A: editable.verConMangueraP2A || false,
          verConMangueraP3A: editable.verConMangueraP3A || false,
          verConMangueraP4A: editable.verConMangueraP4A || false,
          verResortesP1: editable.verResortesP1 || false,
          verResortesP2: editable.verResortesP2 || false,
          verResortesP3: editable.verResortesP3 || false,
          verResortesP4: editable.verResortesP4 || false,
          verContactoresP1: editable.verContactoresP1 || false,
          verContactoresP2: editable.verContactoresP2 || false,
          verContactoresP3: editable.verContactoresP3 || false,
          verContactoresP4: editable.verContactoresP4 || false,
          verCableadoP1: editable.verCableadoP1 || false,
          verCableadoP2: editable.verCableadoP2 || false,
          verCableadoP3: editable.verCableadoP3 || false,
          verCableadoP4: editable.verCableadoP4 || false,
          verConMangueraP1: editable.verConMangueraP1 || false,
          verConMangueraP2: editable.verConMangueraP2 || false,
          verConMangueraP3: editable.verConMangueraP3 || false,
          verConMangueraP4: editable.verConMangueraP4 || false,
        }
      : {
          sanderA: "",
          responsable: "",
          qrCodeA1: "",
          powerPackA: "",
          horasMotorA: "",
          nivelCombustibleA: "",
          perdidasCombustibleA: false,
          presionAceiteA: "",
          nivelAceiteA: false,
          tempRefrigeranteA: "",
          perdidasRefrigeranteA: false,
          nivelRefrigeranteA: false,
          refrigerantePerdidaA: false,
          estadoBorneasA: false,
          limpiezaFiltroAireA: false,
          limpizaA: false,
          qrCodeA2A: "",
          bandaTransportadoraA: false,
          alineacionBandaA: false,
          rolosRetornoA: false,
          rolosVentiladosA: false,
          rolosSdriveA: false,
          unionBandaA: false,
          ctcpA: false,
          acoplamientoCFA: false,
          anclajePlacaCeldasA: false,
          lubricacionRodamientosA: false,
          lubricacionCadenaA: false,
          qrCodeA3A: "",
          hsCircuitoHidraA: "",
          tempAceiteHidraA: "",
          nivelAceiteHidraA: false,
          aceiteHidraPerdidaA: false,
          movimientoCarrosA: false,
          presionRetonoBEA: "",
          presionSantByBPA: "",
          presionTrabajoBPA: "",
          tablerosCerradosA: false,
          cableComandoA: false,
          calibracionBalanzaA: false,
          semaforoLucesA: false,
          observacionesSA: "",
          sanderB: "",
          qrCodeB1: "",
          powerPackB: "",
          horasMotorB: "",
          nivelCombustibleB: "",
          perdidasCombustibleB: false,
          presionAceiteB: "",
          nivelAceiteB: false,
          tempRefrigeranteB: "",
          perdidasRefrigeranteB: false,
          nivelRefrigeranteB: false,
          refrigerantePerdidaB: false,
          estadoBorneasB: false,
          limpiezaFiltroAireB: false,
          limpizaB: false,
          qrCodeB2: "",
          bandaTransportadoraB: false,
          alineacionBandaB: false,
          rolosRetornoB: false,
          rolosVentiladosB: false,
          rolosSdriveB: false,
          unionBandaB: false,
          ctcpB: false,
          acoplamientoCFB: false,
          anclajePlacaCeldasB: false,
          lubricacionRodamientosB: false,
          lubricacionCadenaB: false,
          qrCodeA3B: "",
          hsCircuitoHidraB: "",
          tempAceiteHidraB: "",
          nivelAceiteHidraB: false,
          aceiteHidraPerdidaB: false,
          movimientoCarrosB: false,
          presionRetonoBEB: "",
          presionSantByBPB: "",
          presionTrabajoBPB: "",
          tablerosCerradosB: false,
          cableComandoB: false,
          calibracionBalanzaB: false,
          semaforoLucesB: false,
          observacionesSB: "",
          observacionesGral: "",
          verResortesP1A: false,
          verResortesP2A: false,
          verResortesP3A: false,
          verResortesP4A: false,
          verContactoresP1A: false,
          verContactoresP2A: false,
          verContactoresP3A: false,
          verContactoresP4A: false,
          verCableadoP1A: false,
          verCableadoP2A: false,
          verCableadoP3A: false,
          verCableadoP4A: false,
          verConMangueraP1A: false,
          verConMangueraP2A: false,
          verConMangueraP3A: false,
          verConMangueraP4A: false,
          verResortesP1: false,
          verResortesP2: false,
          verResortesP3: false,
          verResortesP4: false,
          verContactoresP1: false,
          verContactoresP2: false,
          verContactoresP3: false,
          verContactoresP4: false,
          verCableadoP1: false,
          verCableadoP2: false,
          verCableadoP3: false,
          verCableadoP4: false,
          verConMangueraP1: false,
          verConMangueraP2: false,
          verConMangueraP3: false,
          verConMangueraP4: false,
        };

  const handlecodigo = (e, setFieldValue) => {
    if (e === "sanderAQR1" && scannedData) {
      setFieldValue("qrCodeA1", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderAQR2" && scannedData) {
      setFieldValue("qrCodeA2A", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderAQR3" && scannedData) {
      setFieldValue("qrCodeA3A", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR1" && scannedData) {
      setFieldValue("qrCodeB1", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR2" && scannedData) {
      setFieldValue("qrCodeB2", scannedData.text);
      setScannedData();
      onClose();
    }
    if (e === "sanderBQR3" && scannedData) {
      setFieldValue("qrCodeA3B", scannedData.text);
      setScannedData();
      onClose();
    }
  };

  if (!loading && planificacion.dosificadora) {
    steps.push({ title: "Wetsand" });
  }

  useEffect(() => {
    initUsarios();
  }, []);

  return (
    <Stack w="100%" h="100%" overflow={"auto"} p={3}>
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size={"sm"}>Revision Sanders</Heading>
      </HStack>
      <HStack w="100%" px={3} justifyContent="space-between">
        <Stepper index={activeStep} colorScheme="orange">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepIndicator>
                {index !== 4 && (
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                )}
                {index === 4 && (
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={"W"}
                    active={"W"}
                  />
                )}
              </StepIndicator>

              <Box flexShrink="1">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Box>
          <Text color="brand.azul_text" fontSize={14}>
            {sanderBackUp === "SanderA" ? "SANDER A" : "SANDER B"}
          </Text>
          <Text color="brand.azul_text" fontSize={14}>
            {activeStep + 1} de {steps.length} partes
          </Text>
        </Box>
      </HStack>

      {!loading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            setLoading(true);
            if (!planificacion.dosificadora) {
              if (
                (values.observacionesSB &&
                  validateOnSubmit("textArea", values.observacionesSB)) ||
                (values.observacionesGral &&
                  validateOnSubmit("textArea", values.observacionesGral))
              ) {
                setLoading(false);
                return toast({
                  title: "Error",
                  description: "La observación es inválida",
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                });
              }
            }

            let data = values;

            if (tipo === "editar") {
              data = {
                ...data,
                idPlanificacion: editable.idPlanificacion,
                id: editable.idRevisionSander,
                sanderA: editable.idSanderA,
                sanderB: editable.idSanderB,
                equipoRevision: "Sander",
              };
            } else {
              data = {
                ...data,
                idPlanificacion: planificacion.idPlanificacion,
                equipoRevision: "Sander",
              };
            }

            let res;
            if (tipo === "editar") {
              res = await editarRevisionSanders(data);
            } else {
              res = await crearRevisionSanders(data);
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de Sanders ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Revision de Sanders ${
                  tipo === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  tipo === "editar" ? "editar" : "crear"
                } revision de sanders`,
                duration: 3000,
              });
            }

            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form id="FormSander">
              {sanderBackUp === "SanderA" && (
                <>
                  {activeStep === 0 && (
                    <Stack>
                      <HStack justifyContent="space-between">
                        <FormControl w="2xs" size="sm" isRequired>
                          <FormLabel>Responsable</FormLabel>
                          <Select
                            w="2xs"
                            size="sm"
                            placeholder="Seleccione responsable"
                            icon={<MdArrowDropDown />}
                            onChange={handleChange}
                            value={values.responsable}
                            name="responsable"
                          >
                            {usuarios
                              .sort((a, b) => {
                                // Ordenar alfabéticamente por nombre y apellido
                                const nameA = `${a.nombre}`.toUpperCase();
                                const nameB = `${b.nombre}`.toUpperCase();
                                if (nameA < nameB) return -1;
                                if (nameA > nameB) return 1;
                                return 0;
                              })
                              .map((usuario) => (
                                <option
                                  key={usuario.idUsuario}
                                  value={usuario.idUsuario}
                                >
                                  {usuario.nombre}
                                </option>
                              ))}
                          </Select>
                        </FormControl>
                        {tipo === "crear" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander A</FormLabel>
                            <Select
                              w="2xs"
                              size="sm"
                              icon={<MdArrowDropDown />}
                              name="sanderA"
                              placeholder="Seleccionar Sander"
                              value={values.sanderA}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isReadOnly={tipo === "editar" ? true : false}
                            >
                              {sanders.map((sander, i) => (
                                <option key={i} value={sander.idSander}>
                                  {sander.codigoSander}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {tipo === "editar" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander A</FormLabel>
                            <Input
                              value={values.sanderA}
                              w="2xs"
                              size="sm"
                              bg="brand.gris_medio"
                              readOnly
                            />
                          </FormControl>
                        )}
                        <FormControl></FormControl>
                      </HStack>
                      <Text fontWeight="bold" color="brand.gris_medio">
                        Power Pack
                      </Text>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeA1"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR A.1")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={errors.qrCodeA1 && touched.qrCodeA1}
                                w={"2xs"}
                              >
                                <FormLabel>QR Code A.1</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    placeholder="QR Code A.1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeA1"
                                    value={values.qrCodeA1}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.qrCodeA1}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR1"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>

                        <Stack placeSelf={"start"}>
                          <Field
                            name="powerPackA"
                            validate={(value) =>
                              validateField("number", value, "Power Pack")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.powerPackA && touched.powerPackA
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Power Pack</FormLabel>
                                <Input
                                  {...field}
                                  onBlur={handleBlur}
                                  placeholder="Nombre"
                                  w="2xs"
                                  name="powerPackA"
                                  onChange={handleChange}
                                  value={values.powerPackA}
                                />
                                <FormErrorMessage>
                                  {errors.powerPackA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="horasMotorA"
                            validate={(value) =>
                              validateField("number", value, "Horas Motor")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.horasMotorA && touched.horasMotorA
                                }
                                w={"2xs"}
                              >
                                <FormLabel>Horas del Motor</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Horas del Motor"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="horasMotorA"
                                  value={values.horasMotorA}
                                />
                                <FormErrorMessage>
                                  {errors.horasMotorA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="nivelCombustibleA"
                            validate={(value) =>
                              validateField("number", value, "porcentaje nivel")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.nivelCombustibleA &&
                                  touched.nivelCombustibleA
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Nivel de combustible</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="% nivel de combustible"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="nivelCombustibleA"
                                  value={values.nivelCombustibleA}
                                />
                                <FormErrorMessage>
                                  {errors.nivelCombustibleA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={
                            errors.nivelCombustibleA ? "center" : "end"
                          }
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasCombustibleA}
                              name="perdidasCombustibleA"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px">
                            Marcar si NO se registran pérdidas de combustible
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionAceiteA"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "número de presión del aceite"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionAceiteA &&
                                  touched.presionAceiteA
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Presión del aceite</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Presión del aceite"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="presionAceiteA"
                                  value={values.presionAceiteA}
                                />
                                <FormErrorMessage>
                                  {errors.presionAceiteA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={errors.presionAceiteA ? "center" : "end"}
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteA}
                              name="nivelAceiteA"
                            />
                            <Text>Nivel de aceite normal?</Text>
                          </HStack>

                          <Text fontSize="10px">
                            Marcar si el nivel de acite ES NORMAL
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="tempRefrigeranteA"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "número de teperatura del refrigerante"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.tempRefrigeranteA &&
                                  touched.tempRefrigeranteA
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Temperatura refrigerante</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Temperatura refrigerante"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="tempRefrigeranteA"
                                  value={values.tempRefrigeranteA}
                                />
                                <FormErrorMessage>
                                  {errors.tempRefrigeranteA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>

                        <HStack placeContent={"end"}>
                          <FormControl
                            isRequired
                            placeSelf={
                              errors.tempRefrigeranteA ? "center" : "end"
                            }
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Box>
                              <HStack w={"2xs"}>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.perdidasRefrigeranteA}
                                  name="perdidasRefrigeranteA"
                                />
                                <Text>Sin pérdidas?</Text>
                              </HStack>

                              <Text fontSize="10px">
                                Marcar si NO se registran pérdidas de
                                refrigerante
                              </Text>
                            </Box>
                            <Box>
                              <HStack w={"2xs"}>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.nivelRefrigeranteA}
                                  name="nivelRefrigeranteA"
                                />
                                <Text>Nivel de refrigerante normal?</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si el nivel de refrigerante ES NORMAL
                              </Text>
                            </Box>
                          </FormControl>
                        </HStack>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.refrigerantePerdidaA}
                              name="refrigerantePerdidaA"
                            />
                            <Text>Refrigerante: Sin pérdidas?</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no registran pérdidas de líquido
                              refrigerante en el sistema
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.estadoBorneasA}
                              name="estadoBorneasA"
                            />
                            <Text>Eléctrico: Estado borneras</Text>
                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el estado de borneras es normal
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpiezaFiltroAireA}
                              name="limpiezaFiltroAireA"
                            />
                            <Text>Limpieza de filtro primario de aire</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Realizar limpieza con golpes suaves en el cuerpo
                              de filtro. Marcar, una vez realizada la limpieza,
                              si el indicador de saturación de admisión no
                              indica reemplazo
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpizaA}
                              name="limpizaA"
                            />
                            <Text>Limpieza</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no hay depósitos de arena
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack w="100%" justifyContent="end" p={3}>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else {
                              if (!values.responsable) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "Responsable requerido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (!values.sanderA) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "Sander requerido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.powerPackA ||
                                validateOnSubmit("number", values.powerPackA)
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Power Pack requerido o inválido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.nivelCombustibleA ||
                                validateOnSubmit(
                                  "number",
                                  values.nivelCombustibleA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Nivel de combustible requerido o inválido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionAceiteA ||
                                validateOnSubmit(
                                  "number",
                                  values.presionAceiteA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de aceite requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.tempRefrigeranteA ||
                                validateOnSubmit(
                                  "number",
                                  values.tempRefrigeranteA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Temperatura de refigerante requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                (values.horasMotorA &&
                                  validateOnSubmit(
                                    "number",
                                    values.horasMotorA
                                  )) ||
                                (values.qrCodeA1 &&
                                  validateOnSubmit(
                                    "numerosLetras",
                                    values.qrCodeA1
                                  ))
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "Datos invalidos",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 1 && (
                    <Stack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeA2A"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR A.2")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.qrCodeA2A && touched.qrCodeA2A
                                }
                                w={"2xs"}
                              >
                                <FormLabel>QR Code A.2</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeA2A"
                                    value={values.qrCodeA2A}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.qrCodeA2A}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR2"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>
                      </HStack>
                      <HStack>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.bandaTransportadoraA}
                                name="bandaTransportadoraA"
                              />
                              <Text>Banda Transportadora</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si Estado OK. Verificar ausencia de
                              cortaduras, roce, daños.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.alineacionBandaA}
                                name="alineacionBandaA"
                              />
                              <Text>Alineación de Banda</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si la banda se encuentra centrada sobre
                              rodillos y cuna. Alinear si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosRetornoA}
                                name="rolosRetornoA"
                              />
                              <Text>Rolos de Retorno</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la banda a baja velocidad. Marcar si los
                              rodillos giran libremente sin ruido o vibraciones
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosVentiladosA}
                                name="rolosVentiladosA"
                              />
                              <Text>Rolos ventilados</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si no hay fisuras, deformación, falta de
                              bulones, juego en conos, desgaste apreciable
                            </Text>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.rolosSdriveA}
                                name="rolosSdriveA"
                              />
                              <Text>Rolos Sdrive</Text>
                            </HStack>

                            <Text fontSize="10px">
                              Marcar si no hay fisuras, deformación,
                              oscilaciones, desalineación, desgaste apreciable
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.unionBandaA}
                                name="unionBandaA"
                              />
                              <Text>Unión de Banda</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si no hay fisuras, desgaste, roturas,
                              pérdida de tornillos, apertura de fajas, etc.
                            </Text>
                          </FormControl>
                        </Stack>
                        <Stack placeSelf="start">
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.ctcpA}
                                name="ctcpA"
                              />
                              <Text>
                                Cadena de Transmisión de cinta principal
                              </Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si la
                              cadena gira sin vibraciones o roses contra el
                              protector de cadena. Tensar si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.acoplamientoCFA}
                                name="acoplamientoCFA"
                              />
                              <Text>Acoplamiento de Cinta Frontal</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si el
                              elemento elástico está en buen estado, no hay
                              desalineación, juego, vibraciones o deslizamiento
                              sobre ejes.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.anclajePlacaCeldasA}
                                name="anclajePlacaCeldasA"
                              />
                              <Text>Anclaje placas de celdas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las placas de apoyo están fijas y no hay
                              arena entre placas y celdas
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionRodamientosA}
                                name="lubricacionRodamientosA"
                              />
                              <Text>Lubricación de rodamientos</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok luego de haberlos lubricado. “Lubricar
                              cajas de rodamiento correspondientes a rolos
                              tractores (x4), rolos ventilados (x4) y rolo
                              S-Drive (x2)”
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionCadenaA}
                                name="lubricacionCadenaA"
                              />
                              <Text>Lubricación de cadena</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok luego de haber lubricado
                            </Text>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else if (
                              values.qrCodeA2A &&
                              validateOnSubmit(
                                "numerosLetras",
                                values.qrCodeA2A
                              )
                            ) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "QR A.2 incorrecto",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 2 && (
                    <Stack>
                      <HStack justifyContent="space-between">
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeA3A"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR A.1")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.qrCodeA3A && touched.qrCodeA3A
                                }
                                w={"2xs"}
                              >
                                <FormLabel>QR Code A.3</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    w={"2xs"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeA3A"
                                    value={values.qrCodeA3A}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.qrCodeA3A}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderAQR3"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="hsCircuitoHidraA"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Horas Circuito Hidráulico"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.hsCircuitoHidraA &&
                                  touched.hsCircuitoHidraA
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel fontWeight="semibold">
                                  Horas Circuito Hidráulico (Q.Variable)
                                </FormLabel>
                                <Input
                                  {...field}
                                  w={"2xs"}
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="hsCircuitoHidraA"
                                  value={values.hsCircuitoHidraA}
                                />
                                <FormErrorMessage>
                                  {errors.hsCircuitoHidraA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="tempAceiteHidraA"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Horas Circuito Hidráulico"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.tempAceiteHidraA &&
                                  touched.tempAceiteHidraA
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Aceite Hidráulico: Temperatura
                                </FormLabel>
                                <Input
                                  {...field}
                                  w={"2xs"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="tempAceiteHidraA"
                                  value={values.tempAceiteHidraA}
                                />
                                <Text w="xs" fontSize="10px" placeSelf="end">
                                  Registrar valor de temperatura en °C. Rango
                                  normal: 40-80°
                                </Text>
                                <FormErrorMessage>
                                  {errors.tempAceiteHidraA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteHidraA}
                              name="nivelAceiteHidraA"
                            />
                            <Text>Aceite Hidráulico: Nivel</Text>

                            <Text fontSize="10px">
                              Marcar si el Nivel del Aceite Hidráulico es Normal
                              (entre marcas de máximo y mínimo). Si el nivel de
                              hidráulico es inferior al punto mínimo, completar
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.aceiteHidraPerdidaA}
                              name="aceiteHidraPerdidaA"
                            />
                            <Text>Aceite Hidráulico: Sin Pérdidas?</Text>

                            <Text fontSize="10px">
                              Marcar si no se registran pérdidas de aceite
                              hidráulico
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.movimientoCarrosA}
                              name="movimientoCarrosA"
                            />
                            <Text>Movimiento de Carros</Text>

                            <Text fontSize="10px">
                              Marcar si el movimiento de los carros es Normal
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionRetonoBEA"
                            validate={(value) =>
                              validateField(
                                "decimal",
                                value,
                                "registro de presión"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionRetonoBEA &&
                                  touched.presionRetonoBEA
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de retorno de bomba de engranajes
                                </FormLabel>
                                <NumberInput
                                  {...field}
                                  w={"100%"}
                                  defaultValue={values.presionRetonoBEA}
                                  min={0}
                                  max={8}
                                  step={0.1}
                                  maxW={32}
                                  onChange={(val) =>
                                    setFieldValue("presionRetonoBEA", val)
                                  }
                                  onBlur={handleBlur}
                                  name="presionRetonoBEA"
                                  allowMouseWheel
                                >
                                  <NumberInputField
                                    value={values.presionRetonoBEA}
                                    name="presionRetonoBEA"
                                    maxLength={1}
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper
                                      bg="green.500"
                                      color={"white"}
                                    >
                                      <AddIcon />
                                    </NumberIncrementStepper>
                                    <NumberDecrementStepper
                                      bg="red.500"
                                      color={"white"}
                                    >
                                      <MinusIcon />
                                    </NumberDecrementStepper>
                                  </NumberInputStepper>
                                </NumberInput>
                                <Text fontSize="10px">
                                  Registrar presión en bar. Max. 8 bar
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionRetonoBEA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionSantByBPA"
                            validate={(value) =>
                              validateField("number", value, "valor")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionSantByBPA &&
                                  touched.presionSantByBPA
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de stand by de bomba de pistones
                                </FormLabel>
                                <InputGroup w="2xs">
                                  <Input
                                    {...field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="presionSantByBPA"
                                    value={values.presionSantByBPA}
                                  />
                                  <InputRightAddon>BAR</InputRightAddon>
                                </InputGroup>
                                <Text fontSize="10px">
                                  Motor a 1700 RPM y cintas detenidas.
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionSantByBPA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionTrabajoBPA"
                            validate={(value) =>
                              validateField("number", value, "valor de presión")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionTrabajoBPA &&
                                  touched.presionTrabajoBPA
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de trabajo de bomba de pistones
                                </FormLabel>

                                <InputGroup w="2xs">
                                  <Input
                                    {...field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="presionTrabajoBPA"
                                    value={values.presionTrabajoBPA}
                                  />
                                  <InputRightAddon>BAR</InputRightAddon>
                                </InputGroup>
                                <Text fontSize="10px">
                                  Checkear presión durante máxima concentración
                                  de etapa anterior.
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionTrabajoBPA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => setActiveStep(activeStep - 1)}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else {
                              if (
                                !values.hsCircuitoHidraA ||
                                validateOnSubmit(
                                  "number",
                                  values.hsCircuitoHidraA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Horas Circuito Hidráulico requeridos o inválido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.tempAceiteHidraA ||
                                validateOnSubmit(
                                  "number",
                                  values.tempAceiteHidraA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Aceite Hidráulico: Temperatura requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionRetonoBEA ||
                                validateOnSubmit(
                                  "decimal",
                                  values.presionRetonoBEA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de retorno de bomba de engranajes requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionSantByBPA ||
                                validateOnSubmit(
                                  "number",
                                  values.presionSantByBPA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de stand by de bomba de pistones requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionTrabajoBPA ||
                                validateOnSubmit(
                                  "number",
                                  values.presionTrabajoBPA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de trabajo de bomba de pistones requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                values.qrCodeA3A &&
                                validateOnSubmit(
                                  "numerosLetras",
                                  values.qrCodeA3A
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "QR A.3 incorrecto",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 3 && (
                    <Stack>
                      <HStack spacing={20} px={3}>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.tablerosCerradosA}
                                name="tablerosCerradosA"
                              />
                              <Text>Tableros cerrados</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si están bien cerrados
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.cableComandoA}
                                name="cableComandoA"
                              />
                              <Text>Cable de comando</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si está en buenas condiciones
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.calibracionBalanzaA}
                                name="calibracionBalanzaA"
                              />
                              <Text>Calibración de balanzas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar ok si están calibradas. 10% máxima
                              imprecision
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.semaforoLucesA}
                                name="semaforoLucesA"
                              />
                              <Text>Funcionamiento Semáforos y Luces</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las luces y semáforos funcionan
                              correctamente
                            </Text>
                          </FormControl>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="observacionesSA"
                            validate={(value) =>
                              validateField("textArea", value, "Observaciones")
                            }
                            w={"md"}
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors?.observacionesSA &&
                                  touched?.observacionesSA
                                }
                              >
                                <FormLabel>
                                  Observaciones y Comentarios
                                </FormLabel>
                                <Card
                                  variant="unstyled"
                                  bg="brand.fondos_secundarios"
                                >
                                  <CardBody>
                                    <Textarea
                                      {...field}
                                      w={"md"}
                                      placeholder="Descripcion"
                                      name="observacionesSA"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength="1500"
                                      resize="none"
                                    />
                                  </CardBody>
                                  <CardFooter
                                    placeSelf="end"
                                    color="brand.gris_medio"
                                    pr={2}
                                    m={"unset"}
                                  >
                                    {values.observacionesSA.length}/1500
                                  </CardFooter>
                                </Card>

                                <Text fontSize="10px">
                                  Agregue aquí comentarios y observaciones
                                  acerca de la revisión general de equipos
                                </Text>
                                <FormErrorMessage>
                                  {errors?.observacionesSA}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <HStack justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => setActiveStep(activeStep - 1)}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        {!planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormularioEtapa"
                            isDisabled={loading}
                            onClick={() => {
                              if (
                                values.observacionesSA &&
                                validateOnSubmit(
                                  "textArea",
                                  values.observacionesSA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "La observación es inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setSanderBackUp("SanderB");
                                setActiveStep(activeStep - 3);
                              }
                            }}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Pasar a SANDER B
                          </Button>
                        )}
                        {planificacion.dosificadora && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormSander"
                            isDisabled={loading}
                            onClick={() => {
                              if (
                                values.observacionesSA &&
                                validateOnSubmit(
                                  "textArea",
                                  values.observacionesSA
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "La observación es inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Siguiente
                          </Button>
                        )}
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 4 && (
                    <Stack>
                      <HStack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP1A}
                                name="verResortesP1A"
                              />
                              <Text>Verificar estado de resortes P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP2A}
                                name="verResortesP2A"
                              />
                              <Text>Verificar estado de resortes P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP3A}
                                name="verResortesP3A"
                              />
                              <Text>Verificar estado de resortes P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP4A}
                                name="verResortesP4A"
                              />
                              <Text>Verificar estado de resortes P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP1A}
                                name="verContactoresP1A"
                              />
                              <Text>Verificar estado de contactores P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP2A}
                                name="verContactoresP2A"
                              />
                              <Text>Verificar estado de contactores P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP3A}
                                name="verContactoresP3A"
                              />
                              <Text>Verificar estado de contactores P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP4A}
                                name="verContactoresP4A"
                              />
                              <Text>Verificar estado de contactores P4</Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP1A}
                                name="verCableadoP1A"
                              />
                              <Text>Verificar cableado de contactor P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP2A}
                                name="verCableadoP2A"
                              />
                              <Text>Verificar cableado de contactor P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP3A}
                                name="verCableadoP3A"
                              />
                              <Text>Verificar cableado de contactor P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP4A}
                                name="verCableadoP4A"
                              />
                              <Text>Verificar cableado de contactor P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP1A}
                                name="verConMangueraP1A"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P1
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP2A}
                                name="verConMangueraP2A"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P2
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP3A}
                                name="verConMangueraP3A"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P3
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP4A}
                                name="verConMangueraP4A"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P4
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            setSanderBackUp("SanderB");
                            setActiveStep(
                              planificacion.dosificadora
                                ? activeStep - 4
                                : activeStep - 3
                            );
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Pasar a SANDER B
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                </>
              )}
              {sanderBackUp === "SanderB" && (
                <>
                  {activeStep === 0 && (
                    <Stack>
                      <HStack>
                        {tipo === "crear" && (
                          <FormControl isRequired>
                            <FormLabel>Sander B</FormLabel>
                            <Select
                              w="2xs"
                              size="sm"
                              icon={<MdArrowDropDown />}
                              name="sanderB"
                              value={values.sanderB}
                              placeholder="Seleccionar Sander"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {sanders
                                .filter(
                                  (sander) =>
                                    sander.idSander !== parseInt(values.sanderA)
                                )
                                .map((sander, i) => (
                                  <option key={i} value={sander.idSander}>
                                    {sander.codigoSander}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                        {tipo === "editar" && (
                          <FormControl w="2xs" size="sm" isRequired>
                            <FormLabel>Sander B</FormLabel>
                            <Input
                              value={values.sanderB}
                              w="2xs"
                              size="sm"
                              bg="brand.gris_medio"
                              readOnly
                            />
                          </FormControl>
                        )}
                        <FormControl></FormControl>
                        <FormControl></FormControl>
                      </HStack>
                      <Text fontWeight="bold" color="brand.gris_medio">
                        Power Pack
                      </Text>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeB1"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR A.1")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={errors.qrCodeB1 && touched.qrCodeB1}
                                w={"2xs"}
                              >
                                <FormLabel>QR Code B.1</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    placeholder="QR Code B.1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeB1"
                                    value={values.qrCodeB1}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.qrCodeB1}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR1"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="powerPackB"
                            validate={(value) =>
                              validateField("number", value, "Id Pad")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.powerPackB && touched.powerPackB
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Power Pack</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Power Pack"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="powerPackB"
                                  value={values.powerPackB}
                                />
                                <FormErrorMessage>
                                  {errors.powerPackB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>

                        <Stack placeSelf={"start"}>
                          <Field
                            name="horasMotorB"
                            validate={(value) =>
                              validateField("number", value, "Id Pad")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.horasMotorB && touched.horasMotorB
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Horas del Motor</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Horas del Motor"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="horasMotorB"
                                  value={values.horasMotorB}
                                />
                                <FormErrorMessage>
                                  {errors.horasMotorB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="nivelCombustibleB"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Nivel de combustible"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.nivelCombustibleB &&
                                  touched.nivelCombustibleB
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Nivel de combustible</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="% nivel de combustible"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="nivelCombustibleB"
                                  value={values.nivelCombustibleB}
                                />
                                <FormErrorMessage>
                                  {errors.nivelCombustibleB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={
                            errors.nivelCombustibleB ? "center" : "end"
                          }
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasCombustibleB}
                              name="perdidasCombustibleB"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px">
                            Marcar si NO se registran pérdidas de combustible
                          </Text>
                        </FormControl>

                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionAceiteB"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Presión de aceite"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionAceiteB &&
                                  touched.presionAceiteB
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Presión del aceite</FormLabel>

                                <Input
                                  {...field}
                                  placeholder="Presión del aceite"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="presionAceiteB"
                                  value={values.presionAceiteB}
                                />
                                <FormErrorMessage>
                                  {errors.presionAceiteB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={errors.presionAceiteB ? "center" : "end"}
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteB}
                              name="nivelAceiteB"
                            />
                            <Text>Nivel de aceite normal?</Text>
                          </HStack>

                          <Text fontSize="10px">
                            Marcar si el nivel de acite ES NORMAL
                          </Text>
                        </FormControl>
                        <FormControl w="2xs"></FormControl>
                      </HStack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="tempRefrigeranteB"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Temp. refrigerante"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.tempRefrigeranteB &&
                                  touched.tempRefrigeranteB
                                }
                                w={"2xs"}
                                isRequired
                              >
                                <FormLabel>Temperatura refrigerante</FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Temperatura refrigerante"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="tempRefrigeranteB"
                                  value={values.tempRefrigeranteB}
                                />
                                <FormErrorMessage>
                                  {errors.tempRefrigeranteB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={
                            errors.tempRefrigeranteB ? "center" : "end"
                          }
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.perdidasRefrigeranteB}
                              name="perdidasRefrigeranteB"
                            />
                            <Text>Sin pérdidas?</Text>
                          </HStack>
                          <Text fontSize="10px">
                            Marcar si NO se registran pérdidas de refrigerante
                          </Text>
                        </FormControl>
                        <FormControl
                          isRequired
                          w="sm"
                          placeSelf={
                            errors.nivelRefrigeranteB ? "center" : "end"
                          }
                        >
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelRefrigeranteB}
                              name="nivelRefrigeranteB"
                            />
                            <Text>Nivel de refrigerante normal?</Text>
                          </HStack>

                          <Text fontSize="10px">
                            Marcar si el nivel de refrigerante ES NORMAL
                          </Text>
                        </FormControl>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.refrigerantePerdidaB}
                              name="refrigerantePerdidaB"
                            />
                            <Text>Refrigerante: Sin pérdidas?</Text>

                            <Text fontSize="10px">
                              Marcar si no registran pérdidas de líquido
                              refrigerante en el sistema
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.estadoBorneasB}
                              name="estadoBorneasB"
                            />
                            <Text>Eléctrico: Estado borneras</Text>

                            <Text fontSize="10px">
                              Marcar si el estado de borneras es normal
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpiezaFiltroAireB}
                              name="limpiezaFiltroAireB"
                            />
                            <Text>Limpieza de filtro primario de aire</Text>
                            <Text fontSize="10px">
                              Realizar limpieza con golpes suaves en el cuerpo
                              de filtro. Marcar, una vez realizada la limpieza,
                              si el indicador de saturación de admisión no
                              indica reemplazo
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.limpizaB}
                              name="limpizaB"
                            />
                            <Text>Limpieza</Text>
                            <Text fontSize="10px">
                              Marcar si no hay depósitos de arena
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setSanderBackUp("SanderA");
                            setActiveStep(activeStep + 3);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else {
                              if (!values.sanderB) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "Sander requerido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.powerPackB ||
                                validateOnSubmit("number", values.powerPackB)
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Power Pack requerido o inválido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.nivelCombustibleB ||
                                validateOnSubmit(
                                  "number",
                                  values.nivelCombustibleB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Nivel de combustible requerido o inválido",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionAceiteB ||
                                validateOnSubmit(
                                  "number",
                                  values.presionAceiteB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de aceite requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.tempRefrigeranteB ||
                                validateOnSubmit(
                                  "number",
                                  values.tempRefrigeranteB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Temperatura de refigerante requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                (values.horasMotorB &&
                                  validateOnSubmit(
                                    "number",
                                    values.horasMotorB
                                  )) ||
                                (values.qrCodeB1 &&
                                  validateOnSubmit(
                                    "numerosLetras",
                                    values.qrCodeB1
                                  ))
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "Datos invalidos",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 1 && (
                    <Stack>
                      <HStack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeB2"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR B.2")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={errors.qrCodeB2 && touched.qrCodeB2}
                                w={"2xs"}
                              >
                                <FormLabel>QR Code B.2</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeB2"
                                    value={values.qrCodeB2}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <Text fontSize="10px" placeSelf="end">
                                  Capture QR con cámara
                                </Text>
                                <FormErrorMessage>
                                  {errors.qrCodeB2}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR2"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>
                      </HStack>
                      <HStack>
                        <Stack>
                          <CheckboxGroup>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.bandaTransportadoraB}
                                  name="bandaTransportadoraB"
                                />
                                <Text>Banda Transportadora</Text>
                              </HStack>

                              <Text fontSize="10px">
                                Marcar si Estado OK. Verificar ausencia de
                                cortaduras, roce, daños.
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.alineacionBandaB}
                                  name="alineacionBandaB"
                                />
                                <Text>Alineación de Banda</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si la banda se encuentra centrada sobre
                                rodillos y cuna. Alinear si es necesario
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosRetornoB}
                                  name="rolosRetornoB"
                                />
                                <Text>Rolos de Retorno</Text>
                              </HStack>

                              <Text fontSize="10px">
                                Marchar la banda a baja velocidad. Marcar si los
                                rodillos giran libremente sin ruido o
                                vibraciones
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosVentiladosB}
                                  name="rolosVentiladosB"
                                />
                                <Text>Rolos ventilados</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, deformación, falta de
                                bulones, juego en conos, desgaste apreciable
                              </Text>
                            </FormControl>

                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.rolosSdriveB}
                                  name="rolosSdriveB"
                                />
                                <Text>Rolos Sdrive</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, deformación,
                                oscilaciones, desalineación, desgaste apreciable
                              </Text>
                            </FormControl>
                            <FormControl>
                              <HStack>
                                <Switch
                                  onChange={handleChange}
                                  isChecked={values.unionBandaB}
                                  name="unionBandaB"
                                />
                                <Text>Unión de Banda</Text>
                              </HStack>
                              <Text fontSize="10px">
                                Marcar si no hay fisuras, desgaste, roturas,
                                pérdida de tornillos, apertura de fajas, etc.
                              </Text>
                            </FormControl>
                          </CheckboxGroup>
                        </Stack>
                        <Stack placeSelf="start">
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.ctcpB}
                                name="ctcpB"
                              />
                              <Text>
                                Cadena de Transmisión de cinta principal
                              </Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si la
                              cadena gira sin vibraciones o roses contra el
                              protector de cadena. Tensar si es necesario
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.acoplamientoCFB}
                                name="acoplamientoCFB"
                              />
                              <Text>Acoplamiento de Cinta Frontal</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marchar la cinta a baja velocidad. Marcar si el
                              elemento elástico está en buen estado, no hay
                              desalineación, juego, vibraciones o deslizamiento
                              sobre ejes.
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.anclajePlacaCeldasB}
                                name="anclajePlacaCeldasB"
                              />
                              <Text>Anclaje placas de celdas</Text>
                            </HStack>
                            <Text fontSize="10px">
                              Marcar si las placas de apoyo están fijas y no hay
                              arena entre placas y celdas
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionRodamientosB}
                                name="lubricacionRodamientosB"
                              />
                              <Text>Lubricación de rodamientos</Text>
                            </HStack>

                            <Text fontSize="10px">
                              Marcar ok luego de haberlos lubricado. “Lubricar
                              cajas de rodamiento correspondientes a rolos
                              tractores (x4), rolos ventilados (x4) y rolo
                              S-Drive (x2)”
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.lubricacionCadenaB}
                                name="lubricacionCadenaB"
                              />
                              <Text>Lubricación de cadena</Text>

                              <Text fontSize="10px">
                                Marcar ok luego de haber lubricado
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else if (
                              values.qrCodeB2 &&
                              validateOnSubmit("numerosLetras", values.qrCodeB2)
                            ) {
                              setLoading(false);
                              return toast({
                                title: "Error",
                                description: "QR B.2 incorrecto",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                              });
                            } else {
                              setActiveStep(activeStep + 1);
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 2 && (
                    <Stack w="100%" spacing={7}>
                      <HStack justifyContent="space-between">
                        <Stack placeSelf={"start"}>
                          <Field
                            name="qrCodeA3B"
                            validate={(value) =>
                              validateField("numerosLetras", value, "QR B.3")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.qrCodeA3B && touched.qrCodeA3B
                                }
                                w={"2xs"}
                              >
                                <FormLabel>QR Code B.3</FormLabel>
                                <InputGroup size="md">
                                  <Input
                                    {...field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="qrCodeA3B"
                                    value={values.qrCodeA3B}
                                  />
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={onOpen}
                                    >
                                      <MdOutlineQrCode2 />
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                <Text fontSize="10px" placeSelf="end">
                                  Capture QR con cámara
                                </Text>
                                <FormErrorMessage>
                                  {errors.qrCodeA3B}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalBody>
                                <CSSReset />
                                <HandleQrReader
                                  scannedData={scannedData}
                                  setScannedData={setScannedData}
                                  handlecodigo={handlecodigo}
                                  type="sanderBQR3"
                                  setFieldValue={setFieldValue}
                                />
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="hsCircuitoHidraB"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Horas Circuito Hidráulico"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.hsCircuitoHidraB &&
                                  touched.hsCircuitoHidraB
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Horas Circuito Hidráulico (Q.Variable)
                                </FormLabel>
                                <Input
                                  {...field}
                                  type="number"
                                  w="2xs"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="hsCircuitoHidraB"
                                  value={values.hsCircuitoHidraB}
                                />
                                <FormErrorMessage>
                                  {errors.hsCircuitoHidraB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="tempAceiteHidraB"
                            validate={(value) =>
                              validateField(
                                "number",
                                value,
                                "Aceite Hidráulico"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.tempAceiteHidraB &&
                                  touched.tempAceiteHidraB
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Aceite Hidráulico: Temperatura
                                </FormLabel>
                                <Input
                                  {...field}
                                  w="2xs"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="tempAceiteHidraB"
                                  value={values.tempAceiteHidraB}
                                />
                                <Text w="xs" fontSize="10px" placeSelf="end">
                                  Registrar valor de temperatura en °C. Rango
                                  normal: 40-80°
                                </Text>
                                <FormErrorMessage>
                                  {errors.tempAceiteHidraB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <Stack>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.nivelAceiteHidraB}
                              name="nivelAceiteHidraB"
                            />
                            <Text>Aceite Hidráulico: Nivel</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el Nivel del Aceite Hidráulico es Normal
                              (entre marcas de máximo y mínimo). Si el nivel de
                              hidráulico es inferior al punto mínimo, completar
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.aceiteHidraPerdidaB}
                              name="aceiteHidraPerdidaB"
                            />
                            <Text>Aceite Hidráulico: Sin Pérdidas?</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si no se registran pérdidas de aceite
                              hidráulico
                            </Text>
                          </HStack>
                        </FormControl>
                        <FormControl>
                          <HStack>
                            <Switch
                              onChange={handleChange}
                              isChecked={values.movimientoCarrosB}
                              name="movimientoCarrosB"
                            />
                            <Text>Movimiento de Carros</Text>

                            <Text fontSize="10px" placeSelf="end">
                              Marcar si el movimiento de los carros es Normal
                            </Text>
                          </HStack>
                        </FormControl>
                      </Stack>
                      <HStack justifyContent="space-between">
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionRetonoBEB"
                            validate={(value) =>
                              validateField(
                                "decimal",
                                value,
                                "registro de presión"
                              )
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionRetonoBEB &&
                                  touched.presionRetonoBEB
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de retorno de bomba de engranajes
                                </FormLabel>
                                <NumberInput
                                  {...field}
                                  w={"100%"}
                                  defaultValue={values.presionRetonoBEB}
                                  min={0}
                                  max={8}
                                  step={0.1}
                                  maxW={32}
                                  onChange={(val) =>
                                    setFieldValue("presionRetonoBEB", val)
                                  }
                                  onBlur={handleBlur}
                                  name="presionRetonoBEB"
                                  allowMouseWheel
                                >
                                  <NumberInputField
                                    value={values.presionRetonoBEB}
                                    name="presionRetonoBEB"
                                    maxLength={1}
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper
                                      bg="green.500"
                                      color={"white"}
                                    >
                                      <AddIcon />
                                    </NumberIncrementStepper>
                                    <NumberDecrementStepper
                                      bg="red.500"
                                      color={"white"}
                                    >
                                      <MinusIcon />
                                    </NumberDecrementStepper>
                                  </NumberInputStepper>
                                </NumberInput>
                                <Text fontSize="10px">
                                  Registrar presión en bar. Max. 8 bar
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionRetonoBEB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionSantByBPB"
                            validate={(value) =>
                              validateField("number", value, "valor")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionSantByBPB &&
                                  touched.presionSantByBPB
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de stand by de bomba de pistones
                                </FormLabel>
                                <InputGroup w="2xs">
                                  <Input
                                    {...field}
                                    w="2xs"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="presionSantByBPB"
                                    value={values.presionSantByBPB}
                                  />
                                  <InputRightAddon>BAR</InputRightAddon>
                                </InputGroup>
                                <Text fontSize="10px">
                                  Motor a 1700 RPM y cintas detenidas.
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionSantByBPB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>

                        <Stack placeSelf={"start"}>
                          <Field
                            name="presionTrabajoBPB"
                            validate={(value) =>
                              validateField("number", value, "valor")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors.presionTrabajoBPB &&
                                  touched.presionTrabajoBPB
                                }
                                w={"xs"}
                                isRequired
                              >
                                <FormLabel>
                                  Presión de trabajo de bomba de pistones
                                </FormLabel>
                                <InputGroup w="2xs">
                                  <Input
                                    {...field}
                                    w="2xs"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="presionTrabajoBPB"
                                    value={values.presionTrabajoBPB}
                                  />
                                  <InputRightAddon>BAR</InputRightAddon>
                                </InputGroup>
                                <Text w="xs" fontSize="10px">
                                  Checkear presión durante máxima concentración
                                  de etapa anterior.
                                </Text>
                                <FormErrorMessage>
                                  {errors.presionTrabajoBPB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdNavigateNext fontSize="20px" />}
                          variant="solid"
                          form="FormularioEtapa"
                          isDisabled={loading}
                          onClick={() => {
                            if (user.idRol === "usuarioIT") {
                              handleUsuarioIT(setActiveStep, activeStep);
                            } else {
                              if (
                                !values.hsCircuitoHidraB ||
                                validateOnSubmit(
                                  "number",
                                  values.hsCircuitoHidraB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Horas Circuito Hidráulico requeridas o inválidas",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.tempAceiteHidraB ||
                                validateOnSubmit(
                                  "number",
                                  values.tempAceiteHidraB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Aceite Hidráulico: Temperatura requerida o inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionRetonoBEB ||
                                validateOnSubmit(
                                  "decimal",
                                  values.presionRetonoBEB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de retorno de bomba de engranajes requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionSantByBPB ||
                                validateOnSubmit(
                                  "number",
                                  values.presionSantByBPB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de stand by de bomba de pistones requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                !values.presionTrabajoBPB ||
                                validateOnSubmit(
                                  "number",
                                  values.presionTrabajoBPB
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description:
                                    "Presión de trabajo de bomba de pistones requerida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else if (
                                values.qrCodeA3B &&
                                validateOnSubmit(
                                  "numerosLetras",
                                  values.qrCodeA3B
                                )
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "QR B.3 incorrecto",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }
                          }}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Continuar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 3 && (
                    <Stack>
                      <HStack justifyContent="space-between" px={5}>
                        <Stack>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.tablerosCerradosB}
                                name="tablerosCerradosB"
                              />
                              <Text>Tableros cerrados</Text>

                              <Text fontSize="10px">
                                Marcar ok si están bien cerrados
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.cableComandoB}
                                name="cableComandoB"
                              />
                              <Text>Cable de comando</Text>

                              <Text fontSize="10px">
                                Marcar ok si está en buenas condiciones
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.calibracionBalanzaB}
                                name="calibracionBalanzaB"
                              />
                              <Text>Calibración de balanzas</Text>

                              <Text fontSize="10px">
                                Marcar ok si están calibradas. 10% máxima
                                imprecision
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.semaforoLucesB}
                                name="semaforoLucesB"
                              />
                              <Text>Funcionamiento Semáforos y Luces</Text>

                              <Text fontSize="10px">
                                Marcar si las luces y semáforos funcionan
                                correctamente
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <Stack placeSelf={"start"} w={"lg"}>
                          <Field
                            name="observacionesSB"
                            validate={(value) =>
                              validateField("textArea", value, "Observaciones")
                            }
                          >
                            {({ field }) => (
                              <FormControl
                                isInvalid={
                                  errors?.observacionesSB &&
                                  touched?.observacionesSB
                                }
                              >
                                <FormLabel>
                                  Observaciones y Comentarios
                                </FormLabel>
                                <Card
                                  variant="unstyled"
                                  bg="brand.fondos_secundarios"
                                >
                                  <CardBody>
                                    <Textarea
                                      {...field}
                                      w="lg"
                                      placeholder="Descripcion"
                                      name="observacionesSB"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength="1500"
                                      resize="none"
                                    />
                                  </CardBody>
                                  <CardFooter
                                    placeSelf="end"
                                    color="brand.gris_medio"
                                    pr={2}
                                    m={"unset"}
                                  >
                                    {values.observacionesSB.length}/1500
                                  </CardFooter>
                                </Card>
                                <Text fontSize="10px">
                                  Agregue aquí comentarios y observaciones
                                  acerca de la revisión general de equipos
                                </Text>
                                <FormErrorMessage>
                                  {errors?.observacionesSB}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                      </HStack>
                      <Stack placeSelf={"start"} w={"lg"}>
                        <Field
                          name="observacionesGral"
                          validate={(value) =>
                            validateField("textArea", value, "Observaciones")
                          }
                        >
                          {({ field }) => (
                            <FormControl
                              isInvalid={
                                errors?.observacionesGral &&
                                touched?.observacionesGral
                              }
                            >
                              <FormLabel fontSize="20px">
                                Informe final
                              </FormLabel>
                              <Card
                                variant="unstyled"
                                bg="brand.fondos_secundarios"
                              >
                                <CardBody>
                                  <Textarea
                                    {...field}
                                    w="lg"
                                    placeholder="Descripcion"
                                    onBlur={handleBlur}
                                    maxLength="1500"
                                    resize="none"
                                    name="observacionesGral"
                                    onChange={handleChange}
                                  />
                                </CardBody>
                                <CardFooter
                                  placeSelf="end"
                                  color="brand.gris_medio"
                                  pr={2}
                                  m={"unset"}
                                >
                                  {values.observacionesGral.length}/1500
                                </CardFooter>
                              </Card>
                              <FormErrorMessage>
                                {errors?.observacionesGral}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        {!planificacion.dosificadora &&
                          user.idRol !== "usuarioIT" && (
                            <Button
                              fontSize={12}
                              fontWeight="semibold"
                              bg="brand.naranja"
                              color="white"
                              rightIcon={<MdFileDownloadDone fontSize="20px" />}
                              variant="solid"
                              form="FormSander"
                              isDisabled={loading}
                              onClick={handleSubmit}
                              _hover={{ bg: "white", color: "brand.naranja" }}
                            >
                              Finalizar
                            </Button>
                          )}
                        {(planificacion.dosificadora ||
                          user.idRol === "usuarioIT") && (
                          <Button
                            fontSize={12}
                            fontWeight="semibold"
                            bg="brand.naranja"
                            color="white"
                            rightIcon={<MdNavigateNext fontSize="20px" />}
                            variant="solid"
                            form="FormSander"
                            isDisabled={loading}
                            onClick={() => {
                              if (
                                (values.observacionesSB &&
                                  validateOnSubmit(
                                    "textArea",
                                    values.observacionesSB
                                  )) ||
                                (values.observacionesGral &&
                                  validateOnSubmit(
                                    "textArea",
                                    values.observacionesGral
                                  ))
                              ) {
                                setLoading(false);
                                return toast({
                                  title: "Error",
                                  description: "La observación es inválida",
                                  status: "error",
                                  isClosable: true,
                                  duration: 3000,
                                });
                              } else {
                                setActiveStep(activeStep + 1);
                              }
                            }}
                            _hover={{ bg: "white", color: "brand.naranja" }}
                          >
                            Siguiente
                          </Button>
                        )}
                      </HStack>
                    </Stack>
                  )}
                  {activeStep === 4 && (
                    <Stack>
                      <HStack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP1}
                                name="verResortesP1"
                              />
                              <Text>Verificar estado de resortes P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP2}
                                name="verResortesP2"
                              />
                              <Text>Verificar estado de resortes P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP3}
                                name="verResortesP3"
                              />
                              <Text>Verificar estado de resortes P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verResortesP4}
                                name="verResortesP4"
                              />
                              <Text>Verificar estado de resortes P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP1}
                                name="verContactoresP1"
                              />
                              <Text>Verificar estado de contactores P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP2}
                                name="verContactoresP2"
                              />
                              <Text>Verificar estado de contactores P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP3}
                                name="verContactoresP3"
                              />
                              <Text>Verificar estado de contactores P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verContactoresP4}
                                name="verContactoresP4"
                              />
                              <Text>Verificar estado de contactores P4</Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                        <Stack>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP1}
                                name="verCableadoP1"
                              />
                              <Text>Verificar cableado de contactor P1</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP2}
                                name="verCableadoP2"
                              />
                              <Text>Verificar cableado de contactor P2</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP3}
                                name="verCableadoP3"
                              />
                              <Text>Verificar cableado de contactor P3</Text>
                            </HStack>
                          </FormControl>
                          <FormControl isRequired w="sm">
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verCableadoP4}
                                name="verCableadoP4"
                              />
                              <Text>Verificar cableado de contactor P4</Text>
                            </HStack>
                          </FormControl>

                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP1}
                                name="verConMangueraP1"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P1
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP2}
                                name="verConMangueraP2"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P2
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP3}
                                name="verConMangueraP3"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P3
                              </Text>
                            </HStack>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Switch
                                onChange={handleChange}
                                isChecked={values.verConMangueraP4}
                                name="verConMangueraP4"
                              />
                              <Text>
                                verificar conectores y mangueras de aire -
                                contactor P4
                              </Text>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </HStack>
                      <HStack w="100%" justifyContent="space-between" p={3}>
                        <Button
                          fontSize={12}
                          letterSpacing={1}
                          border="1px solid orange"
                          fontWeight="semibold"
                          variant="outline"
                          color="brand.naranja"
                          leftIcon={<MdNavigateBefore fontSize="20px" />}
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}
                          alignSelf="end"
                          _hover={{ bg: "brand.naranja", color: "white" }}
                        >
                          Atras
                        </Button>
                        <Button
                          fontSize={12}
                          fontWeight="semibold"
                          bg="brand.naranja"
                          color="white"
                          rightIcon={<MdFileDownloadDone fontSize="20px" />}
                          variant="solid"
                          form="FormSander"
                          isDisabled={loading || user.idRol === "usuarioIT"}
                          onClick={handleSubmit}
                          _hover={{ bg: "white", color: "brand.naranja" }}
                        >
                          Finalizar
                        </Button>
                      </HStack>
                    </Stack>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default RevisionSander;
