import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  IconButton,
  Button,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getClientes,
  editarClientes,
  crearClientes,
} from "./../../helpers/api.helper";
import { validateField } from "../../../../utils/Validaciones";

const FormCrearEditarCliente = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getClientes(id);

    if (res.status === 200) {
      setCliente(res.data[0]);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          ciudadCliente: cliente.ciudadCliente,
          cuitCuilCliente: cliente.cuitCuilCliente,
          direccionCliente: cliente.direccionCliente,
          emaillCliente: cliente.emaillCliente,
          nombreCliente: cliente.nombreCliente,
          nombreEmpresaCl: cliente.nombreEmpresaCl,
          provinciaCliente: cliente.provinciaCliente,
          razonSocialCliente: cliente.razonSocialCliente,
          telefonoCliente: cliente.telefonoCliente,
          tipoCliente: cliente.tipoCliente,
        }
      : {
          ciudadCliente: "",
          cuitCuilCliente: "",
          direccionCliente: "",
          emaillCliente: "",
          nombreCliente: "",
          nombreEmpresaCl: "",
          provinciaCliente: "",
          razonSocialCliente: "",
          telefonoCliente: "",
          tipoCliente: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} Cliente
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.nombreCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre del cliente",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.cuitCuilCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "CUIT/CUIL requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.razonSocialCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Razon social requerida",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.telefonoCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Telefono requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.emaillCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Email requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
            if (!values.tipoCliente) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Tipo de cliente requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarClientes({
                ciudadCliente: values.ciudadCliente,
                cuitCuilCliente: values.cuitCuilCliente,
                direccionCliente: values.direccionCliente,
                emaillCliente: values.emaillCliente,
                nombreCliente: values.nombreCliente,
                nombreEmpresaCl: values.nombreEmpresaCl,
                provinciaCliente: values.provinciaCliente,
                razonSocialCliente: values.razonSocialCliente,
                telefonoCliente: values.telefonoCliente,
                tipoCliente: values.tipoCliente,
                id: cliente.idCliente,
              });
            }
            if (type === "crear") {
              res = await crearClientes({
                ciudadCliente: values.ciudadCliente,
                cuitCuilCliente: values.cuitCuilCliente,
                direccionCliente: values.direccionCliente,
                emaillCliente: values.emaillCliente,
                nombreCliente: values.nombreCliente,
                nombreEmpresaCl: values.nombreEmpresaCl,
                provinciaCliente: values.provinciaCliente,
                razonSocialCliente: values.razonSocialCliente,
                telefonoCliente: values.telefonoCliente,
                tipoCliente: values.tipoCliente,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Cliente ${
                  type === "crear" ? "creado" : "editado"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } cliente`,
                description:
                  res.data.error.message || "Error al editar usuario",
              });
            }

            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="formClientes">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="nombreCliente"
                      validate={(value) =>
                        validateField("letras", value, "nombre de cliente")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.nombreCliente && touched.nombreCliente
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">Nombre</FormLabel>
                          <Input
                            {...field}
                            name="nombreCliente"
                            type="text"
                            placeholder="Nombre"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nombreCliente}
                          />
                          <FormErrorMessage>
                            {errors.nombreCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Stack placeSelf={"start"}>
                    <Field
                      name="cuitCuilCliente"
                      validate={(value) =>
                        validateField("number", value, "CUIT/CUIL")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.cuitCuilCliente && touched.cuitCuilCliente
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">CUIT/CUIL</FormLabel>
                          <Input
                            {...field}
                            name="cuitCuilCliente"
                            type="text"
                            placeholder="CUIT/CUIL"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cuitCuilCliente}
                          />
                          <FormErrorMessage>
                            {errors.cuitCuilCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Stack placeSelf={"start"}>
                    <Field
                      name="razonSocialCliente"
                      validate={(value) =>
                        validateField("letras", value, "razón social")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.razonSocialCliente &&
                            touched.razonSocialCliente
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">Razón social</FormLabel>
                          <Input
                            {...field}
                            name="razonSocialCliente"
                            type="email"
                            placeholder="Razón social"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.razonSocialCliente}
                          />
                          <FormErrorMessage>
                            {errors.razonSocialCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </HStack>
                <HStack spacing={10}>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="telefonoCliente"
                      validate={(value) =>
                        validateField("number", value, "teléfono")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.telefonoCliente && touched.telefonoCliente
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">Teléfono</FormLabel>
                          <Input
                            {...field}
                            name="telefonoCliente"
                            type={"tel"}
                            placeholder="000-0000"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telefonoCliente}
                          />
                          <FormErrorMessage>
                            {errors.telefonoCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Stack placeSelf={"start"}>
                    <Field
                      name="emaillCliente"
                      validate={(value) =>
                        validateField("email", value, "correo")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.emaillCliente && touched.emaillCliente
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">Correo</FormLabel>
                          <Input
                            {...field}
                            name="emaillCliente"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.emaillCliente}
                          />
                          <FormErrorMessage>
                            {errors.emaillCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <FormControl w={"xs"} alignSelf="center" isRequired>
                    <FormLabel fontWeight="bold">Tipo cliente</FormLabel>
                    <Select
                      name="tipoCliente"
                      placeholder="Seleccione tipo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoCliente}
                    >
                      <option value={"companiaServicio"}>
                        Compania servicio
                      </option>
                      <option value={"operadora"}>Operadora</option>
                    </Select>
                  </FormControl>
                </HStack>
                <HStack spacing={10}>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="direccionCliente"
                      validate={(value) =>
                        validateField("numerosLetras", value, "direccion")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.direccionCliente && touched.direccionCliente
                          }
                          w={"xs"}
                        >
                          <FormLabel fontWeight="bold">Calle</FormLabel>
                          <Input
                            {...field}
                            name="direccionCliente"
                            type="text"
                            placeholder="Dirección"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.direccionCliente}
                          />
                          <FormErrorMessage>
                            {errors.direccionCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="ciudadCliente"
                      validate={(value) =>
                        validateField("letras", value, "cuidad")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.ciudadCliente && touched.ciudadCliente
                          }
                          w={"xs"}
                        >
                          <FormLabel fontWeight="bold">Ciudad</FormLabel>
                          <Input
                            {...field}
                            name="ciudadCliente"
                            type={"text"}
                            placeholder="Cuidad"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ciudadCliente}
                          />
                          <FormErrorMessage>
                            {errors.ciudadCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Stack placeSelf={"start"}>
                    <Field
                      name="provinciaCliente"
                      validate={(value) =>
                        validateField("letras", value, "provincia")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.provinciaCliente && touched.provinciaCliente
                          }
                          w={"xs"}
                        >
                          <FormLabel fontWeight="bold">Provincia</FormLabel>
                          <Input
                            {...field}
                            name="provinciaCliente"
                            type={"text"}
                            placeholder="Provincia"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.provinciaCliente}
                          />
                          <FormErrorMessage>
                            {errors.provinciaCliente}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formClientes"
                  color="white"
                  isLoading={loading}
                  isDisabled={user.idRol === "usuarioIT"}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarCliente;
