import React from "react";
import {
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Select,
  Card,
  CardBody,
  Checkbox,
  CardFooter,
  Stack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Field } from "formik";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { validateField } from "../../../utils/Validaciones";

const SegundoPasoRegistroFijo = (props) => {
  const {
    loading,
    setActiveStep,
    activeStep,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    usuarios,
    errors,
    touched,
    user,
  } = props;

  return (
    <Stack w="100%" px={5}>
      <HStack w="100%" justifyContent={"space-between"} pb={3}>
        <Stack placeSelf={"start"} w={"xs"}>
          <Field
            name="accionesRegistroEventos"
            validate={(value) => validateField("textArea", value, "texto")}
            w="xs"
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors?.accionesRegistroEventos &&
                  touched?.accionesRegistroEventos
                }
                isRequired
              >
                <FormLabel fontWeight="semibold">Acciones Inmediatas</FormLabel>
                <Card variant="unstyled" bg="brand.fondos_secundarios">
                  <CardBody>
                    <Textarea
                      {...field}
                      w="xs"
                      placeholder="Descripcion"
                      name="accionesRegistroEventos"
                      onChange={handleChange}
                      value={values.accionesRegistroEventos}
                      maxLength="250"
                      resize="none"
                    />
                  </CardBody>
                  <CardFooter
                    placeSelf="end"
                    color="brand.gris_medio"
                    pr={2}
                    m={"unset"}
                  >
                    {values.accionesRegistroEventos.length}/250
                  </CardFooter>
                </Card>
                <FormErrorMessage>
                  {errors?.accionesRegistroEventos}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack placeSelf={"start"}>
          <Field
            name="responsableAccionRegistroEventos"
            validate={(value) => validateField("letras", value, "responsable")}
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors.responsableAccionRegistroEventos &&
                  touched.responsableAccionRegistroEventos
                }
                w={"2xs"}
                isRequired
              >
                <FormLabel fontWeight="semibold">
                  Responsable Acción Inmediatas
                </FormLabel>
                <Input
                  {...field}
                  w="2xs"
                  type="text"
                  placeholder="Resp."
                  name="responsableAccionRegistroEventos"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.responsableAccionRegistroEventos}
                />
                <FormErrorMessage>
                  {errors.responsableAccionRegistroEventos}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <FormControl w="2xs" placeSelf="start">
          <FormLabel fontWeight="semibold">
            ¿El servicio quedó reestablecido?
          </FormLabel>
          <HStack>
            <Checkbox
              name="servicioReestRegistroEventos"
              onChange={handleChange}
              isChecked={values.servicioReestRegistroEventos}
            >
              SI
            </Checkbox>
          </HStack>
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent={"space-between"} pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Detectó</FormLabel>
          <Select
            w="2xs"
            name="detectoRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.detectoRegistroEventos}
            placeholder="Seleccione detector"
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Responsable registro</FormLabel>
          <Select
            w="2xs"
            name="responsableRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Seleccione responsable"
            value={values.responsableRegistroEventos}
          >
            {usuarios
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((usuario) => (
                <option value={usuario.idUsuario} key={usuario.idUsuario}>
                  {usuario.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del registro</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaAccionRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaAccionRegistroEventos}
          />
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent={"space-between"} py={5}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          color="brand.naranja"
          border="1px solid #FF8200"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          variant="outline"
          onClick={() => setActiveStep(activeStep - 1)}
          isDisabled={loading}
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <Button
          fontSize={12}
          fontWeight="semibold"
          bg="brand.naranja"
          color="white"
          rightIcon={<MdNavigateNext fontSize="20px" />}
          variant="solid"
          form="FormularioRegistroEvento"
          onClick={handleSubmit}
          isDisabled={loading || user.idRol === "usuarioIT"}
          _hover={{ bg: "white", color: "brand.naranja" }}
        >
          Continuar
        </Button>
      </HStack>
    </Stack>
  );
};

export default SegundoPasoRegistroFijo;
