import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  Box,
  IconButton,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Switch,
  useToast,
  Select,
  FormErrorMessage,
} from "@chakra-ui/react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  getPlantas,
  editarPlantas,
  crearPlantas,
} from "../../helpers/api.helper";
import { validateField } from "../../../../utils/Validaciones";

const FormCrearEditarPlanta = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState(false);
  const [planta, setPlanta] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getPlantas(id);

    if (res.status === 200) {
      setPlanta(res.data[0]);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  let initialValues =
    type === "editar"
      ? {
          nombrePlanta: planta.nombrePlanta,
          tipoArena1: planta.tipoArena1,
          tipoArena2: planta.tipoArena2,
          tipoArena3: planta.tipoArena3,
          tipoArena4: planta.tipoArena4,
          wetsand: planta.wetsand,
        }
      : {
          nombrePlanta: "",
          tipoArena1: "",
          tipoArena2: "",
          tipoArena3: "",
          tipoArena4: "",
          wetsand: "",
        };

  return (
    <Stack w="100%" p={5}>
      <HStack>
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <HStack w="100%" justifyContent="space-between" pr={5} pb={5}>
          <HStack>
            <Heading
              fontWeight="medium"
              size="xl"
              fontFamily="fonts.title"
              color="brand.gris_primario"
            >
              {type === "editar" ? "Editar" : "Nueva"} Planta
            </Heading>
          </HStack>
        </HStack>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.nombrePlanta) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Nombre de la planta requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarPlantas({
                nombrePlanta: values.nombrePlanta,
                tipoArena1: values.tipoArena1,
                tipoArena2: values.tipoArena2,
                tipoArena3: values.tipoArena3,
                tipoArena4: values.tipoArena4,
                wetsand: values.wetsand,
                id: planta.idPlanta,
              });
            }
            if (type === "crear") {
              res = await crearPlantas({
                nombrePlanta: values.nombrePlanta,
                tipoArena1: values.tipoArena1,
                tipoArena2: values.tipoArena2,
                tipoArena3: values.tipoArena3,
                tipoArena4: values.tipoArena4,
                wetsand: values.wetsand,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: `Planta ${
                  type === "crear" ? "creada" : "editada"
                } correctamente`,
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } planta`,
                description: res.data.error.message || "Error al editar planta",
              });
            }

            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="formPlanta">
              <Stack w="100%" px={7}>
                <HStack pb={5}>
                  <Stack placeSelf={"start"} w={"sm"}>
                    <Field
                      name="nombrePlanta"
                      validate={(value) =>
                        validateField("letras", value, "La planta")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.nombrePlanta && touched.nombrePlanta
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel>Planta</FormLabel>
                          <Input
                            {...field}
                            w={"xs"}
                            placeholder="Nombre de planta"
                            type="text"
                            name="nombrePlanta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nombrePlanta}
                          />
                          <FormErrorMessage>
                            {errors.nombrePlanta}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <FormControl w={"sm"} placeSelf={"start"}>
                    <FormLabel>Wetsand</FormLabel>
                    <Switch
                      size={"lg"}
                      colorScheme={"green"}
                      name="wetsand"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.wetsand}
                    />
                  </FormControl>
                </HStack>
                <HStack pb={5}>
                  <FormControl w={"sm"}>
                    <FormLabel>Tipos de Arena</FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="Tipo de Arena 1"
                      type="text"
                      name="tipoArena1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoArena1}
                    >
                      {" "}
                      <option value={"100"}>100</option>
                      <option value={"30/70"}>30/70</option>
                      <option value={"70/140"}>70/140</option>
                      <option value={"50/70"}>50/70</option>
                      <option value={"40/80"}>40/80</option>
                      <option value={"40/70"}>40/70</option>
                      <option value={"30/50"}>30/50</option>
                      <option value={"20/40"}>20/40</option>
                      <option value={"otros"}>Otros</option>
                    </Select>
                  </FormControl>
                  <FormControl w={"sm"} placeSelf={"end"}>
                    <FormLabel></FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="Tipo de Arena 2"
                      type="text"
                      name="tipoArena2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoArena2}
                    >
                      {" "}
                      <option value={"100"}>100</option>
                      <option value={"30/70"}>30/70</option>
                      <option value={"70/140"}>70/140</option>
                      <option value={"50/70"}>50/70</option>
                      <option value={"40/80"}>40/80</option>
                      <option value={"40/70"}>40/70</option>
                      <option value={"30/50"}>30/50</option>
                      <option value={"20/40"}>20/40</option>
                      <option value={"otros"}>Otros</option>
                    </Select>
                  </FormControl>
                </HStack>
                <HStack pb={5}>
                  <FormControl w={"sm"} placeSelf={"end"}>
                    <FormLabel></FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="Tipo de Arena 3"
                      type="text"
                      name="tipoArena3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoArena3}
                    >
                      <option value={"100"}>100</option>
                      <option value={"30/70"}>30/70</option>
                      <option value={"70/140"}>70/140</option>
                      <option value={"50/70"}>50/70</option>
                      <option value={"40/80"}>40/80</option>
                      <option value={"40/70"}>40/70</option>
                      <option value={"30/50"}>30/50</option>
                      <option value={"20/40"}>20/40</option>
                      <option value={"otros"}>Otros</option>
                    </Select>
                  </FormControl>
                  <FormControl w={"sm"} placeSelf={"end"}>
                    <FormLabel></FormLabel>
                    <Select
                      w={"xs"}
                      placeholder="Tipo de Arena 4"
                      type="text"
                      name="tipoArena4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipoArena4}
                    >
                      <option value={"100"}>100</option>
                      <option value={"30/70"}>30/70</option>
                      <option value={"70/140"}>70/140</option>
                      <option value={"50/70"}>50/70</option>
                      <option value={"40/80"}>40/80</option>
                      <option value={"40/70"}>40/70</option>
                      <option value={"30/50"}>30/50</option>
                      <option value={"20/40"}>20/40</option>
                      <option value={"otros"}>Otros</option>
                    </Select>
                  </FormControl>
                </HStack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone fontSize="20px" />}
                  form="formPlanta"
                  isDisabled={loading || user.idRol === "usuarioIT"}
                  _hover={{ bg: "white", color: "brand.naranja" }}
                  bg={"brand.naranja"}
                  color={"white"}
                  size={"md"}
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarPlanta;
