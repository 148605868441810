import React, { useState, useEffect } from "react";
import {
  IconButton,
  Heading,
  useToast,
  HStack,
  Stack,
  Divider,
  CircularProgress,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getUltimasRevisiones } from "../../../helpers/api.helper";
import { getPlanificaciones } from "../../../helpers/api.helper";
import ButtonRevision from "./ButtonRevision";

const CrearEditarRevision = () => {
  const [ultimasRevisiones, setUltimasRevisiones] = useState([]);
  const [loading, setLoading] = useState([]);
  const [planificacion, setPlanificacion] = useState([]);
  const padLocacion = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const today = new Date();

  const initData = async () => {
    setLoading(true);

    let resolve = await getUltimasRevisiones(padLocacion);

    if (resolve.status === 200) {
      setUltimasRevisiones(resolve.data);
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar los datos",
        duration: 3000,
      });
    }

    resolve = await getPlanificaciones(padLocacion);

    if (resolve.status === 200) {
      setPlanificacion(resolve.data[0]);
    } else {
      toast({
        status: "error",
        isClosable: true,
        title: "Error al cargar los datos",
        duration: 3000,
      });
    }
    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" h={"lg"} placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%">
      <HStack>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />
        <Heading size="lg">Revisión entre turnos</Heading>
      </HStack>
      <Divider />
      <br />
      {loading && loadingtable()}
      {!loading && (
        <Stack w="100%" placeContent="center" spacing={7}>
          <HStack justifyContent="space-around">
            <ButtonRevision
              padLocacion={padLocacion}
              equipo={"revisionSander"}
              today={today}
              objetoEquipo={ultimasRevisiones}
            />
            <ButtonRevision
              padLocacion={padLocacion}
              equipo={"revisionLuminarias"}
              today={today}
              objetoEquipo={ultimasRevisiones}
            />
          </HStack>
          <HStack justifyContent="space-around">
            <ButtonRevision
              padLocacion={padLocacion}
              equipo={"revisionAutoelevador"}
              today={today}
              objetoEquipo={ultimasRevisiones}
            />
            {planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionDosificadora"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
            {!planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionSandVan"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
          </HStack>
          <HStack justifyContent="space-around">
            {planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionSandVan"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
            {!planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionGenerador"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
            <ButtonRevision
              padLocacion={padLocacion}
              equipo={"revisionMiniPala"}
              today={today}
              objetoEquipo={ultimasRevisiones}
            />
          </HStack>
          <HStack justifyContent="space-around">
            {planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionGenerador"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
            {planificacion.dosificadora && (
              <ButtonRevision
                padLocacion={padLocacion}
                equipo={"revisionBaseSandVan"}
                today={today}
                objetoEquipo={ultimasRevisiones}
              />
            )}
          </HStack>
        </Stack>
      )}
    </Stack>
  );
};

export default CrearEditarRevision;
