import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  HStack,
  VStack,
  Button,
  Select,
  Divider,
  useToast,
  Box,
  InputRightAddon,
  IconButton,
  InputGroup,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  MdOutlineAdd,
  MdNavigateNext,
  MdNavigateBefore,
  MdFileDownloadDone,
  MdClose,
} from "react-icons/md";
import { FieldArray, Field } from "formik";
import {
  getPlantas,
  editarPlanificacion3,
  crearPlanificacion3,
  getDetallePlantas,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";
import { handleUsuarioIT } from "../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../utils/Validaciones";

const TercerPaso = (props) => {
  const {
    activeStep,
    setActiveStep,
    id,
    type,
    initPlanificacion,
    onClose,
    values,
    handleChange,
    seleccionado,
    handleBlur,
    setFieldValue,
    expanded,
    ultima,
    formValues,
    user,
    touched,
    errors,
  } = props;
  const [loading, setLoading] = useState(false);
  const [plantas, setPlantas] = useState([]);

  const toast = useToast();
  const navigate = useNavigate();

  const initPlantas = async () => {
    let res = await getPlantas();

    if (res.status === 200) {
      setPlantas(res.data);
    } else {
      return toast({
        description: "Error al cargar plantas",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (values, event) => {
    setLoading(true);

    if (!values.plantas) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Se requiere al menos una planta",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.plantas[0].idPlanta) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Se requiere al menos una planta",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    if (values.plantas.length > 0) {
      values.plantas.forEach((element, index) => {
        if (
          (element.targetIdaPozoPlanta &&
            validateOnSubmit("numberWnull", element.targetIdaPozoPlanta)) ||
          (element.targetVueltaPozoPlanta &&
            validateOnSubmit("numberWnull", element.targetVueltaPozoPlanta)) ||
          (element.targetStandBy &&
            validateOnSubmit("numberWnull", element.targetStandBy)) ||
          (element.targetTnsDiaPlanta &&
            validateOnSubmit("numberWnull", element.targetTnsDiaPlanta))
        ) {
          setLoading(false);
          return toast({
            title: "Error",
            description: `Los datos de la planta ${index + 1} son incorrectos`,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        }
      });
    }

    let data = values.plantas.map((planta) => {
      return planta;
    });

    let plan;
    let resolve = await getDetallePlantas();

    if (resolve.status === 200) {
      plan = resolve.data;
    }

    let datos;
    if (formValues.desMontTransp) {
      datos = { estado: "activo" };
    } else {
      datos = { estado: "proyectado" };
    }
    let res;

    if (type === "editar" || type === "reActivar") {
      res = await editarPlanificacion3({
        detallePlanta: [...data],
        id: formValues.idPlanificacion,
        estado: datos.estado,
      });
    } else if (type === "continuar") {
      res = await editarPlanificacion3({
        detallePlanta: [...data],
        id: seleccionado.idPlanificacion,
        estado: datos.estado,
      });
    } else {
      if (
        ultima.length >= 1 &&
        plan.some((e) => e.idPadlocacion === data.idPadlocacion)
      ) {
        res = await editarPlanificacion3({
          detallePlanta: [...data],
          id: id,
          estado: datos.estado,
        });
      } else {
        res = await crearPlanificacion3({
          detallePlanta: [...data],
          id: id,
          estado: datos.estado,
        });
      }
    }

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: `Tercer paso ${
          type === "editar" ? "editado" : "creado"
        } correctamente`,
        duration: 3000,
      });
    } else {
      setLoading(false);
      return toast({
        status: "error",
        isClosable: true,
        title: `Error al ${
          type === "editar" ? "editar" : "crear"
        } el tercer paso`,
        description: res.message,
      });
    }
    if (event === "next") {
      setActiveStep(activeStep + 1);
    } else {
      navigate("/operaciones/planificacion");
      if (type === "continuar") {
        initPlanificacion();
      }
    }

    if (type === "continuar" && event === "finalizar") {
      onClose();
    }
    setLoading(false);
  };

  const handleVDCalc = (values, index, setFieldValue) => {
    let elemento = values.plantas[index];
    if (
      elemento.targetIdaPozoPlanta &&
      elemento.targetVueltaPozoPlanta &&
      elemento.targetStandBy &&
      elemento.standByCamion
    ) {
      const targetVCD =
        (24 * 60 - parseInt(elemento.standByCamion)) /
        (parseInt(elemento.targetIdaPozoPlanta) +
          parseInt(elemento.targetVueltaPozoPlanta) +
          parseInt(elemento.targetStandBy));
      setFieldValue(`plantas[${index}].targetVCD`, targetVCD.toFixed(2));
    }
  };

  const calcVueltaCompleta = (e, index, setFieldValue) => {
    if (e.targetStandBy && e.targetIdaPozoPlanta && e.targetVueltaPozoPlanta) {
      let total =
        parseInt(e.targetStandBy) +
        parseInt(e.targetIdaPozoPlanta) +
        parseInt(e.targetVueltaPozoPlanta);
      setFieldValue(`plantas[${index}.targetVueltaCompleta]`, total.toString());
    }
  };
  useEffect(() => {
    initPlantas();
  }, []);

  return (
    <Stack p={3}>
      <FieldArray name="plantas">
        {({ push, remove }) => (
          <Stack>
            {values.plantas.map((elemento, index) => {
              return (
                <Stack key={index} spacing={10}>
                  <HStack
                    w="100%"
                    placeSelf="start"
                    justifyContent={!expanded ? "space-between" : "unset"}
                  >
                    <FormControl w="xs" placeSelf={"start"} isRequired>
                      <FormLabel fontWeight="semibold">
                        Planta {index + 1}
                      </FormLabel>
                      <Select
                        w="2xs"
                        placeholder="Nombre"
                        name={`plantas[${index}].idPlanta`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.plantas[index].idPlanta}
                      >
                        {plantas.map((planta) => (
                          <option key={planta.idPlanta} value={planta.idPlanta}>
                            {planta.nombrePlanta}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <Stack placeSelf={"start"}>
                      <Field
                        name={`plantas[${index}].targetIdaPozoPlanta`}
                        validate={(value) =>
                          validateField(
                            "number",
                            value?.toString(),
                            "Ida Pozo-Planta"
                          )
                        }
                      >
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              touched?.plantas &&
                              touched?.plantas[index]?.targetIdaPozoPlanta &&
                              errors?.plantas &&
                              errors?.plantas[index]?.targetIdaPozoPlanta
                            }
                            w={"xs"}
                          >
                            <FormLabel fontWeight="semibold">
                              Target IDA Pozo-Planta
                            </FormLabel>
                            <InputGroup w="2xs">
                              <Input
                                {...field}
                                type="string"
                                placeholder="Tiempo"
                                name={`plantas[${index}].targetIdaPozoPlanta`}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                value={
                                  values.plantas[index].targetIdaPozoPlanta
                                }
                              />
                              <InputRightAddon
                                bg="brand.gris_primario"
                                color="white"
                              >
                                mins
                              </InputRightAddon>
                            </InputGroup>
                            <FormErrorMessage>
                              {errors.plantas &&
                                errors?.plantas[index]?.targetIdaPozoPlanta}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <Stack placeSelf={"start"}>
                      <Field
                        name={`plantas[${index}].targetVueltaPozoPlanta`}
                        validate={(value) =>
                          validateField(
                            "number",
                            value?.toString(),
                            "Vuelta Pozo-Planta"
                          )
                        }
                      >
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              touched?.plantas &&
                              touched?.plantas[index]?.targetVueltaPozoPlanta &&
                              errors?.plantas &&
                              errors?.plantas[index]?.targetVueltaPozoPlanta
                            }
                            w={"xs"}
                          >
                            <FormLabel fontWeight="semibold">
                              Target VUELTA Pozo-Planta
                            </FormLabel>
                            <InputGroup w="2xs">
                              <Input
                                {...field}
                                type="string"
                                placeholder="Tiempo"
                                name={`plantas[${index}].targetVueltaPozoPlanta`}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                value={
                                  values.plantas[index].targetVueltaPozoPlanta
                                }
                              />
                              <InputRightAddon
                                bg="brand.gris_primario"
                                color="white"
                              >
                                mins
                              </InputRightAddon>
                            </InputGroup>
                            <FormErrorMessage>
                              {errors.plantas &&
                                errors?.plantas[index]?.targetVueltaPozoPlanta}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <Box>
                      <IconButton
                        key={elemento.index}
                        fontSize="12px"
                        variant="link"
                        onClick={() => remove(index)}
                        isDisabled={values.plantas.length <= 1}
                        icon={<MdClose />}
                      />
                    </Box>
                  </HStack>
                  <HStack
                    w="100%"
                    placeSelf="start"
                    justifyContent={!expanded ? "space-between" : "unset"}
                  >
                    <Stack placeSelf={"start"}>
                      <Field
                        name={`plantas[${index}].targetStandBy`}
                        validate={(value) =>
                          validateField(
                            "number",
                            value?.toString(),
                            "Target Standby"
                          )
                        }
                      >
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              touched?.plantas &&
                              touched?.plantas[index]?.targetStandBy &&
                              errors?.plantas &&
                              errors?.plantas[index]?.targetStandBy
                            }
                            w={"xs"}
                          >
                            <FormLabel fontWeight="semibold">
                              Target Standby
                            </FormLabel>
                            <InputGroup w="2xs">
                              <Input
                                {...field}
                                type="string"
                                placeholder="Tiempo"
                                name={`plantas[${index}].targetStandBy`}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleVDCalc(values, index, setFieldValue);
                                  calcVueltaCompleta(
                                    values.plantas[index],
                                    index,
                                    setFieldValue
                                  );
                                }}
                                value={values.plantas[index].targetStandBy}
                              />
                              <InputRightAddon
                                bg="brand.gris_primario"
                                color="white"
                              >
                                mins
                              </InputRightAddon>
                            </InputGroup>
                            <FormErrorMessage>
                              {errors.plantas &&
                                errors?.plantas[index]?.targetStandBy}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <Stack placeSelf={"start"}>
                      <Field
                        name={`plantas[${index}].targetTnsDiaPlanta`}
                        validate={(value) =>
                          validateField(
                            "number",
                            value?.toString(),
                            "Tns. por día"
                          )
                        }
                      >
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              touched?.plantas &&
                              touched?.plantas[index]?.targetTnsDiaPlanta &&
                              errors?.plantas &&
                              errors?.plantas[index]?.targetTnsDiaPlanta
                            }
                            w={"xs"}
                          >
                            <FormLabel fontWeight="semibold">
                              Target Tns. por día
                            </FormLabel>
                            <Input
                              {...field}
                              w="2xs"
                              type="string"
                              placeholder="Cantidad"
                              name={`plantas[${index}].targetTnsDiaPlanta`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.plantas[index].targetTnsDiaPlanta}
                            />
                            <FormErrorMessage>
                              {errors.plantas &&
                                errors?.plantas[index]?.targetTnsDiaPlanta}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <FormControl w="xs" placeSelf={"start"}>
                      <FormLabel fontWeight="semibold">Target VCD</FormLabel>
                      <Input
                        w="2xs"
                        placeholder="Cantidad"
                        _placeholder={{ color: "white" }}
                        name={`plantas[${index}].targetVCD`}
                        onBlur={() =>
                          handleVDCalc(values, index, setFieldValue)
                        }
                        value={values.plantas[index].targetVCD}
                        readOnly
                        bg="brand.gris_medio"
                      />
                    </FormControl>
                    <Box w={10}></Box>
                  </HStack>
                  <HStack
                    w="100%"
                    justifyContent={!expanded ? "space-between" : "unset"}
                    placeSelf="start"
                  >
                    <FormControl w="xs" placeSelf={"start"}>
                      <FormLabel fontWeight="semibold">
                        Standby Camion
                      </FormLabel>
                      <Select
                        w="2xs"
                        placeholder="Cantidad"
                        name={`plantas[${index}].standByCamion`}
                        onChange={(e) => {
                          handleChange(e);
                          handleVDCalc(values, index, setFieldValue);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleVDCalc(values, index, setFieldValue);
                        }}
                        value={values.plantas[index].standByCamion}
                      >
                        <option value="60">1 h</option>
                        <option value="120">2 h</option>
                        <option value="180">3 h</option>
                      </Select>
                    </FormControl>
                    <FormControl w="xs" placeSelf={"start"}>
                      <FormLabel fontWeight="semibold">
                        Target Vuelta completa
                      </FormLabel>
                      <InputGroup w="2xs">
                        <Input
                          type="string"
                          placeholder="Tiempo"
                          name={`plantas[${index}].targetVueltaCompleta`}
                          value={values.plantas[index].targetVueltaCompleta}
                          readOnly
                          bg="brand.gris_medio"
                          _placeholder={{ color: "white" }}
                        />
                        <InputRightAddon bg="brand.gris_primario" color="white">
                          mins
                        </InputRightAddon>
                      </InputGroup>
                    </FormControl>
                    <FormControl w="xs"></FormControl>
                    <Box w={10}></Box>
                  </HStack>
                  <Divider pt={2} />
                </Stack>
              );
            })}
            <Button
              color="brand.naranja"
              placeSelf="self-start"
              fontSize="12px"
              variant="link"
              size="lg"
              leftIcon={<MdOutlineAdd size={18} />}
              onClick={() => {
                push({
                  nombrePlanta: "",
                  targetIdaPozoPlanta: "",
                  targetVueltaPozoPlanta: "",
                  targetStandBy: "",
                  targetTnsDiaPlanta: "",
                  targetVCD: "",
                  standByCamion: "",
                  targetVueltaCompleta: "",
                });
              }}
              textColor="black"
            >
              Agregar planta
            </Button>
          </Stack>
        )}
      </FieldArray>

      <HStack w="100%" justifyContent="space-between" py={2} pr={2}>
        <Button
          fontSize={12}
          letterSpacing={1}
          border="1px solid orange"
          fontWeight="semibold"
          variant="outline"
          color="brand.naranja"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          onClick={() => setActiveStep(activeStep - 1)}
          alignSelf="end"
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <VStack>
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            onClick={() =>
              user.idRol === "usuarioIT"
                ? handleUsuarioIT(setActiveStep, activeStep)
                : handleSubmit(values, "next")
            }
            variant="solid"
            form="FormularioPlanificacion"
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
          <Button
            color="brand.naranja"
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            variant="outline"
            leftIcon={<MdFileDownloadDone fontSize="20px" />}
            _hover={{ bg: "brand.naranja", color: "white" }}
            textColor="brand.naranja"
            form="FormularioPlanificacion"
            onClick={() => handleSubmit(values, "finalizar")}
            isDisabled={loading || user.idRol === "usuarioIT"}
          >
            Finalizar
          </Button>
        </VStack>
      </HStack>
    </Stack>
  );
};

export default TercerPaso;
