import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Button,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import {
  eliminarRevisionAutoelevadores,
  eliminarRevisionDosificadoras,
  eliminarRevisionEntreTurno,
  eliminarRevisionGeneradores,
  eliminarRevisionMiniPala,
  eliminarRevisionSanders,
} from "../../../helpers/api.helper";
import { MdOutlineDelete } from "react-icons/md";

const EliminarRevision = (props) => {
  const { revision, initData, user } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  let IdRevision;

  const handleEliminarRevision = async (revision) => {
    setLoading(true);
    let res;
    if (revision.equipoRevision === "Autoelevador") {
      IdRevision = revision.idRevisionAutoE;
      res = await eliminarRevisionAutoelevadores({
        id: IdRevision,
      });
    } else if (revision.equipoRevision === "Generador") {
      IdRevision = revision.idRevisionGenerador;
      res = await eliminarRevisionGeneradores({
        id: IdRevision,
      });
    } else if (revision.equipoRevision === "Sander") {
      IdRevision = revision.idRevisionSander;
      res = await eliminarRevisionSanders({
        id: IdRevision,
      });
    } else if (revision.equipoRevision === "Mini Pala") {
      IdRevision = revision.idRevisionMP;
      res = await eliminarRevisionMiniPala({
        id: IdRevision,
      });
    } else if (revision.equipoRevision === "Dosificadora") {
      IdRevision = revision.idRevisionDosificadora;
      res = await eliminarRevisionDosificadoras({
        id: IdRevision,
      });
    }

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Revision entre turnos eliminada correctamente",
        duration: 3000,
      });
      initData();
      onClose();
    } else {
      toast({
        title: "Error al eliminar Revision entre turnos",
        description: res.data,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Eliminar revision entre turnos">
        <IconButton
          variant="link"
          icon={<MdOutlineDelete fontSize="20px" />}
          size="sm"
          _hover={{ color: "white", bg: "brand.naranja" }}
          onClick={onOpen}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              color="brand.gris_primario"
              fontSize="lg"
              fontWeight="bold"
            >
              Eliminar Revision entre turnos
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que desea eliminar la Revision entre turnos?
              <br /> Esta acción no se puede revertir.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="link" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                bg="brand.naranja"
                onClick={() => handleEliminarRevision(revision)}
                ml={3}
                isDisabled={loading || user.idRol === 'usuarioIT'}
                color="white"
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EliminarRevision;
