import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  IconButton,
  Text,
  Box,
  HStack,
  Stack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tooltip,
} from "@chakra-ui/react";
import {
  MdOutlineMarkunread,
  MdOutlineDrafts,
  MdOutlineMarkEmailRead,
} from "react-icons/md";
import { putNotificacionesII } from "../../config/helpers/api.helper";
/* import { useNavigate } from "react-router-dom"; */
import { ReactComponent as Bell } from "../../../assets/iconos/notifications_FILL0.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { putUsuarios } from "../../usuarios/helpers/api.helper";

const Notificationes = (props) => {
  const { notificaciones, setNotificaciones, usuario, setUsuario, loading } =
    props;
  /*  const navigate = useNavigate(); */
  let today = moment().format("YYYY-MM-DD");
  const { user } = useSelector((state) => state.user);

  const handleLeido = async (element) => {
    if (user.idRol === "usuarioIT") {
      const editar = notificaciones.map((item) => {
        if (item.idnotificacionesBS === element.idnotificacionesBS) {
          return {
            ...item,
            leidoNBS: !element.leidoNBS,
          };
        }
        return item;
      });

      setNotificaciones(editar);
    } else {
      let data = {
        id: element.idnotificacionesBS,
        leidoNBS: !element.leidoNBS,
      };
      let res = await putNotificacionesII({ leido: [data] });

      if (res.status === 200) {
        const editar = notificaciones.map((item) => {
          if (item.idnotificacionesBS === element.idnotificacionesBS) {
            return {
              ...item,
              leidoNBS: !element.leidoNBS,
            };
          }
          return item;
        });

        setNotificaciones(editar);
      }
    }
  };

  const handleAllAsRead = async () => {
    if (user.idRol === "usuarioIT") {
      const editar = notificaciones.map((e) => {
        return {
          ...e,
          leidoNBS: true,
        };
      });
      setNotificaciones(editar);
    } else {
      let data = notificaciones.map((element) => {
        return {
          id: element.idnotificacionesBS,
          leidoNBS: !element.leidoNBS,
        };
      });
      let res = await putNotificacionesII({ leido: data });

      if (res.status === 200) {
        const editar = notificaciones.map((item) => {
          return {
            ...item,
            leidoNBS: true,
          };
        });

        setNotificaciones(editar);
      }
    }
  };

  const handleGlobalActivas = async () => {
    if (user.idRol === "usuarioIT") {
      setUsuario({ ...usuario, notificacionGlobalActivas: false });
    } else {
      if (usuario.notificacionGlobalActivas === true) {
        let res = await putUsuarios({
          notificacionGlobalActivas: false,
          idUsuario: user.idUsuario,
        });
        if (res.status === 200) {
          setUsuario({ ...usuario, notificacionGlobalActivas: false });
        }
      }
    }
  };

  /*   const openModal = async (item) => {
    if (user.idRol === "usuarioIT") {
      const editar = notificaciones.map((e) => {
        if (e.idnotificacionesBS === item.idnotificacionesBS) {
          return {
            ...e,
            leidoNBS: !item.leidoNBS,
          };
        }
        return e;
      });

      setNotificaciones(editar);
    } else {
      if (item.nombreTipoE === "Registro Evento") {
        await putNotificacionesII({
          id: item.idnotificacionesBS,
          leidoNBS: true,
        });
        return navigate(`/operaciones/curso/eventos/${item.padLocacion}`);
      } else if (item.eventoNBS === "Creación Etapa") {
        await putNotificacionesII({
          id: item.idnotificacionesBS,
          leidoNBS: true,
        });
        return navigate(`/operaciones/curso/etapas/${item.padLocacion}`);
      } else {
        let data = {
          id: item.idnotificacionesBS,
          leidoNBS: !item.leidoNBS,
        };
        let res = await putNotificacionesII(data);

        if (res.status === 200) {
          const editar = notificaciones.map((e) => {
            if (e.idnotificacionesBS === item.idnotificacionesBS) {
              return {
                ...e,
                leidoNBS: !item.leidoNBS,
              };
            }
            return e;
          });

          setNotificaciones(editar);
        }
      }
    }
  }; */

  /*  const handleDif = () => {
    let notificacionesVisibles = [];

    for (let index = 0; index < notificaciones.length; index++) {
      const element = notificaciones[index];
      let a = moment.utc(element.fechaEnvioNBS).format("YYYY-MM-DD");
      const diffHoras = moment(today).diff(a, "days");
      if (diffHoras < 2) {
        notificacionesVisibles.push(element);
      }
    }
    let b = notificaciones.filter((e) => e.leidoNBS === false);
    notificacionesVisibles = [...notificacionesVisibles, ...b];
    return notificacionesVisibles;
  }; */

  const obtenerTextoAntesDelPunto = (texto) => {
    // Buscar la posición del primer punto en el texto
    const posicionPunto = texto.indexOf(".");

    // Si no se encuentra un punto, devolver el texto completo
    if (posicionPunto === -1) {
      return texto;
    }

    // Devolver todo el texto antes del primer punto
    return texto.substring(0, posicionPunto);
  };

  return (
    !loading && (
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Box position={"relative"} display={"inline-block"}>
            <IconButton
              bg="gray.300"
              borderRadius="50%"
              name="notificaciones"
              onClick={() => handleGlobalActivas()}
            >
              <Box>
                <Bell />
              </Box>
            </IconButton>
            {notificaciones.length > 0 &&
              usuario.notificacionGlobalActivas === true &&
              notificaciones.some((e) => e.leidoNBS === false) && (
                <Box
                  position="absolute"
                  top="1px"
                  right="-7px"
                  bg="#B21515"
                  color="white"
                  borderRadius="50%"
                  placeContent={"center"}
                  width="20px"
                  height="20px"
                  display="flex"
                  fontWeight={"light"}
                  fontSize="12px"
                  zIndex={10}
                >
                  <Text>
                    {notificaciones.filter((e) => e.leidoNBS === false).length <
                    99
                      ? notificaciones.filter((e) => e.leidoNBS === false)
                          .length
                      : "+99"}
                  </Text>
                </Box>
              )}
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader letterSpacing={1}>
            <HStack w={"100%"} justifyContent={"space-between"}>
              <Text>
                Notificaciones{" "}
                {`(${
                  notificaciones.filter((e) => e.leidoNBS === false).length
                })`}
              </Text>
              <Tooltip label="Marcar todos como leídos">
                <IconButton
                  variant={"link"}
                  icon={<MdOutlineMarkEmailRead />}
                  onClick={handleAllAsRead}
                  cursor={"pointer"}
                />
              </Tooltip>
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <Stack overflow={"auto"} maxH={"xs"}>
              {notificaciones
                .sort(
                  (a, b) =>
                    moment(b.fechaCreacionBS).valueOf() -
                    moment(a.fechaCreacionBS).valueOf()
                )
                .map((elem) => (
                  <Card
                    key={elem.idnotificacionesBS}
                    size={"sm"}
                    variant="unstyled"
                    bg={
                      elem.leidoNBS === false
                        ? "brand.fondos_secundarios"
                        : "white"
                    }
                    p={2}
                  >
                    <CardHeader>
                      <HStack justifyContent={"space-between"} borderRadius={5}>
                        <Heading
                          size={"md"}
                          fontWeight={"semibold"}
                          color={"gray.600"}
                          textAlign={"left"}
                        >
                          {elem.tituloNBS}
                        </Heading>
                        <Tooltip
                          label={
                            elem.leidoNBS === false
                              ? "Marcar como leído"
                              : "Marcar como no leído"
                          }
                        >
                          <IconButton
                            variant={"link"}
                            onClick={() => handleLeido(elem)}
                            cursor={"pointer"}
                            icon={
                              elem.leidoNBS === false ? (
                                <MdOutlineMarkunread />
                              ) : (
                                <MdOutlineDrafts />
                              )
                            }
                          />
                        </Tooltip>
                      </HStack>
                    </CardHeader>
                    {/*  onClick={() => openModal(elem)} */}
                    <CardBody>
                      <Heading size={"sm"} color={"gray"} fontWeight={"normal"}>
                        {`${obtenerTextoAntesDelPunto(elem.descripciónNBS)}.`}
                      </Heading>
                    </CardBody>
                    <CardFooter
                      w={"100%"}
                      size={"sm"}
                      color={"gray"}
                      fontWeight={"normal"}
                      justifyContent={"end"}
                    >
                      {moment(elem.fechaCreacionNBS).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </CardFooter>
                  </Card>
                ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  );
};

export default Notificationes;
