import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  IconButton,
  Button,
  useToast,
  Switch,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdArrowBack, MdFileDownloadDone } from "react-icons/md";
import {
  getSanders,
  editarSanders,
  crearSanders,
} from "./../../helpers/api.helper";
import { validateField } from "../../../../utils/Validaciones";

const FormCrearEditarSander = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState(false);
  const [sander, setSander] = useState([]);
  const navigate = useNavigate();
  const id = useParams();
  const toast = useToast();

  const initData = async () => {
    setLoading(true);
    let res = await getSanders(id);

    if (res.status === 200) {
      setSander({
        codigoSander: res.data[0].codigoSander.substring(6),
        estadoSander: res.data[0].estadoSander,
        powerPack: res.data[0].powerPack,
        idSander: res.data[0].idSander,
      });
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        isClosable: true,
        duration: 3000,
      });
    }

    setLoading(false);
  };

  let initialValues =
    type === "editar"
      ? {
          codigoSander: sander.codigoSander,
          estadoSander: sander.estadoSander === "inactivo" ? false : true,
          powerPack: sander.powerPack,
        }
      : {
          codigoSander: "",
          estadoSander: "",
          powerPack: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack bg="brand.fondo" w="100%" h="md" borderRadius={10} spacing={7}>
      <HStack w="100%" pl={5}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate(-1)}
          fontSize="24px"
        />

        <Heading fontWeight="medium" py={3}>
          {type === "editar" ? "Editar" : "Nuevo"} Sander
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.codigoSander) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Codigo de sander requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            } else if (!/^\d+$/.test(values.codigoSander)) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Ingrese solo números",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            let res;

            if (type === "editar") {
              res = await editarSanders({
                codigoSander: `SANDER${values.codigoSander}`,
                estadoSander:
                  values.estadoSander === true ? "activo" : "inactivo",
                powerPack: values.powerPack,
                id: sander.idSander,
              });
            }
            if (type === "crear") {
              res = await crearSanders({
                codigoSander: `SANDER${values.codigoSander}`,
                estadoSander:
                  values.estadoSander === true ? "activo" : "inactivo",
                powerPack: values.powerPack,
              });
            }

            if (res.status === 200) {
              toast({
                status: "success",
                isClosable: true,
                title: "Sander creado correctamente",
                duration: 3000,
              });
              navigate(-1);
            } else {
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "crear" ? "crear" : "editar"
                } sander`,
                description: res.data.error.message || "Error al editar sander",
              });
            }

            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form id="formSander">
              <Stack w="100%" spacing={10} px={7}>
                <HStack w="100%" spacing={10}>
                  <Stack placeSelf={"start"}>
                    <Field
                      name="codigoSander"
                      validate={(value) =>
                        validateField("number", value, "El código")
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.codigoSander && touched.codigoSander
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="bold">Nombre</FormLabel>
                          <InputGroup w={"xs"}>
                            <InputLeftAddon>SANDER</InputLeftAddon>
                            <Input
                              {...field}
                              name="codigoSander"
                              type="text"
                              maxLength={2}
                              placeholder="Codigo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.codigoSander}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {errors.codigoSander}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <FormControl w={"xs"} placeSelf={"start"}>
                    <FormLabel fontWeight="bold">Estado</FormLabel>
                    <Switch
                      size={"lg"}
                      name="estadoSander"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isChecked={values.estadoSander}
                    />
                  </FormControl>
                </HStack>
                <Stack placeSelf={"start"}>
                  <Field
                    name="powerPack"
                    validate={(value) =>
                      validateField("numberWnull", value, "El código")
                    }
                  >
                    {({ field }) => (
                      <FormControl
                        isInvalid={errors.powerPack && touched.powerPack}
                        w={"xs"}
                      >
                        <FormLabel fontWeight="bold">Power Pack</FormLabel>
                        <Input
                          {...field}
                          name="powerPack"
                          type="text"
                          placeholder="Codigo"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.powerPack}
                        />
                        <FormErrorMessage>{errors.powerPack}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formSander"
                  color="white"
                  isLoading={loading}
                  isDisabled={user.idRol === "usuarioIT"}
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default FormCrearEditarSander;
