import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  CardHeader,
  Text,
  Heading,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
  Button,
  Tooltip,
  useToast,
  IconButton,
  Input,
  CircularProgress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
} from "@chakra-ui/react";
import EliminarClientes from "./components/clientes/EliminarCliente";
import { getClientes } from "./helpers/api.helper";
import { useNavigate } from "react-router-dom";
import {
  MdAddCircleOutline,
  MdOutlineCreate,
  MdFilterList,
  MdRefresh,
  MdCheck,
} from "react-icons/md";
import { useSelector } from "react-redux";

const Cliente = () => {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterBy, setFilterBy] = useState({
    nombreCliente: "",
    razonSocialCliente: "",
    tipoCliente: "",
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);

  const initData = async () => {
    setLoading(true);
    let res = await getClientes();
    if (res.status === 200) {
      setClientes(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar los clientes",
        duration: 3000,
        isClosable: true,
        status: "error",
      });
    }
    setLoading(false);
  };

  const showFiltrados = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return clientes;
    }
    return clientes.filter((cliente) => {
      const { nombreCliente, razonSocialCliente, tipoCliente } = filterBy;

      return (
        (!nombreCliente ||
          cliente.nombreCliente
            .toLowerCase()
            .includes(nombreCliente.toLowerCase())) &&
        (!razonSocialCliente ||
          cliente.razonSocialCliente
            .toLowerCase()
            .includes(razonSocialCliente.toLowerCase())) &&
        (!tipoCliente || cliente.tipoCliente === tipoCliente)
      );
    });
  };
  const filterStyle = (filterName) => ({
    // Aplicar un estilo diferente al filtro seleccionado
    backgroundColor:
      filterName === selectedFilter ? "lightblue" : "transparent",
  });

  const cleanFilter = () => {
    setFilterBy({
      nombreCliente: "",
      razonSocialCliente: "",
      tipoCliente: "",
    });
  };
  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="xl" px={5}>
      <HStack w="100%" justifyContent="space-between">
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          Clientes
        </Heading>

        <HStack>
          {(user.idRol === "admin" || user.idRol === "coorOp" || user.idRol === "usuarioIT") && (
            <Button
              size="sm"
              variant="outline"
              border="1px solid #FF8200"
              leftIcon={<MdAddCircleOutline fontSize="20px" />}
              color="brand.naranja"
              onClick={() => navigate("/inventario/clientes/nuevo")}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Nuevo cliente
            </Button>
          )}
          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem py={1}>
                    <Input
                      placeholder="Por nombre"
                      variant={"filled"}
                      size="sm"
                      w="2xs"
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          nombreCliente: e.target.value,
                        }));
                        showFiltrados();
                      }}
                      value={filterBy.nombreCliente}
                    />
                  </AccordionItem>
                  <AccordionItem py={1}>
                    <Input
                      placeholder="Por razón social"
                      variant={"filled"}
                      size="sm"
                      w="2xs"
                      onChange={(e) => {
                        setFilterBy((prevstate) => ({
                          ...prevstate,
                          razonSocialCliente: e.target.value,
                        }));
                        showFiltrados();
                      }}
                      value={filterBy.razonSocialCliente}
                    />
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Tipo de cliente
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          <HStack>
                            {selectedFilter === "companiaServicio" && (
                              <MdCheck />
                            )}

                            <ListItem
                              style={filterStyle("companiaServicio")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoCliente: "companiaServicio",
                                }));
                                setSelectedFilter("companiaServicio");
                                showFiltrados();
                              }}
                            >
                              Compañia de servicio
                            </ListItem>
                          </HStack>
                          <HStack>
                            {selectedFilter === "operadora" && <MdCheck />}

                            <ListItem
                              style={filterStyle("operadora")}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  tipoCliente: "operadora",
                                }));
                                setSelectedFilter("operadora");
                                showFiltrados();
                              }}
                            >
                              Operadora
                            </ListItem>
                          </HStack>
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      {loading && loadingtable()}
      {!loading && clientes.length > 0 && (
        <SimpleGrid w="100%" columns={3} spacing={10}>
          {showFiltrados().map((cliente) => (
            <Card
              key={cliente.idCliente}
              borderRadius={5}
              bg={"#F5F5F5"}
              size={"sm"}
            >
              <CardHeader>
                <HStack w="100%" justifyContent="space-between">
                  <Heading size={"xs"}>{cliente.nombreCliente}</Heading>
                  {(user.idRol === "admin" ||
                    user.idRol === "coorOp" ||
                    user.idRol === "usuarioIT") && (
                    <Box>
                      <Tooltip label="Editar Cliente">
                        <IconButton
                          variant={"link"}
                          icon={<MdOutlineCreate fontSize="20px" />}
                          isDisabled={user.idRol === "usuarioIT"}
                          color="black"
                          onClick={() =>
                            navigate(
                              `/inventario/clientes/editar/${cliente.idCliente}`
                            )
                          }
                          _hover={{ color: "#FF8200" }}
                        />
                      </Tooltip>
                      <EliminarClientes
                        cliente={cliente}
                        initData={initData}
                        user={user}
                      />
                    </Box>
                  )}
                </HStack>
              </CardHeader>
              <CardBody>
                <HStack>
                  <Text fontWeight="semibold">CUIT: </Text>
                  <Text>{cliente.cuitCuilCliente}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="semibold">Razon social: </Text>
                  <Text>{cliente.razonSocialCliente}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="semibold">Tipo: </Text>
                  <Text>
                    {cliente.tipoCliente === "operadora"
                      ? "Operadora"
                      : cliente.tipoCliente === "companiaServicio"
                      ? "Compañia de servicio"
                      : "-"}
                  </Text>
                </HStack>

                <HStack>
                  <Text fontWeight="semibold">Contacto: </Text>
                  <Text>{cliente.telefonoCliente}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="semibold">Correo: </Text>
                  <Text>{cliente.emaillCliente}</Text>
                </HStack>
                <HStack>
                  <Text>
                    {cliente.direccionCliente} - {cliente.ciudadCliente} -{" "}
                    {cliente.provinciaCliente}
                  </Text>
                </HStack>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      )}
      {!loading && clientes.length < 1 && (
        <Heading textAlign={"center"} size={"md"}>
          No hay datos para mostrar
        </Heading>
      )}
    </Stack>
  );
};

export default Cliente;
