import React, { useEffect, useState } from "react";
import {
  Step,
  Box,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
  Text,
  Divider,
  Heading,
  useToast,
  CircularProgress,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import PrimerPaso from "./PrimerPaso";
import SegundoPaso from "./SegundoPaso";
import TercerPaso from "./TercerPaso";
import CuartoPaso from "./CuartoPaso";
import {
  getPlanificaciones,
  getClientes,
  getDetallePlantas,
} from "../../helpers/api.helper";
import QuintoPaso from "./QuintoPaso";
import moment from "moment";
import { useSelector } from "react-redux";

const FormCrearEditarPlanificacion = (props) => {
  const { seleccionado, type, onClose, initPlanificacion, expanded } = props;
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [planificaciones, setPlanificaciones] = useState([]);
  const [plan, setPlan] = useState([]);
  const [ultima, setUltima] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [formValues, setFormValues] = useState({
    initialValues: {
      idPadlocacion: "",
      idSET: "",
      fechaInicio: "",
      fechaFinal: "",
      fechaDTM: "",
      cliente: "",
      operadora: "",
      companiaServicio: "",
      nombreAreaHidrocarburifera: "",
      descripcionAreaHidrocarburifera: "",
      direccion: "",
      ciudad: "",
      provincia: "",
      pozos: [{ nombrePozo: "", numeroEtapasPozo: "" }],
      mallas: [
        {
          tipoMalla: "",
          tnsArenaSc: "",
          scEtapas: "",
          tipoArenaMalla: "seca",
          targetTnsDiaM: "",
        },
      ],
      etapasTotalPad: "",
      targeStandbyPozoGral: "",
      etapasDiaMalla: "",
      totalTns: "",
      targetCantidadCamiones: "",
      idPlanificacion: id,
      plantas: [
        {
          idPlanta: "",
          targetIdaPozoPlanta: "",
          targetVueltaPozoPlanta: "",
          targetStandBy: "",
          targetTnsDiaPlanta: "",
          targetVCD: "",
          standByCamion: "",
          targetVueltaCompleta: "",
        },
      ],
      sanderPrincipal: "",
      sanderBackup: "",
      idSandVan: "",
      SandCubes: "",
      idAutoelevador1: "",
      idAutoelevador2: "",
      camiones: [{ dominio: "", idRSV: "", empresaTransportista: [] }],
      dosificadora: "",
      desMontTransp: false,
    },
  });
  const idParam = useParams();
  const { user } = useSelector((state) => state.user);

  const isSupervisor =
    user.idRol === "userOperaciones" && user.puesto === "supervisor";
  let progressPercent = 0;

  if (type === "continuar") {
    if (seleccionado.UserPaso4) {
      progressPercent = 3;
    } else if (seleccionado.detallePlantas) {
      progressPercent = 2;
    } else if (seleccionado.UserPaso2) {
      progressPercent = 1;
    } else {
      progressPercent = 0;
    }
  } else if (type === "editar") {
    if (isSupervisor) {
      progressPercent = 3;
    }
  }
  const steps = [
    { title: "Cliente y Operadora" },
    { title: "Sandcube y arena" },
    { title: "Planta" },
    { title: "Armado del Set" },
    { title: "Asignacion de Sandcubes" },
  ];
  const navigate = useNavigate();
  const toast = useToast();
  const { activeStep, setActiveStep } = useSteps({
    index: type === "continuar" ? progressPercent + 1 : progressPercent,
    count: steps.length,
  });
  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const initData = async () => {
    if (type === "editar" || type === "reActivar") {
      let res = await getPlanificaciones(idParam);

      if (res.status === 200) {
        setFormValues({
          initialValues: {
            idPadlocacion: res.data[0].idPadlocacion,
            idSET: res.data[0].idSET,
            fechaInicio: moment(res.data[0].fechaInicio).format("YYYY-MM-DD"),
            fechaFinal: moment(res.data[0].fechaFinal).format("YYYY-MM-DD"),
            fechaDTM: moment(res.data[0].fechaDTM).format("YYYY-MM-DD"),
            cliente: res.data[0].cliente.idCliente,
            operadora: res.data[0]?.operadora,
            companiaServicio: res.data[0]?.companiaServicio,
            nombreAreaHidrocarburifera: res.data[0].nombreAreaHidrocarburifera,
            descripcionAreaHidrocarburifera:
              res.data[0].descripcionAreaHidrocarburifera,
            direccion: res.data[0].direccion,
            ciudad: res.data[0].ciudad,
            provincia: res.data[0].provincia,
            pozos: res.data[0].pozos
              ? res.data[0].pozos
              : [{ nombrePozo: "", numeroEtapasPozo: "" }],
            mallas: res.data[0].mallas
              ? res.data[0].mallas
              : [
                  {
                    tnsArenaSc: "",
                    scEtapas: "",
                    tipoArenaMalla: "seca",
                    targetTnsDiaM: "",
                  },
                ],
            etapasTotalPad: res.data[0].etapasTotalPad,
            targeStandbyPozoGral: res.data[0].targeStandbyPozoGral,
            totalTns: res.data[0].totalTns,
            etapasDiaMalla: res.data[0].etapasDiaMalla,
            targetCantidadCamiones: res.data[0].targetCantidadCamiones,
            idPlanificacion: res.data[0].idPlanificacion,
            plantas: res.data[0].detallePlantas
              ? res.data[0].detallePlantas
              : [
                  {
                    idPlanta: "",
                    targetIdaPozoPlanta: "",
                    targetVueltaPozoPlanta: "",
                    targetStandBy: "",
                    targetTnsDiaPlanta: "",
                    targetVCD: "",
                    standByCamion: "",
                    targetVueltaCompleta: "",
                  },
                ],
            sanderPrincipal: parseInt(res.data[0].sanderPrincipal?.idSander),
            sanderBackup: parseInt(res.data[0].sanderBackup?.idSander),
            idSandVan: res.data[0].sandvan?.idSandVan,
            SandCubes: res.data[0].SandCubes,
            idAutoelevador1: parseInt(res.data[0].autoelevador1?.idAutoE) || "",
            idAutoelevador2: parseInt(res.data[0].autoelevador2?.idAutoE) || "",
            camiones: res.data[0].camiones
              ? res.data[0].camiones
              : [{ dominio: "", idRSV: "", empresaTransportista: [] }],
            dosificadora: res.data[0].dosificadora?.idDosificadora,
            desMontTransp: res.data[0].desMontTransp,
            estado: res.data[0].estado,
            scAsociados: res.data[0].scAsociados,
          },
        });
      } else {
        return toast({
          title: "Error",
          description: "Error al cargar los datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    if (type === "continuar") {
      let res = await getPlanificaciones({ id: seleccionado.idPlanificacion });

      if (res.status === 200) {
        setFormValues({
          initialValues: {
            idPadlocacion: res.data[0].idPadlocacion,
            idSET: res.data[0].idSET,
            fechaInicio: moment(res.data[0].fechaInicio).format("YYYY-MM-DD"),
            fechaFinal: moment(res.data[0].fechaFinal).format("YYYY-MM-DD"),
            fechaDTM: moment(res.data[0].fechaDTM).format("YYYY-MM-DD"),
            cliente: res.data[0].cliente.idCliente,
            operadora: res.data[0]?.operadora,
            companiaServicio: res.data[0]?.companiaServicio,
            nombreAreaHidrocarburifera: res.data[0].nombreAreaHidrocarburifera,
            descripcionAreaHidrocarburifera:
              res.data[0].descripcionAreaHidrocarburifera,
            direccion: res.data[0].direccion,
            ciudad: res.data[0].ciudad,
            provincia: res.data[0].provincia,
            pozos: res.data[0].pozos
              ? res.data[0].pozos
              : [{ nombrePozo: "", numeroEtapasPozo: "" }],
            mallas: res.data[0].mallas
              ? res.data[0].mallas
              : [
                  {
                    tipoMalla: "",
                    tnsArenaSc: "",
                    scEtapas: "",
                    tipoArenaMalla: "seca",
                    targetTnsDiaM: "",
                  },
                ],
            etapasTotalPad: res.data[0].etapasTotalPad,
            targeStandbyPozoGral: res.data[0].targeStandbyPozoGral,
            totalTns: res.data[0].totalTns,
            etapasDiaMalla: res.data[0].etapasDiaMalla,
            targetCantidadCamiones: res.data[0].targetCantidadCamiones,
            idPlanificacion: res.data[0].idPlanificacion,
            plantas: res.data[0].detallePlantas
              ? res.data[0].detallePlantas
              : [
                  {
                    idPlanta: "",
                    targetIdaPozoPlanta: "",
                    targetVueltaPozoPlanta: "",
                    targetStandBy: "",
                    targetTnsDiaPlanta: "",
                    targetVCD: "",
                    standByCamion: "",
                    targetVueltaCompleta: "",
                  },
                ],
            sanderPrincipal: parseInt(res.data[0].sanderPrincipal?.idSanderP),
            sanderBackup: parseInt(res.data[0].sanderBackup?.idSanderB),
            idSandVan: res.data[0].sandvan?.idSandVan,
            SandCubes: res.data[0].SandCubes,
            idAutoelevador1: parseInt(res.data[0].autoelevador1?.idAutoE1),
            idAutoelevador2: parseInt(res.data[0].autoelevador2?.idAutoE2),
            camiones: res.data[0].camiones
              ? res.data[0].camiones
              : [{ dominio: "", idRSV: "", empresaTransportista: [] }],
            dosificadora: res.data[0].dosificadora?.idDosificadora,
            desMontTransp: res.data[0].desMontTransp,
            estado: res.data[0].estado,
          },
        });
      } else {
        return toast({
          title: "Error",
          description: "Error al cargar los datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    let res = await getPlanificaciones({ nueva: "fecha" });

    if (res.status === 200) {
      setPlanificaciones(res.data);
    } else {
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    let resolve = await getPlanificaciones();
    if (resolve.status === 200) {
      setPlan(resolve.data);
    }

    res = await getClientes();

    if (res.status === 200) {
      setClientes(res.data);
    }

    setLoading(false);
  };

  const initDetallePlanta = async () => {
    let resolve = await getDetallePlantas({ idPlanificacion: id });
    if (resolve.status === 200) {
      setUltima(resolve.data);
    }
  };

  const handleId = async () => {
    setLoading(true);
    let res = await getPlanificaciones();

    if (res.status === 200) {
      let response = res.data;
      setId(response[response.length - 1].idPlanificacion);
    } else {
      return toast({
        description: "Error al obtener id",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="100%" overflow={"auto"}>
      <HStack pl={3} w="100%">
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => {
            type === "continuar"
              ? onClose()
              : navigate("/operaciones/planificacion");
          }}
          fontSize="24px"
        />
        <Heading fontWeight="semibold" placeSelf="start">
          Planificación
        </Heading>
      </HStack>
      {loading && loadingtable()}
      {!loading && (
        <>
          <HStack w="100%" justifyContent="space-between">
            <Stepper index={activeStep} w="100%" px={2} colorScheme="orange">
              {steps.map((step) => (
                <Step key={step}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle fontSize="12px">{step.title}</StepTitle>
                  </Box>
                </Step>
              ))}
            </Stepper>
            <Text color="brand.azul_text" fontSize={14} pr={1}>
              {activeStep + 1} de {steps.length} partes
            </Text>
          </HStack>
          <Divider w="100%" colorScheme="blackAlpha" />

          <Formik
            initialValues={formValues.initialValues}
            enableReinitialize={true}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
            }) => (
              <Stack w="100%">
                <Form id="FormularioPlanificacion">
                  {activeStep === 0 && (
                    <PrimerPaso
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      setId={setId}
                      type={type}
                      handleId={handleId}
                      planificaciones={planificaciones}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFormValues={setFormValues}
                      clientes={clientes}
                      expanded={expanded}
                      formValues={formValues.initialValues}
                      setPlan={setPlan}
                      errors={errors}
                      touched={touched}
                      user={user}
                    />
                  )}
                  {activeStep === 1 && (
                    <SegundoPaso
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      id={id}
                      type={type}
                      onClose={onClose}
                      initPlanificacion={initPlanificacion}
                      values={values}
                      seleccionado={seleccionado}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFormValues={setFormValues}
                      expanded={expanded}
                      formValues={formValues.initialValues}
                      plan={plan}
                      errors={errors}
                      touched={touched}
                      user={user}
                    />
                  )}
                  {activeStep === 2 && (
                    <TercerPaso
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      id={id}
                      type={type}
                      onClose={onClose}
                      initPlanificacion={initPlanificacion}
                      seleccionado={seleccionado}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFormValues={setFormValues}
                      formValues={formValues.initialValues}
                      expanded={expanded}
                      ultima={ultima}
                      errors={errors}
                      touched={touched}
                      user={user}
                    />
                  )}
                  {activeStep === 3 && (
                    <CuartoPaso
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      id={id}
                      seleccionado={seleccionado}
                      type={type}
                      onClose={onClose}
                      initPlanificacion={initPlanificacion}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFormValues={setFormValues}
                      formValues={formValues.initialValues}
                      expanded={expanded}
                      plan={plan}
                      initDetallePlanta={initDetallePlanta}
                      errors={errors}
                      touched={touched}
                      user={user}
                    />
                  )}
                  {activeStep === 4 && (
                    <QuintoPaso
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      id={id}
                      type={type}
                      onClose={onClose}
                      initPlanificacion={initPlanificacion}
                      seleccionado={seleccionado}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFormValues={setFormValues}
                      expanded={expanded}
                      plan={plan}
                      user={user}
                      formValues={formValues.initialValues}
                    />
                  )}
                </Form>
              </Stack>
            )}
          </Formik>
        </>
      )}
    </Stack>
  );
};

export default FormCrearEditarPlanificacion;
