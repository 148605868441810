import React, { useState } from "react";
import {
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { eliminarGeocercas } from "../helpers/api.helper";

const EliminarGeocerca = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const { geocerca, initData, seleccionado, user } = props;

  const toast = useToast();

  const handleEliminar = async () => {
    setLoading(true);
    let res = await eliminarGeocercas({
      id: geocerca.idGeocerca,
    });
    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Geocerca eliminada correctamente",
        duration: 3000,
      });
      initData();
      onClose();
    } else {
      return toast({
        title: "Error al eliminar geocerca",
        description: res.data,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Eliminar Geocerca">
        <IconButton
          variant="link"
          icon={<MdOutlineDelete fontSize="20px" />}
          onClick={onOpen}
          color={
            seleccionado && seleccionado.idGeocerca === geocerca.idGeocerca
              ? "brand.fondos_secundarios"
              : "gray.500"
          }
          _hover={{ color: "brand.naranja" }}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              color="brand.gris_primario"
              fontSize="lg"
              fontWeight="bold"
            >
              Eliminar Geocerca
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que desea eliminar el <strong>{"Geocerca"}</strong>?
              <br />
              Esta acción no se puede revertir.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="link" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                bg="brand.naranja"
                onClick={handleEliminar}
                ml={3}
                isDisabled={loading || user.idRol === "usuarioIT"}
                color="white"
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EliminarGeocerca;
