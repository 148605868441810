import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  HStack,
  VStack,
  useToast,
  Card,
  CardBody,
  CardFooter,
  Button,
  Select,
  RadioGroup,
  Radio,
  FormErrorMessage,
} from "@chakra-ui/react";
import { MdNavigateNext, MdFileDownloadDone } from "react-icons/md";
import { FieldArray, Field } from "formik";
import {
  crearPlanificacion,
  editarPlanificacion1,
  getPlanificaciones,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { handleUsuarioIT } from "../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../utils/Validaciones";

const PrimerPaso = (props) => {
  const {
    activeStep,
    setActiveStep,
    type,
    handleId,
    planificaciones,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    clientes,
    expanded,
    setPlan,
    formValues,
    errors,
    touched,
    user,
  } = props;
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const toast = useToast();
  const navigate = useNavigate();

  function formatDate(date, fecha) {
    const year = new Date(date).getFullYear();
    const month = String(new Date(date).getMonth() + 1).padStart(2, "0");
    const day = String(new Date(date).getDate()).padStart(2, "0");

    if (fecha === "FI") {
      return `${year}-${month}-${day}T00:00:00.0000`;
    } else if (fecha === "FF") {
      return `${year}-${month}-${day}T23:59:59.0000`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }

  const hadnleOperadora = (value) => {
    setFieldValue("operadora", value.nombreCliente);
    setFieldValue("companiaServicio", value.nombreCliente);
  };

  const handleSubmit = async (values, event) => {
    setLoading(true);
    if (
      !values.idPadlocacion ||
      validateOnSubmit("text", values.idPadlocacion)
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "ID de pad/locacion requerido o incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.fechaInicio) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha de inicio de operacion requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.fechaFinal) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha de fin de operacion requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.cliente) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Cliente requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.operadora || validateOnSubmit("text", values.operadora)) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Operadora requerido o incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (!values.companiaServicio) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Compañía de servicio requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (
      (values.nombreAreaHidrocarburifera &&
        validateOnSubmit("text", values.nombreAreaHidrocarburifera)) ||
      (values.descripcionAreaHidrocarburifera &&
        validateOnSubmit("textArea", values.descripcionAreaHidrocarburifera))
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Area hidrocarburifera es incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    if (
      (values.direccion && validateOnSubmit("text", values.direccion)) ||
      (values.provincia && validateOnSubmit("text", values.provincia)) ||
      (values.ciudad && validateOnSubmit("text", values.ciudad))
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "La dirección es incorrecta",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    let plan = [];
    let resolve = await getPlanificaciones();

    if (resolve.status === 200) {
      plan = resolve.data;
    } else if (resolve.status === 204) {
      plan = [];
    }

    let data =
      type === "editar" || type === "reActivar"
        ? {
            idPadlocacion: values.idPadlocacion,
            idSET: values.idSET,
            fechaInicio: formatDate(values.fechaInicio, "FI"),
            fechaFinal: formatDate(values.fechaFinal, "FF"),
            fechaDTM: formatDate(values.fechaDTM, "DMT"),
            cliente: values.cliente,
            operadora: values.operadora,
            companiaServicio: values.otro
              ? values.otro
              : values.companiaServicio,
            descripcionAreaHidrocarburifera: text,
            nombreAreaHidrocarburifera: values.nombreAreaHidrocarburifera,
            direccion: values.direccion,
            ciudad: values.ciudad,
            provincia: values.provincia,
            estado: formValues.estado,
            id: formValues.idPlanificacion,
            desMontTransp: false,
          }
        : {
            idPadlocacion: values.idPadlocacion,
            idSET: values.idSET,
            fechaInicio: formatDate(values.fechaInicio, "FI"),
            fechaFinal: formatDate(values.fechaFinal, "FF"),
            fechaDTM: formatDate(values.fechaDTM, "DMT"),
            cliente: values.cliente[0].idCliente,
            operadora: values.operadora,
            companiaServicio: values.otro
              ? values.otro
              : values.companiaServicio,
            descripcionAreaHidrocarburifera: text,
            nombreAreaHidrocarburifera: values.nombreAreaHidrocarburifera,
            direccion: values.direccion,
            ciudad: values.ciudad,
            provincia: values.provincia,
            estado: "proyectado",
            desMontTransp: false,
          };
    let res;

    if (user.idRol === "usuarioIT") {
      setActiveStep(activeStep + 1);
    } else {
      if (event === "continuar") {
        if (type === "editar" || type === "reActivar") {
          res = await editarPlanificacion1(data);
        } else {
          if (
            plan.length > 0 &&
            plan.some((e) => e.idPadlocacion === data.idPadlocacion)
          ) {
            let a = plan.filter((e) => e.idPadlocacion === data.idPadlocacion);

            res = await editarPlanificacion1({
              ...data,
              id: a[0].idPlanificacion,
            });
          } else {
            res = await crearPlanificacion(data);
          }
        }

        if (res.status === 200) {
          toast({
            status: "success",
            isClosable: true,
            title: `Primer paso ${
              type === "editar" || type === "reActivar" ? "editado" : "creado"
            } correctamente`,
            duration: 3000,
          });
        } else {
          setLoading(false);
          return toast({
            status: "error",
            isClosable: true,
            title: `Error al ${
              type === "editar" || type === "reActivar" ? "editar" : "crear"
            } el primer paso`,
            description: res.message,
          });
        }
        if (type === "crear") {
          handleId();
        } else {
          setActiveStep(activeStep + 1);
        }
      } else {
        if (type === "editar" || type === "reActivar") {
          res = await editarPlanificacion1(data);
        } else {
          res = await crearPlanificacion(data);
        }

        if (res.status === 200) {
          toast({
            status: "success",
            isClosable: true,
            title: `Primer paso ${
              type === "editar" || type === "reActivar" ? "editado" : "creado"
            } correctamente`,
            duration: 3000,
          });
        } else {
          setLoading(false);
          return toast({
            status: "error",
            isClosable: true,
            title: `Error al ${
              type === "editar" || type === "reActivar" ? "editar" : "crear"
            } el primer paso`,
            description: res.message,
          });
        }

        navigate(-1);
      }
      setPlan(data);
    }

    setLoading(false);
  };

  const handleCliente = (elemento) => {
    let cliente = clientes.filter((e) => e.idCliente === elemento);

    return cliente[0].nombreCliente;
  };

  const handleCaracteres = (event) => {
    const { value } = event.target;
    setText(value);
    setFieldValue("descripcionAreaHidrocarburifera", value);
  };
  const charCount = text.length;

  const verificarFechaInicio = (fecha, setFieldValue, idSET) => {
    let filtrado = planificaciones.filter((e) => e.idSET === idSET);
    filtrado = filtrado.filter(
      (e) => e.estado === "activo" || e.estado === "proyectado"
    );
    filtrado = filtrado.map((e) => ({
      fechaInicio: e.fechaInicio,
      fechaFinal: e.fechaFinal,
    }));

    const fechaObj = moment(fecha);

    for (let i = 0; i < filtrado.length; i++) {
      const fi = moment(filtrado[i].fechaInicio);
      const ff = moment(filtrado[i].fechaFinal);

      if (fi.isBefore(fechaObj) && ff.isAfter(fechaObj)) {
        toast({
          title: "Atención",
          description: "Esa fecha se superpone con otra planificación",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        break;
      }
    }

    setFieldValue("fechaInicio", fecha);
  };

  const verificarFechaFin = (fecha, setFieldValue, idSET) => {
    let filtrado = planificaciones.filter((e) => e.idSET === idSET);
    filtrado = filtrado.filter(
      (e) => e.estado === "activo" || e.estado === "proyectado"
    );
    filtrado = filtrado.map((e) => ({
      fechaInicio: e.fechaInicio,
      fechaFinal: e.fechaFinal,
    }));
    const fechaObj = moment(fecha);

    for (let i = 0; i < filtrado.length; i++) {
      const fi = moment(filtrado[i].fechaInicio);
      const ff = moment(filtrado[i].fechaFinal);

      if (fi.isBefore(fechaObj) && ff.isAfter(fechaObj)) {
        toast({
          title: "Atención",
          description: "Esa fecha se superpone con otra planificación",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        break;
      }
    }

    setFieldValue("fechaFinal", fecha);
  };

  return (
    <Stack px={3}>
      <HStack
        pb={1}
        w="100%"
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        <Field
          name="idPadlocacion"
          validate={(value) => validateField("text", value, "Id Pad")}
        >
          {({ field }) => (
            <FormControl
              isInvalid={errors.idPadlocacion && touched.idPadlocacion}
              w={"xs"}
              isRequired
            >
              <FormLabel fontWeight="bold">ID Pad / Locación</FormLabel>
              <Input
                {...field}
                onBlur={handleBlur}
                placeholder="Nombre"
                w="2xs"
                type="text"
                name="idPadlocacion"
                onChange={handleChange}
                value={values.idPadlocacion}
                readOnly={
                  type === "editar" && values.estado === "activo" ? true : false
                }
              />
              <FormErrorMessage>{errors.idPadlocacion}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold" letterSpacing={1}>
            Identificacion del SET
          </FormLabel>
          <RadioGroup
            name="idSET"
            value={values.idSET}
            defaultValue={type === "editar" ? values.idSET : ""}
          >
            <Stack direction="row" spacing={16}>
              <Radio value="set1" onChange={handleChange} onBlur={handleBlur}>
                SET 1
              </Radio>
              <Radio value="set2" onChange={handleChange} onBlur={handleBlur}>
                SET 2
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl w="xs"></FormControl>
      </HStack>
      <HStack
        pb={1}
        spacing={20}
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha Inicio</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaInicio"
            onChange={(e) =>
              verificarFechaInicio(e.target.value, setFieldValue, values.idSET)
            }
            onBlur={handleBlur}
            value={values.fechaInicio}
            disabled={!values.idSET ? true : false}
          />
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha de Final</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaFinal"
            onChange={(e) =>
              verificarFechaFin(e.target.value, setFieldValue, values.idSET)
            }
            min={values.fechaInicio}
            onBlur={handleBlur}
            value={values.fechaFinal}
            disabled={!values.idSET ? true : false}
          />
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha DTM</FormLabel>
          <Input
            w="2xs"
            type="date"
            name="fechaDTM"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaDTM}
          />
        </FormControl>
      </HStack>
      <HStack
        pb={1}
        spacing={20}
        justifyContent={!expanded ? "space-between" : "unset"}
      >
        {(type === "editar" || type === "reActivar") && (
          <>
            <FormControl w="xs" placeSelf={"start"}>
              <FormLabel fontWeight="semibold">Cliente</FormLabel>
              <Input
                w="2xs"
                type="text"
                name="cliente"
                value={handleCliente(values.cliente)}
                readOnly
              />
            </FormControl>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Operadora</FormLabel>
              <Input
                w="2xs"
                type="text"
                name="operadora"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.operadora}
                readOnly
              />
            </FormControl>
            <FormControl w="xs">
              <FormLabel fontWeight="semibold">Compañía de servicio</FormLabel>
              <Select
                w="2xs"
                type="text"
                placeholder="Nombre"
                name="companiaServicio"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companiaServicio}
              >
                <option value="Schlumberger">Schlumberger</option>
                <option value="Halliburton">Halliburton</option>
                <option value="Weatherford">Weatherford</option>
                <option value="Otro">Otro</option>
              </Select>
            </FormControl>
            {values.companiaServicio === "Otro" && (
              <FormControl w="xs">
                <FormLabel fontWeight="semibold"></FormLabel>
                <Input
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="otro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formValues.otro}
                />
              </FormControl>
            )}
          </>
        )}
        {type === "crear" && (
          <FormControl w="xs" isRequired placeSelf={"start"}>
            <FormLabel fontWeight="semibold">Cliente</FormLabel>
            <FieldArray name="cliente">
              {({ push, remove }) => (
                <Select
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="cliente"
                  onChange={(e) => {
                    remove(values.cliente[0]);
                    push(JSON.parse(e.target.value));
                    hadnleOperadora(JSON.parse(e.target.value));
                  }}
                  onBlur={handleBlur}
                >
                  {clientes.map((cliente) => {
                    return (
                      <option
                        key={cliente.idCliente}
                        value={JSON.stringify(cliente)}
                      >
                        {cliente.nombreCliente}
                      </option>
                    );
                  })}
                </Select>
              )}
            </FieldArray>
          </FormControl>
        )}

        {type === "crear" && values.cliente.length > 0 && (
          <>
            <FormControl w="xs" placeSelf={"start"}>
              <FormLabel fontWeight="semibold">Operadora</FormLabel>
              {values.cliente[0].tipoCliente === "operadora" && (
                <Input
                  w="2xs"
                  type="text"
                  name="operadora"
                  onBlur={handleBlur}
                  value={values.cliente[0].nombreCliente}
                  readOnly
                />
              )}
              {values.cliente[0].tipoCliente === "companiaServicio" && (
                <Stack placeSelf={"start"}>
                  <Field
                    name="operadora"
                    validate={(value) =>
                      validateField("text", value, "operadora")
                    }
                  >
                    {({ field }) => (
                      <FormControl
                        isInvalid={errors.operadora && touched.operadora}
                        w={"xs"}
                      >
                        <Input
                          {...field}
                          w="2xs"
                          type="text"
                          name="operadora"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.operadora}
                        />
                        <FormErrorMessage>{errors.operadora}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              )}
            </FormControl>
            <FormControl w="xs" placeSelf={"start"}>
              <FormLabel fontWeight="semibold">Compañía de servicio</FormLabel>
              {values.cliente[0].tipoCliente === "companiaServicio" && (
                <Input
                  w="2xs"
                  type="text"
                  name="companiaServicio"
                  onBlur={handleBlur}
                  value={values.cliente[0].nombreCliente}
                  readOnly
                />
              )}
              {values.cliente[0].tipoCliente === "operadora" && (
                <Select
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="companiaServicio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companiaServicio}
                >
                  {clientes.map((cliente) => (
                    <option
                      key={cliente.idCliente}
                      value={cliente.nombreCliente}
                    >
                      {cliente.nombreCliente}
                    </option>
                  ))}
                  <option value="Otro">Otro</option>
                </Select>
              )}
            </FormControl>
            {values.companiaServicio === "Otro" && (
              <FormControl w="xs">
                <FormLabel fontWeight="semibold"></FormLabel>
                <Input
                  w="2xs"
                  type="text"
                  placeholder="Nombre"
                  name="otro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otro}
                />
              </FormControl>
            )}
          </>
        )}
      </HStack>
      <HStack
        w="100%"
        justifyContent={!expanded ? "space-between" : "unset"}
        pb={1}
        spacing={20}
      >
        <Stack>
          <Field
            name="nombreAreaHidrocarburifera"
            validate={(value) => validateField("text", value, "nombre area")}
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors.nombreAreaHidrocarburifera &&
                  touched.nombreAreaHidrocarburifera
                }
                w={"xs"}
              >
                <FormLabel fontWeight="bold">Área hidrocarburífera</FormLabel>
                <Stack>
                  <Input
                    {...field}
                    w="2xs"
                    type="text"
                    placeholder="Nombre"
                    name="nombreAreaHidrocarburifera"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nombreAreaHidrocarburifera}
                  />
                  <FormErrorMessage>
                    {errors.nombreAreaHidrocarburifera}
                  </FormErrorMessage>
                </Stack>
              </FormControl>
            )}
          </Field>
          <Field
            name="descripcionAreaHidrocarburifera"
            validate={(value) => validateField("textArea", value, "text area")}
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors.descripcionAreaHidrocarburifera &&
                  touched.descripcionAreaHidrocarburifera
                }
                w={"xs"}
              >
                <Card w="2xs" variant="unstyled" bg="brand.fondos_secundarios">
                  <CardBody>
                    <Textarea
                      {...field}
                      w="2xs"
                      placeholder="Descripción"
                      name="descripcionAreaHidrocarburifera"
                      onChange={handleCaracteres}
                      onBlur={handleBlur}
                      value={values.descripcionAreaHidrocarburifera}
                      maxLength="250"
                      resize="none"
                    />
                  </CardBody>
                  <CardFooter placeSelf="end" color="brand.gris_medio">
                    {charCount}/250
                  </CardFooter>
                </Card>
                <FormErrorMessage>
                  {errors.descripcionAreaHidrocarburifera}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>

        <FormControl w="xs" placeSelf={"start"}>
          <FormLabel fontWeight="semibold">Localización</FormLabel>
          <Stack>
            <Field
              name="direccion"
              validate={(value) =>
                validateField("numerosLetras", value, "direccion")
              }
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.direccion && touched.direccion}
                  w={"xs"}
                  isRequired
                >
                  <HStack>
                    <Input
                      {...field}
                      w="2xs"
                      type="text"
                      placeholder="Dirección"
                      name="direccion"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.direccion}
                    />
                    <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                  </HStack>
                </FormControl>
              )}
            </Field>

            <Field
              name="ciudad"
              validate={(value) => validateField("letras", value, "ciudad")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.ciudad && touched.ciudad}
                  w={"xs"}
                >
                  <HStack>
                    <Input
                      {...field}
                      w="2xs"
                      type="text"
                      placeholder="Ciudad"
                      name="ciudad"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ciudad}
                    />
                    <FormErrorMessage>{errors.ciudad}</FormErrorMessage>
                  </HStack>
                </FormControl>
              )}
            </Field>

            <Field
              name="provincia"
              validate={(value) => validateField("letras", value, "provincia")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.provincia && touched.provincia}
                  w={"xs"}
                >
                  <HStack>
                    <Input
                      {...field}
                      w="2xs"
                      type="text"
                      placeholder="Provincia"
                      name="provincia"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.provincia}
                    />
                    <FormErrorMessage>{errors.provincia}</FormErrorMessage>
                  </HStack>
                </FormControl>
              )}
            </Field>
          </Stack>
        </FormControl>
        <VStack w="xs" placeSelf="end">
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            onClick={() =>
              user.idRol === "usuarioIT"
                ? handleUsuarioIT(setActiveStep, activeStep)
                : handleSubmit(values, "continuar")
            }
            variant="solid"
            form="FormularioPlanificacion"
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
          <Button
            color="brand.naranja"
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            variant="outline"
            leftIcon={<MdFileDownloadDone fontSize="20px" />}
            textColor="brand.naranja"
            form="FormularioPlanificacion"
            onClick={() => handleSubmit(values, "finalizar")}
            isDisabled={loading || user.idRol === "usuarioIT"}
            _hover={{ bg: "brand.naranja", color: "white" }}
          >
            Finalizar
          </Button>
        </VStack>
      </HStack>
    </Stack>
  );
};

export default PrimerPaso;
