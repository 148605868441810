import { Button, HStack, Box } from "@chakra-ui/react";
import React from "react";

const BotonOperacion = React.forwardRef(
  (
    { leftIcon, text, rightIcon, onMouseEnter, onMouseLeave, user, roles },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        fontSize="18px"
        fontWeight="bold"
        w="xs"
        h="80px"
        placeContent="flex-start"
        bg="brand.fondos_primarios"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        _hover={{ bg: "brand.naranja", color: "white" }}
        cursor={roles.includes(user.idRol) ? "pointer" : "not-allowed"}
        borderLeftRadius={"unset"}
      >
        <HStack justifyContent={"flex-start"} width={"100%"}>
          {leftIcon}
          <Box
            flexGrow={1}
            flexBasis={0}
            textAlign="left"
            marginLeft="15px"
            //display={{ base: "none", xl: "block" }} // Oculta el texto en resoluciones menores a 1400px
            //_hover={{ display: "inline" }}
          >
            {text}
          </Box>
          {rightIcon}
        </HStack>
      </Button>
    );
  }
);

export default BotonOperacion;
