import React, { useEffect, useState } from "react";
import {
  HStack,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  IconButton,
  Switch,
  useToast,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import {
  MdNavigateNext,
  MdOutlineAdd,
  MdArrowBack,
  MdArrowDropDown,
  MdDelete,
} from "react-icons/md";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  editarAsociacionCSC,
  crearAsociacionCSC,
  getSandcubesPlani,
  editarSandcubes,
  getPlanificaciones,
} from "../../helpers/api.helper";
import moment from "moment";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";

const CrearEditarAsociacion = (props) => {
  const { type, onClose, initAsociaciones, mobile, asociacion } = props;
  const [planificacion, setPlanificacion] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [camionSeleccionado, setCamionSeleccionado] = useState(
    type === "editar" ? asociacion.camion : ""
  );
  const [sandcubeSeleccionado1, setSandcubeSeleccionado1] = useState(
    type === "editar" ? asociacion.sandCube1 : null
  );
  const [sandcubeSeleccionado2, setSandcubeSeleccionado2] = useState(
    type === "editar" ? asociacion.sandCube2 : null
  );
  const [sandcubeSeleccionado3, setSandcubeSeleccionado3] = useState(
    type === "editar" && asociacion.sandCube ? asociacion.sandCube : null
  );
  const [scs3, setScs3] = useState(
    type === "editar" && asociacion.sandCube ? true : false
  );
  const [scs, setScs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const id = useParams();
  const fechaHoy = moment().format("YYYY-MM-DDTHH:mm");
  let sandcubeOptions;
  let camionOptions;
  let plantaOptions;

  const initData = async () => {
    let res;
    if (type === "nueva") {
      res = await getPlanificaciones(id);

      if (res.status === 200) {
        setPlanificacion(res.data[0]);
      } else if (res.status === 400) {
        setLoading(false);
        return toast({
          title: "Error",
          description: " Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    if (type === "editar") {
      res = await getPlanificaciones({
        padLocacion: asociacion.padLocacionACSC,
      });

      if (res.status === 200) {
        setPlanificacion(res.data[0]);
      } else if (res.status === 400) {
        setLoading(false);
        return toast({
          title: "Error",
          description: " Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    res = await getSandcubesPlani();

    if (res.status === 200) {
      setScs(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  const customStyles = {
    // Estilo para el contenedor del Select
    container: (provided) => ({
      ...provided,
      width: "100%", // Ajusta el ancho según lo necesites
    }),
    // Estilo para el input (control)
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#3182CE" : "#E2E8F0", // Cambia el color del borde cuando está enfocado
      boxShadow: state.isFocused ? "0 0 0 1px #3182CE" : "none",
      borderRadius: "0.375rem", // Bordes redondeados
      height: "2.rem", // Altura del control
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F3F6F9", // Fondo blanco por defecto
      color: "black", // Color de texto negro por defecto
      textColor: "black",
      "&:hover": {
        borderColor: "#3182CE", // Cambia el color del borde al pasar el mouse
      },
      "& > div": {
        border: "none", // Eliminar cualquier borde en el control
      },
      width: mobile ? "xs" : "2xs",
    }),
    // Estilo para el indicador (ícono)
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0 0.5rem", // Espaciado del indicador
      border: "none", // Sin borde en el contenedor del indicador
      backgroundColor: "transparent", // Fondo transparente para el indicador
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: "0px",
    }),
    // Estilo para la opción seleccionada
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#3182CE" // Fondo azul si está seleccionado
        : state.isFocused
        ? "#E2E8F0" // Fondo gris claro al pasar el mouse
        : "white", // Fondo blanco por defecto
      color: state.isSelected ? "white" : "black", // Texto blanco si está seleccionado, negro si no
      padding: "0.5rem 1rem", // Espaciado interno de las opciones
      "&:hover": {
        backgroundColor: "#E2E8F0", // Color de fondo al pasar el mouse
      },
    }),
    // Estilo para el menú
    menu: (provided) => ({
      ...provided,
      zIndex: 10, // Asegura que el menú aparezca sobre otros elementos
      borderRadius: "0.375rem", // Bordes redondeados para el menú
      marginTop: "0.25rem", // Espaciado superior del menú
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Sombra sutil para el menú
      backgroundColor: "white", // Fondo blanco para el menú
    }),
    // Estilo para las opciones dentro del menú
    menuList: (provided) => ({
      ...provided,
      borderRadius: "0.375rem", // Bordes redondeados para la lista de opciones
      padding: 0, // Sin espaciado adicional
      backgroundColor: "white", // Fondo blanco para la lista de opciones
    }),
  };

  const validateValues = (values) => {
    if (!values.camion) {
      return { isValid: false, message: "Camión requerido" };
    }

    if (!values.sandcubes[0].sandcube || !values.sandcubes[1].sandcube) {
      return { isValid: false, message: "Sandcubes requeridos" };
    }

    if (
      values.sandcubes[0]?.sandcube === values.sandcubes[1]?.sandcube ||
      values.sandcubes[1]?.sandcube === values.sandcubes[2]?.sandcube ||
      values.sandcubes[0]?.sandcube === values.sandcubes[2]?.sandcube
    ) {
      return { isValid: false, message: "Los Sandcubes deben ser distintos" };
    }

    return { isValid: true };
  };

  const initialValues =
    type === "editar"
      ? {
          fecha: moment(asociacion.fechaCreacionACSC).format(
            "YYYY-MM-DDTHH:mm"
          ),
          planta: asociacion.planta ? asociacion.planta.idPlanta : "",
          sandcubes: [
            {
              sandcube: asociacion.sandCube1?.idSandCube || "",
              malla: asociacion.sandCube1?.malla || "",
              tipoArena: asociacion.sandCube1?.tipoArena || "",
            },
            {
              sandcube: asociacion.sandCube2?.idSandCube || "",
              malla: asociacion.sandCube2?.malla || "",
              tipoArena: asociacion.sandCube2?.tipoArena || "",
            },
            { sandcube: "", malla: "", tipoArena: "" },
          ],
          camion: asociacion.camion?.dominio || "",
          nombreCamion: asociacion.camion?.nombreCamion || "",
          empresa: asociacion.camion?.nombreEmpresaT || "",
          vueltaBaseACSC: asociacion.vueltaBaseACSC || false,
        }
      : {
          idPadlocacion: "",
          fecha: fechaHoy,
          planta: "",
          sandcubes: [
            { sandcube: "", malla: "", tipoArena: "" },
            { sandcube: "", malla: "", tipoArena: "" },
            { sandcube: "", malla: "", tipoArena: "" },
          ],
          camion: "",
          nombreCamion: "",
          empresa: "",
          vueltaBaseACSC: false,
        };

  if (type === "editar" && asociacion.sandCube) {
    initialValues.sandcubes[2] = {
      sandcube: asociacion.sandCube.idSandCube,
      malla: asociacion.sandCube.malla,
      tipoArena: asociacion.sandCube.tipoArena,
    };
  }

  if (!loading) {
    sandcubeOptions = scs.map((sandcube) => ({
      value: sandcube.idSandCube,
      label: sandcube.nombreSandCube,
      ...sandcube, // Incluye el resto de las propiedades si las necesitas
    }));

    camionOptions = planificacion.camiones.map((camion) => ({
      value: camion.idCamion,
      label: `${camion.dominio} - ${camion.nombreCamion}`,
      ...camion, // Incluye el resto de las propiedades si las necesitas
    }));

    plantaOptions = planificacion.detallePlantas.map((planta) => ({
      value: planta.idPlanta,
      label: planta.nombrePlanta,
    }));
  }

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <MdArrowDropDown style={{ color: "#000" }} />{" "}
        {/* Icono personalizado */}
      </components.DropdownIndicator>
    );
  };

  return (
    <Stack w="100%" h={"100%"}>
      <HStack w="100%" pl={5}>
        {type === "nueva" && (
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          </Box>
        )}
        {type === "editar" && (
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => onClose()}
              fontSize="24px"
            />
          </Box>
        )}
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          {type === "nueva" ? "Nueva" : "Editar"} asociación Camión/Sandcubes
        </Heading>
        <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
          en {planificacion.idPadlocacion}
        </Text>
      </HStack>
      <Stack
        w="100%"
        bg="brand.fondo"
        borderRadius={5}
        px={3}
        position={"relative"}
        h={"100%"}
      >
        {loading && loadingtable()}
        {!loading && (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const validation = validateValues(values);
              if (!validation.isValid) {
                toast({
                  title: "Error",
                  description: validation.message,
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                });
                setSubmitting(false);
                return;
              }

              const sendSandCubes = [
                sandcubeSeleccionado1,
                sandcubeSeleccionado2,
                sandcubeSeleccionado3,
              ]
                .filter(Boolean)
                .map((sandcube) => ({
                  idSandCube: sandcube.idSandCube,
                  malla: sandcube.malla,
                  tipoArena: sandcube.tipoArena,
                  nombreSandCube: sandcube.nombreSandCube,
                  condicion: sandcube.condicion,
                  toneladasSandCube: sandcube.toneladasSandCube,
                  statusSandCube: sandcube.statusSandCube,
                }));

              try {
                await editarSandcubes({ sandCubes: sendSandCubes });

                const asociacionPayload = {
                  padLocacionACSC:
                    type === "editar"
                      ? asociacion.padLocacionACSC
                      : planificacion.idPadlocacion,
                  idPlanta: parseInt(values.planta),
                  idSandCube1: sandcubeSeleccionado1.idSandCube,
                  idSandCube2: sandcubeSeleccionado2.idSandCube,
                  idSandCube3: sandcubeSeleccionado3?.idSandCube,
                  idCamion: camionSeleccionado.idCamion,
                  vueltaBaseACSC: values.vueltaBaseACSC,
                  ...(type !== "editar" && { fechaCreacionACSC: values.fecha }),
                };

                let res;
                if (type === "editar") {
                  res = await editarAsociacionCSC({
                    id: asociacion.idACSC,
                    ...asociacionPayload,
                  });
                } else {
                  res = await crearAsociacionCSC(asociacionPayload);
                }

                if (res.status === 200 || res.status === 204) {
                  toast({
                    status: "success",
                    isClosable: true,
                    title: `Asociación ${
                      type === "editar" ? "editada" : "creada"
                    } correctamente`,
                    duration: 3000,
                  });
                  if (res.status === 204) {
                    toast({
                      status: "warning",
                      isClosable: true,
                      title: `No hay usuarios activos para notificar`,
                      duration: 3000,
                    });
                  }
                  type === "editar" ? onClose() : navigate(-1);
                  type === "editar" && initAsociaciones();
                } else if (res.status === 409) {
                  toast({
                    status: "warning",
                    isClosable: true,
                    title: "Aviso",
                    description:
                      res.data.length === 1
                        ? `Sandcube ${res.data[0].nombreSandCube} ya esta asociado`
                        : `Sandcubes ${res.data
                            .map((element) => element.nombreSandCube)
                            .join(", ")} ya estan asociados`,
                    duration: 3000,
                  });
                } else {
                  toast({
                    status: "error",
                    isClosable: true,
                    title: `Error al ${
                      type === "editar" ? "editar" : "crear"
                    } asociación`,
                    description: res.data.message,
                    duration: 3000,
                  });
                }
              } catch (error) {
                toast({
                  status: "error",
                  isClosable: true,
                  title: "Error inesperado",
                  description: "Ocurrió un error al procesar la solicitud.",
                  duration: 3000,
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form id="formAsociacion">
                <Stack spacing={mobile ? 0 : 10}>
                  <Stack pb={5} spacing={mobile ? 20 : 3} direction={"row"}>
                    <FormControl w={mobile ? "xs" : "2xs"}>
                      <FormLabel fontWeight="semibold">Fecha y Hora</FormLabel>
                      <Input
                        w={mobile ? "xs" : "2xs"}
                        type="datetime-local"
                        value={type === "crear" ? fechaHoy : values.fecha}
                        bg="brand.gris_medio"
                        isReadOnly
                      />
                    </FormControl>
                    <FormControl w={mobile ? "xs" : "2xs"}>
                      <FormLabel fontWeight="semibold">Planta</FormLabel>
                      <Select
                        components={{
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                        styles={customStyles}
                        menuPlacement="auto"
                        placeholder="Nombre"
                        name="planta"
                        options={plantaOptions}
                        value={plantaOptions.find(
                          (option) => option.value === values.planta
                        )}
                        onChange={(selectedOption) => {
                          // Aquí utilizamos setFieldValue para actualizar el campo "planta" en Formik
                          setFieldValue(
                            "planta",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        onBlur={() => handleBlur("planta")} // Asegúrate de pasar el nombre del campo
                        icon={<MdArrowDropDown />}
                        isClearable
                      />
                    </FormControl>

                    <FormControl w={mobile ? "xs" : "2xs"}>
                      <FormLabel fontWeight="semibold">Vuelta a base</FormLabel>
                      <Switch
                        name="vueltaBaseACSC"
                        onChange={handleChange}
                        isChecked={values.vueltaBaseACSC}
                      />
                    </FormControl>
                  </Stack>
                  <Stack pb={5} spacing={3} direction={"row"}>
                    {!values.camion && (
                      <FormControl w="2xs" isRequired>
                        <FormLabel fontWeight="semibold">Camión</FormLabel>
                        <Select
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                          styles={customStyles}
                          chakraStyles={customStyles}
                          menuPlacement="auto"
                          placeholder="Buscar Camión"
                          options={camionOptions}
                          value={camionOptions.find(
                            (option) => option.dominio === values.camion
                          )}
                          onChange={(selectedOption) => {
                            setCamionSeleccionado(selectedOption);
                            setFieldValue(
                              "camion",
                              selectedOption ? selectedOption.dominio : null
                            );
                            setFieldValue(
                              "empresa",
                              selectedOption
                                ? selectedOption.empresaTransportista
                                    .nombreEmpresaT
                                : null
                            );
                            setFieldValue(
                              "nombreCamion",
                              selectedOption
                                ? selectedOption.nombreCamion
                                : null
                            );
                          }}
                          name={"camion"}
                        />
                      </FormControl>
                    )}
                    {values.camion && (
                      <HStack>
                        <FormControl w="2xs" isRequired>
                          <FormLabel fontWeight="semibold">Camión</FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            menuPlacement="auto"
                            name={"camion"}
                            placeholder="Buscar Camión"
                            options={camionOptions}
                            value={{
                              value: values.camion,
                              label: camionOptions.find(
                                (option) => option.dominio === values.camion
                              )?.dominio,
                            }}
                            onChange={(selectedOption) => {
                              setCamionSeleccionado(selectedOption);
                              setFieldValue("camion", selectedOption.dominio);
                              setFieldValue(
                                "empresa",
                                selectedOption.empresaTransportista
                                  .nombreEmpresaT
                              );
                              setFieldValue(
                                "nombreCamion",
                                selectedOption.nombreCamion
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                        >
                          <FormLabel fontWeight="semibold">Interno</FormLabel>
                          <Input
                            w="2xs"
                            type="text"
                            value={values.nombreCamion}
                            readOnly
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                        >
                          <FormLabel fontWeight="semibold">Empresa</FormLabel>
                          <Input w="2xs" value={values.empresa} readOnly />
                        </FormControl>
                      </HStack>
                    )}
                  </Stack>
                </Stack>
                <HStack w="100%" spacing={mobile ? 20 : 10}>
                  <Stack
                    pb={5}
                    // spacing={mobile ? 20 : 5}
                    direction={mobile ? "row" : "column"}
                  >
                    {!values.sandcubes[0]?.sandcube && (
                      <FormControl w={"2xs"} isRequired>
                        <FormLabel fontWeight="semibold">Sandcube</FormLabel>
                        <Select
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                          styles={customStyles}
                          menuPlacement="auto"
                          name={`sandcubes[0].nombreSandCube`} // Accedemos a Formik values
                          placeholder="Buscar Sandcube"
                          options={sandcubeOptions}
                          value={
                            type === "nueva" || !values.sandcubes[0]?.sandcube
                              ? null
                              : sandcubeOptions.find(
                                  (option) =>
                                    option.label ===
                                    values.sandcubes[0]?.sandcube
                                )
                          }
                          onChange={(selectedOption) => {
                            setSandcubeSeleccionado1(selectedOption);
                            handleChange({
                              target: {
                                value: selectedOption?.value,
                              },
                            });
                            setFieldValue(
                              `sandcubes[0].sandcube`,
                              selectedOption ? selectedOption.value : null
                            ); // Usamos setFieldValue para Formik
                          }}
                        />
                      </FormControl>
                    )}

                    {values.sandcubes[0]?.sandcube && (
                      <HStack>
                        <FormControl w={"2xs"} isRequired>
                          <FormLabel fontWeight="semibold">Sandcube</FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            options={sandcubeOptions}
                            name={`sandcubes[0].sandcube`} // Formik name
                            value={{
                              value: values.sandcubes[0]?.sandcube,
                              label: sandcubeOptions.find(
                                (option) =>
                                  option.value === values.sandcubes[0]?.sandcube
                              )?.label,
                            }}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado1(selectedOption);
                              setFieldValue(
                                `sandcubes[0].sandcube`,
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                          paddingLeft={1}
                        >
                          <FormLabel fontWeight="semibold">Malla</FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            placeholder="- Tipo -"
                            name={`sandcubes[0].malla`} // Formik name
                            options={[
                              { value: "100", label: "100" },
                              { value: "30/70", label: "30/70" },
                            ]}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado1((prevstate) => ({
                                ...prevstate,
                                malla: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));
                              setFieldValue(
                                `sandcubes[0].malla`,
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            value={
                              sandcubeSeleccionado1?.malla
                                ? {
                                    value: sandcubeSeleccionado1?.malla,
                                    label: sandcubeSeleccionado1?.malla,
                                  }
                                : null // Asegúrate de que esté vacío en creación
                            }
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                          paddingLeft={1}
                        >
                          <FormLabel fontWeight="semibold">
                            Tipo de arena
                          </FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            placeholder="- Tipo -"
                            name={`sandcubes[0].tipoArena`} // Formik name
                            options={[
                              { value: "seca", label: "seca" },
                              { value: "humeda", label: "humeda" },
                            ]}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado1((prevstate) => ({
                                ...prevstate,
                                tipoArena: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));
                              setFieldValue(
                                `sandcubes[0].tipoArena`,
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            value={
                              sandcubeSeleccionado1?.tipoArena
                                ? {
                                    value: sandcubeSeleccionado1?.tipoArena,
                                    label: sandcubeSeleccionado1?.tipoArena,
                                  }
                                : null // Asegúrate de que esté vacío en creación
                            }
                          />
                        </FormControl>
                      </HStack>
                    )}
                  </Stack>
                </HStack>
                <HStack w="100%" spacing={mobile ? 20 : 10}>
                  <Stack
                    pb={5}
                    spacing={mobile ? 20 : 3}
                    direction={mobile ? "row" : "column"}
                  >
                    {!values.sandcubes[1]?.sandcube && (
                      <FormControl w={"2xs"} isRequired>
                        <FormLabel fontWeight="semibold">Sandcube</FormLabel>
                        <Select
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                          styles={customStyles}
                          chakraStyles={customStyles}
                          menuPlacement="auto"
                          name={`sandcubes[1].nombreSandCube`} // Accedemos a Formik values
                          placeholder="Buscar Sandcube"
                          options={sandcubeOptions}
                          value={
                            type === "nueva" || !values.sandcubes[1]?.sandcube
                              ? null
                              : sandcubeOptions.find(
                                  (option) =>
                                    option.label ===
                                    values.sandcubes[1]?.sandcube
                                )
                          }
                          onChange={(selectedOption) => {
                            setSandcubeSeleccionado2(selectedOption);
                            handleChange({
                              target: {
                                value: selectedOption?.value,
                              },
                            });
                            setFieldValue(
                              `sandcubes[1].sandcube`,
                              selectedOption ? selectedOption.value : null
                            ); // Usamos setFieldValue para Formik
                          }}
                        />
                      </FormControl>
                    )}

                    {values.sandcubes[1]?.sandcube && (
                      <HStack>
                        <FormControl w={"2xs"} isRequired>
                          <FormLabel fontWeight="semibold">Sandcube</FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            options={sandcubeOptions}
                            name={`sandcubes[1].sandcube`} // Formik name
                            value={{
                              value: values.sandcubes[1]?.sandcube,
                              label: sandcubeOptions.find(
                                (option) =>
                                  option.value === values.sandcubes[1]?.sandcube
                              )?.label,
                            }}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado2(selectedOption);
                              setFieldValue(
                                `sandcubes[1].sandcube`,
                                selectedOption ? selectedOption.value : ""
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                          paddingLeft={1}
                        >
                          <FormLabel fontWeight="semibold">Malla</FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            placeholder="- Tipo -"
                            name={`sandcubes[1].malla`} // Formik name
                            options={[
                              { value: "100", label: "100" },
                              { value: "30/70", label: "30/70" },
                            ]}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado2((prevstate) => ({
                                ...prevstate,
                                malla: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));

                              // Verifica si selectedOption no es null
                              setFieldValue(
                                `sandcubes[1].malla`,
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            value={
                              sandcubeSeleccionado2?.malla
                                ? {
                                    value: sandcubeSeleccionado2?.malla,
                                    label: sandcubeSeleccionado2?.malla,
                                  }
                                : null // Asegúrate de que esté vacío en creación
                            }
                          />
                        </FormControl>
                        <FormControl
                          placeSelf={mobile ? "end" : "start"}
                          w={"2xs"}
                          paddingLeft={1}
                        >
                          <FormLabel fontWeight="semibold">
                            Tipo de arena
                          </FormLabel>
                          <Select
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                            styles={customStyles}
                            chakraStyles={customStyles}
                            menuPlacement="auto"
                            placeholder="- Tipo -"
                            name={`sandcubes[1].tipoArena`} // Formik name
                            options={[
                              { value: "seca", label: "seca" },
                              { value: "humeda", label: "humeda" },
                            ]}
                            onChange={(selectedOption) => {
                              setSandcubeSeleccionado2((prevstate) => ({
                                ...prevstate,
                                tipoArena: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));

                              // Verifica si selectedOption no es null
                              setFieldValue(
                                `sandcubes[1].tipoArena`,
                                selectedOption ? selectedOption.value : null
                              );
                            }}
                            value={
                              sandcubeSeleccionado2?.tipoArena
                                ? {
                                    value: sandcubeSeleccionado2?.tipoArena,
                                    label: sandcubeSeleccionado2?.tipoArena,
                                  }
                                : null // Asegúrate de que esté vacío en creación
                            }
                          />
                        </FormControl>
                      </HStack>
                    )}
                  </Stack>
                </HStack>
                {!scs3 && (
                  <Button
                    color="brand.naranja"
                    fontSize="12px"
                    variant="link"
                    size="lg"
                    leftIcon={<MdOutlineAdd size={18} />}
                    onClick={() => {
                      setScs3(true);
                    }}
                    textColor="black"
                  >
                    Agregar Sandcube
                  </Button>
                )}
                {scs3 && (
                  <HStack w="100%" spacing={mobile ? 20 : 10}>
                    <Stack
                      pb={5}
                      spacing={mobile ? 20 : 3}
                      direction={mobile ? "row" : "column"}
                    >
                      {!values.sandcubes[2]?.sandcube && (
                        <HStack>
                          <FormControl w={"2xs"} isRequired>
                            <FormLabel fontWeight="semibold">
                              Sandcube
                            </FormLabel>
                            <Select
                              components={{
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                              styles={customStyles}
                              chakraStyles={customStyles}
                              menuPlacement="auto"
                              name={`sandcubes[2].nombreSandCube`} // Accedemos a Formik values
                              placeholder="Buscar Sandcube"
                              options={sandcubeOptions}
                              value={
                                type === "nueva" ||
                                !values.sandcubes[2]?.sandcube
                                  ? null
                                  : sandcubeOptions.find(
                                      (option) =>
                                        option.label ===
                                        values.sandcubes[2]?.sandcube
                                    )
                              }
                              onChange={(selectedOption) => {
                                setSandcubeSeleccionado3(selectedOption);
                                setFieldValue(
                                  `sandcubes[2].sandcube`,
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                            />
                          </FormControl>
                          <IconButton
                            variant={"link"}
                            icon={<MdDelete />}
                            onClick={() => {
                              setScs3(false);
                            }}
                            placeSelf={"end"}
                            pb={2}
                          />
                        </HStack>
                      )}

                      {values.sandcubes[2]?.sandcube && (
                        <HStack>
                          <FormControl w={"2xs"} isRequired>
                            <FormLabel fontWeight="semibold">
                              Sandcube
                            </FormLabel>
                            <Select
                              components={{
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                              styles={customStyles}
                              chakraStyles={customStyles}
                              menuPlacement="auto"
                              options={sandcubeOptions}
                              name={`sandcubes[2].sandcube`} // Formik name
                              value={{
                                value: values.sandcubes[2]?.sandcube,
                                label: sandcubeOptions.find(
                                  (option) =>
                                    option.value ===
                                    values.sandcubes[2]?.sandcube
                                )?.label,
                              }}
                              onChange={(selectedOption) => {
                                setSandcubeSeleccionado3(selectedOption);
                                setFieldValue(
                                  `sandcubes[2].sandcube`,
                                  selectedOption.value
                                );
                              }}
                            />
                          </FormControl>
                          <FormControl
                            placeSelf={mobile ? "end" : "start"}
                            w={"2xs"}
                            paddingLeft={1}
                          >
                            <FormLabel fontWeight="semibold">Malla</FormLabel>
                            <Select
                              components={{
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                              styles={customStyles}
                              chakraStyles={customStyles}
                              menuPlacement="auto"
                              placeholder="- Tipo -"
                              name={`sandcubes[2].malla`} // Formik name
                              options={[
                                { value: "100", label: "100" },
                                { value: "30/70", label: "30/70" },
                              ]}
                              onChange={(selectedOption) => {
                                setSandcubeSeleccionado3((prevstate) => ({
                                  ...prevstate,
                                  malla: selectedOption
                                    ? selectedOption.value
                                    : null,
                                }));

                                setFieldValue(
                                  `sandcubes[2].malla`,
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              value={
                                sandcubeSeleccionado3?.malla
                                  ? {
                                      value: sandcubeSeleccionado3?.malla,
                                      label: sandcubeSeleccionado3?.malla,
                                    }
                                  : null // Asegúrate de que esté vacío en creación
                              }
                            />
                          </FormControl>
                          <FormControl
                            placeSelf={mobile ? "end" : "start"}
                            w={"2xs"}
                            paddingLeft={1}
                          >
                            <FormLabel fontWeight="semibold">
                              Tipo de arena
                            </FormLabel>
                            <Select
                              components={{
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                              styles={customStyles}
                              chakraStyles={customStyles}
                              menuPlacement="auto"
                              placeholder="- Tipo -"
                              name={`sandcubes[2].tipoArena`} // Formik name
                              options={[
                                { value: "seca", label: "seca" },
                                { value: "humeda", label: "humeda" },
                              ]}
                              onChange={(selectedOption) => {
                                setSandcubeSeleccionado3((prevstate) => ({
                                  ...prevstate,
                                  tipoArena: selectedOption
                                    ? selectedOption.value
                                    : null,
                                }));

                                // Verifica si selectedOption no es null
                                setFieldValue(
                                  `sandcubes[2].tipoArena`,
                                  selectedOption ? selectedOption.value : null
                                );
                              }}
                              value={
                                sandcubeSeleccionado3?.tipoArena
                                  ? {
                                      value: sandcubeSeleccionado3?.tipoArena,
                                      label: sandcubeSeleccionado3?.tipoArena,
                                    }
                                  : null // Asegúrate de que esté vacío en creación
                              }
                            />
                          </FormControl>
                          <IconButton
                            variant={"link"}
                            icon={<MdDelete />}
                            onClick={() => {
                              setScs3(false);
                              setSandcubeSeleccionado3(null);
                              setFieldValue(`sandcubes[2].sandcube`, null);
                            }}
                            placeSelf={"end"}
                            pb={2}
                          />
                        </HStack>
                      )}
                    </Stack>
                  </HStack>
                )}
                <HStack w={"100%"} justifyContent="end">
                  <Button
                    fontSize={12}
                    fontWeight="semibold"
                    bg="brand.naranja"
                    color="white"
                    rightIcon={<MdNavigateNext fontSize="20px" />}
                    variant="solid"
                    form="formAsociacion"
                    onClick={handleSubmit}
                    isDisabled={isSubmitting || user.idRol === "usuarioIT"}
                    _hover={{ bg: "white", color: "brand.naranja" }}
                  >
                    {isSubmitting ? "Enviando..." : "Finalizar"}
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        )}
      </Stack>
    </Stack>
  );
};

export default CrearEditarAsociacion;
