import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  HStack,
  Text,
  Heading,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Thead,
  Center,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  Divider,
  Radio,
  RadioGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  Tooltip,
  IconButton,
  useToast,
  CircularProgress,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineGetApp,
  MdOutlineCreate,
  MdMoreVert,
  MdFilterList,
  MdRefresh,
  MdAddCircleOutline,
  MdCheck,
  MdOutlineImportExport,
} from "react-icons/md";
import { BsInfo } from "react-icons/bs";
import {
  descargarPlanificacion,
  getPlanificaciones,
  editarPlanificacion,
  getClientes,
} from "./helpers/api.helper";
import TempButton from "./components/curso/TempButton";
import BtnEliminarPlanificacion from "./components/planificacion/BtnEliminarPlanificacion";
import BtnInactivar from "./components/planificacion/BtnInactivar";
import BtnReActivar from "./components/planificacion/BtnReActivar";
import Example from "./components/planificacion/StepExample";
import LineaTiempoPlanificaciones from "./components/planificacion/LineaTiempoPlanificaciones";
import { useSelector } from "react-redux";
import { sortData } from "../../utils/sortData";
import ContinuarPlanificacion from "./components/planificacion/ContinuarPlanificacion";
import moment from "moment/moment";
import EditarCamionesSandcubes from "./components/planificacion/EditarCamionesSandcubes";
import DatePicker from "react-datepicker";

const Planificacion = (props) => {
  const { mobile } = props;
  const [planificaciones, setPlanificaciones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seleccionado, setSeleccionado] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [filterBy, setFilterBy] = useState({
    set: "",
    cliente: "",
    estado: "",
    inicio: "",
    fin: "",
  });
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const initialFocusRef = useRef();
  const { user } = useSelector((state) => state.user);
  const toast = useToast();
  const navigate = useNavigate();

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getPlanificaciones();

    if (res.status === 200) {
      setPlanificaciones(res.data);
    } else if (res.status === 204) {
      setLoading(false);
      return toast({
        title: "Exito",
        description: "Planificaciónes cargadas correctamente",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Error al cargar planificaciones",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Error al cargar planificaciones",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    res = await getClientes();

    if (res.status === 200) {
      setClientes(res.data);
    } else {
      setLoading(false);
      toast({
        title: "Error",
        description: "Error al cargar clientes",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setSeleccionado();
    setLoading(false);
  };
  const handleColorEstado = (e) => {
    switch (e) {
      case "proyectado":
        return "brand.azul_text";
      case "activo":
        return "brand.verde";
      case "inactivo":
        return "brand.rojo";
      case "terminado":
        return "brand.gris_medio";

      default:
        return "-";
    }
  };

  const filterStyle = (filterName) => ({
    // Aplicar un estilo diferente al filtro seleccionado
    backgroundColor:
      filterName === selectedFilter ? "lightblue" : "transparent",
  });

  const handleTextoEstado = (e) => {
    switch (e) {
      case "proyectado":
        return "Proyectado";
      case "activo":
        return "Activo";
      case "inactivo":
        return "Inactivo";
      case "terminado":
        return "Terminado";

      default:
        return "-";
    }
  };
  const handleEtapas = (e) => {
    let total = e.pozos?.reduce((acumulador, elemento) => {
      return acumulador + parseInt(elemento.numeroEtapasPozo);
    }, 0);
    return total;
  };
  const handleSets = (e) => {
    switch (e) {
      case "set1":
        return "Set 1";
      case "set2":
        return "Set 2";
      default:
        return "-";
    }
  };
  const handleDownload = async (e) => {
    let response = await descargarPlanificacion({
      id: e.idPlanificacion,
      detallePlantas: e.detallePlantas || [],
      nombre: e.idPadlocacion,
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${e.idPadlocacion}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };
  const handleTerminar = async () => {
    let res = await editarPlanificacion({
      id: seleccionado.idPlanificacion,
      estado: "terminado",
    });

    if (res.status === 200) {
      initData();
      return toast({
        description: "Finalizado correctamente",
        title: "Exito",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      return toast({
        description: "Error al finalizar",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const showPlanificaciones = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return planificaciones;
    }
    return planificaciones.filter((planificacion) => {
      const { set, cliente, estado, inicio, fin } = filterBy;

      return (
        (!set || planificacion.idSET === set) &&
        (!cliente || planificacion.cliente.nombreCliente === cliente) &&
        (!estado || planificacion.estado === estado) &&
        (!inicio || planificacion.fechaInicio <= inicio) &&
        (!fin || planificacion.fechaFinal >= fin)
      );
    });
  };
  const cleanFilter = () => {
    setSelectedFilter(null);

    setFilterBy({
      set: "",
      cliente: "",
      estado: "",
      inicio: "",
      fin: "",
    });
  };
  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {}, [sortField, sortDirection]);

  const isSupervisor =
    user.idRol === "userOperaciones" && user.puesto === "supervisor";
  const isAdminOrCoordinator =
    user.idRol === "admin" || user.idRol === "coorOp";

  return (
    <Stack w="100%">
      <HStack
        w="100%"
        bg="brand.fondos_secundario"
        justifyContent={"space-between"}
      >
        <HStack spacing={5} mt={"-0.5rem"}>
          <Box pl={5}>
            <Heading
              fontWeight="medium"
              size="xl"
              fontFamily="fonts.title"
              color="brand.gris_primario"
            >
              Planificaciones
            </Heading>
            <Text fontSize="12px">Listado de Planificaciónes realizadas</Text>
          </Box>
          <LineaTiempoPlanificaciones
            planificaciones={showPlanificaciones()}
            type="planificaciones"
          />
        </HStack>

        <HStack spacing={3} px={3}>
          {(user.idRol === "coorOp" ||
            user.idRol === "usuarioIT" ||
            user.idRol === "admin") && (
            <Button
              size="sm"
              variant="outline"
              border="1px solid #FF8200"
              leftIcon={<MdAddCircleOutline fontSize="20px" />}
              color="brand.naranja"
              onClick={() => {
                navigate("/operaciones/planificacion/nueva");
              }}
              _hover={{ bg: "brand.naranja", color: "white" }}
            >
              Nueva Planificación
            </Button>
          )}

          <Popover isLazy>
            <PopoverTrigger>
              <IconButton
                icon={<MdFilterList fontSize="20px" />}
                bg={"brand.fondos_secundarios"}
                borderRadius="50%"
                color={"black"}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <Tooltip label="Limpiar filtro">
                  <IconButton
                    icon={<MdRefresh />}
                    onClick={cleanFilter}
                    variant="link"
                    justifySelf={"end"}
                  />
                </Tooltip>
              </PopoverHeader>
              <PopoverBody maxH={"sm"} overflowY={"auto"}>
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Por Set
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={2} cursor={"pointer"}>
                        <HStack>
                          {selectedFilter === "set1" && <MdCheck />}
                          <ListItem
                            style={filterStyle("set1")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                set: "set1",
                              }));
                              showPlanificaciones();
                              setSelectedFilter("set1");
                            }}
                          >
                            Set 1
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "set2" && <MdCheck />}
                          <ListItem
                            style={filterStyle("set2")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                set: "set2",
                              }));
                              setSelectedFilter("set2");
                              showPlanificaciones();
                            }}
                          >
                            Set 2
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Estado
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={1} cursor={"pointer"}>
                        <HStack>
                          {" "}
                          {selectedFilter === "activo" && <MdCheck />}
                          <ListItem
                            style={filterStyle("activo")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                estado: "activo",
                              }));
                              setSelectedFilter("activo");
                              showPlanificaciones();
                            }}
                          >
                            Activo
                          </ListItem>
                        </HStack>

                        <HStack>
                          {selectedFilter === "inactivo" && <MdCheck />}

                          <ListItem
                            style={filterStyle("inactivo")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                estado: "inactivo",
                              }));
                              setSelectedFilter("inactivo");
                              showPlanificaciones();
                            }}
                          >
                            Inactivo
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "proyectado" && <MdCheck />}

                          <ListItem
                            style={filterStyle("proyectado")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                estado: "proyectado",
                              }));
                              setSelectedFilter("proyectado");
                              showPlanificaciones();
                            }}
                          >
                            Proyectado
                          </ListItem>
                        </HStack>
                        <HStack>
                          {selectedFilter === "terminado" && <MdCheck />}

                          <ListItem
                            style={filterStyle("terminado")}
                            onClick={() => {
                              setFilterBy((prevstate) => ({
                                ...prevstate,
                                estado: "terminado",
                              }));
                              setSelectedFilter("terminado");
                              showPlanificaciones();
                            }}
                          >
                            Terminado
                          </ListItem>
                        </HStack>
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      Cliente
                    </AccordionButton>
                    <AccordionPanel>
                      <List spacing={1} cursor={"pointer"}>
                        {clientes.map((cliente) => (
                          <HStack key={cliente.idCliente}>
                            {selectedFilter === cliente && <MdCheck />}
                            <ListItem
                              style={filterStyle(cliente)}
                              key={cliente.idCliente}
                              onClick={() => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  cliente: cliente.nombreCliente,
                                }));
                                setSelectedFilter(cliente);
                                showPlanificaciones();
                              }}
                            >
                              {cliente.nombreCliente}
                            </ListItem>
                          </HStack>
                        ))}
                      </List>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton fontWeight={"bold"}>
                      <DatePicker
                        selectsRange={true}
                        startDate={filterBy.inicio}
                        endDate={filterBy.fin}
                        dateFormat="dd/MM/yyyy"
                        onChange={(update) => {
                          setFilterBy((prevstate) => ({
                            ...prevstate,
                            inicio: update[0],
                            fin: update[1],
                          }));
                          setDateRange(update);
                          showPlanificaciones();
                        }}
                        placeholderText="   Fecha"
                      />
                    </AccordionButton>
                  </AccordionItem>
                </Accordion>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      <HStack
        w="100%"
        px={3}
        justifyContent={"space-between"}
        bg="brand.fondos_secundario"
        borderRadius={5}
        h={"lg"}
      >
        <TableContainer
          w={!mobile ? "100%" : "60%"}
          overflowX={"auto"}
          overflowY="auto"
          h={"lg"}
        >
          <Table variant="simple" size="sm">
            <Thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#F3F6F9",
                opacity: 1,
              }}
            >
              <Tr borderBottom="2px solid black">
                <Th></Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "idPadlocacion",
                        planificaciones
                      );
                      setSortField("idPadlocacion");
                      setSortDirection(direction);
                      setPlanificaciones(sortedData);
                    }}
                  >
                    Nombre
                    {sortDirection === "asc" &&
                      sortField === "idPadlocacion" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "idPadlocacion" && <ArrowDownIcon />}
                  </span>
                  <span className="hover-icon" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon1").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "cliente.nombreCliente",
                        planificaciones
                      );
                      setSortField("cliente.nombreCliente");
                      setSortDirection(direction);
                      setPlanificaciones(sortedData);
                    }}
                  >
                    Cliente
                    {sortDirection === "asc" &&
                      sortField === "cliente.nombreCliente" && <ArrowUpIcon />}
                    {sortDirection === "des" &&
                      sortField === "cliente.nombreCliente" && (
                        <ArrowDownIcon />
                      )}
                  </span>
                  <span className="hover-icon1" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  textAlign={"right"}
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon2").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "etapasTotalPad",
                        planificaciones
                      );
                      setSortField("etapas");
                      setSortDirection(direction);
                      setPlanificaciones(sortedData);
                    }}
                  >
                    Etapas
                    {sortDirection === "asc" && sortField === "etapas" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "etapas" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon2" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon3").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "idSET",
                        planificaciones
                      );
                      setSortField("etapas");
                      setSortDirection(direction);
                      setPlanificaciones(sortedData);
                    }}
                  >
                    SET
                    {sortDirection === "asc" && sortField === "idSET" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "idSET" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon3" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                <Th>WetSand</Th>
                <Th
                  _hover={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    (document.querySelector(".hover-icon4").style.display =
                      "inline-block")
                  }
                  onMouseLeave={() =>
                    (document.querySelector(".hover-icon4").style.display =
                      "none")
                  }
                >
                  <span
                    className="sortable"
                    onClick={() => {
                      const { sortedData, direction } = sortData(
                        "estado",
                        planificaciones
                      );
                      setSortField("estado");
                      setSortDirection(direction);
                      setPlanificaciones(sortedData);
                    }}
                  >
                    Estado
                    {sortDirection === "asc" && sortField === "estado" && (
                      <ArrowUpIcon />
                    )}
                    {sortDirection === "des" && sortField === "estado" && (
                      <ArrowDownIcon />
                    )}
                  </span>
                  <span className="hover-icon4" style={{ display: "none" }}>
                    <MdOutlineImportExport />
                  </span>
                </Th>
                {(isAdminOrCoordinator ||
                  isSupervisor ||
                  user.idRol === "usuarioIT") && <Th>Acciones</Th>}
              </Tr>
            </Thead>
            {loading && (
              <Tbody>
                <Tr>
                  <Td colSpan={8}>{loadingtable()}</Td>
                </Tr>
              </Tbody>
            )}
            {!loading && planificaciones.length < 1 && (
              <Tbody>
                <Tr>
                  <Td
                    colSpan={6}
                    textAlign="center"
                    fontWeight="bold"
                    color="brand.gris_primario"
                  >
                    No hay planificaciónes creadas
                  </Td>
                </Tr>
              </Tbody>
            )}
            {!loading && planificaciones.length >= 1 && (
              <Tbody textAlign="center" overflowY="auto">
                {showPlanificaciones().map((ejemplo) => (
                  <Tr
                    key={ejemplo.idPlanificacion}
                    value={ejemplo.idPlanificacion}
                    onClick={() => {
                      setSeleccionado(ejemplo);
                    }}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Td>
                      <RadioGroup value={seleccionado?.idPlanificacion}>
                        <Radio
                          value={ejemplo.idPlanificacion}
                          border="1px solid black"
                          colorScheme="blackAlpha"
                        />
                      </RadioGroup>
                    </Td>
                    <Td textOverflow="ellipsis">{ejemplo.idPadlocacion}</Td>
                    <Td>{ejemplo.cliente.nombreCliente}</Td>
                    <Td textAlign={"right"}>{handleEtapas(ejemplo)}</Td>
                    <Td>{handleSets(ejemplo.idSET)}</Td>
                    <Td>{ejemplo.dosificadora ? "SI" : "NO"}</Td>
                    <Td color={handleColorEstado(ejemplo.estado)}>
                      {handleTextoEstado(ejemplo.estado)}
                    </Td>
                    {(isAdminOrCoordinator ||
                      isSupervisor ||
                      user.idRol === "usuarioIT") && (
                      <Td>
                        {(((ejemplo.estado === "proyectado" ||
                          ejemplo.estado === "activo") &&
                          isAdminOrCoordinator) ||
                          (isSupervisor &&
                            (ejemplo.estado === "proyectado" ||
                              ejemplo.estado === "activo")) ||
                          (user.idRol === "usuarioIT" &&
                            (ejemplo?.UserPaso4 || ejemplo?.scAsociados))) && (
                          <Tooltip label="Editar Planificación">
                            <IconButton
                              icon={<MdOutlineCreate fontSize="20px" />}
                              variant="link"
                              size={"sm"}
                              isDisabled={user.idRol === "usuarioIT"}
                              onClick={() =>
                                navigate(
                                  `/operaciones/planificacion/editar/${ejemplo.idPlanificacion}`
                                )
                              }
                              _hover={{ color: "brand.naranja" }}
                            />
                          </Tooltip>
                        )}
                        {((ejemplo.estado === "proyectado" &&
                          isAdminOrCoordinator) ||
                          user.idRol === "usuarioIT") && (
                          <BtnEliminarPlanificacion
                            planificacion={ejemplo}
                            initData={initData}
                            user={user}
                          />
                        )}
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>

        {seleccionado && (
          <Card
            w={"40%"}
            bg="brand.layout"
            border="none"
            boxShadow="none"
            h={"lg"}
            variant={"outline"}
          >
            <CardHeader pb="unset">
              <HStack w="100%" justifyContent="space-between">
                <Heading
                  textColor="brand.azul_text"
                  size="sm"
                  fontWeight="semibold"
                >
                  {seleccionado.idPadlocacion.toUpperCase()}
                </Heading>
                <Example seleccionado={seleccionado} />
                {(isAdminOrCoordinator ||
                  user.idRol === "usuarioIT" ||
                  isSupervisor) && (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MdMoreVert />}
                      variant="link"
                    />
                    <MenuList>
                      {seleccionado.UserPaso4 && (
                        <MenuItem>
                          <EditarCamionesSandcubes
                            seleccionado={seleccionado}
                            initPlan={initData}
                            user={user}
                          />
                        </MenuItem>
                      )}
                      {seleccionado.estado !== "terminado" && (
                        <MenuItem>
                          {seleccionado.idSET &&
                            (seleccionado.estado === "proyectado" ||
                              seleccionado.estado === "activo") &&
                            (user.idRol === "admin" ||
                              user.idRol === "coorOp" ||
                              user.idRol === "usuarioIT") && (
                              <BtnInactivar
                                seleccionado={seleccionado}
                                initData={initData}
                                user={user}
                              />
                            )}
                          {seleccionado.estado === "inactivo" &&
                            (user.idRol === "admin" ||
                              user.idRol === "coorOp" ||
                              user.idRol === "usuarioIT") && (
                              <BtnReActivar
                                seleccionado={seleccionado}
                                initData={initData}
                                user={user}
                              />
                            )}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </CardHeader>
            <Divider pt={1} color="#EBEAEA" />
            <CardBody overflowY="auto" overflowX={"hidden"}>
              <HStack pb={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">ID Pozo</FormLabel>
                  {seleccionado.pozos &&
                    seleccionado.pozos.map((e) => (
                      <Text fontSize="sm" key={e.idPozo}>
                        {e.nombrePozo}
                      </Text>
                    ))}
                </FormControl>

                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Etapas</FormLabel>
                  {seleccionado.pozos &&
                    seleccionado.pozos.map((e) => (
                      <Text fontSize="sm" key={e.idPozo}>
                        {e.numeroEtapasPozo}
                      </Text>
                    ))}
                </FormControl>

                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">DTM Completo</FormLabel>
                  {seleccionado.desMontTransp && <Text fontSize="sm">SI</Text>}
                  {!seleccionado.desMontTransp && <Text fontSize="sm">NO</Text>}
                </FormControl>
              </HStack>
              <Divider py={1} />
              <HStack py={1}>
                <FormControl alignSelf="start">
                  <FormLabel alignSelf="start" fontWeight="semibold">
                    Cliente
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.cliente.nombreCliente}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Compañía de servicio
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.companiaServicio}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Operadora
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.cliente.nombreCliente}
                  </Text>
                </FormControl>
              </HStack>
              <Divider py={1} />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Fecha Inicio</FormLabel>
                  <Text fontSize="sm">
                    {moment(seleccionado.fechaInicio).format("DD/MM/YYYY")}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Fecha Fin</FormLabel>
                  <Text fontSize="sm">
                    {moment(seleccionado.fechaFinal).format("DD/MM/YYYY")}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Fecha DTM</FormLabel>
                  <Text fontSize="sm">
                    {moment(seleccionado.fechaDTM).format("DD/MM/YYYY")}
                  </Text>
                </FormControl>
              </HStack>
              <Divider py={1} />
              <HStack pt={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Area Hidrocarburifera
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.nombreAreaHidrocarburifera}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold"></FormLabel>
                  <HStack>
                    <Popover
                      initialFocusRef={initialFocusRef}
                      placement="bottom"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <Tooltip label="Descripcion">
                          <IconButton
                            icon={<BsInfo fontSize="24px" />}
                            variant="outline"
                            color="brand.azul_fondo"
                            placeItems="center"
                            size="sm"
                          />
                        </Tooltip>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverHeader>Descripción</PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody>
                          {seleccionado.descripcionAreaHidrocarburifera}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Localizacion</FormLabel>
                  <Text fontSize="sm">{seleccionado.direccion}</Text>
                  <Text fontSize="sm">{seleccionado.ciudad}</Text>
                  <Text fontSize="sm">{seleccionado.provincia}</Text>
                </FormControl>
              </HStack>
              <Divider py={1} />
              <HStack pt={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Malla</FormLabel>
                  {seleccionado.mallas?.map((malla) => (
                    <Text key={malla.idMalla} fontSize="sm">
                      {malla.tipoMalla}
                    </Text>
                  ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Tipo Arena</FormLabel>
                  {seleccionado.mallas?.map((malla) => (
                    <Text fontSize="sm" key={malla.idMalla}>
                      {malla.tipoArenaMalla === "seca" && "Seca"}
                      {malla.tipoArenaMalla === "humeda" && "Humeda"}
                    </Text>
                  ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Target Standby Pozo
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.targeStandbyPozoGral} mins
                  </Text>
                </FormControl>
              </HStack>
              <HStack pb={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Tns. Arena/SC</FormLabel>
                  {seleccionado.mallas?.map((malla) => (
                    <Text key={malla.idMalla} fontSize="sm">
                      {malla.tnsArenaSc}
                    </Text>
                  ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Sandcube/Etapa</FormLabel>
                  {seleccionado.mallas?.map((malla) => (
                    <Text key={malla.idMalla} fontSize="sm">
                      {malla.scEtapas}
                    </Text>
                  ))}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">
                    Target tns por día
                  </FormLabel>
                  {seleccionado.mallas?.map((malla) => (
                    <Text fontSize="sm" key={malla.idMalla}>
                      {malla.targetTnsDiaM}
                    </Text>
                  ))}
                </FormControl>
              </HStack>
              <HStack py={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Toneladas Total</FormLabel>
                  <Text fontSize="sm">{seleccionado.totalTns}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Etapas total/pad</FormLabel>
                  <Text fontSize="sm">{handleEtapas(seleccionado)}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Cantidad camiones</FormLabel>
                  <Text fontSize="sm">{seleccionado.camiones?.length}</Text>
                </FormControl>
              </HStack>
              <HStack>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Etapas por día</FormLabel>
                  <Text fontSize="sm">{seleccionado.etapasDiaMalla}</Text>
                </FormControl>
              </HStack>
              <Divider py={1} />
              {seleccionado.detallePlantas?.map((elemento, index) => (
                <Stack py={2} key={elemento.nombre} spacing={3}>
                  <HStack pt={1}>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Planta {index + 1}
                      </FormLabel>
                      <Text fontSize="sm">{elemento.nombrePlanta}</Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Target IDA Pozo-Planta
                      </FormLabel>
                      <Text fontSize="sm">
                        {elemento.targetIdaPozoPlanta} mins
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Target VUELTA Pozo-Planta
                      </FormLabel>
                      <Text fontSize="sm">
                        {elemento.targetVueltaPozoPlanta} mins
                      </Text>
                    </FormControl>
                  </HStack>
                  <HStack pt={1}>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Target Standby
                      </FormLabel>
                      <Text fontSize="sm">{elemento.targetStandBy} mins</Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Target Vuelta completa
                      </FormLabel>
                      <Text fontSize="sm">
                        {elemento.targetVueltaCompleta} mins
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold">Target VCD</FormLabel>
                      <Text fontSize="sm">{elemento.targetVCD}</Text>
                    </FormControl>
                  </HStack>
                  <HStack pt={1}>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Target Tns. por día
                      </FormLabel>
                      <Text fontSize="sm">{elemento.targetTnsDiaPlanta}</Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold">
                        Standby del Camion
                      </FormLabel>
                      <Text fontSize="sm">{elemento.standByCamion} mins</Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="semibold"></FormLabel>
                      <Text fontSize="sm"></Text>
                    </FormControl>
                  </HStack>
                </Stack>
              ))}
              <Divider py={1} />
              <HStack pt={2} pb={3}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Set Asignado</FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.idSET
                      ? handleSets(seleccionado.idSET)
                      : "Sin asignar"}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  {seleccionado.dosificadora && (
                    <>
                      <FormLabel fontWeight="semibold">Dosificadora</FormLabel>
                      <Text fontSize="sm">
                        {seleccionado.dosificadora.nombreDosificadora}
                      </Text>
                    </>
                  )}
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold"></FormLabel>
                  <Text fontSize="sm"></Text>
                </FormControl>
              </HStack>
              <HStack py={1} pb={3}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Sandcubes</FormLabel>
                  <Text fontSize="sm">{seleccionado.SandCubes}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">SandVan</FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.sandvan?.codigoSandVan}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Sanders</FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.sanderPrincipal?.codigoSander}
                  </Text>
                  {seleccionado.sanderBackup && (
                    <Text fontSize="sm">
                      {seleccionado.sanderBackup.codigoSander}
                    </Text>
                  )}
                </FormControl>
              </HStack>
              <HStack pb={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold">Autoelevadores</FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.autoelevador1?.nombreAutoE}
                  </Text>
                  {seleccionado.autoelevador2 && (
                    <Text fontSize="sm">
                      {seleccionado.autoelevador2?.nombreAutoE}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Camiones</FormLabel>
                  {seleccionado.camiones
                    ?.sort((a) => (a.camionActivo === "false" ? 1 : -1))
                    .map((e) => (
                      <Text
                        fontSize="sm"
                        key={e.dominio}
                        color={e.camionActivo === "false" ? "red" : "inherit"}
                      >
                        {`${e.nombreCamion ? `${e.nombreCamion}` : ""}${
                          e.dominio ? ` - ${e.dominio}` : ""
                        }${e.nombreEmpresaT ? ` - ${e.nombreEmpresaT}` : ""}`}
                      </Text>
                    ))}
                </FormControl>
              </HStack>
            </CardBody>
            <Divider color="#EBEAEA" />
            <CardFooter justifyContent="space-between">
              {(user.idRol !== "cliente" || user.idRol !== "usuarioBrent") && (
                <Button
                  variant="outline"
                  border="1px solid #FF8200"
                  leftIcon={<MdOutlineGetApp fontSize="20px" />}
                  size="sm"
                  fontWeight="semibold"
                  fontSize={12}
                  letterSpacing={1}
                  color={"brand.naranja"}
                  onClick={() => handleDownload(seleccionado)}
                  _hover={{ bg: "brand.naranja", color: "white" }}
                >
                  Descargar
                </Button>
              )}

              {seleccionado.estado === "activo" &&
                seleccionado.UserPaso5 &&
                (user.idRol === "admin" ||
                  user.idRol === "usuarioIT" ||
                  user.idRol === "coorOp") && (
                  <Button
                    variant="link"
                    fontSize="20px"
                    letterSpacing={1}
                    onClick={() => handleTerminar()}
                    justifySelf="center"
                    color="brand.naranja"
                    size="sm"
                    isDisabled={user.idRol === "usuarioIT"}
                  >
                    Finalizar
                  </Button>
                )}
              {seleccionado.estado === "proyectado" &&
                seleccionado.desMontTransp === false &&
                (user.idRol === "admin" ||
                  user.idRol === "usuarioIT" ||
                  user.idRol === "coorOp") && (
                  <TempButton
                    seleccionado={seleccionado}
                    initData={initData}
                    planificaciones={planificaciones}
                    user={user}
                  />
                )}
              {!seleccionado.UserPaso5 &&
                (user.idRol === "admin" ||
                  user.idRol === "usuarioIT" ||
                  user.idRol === "coorOp" ||
                  (user.idRol === "userOperaciones" &&
                    user.puesto === "supervisor" &&
                    seleccionado.detallePlantas)) && (
                  <ContinuarPlanificacion
                    seleccionado={seleccionado}
                    initData={initData}
                    user={user}
                  />
                )}
            </CardFooter>
          </Card>
        )}
        {!seleccionado && (
          <Center w={"40%"} justifyContent={"center"}>
            <Text
              fontSize={"14px"}
              color={"gray.400"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              La información se verá una vez <br /> que se seleccione una
              planificación
            </Text>
          </Center>
        )}
      </HStack>
    </Stack>
  );
};

export default Planificacion;
