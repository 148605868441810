import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { getNotificacionesII } from "./helpers/api.helper";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import moment from "moment";
import { getUsuarios } from "../usuarios/helpers/api.helper";

const Alertas = (props) => {
  const { notificaciones, setNotificaciones, setUsuario } = props;
  const toast = useToast();
  const socket = io(process.env.REACT_APP_URL, {
    transports: ["websocket", "polling"], // Probar con 'websocket' y 'polling'
    withCredentials: true,
  });
  const { user } = useSelector((state) => state.user);

  const handleAlerta = async () => {
    let res = await getNotificacionesII({
      destinatarioNBS: user.username,
      inicio: moment().subtract(1, "days").format("YYYY-MM-DD"),
      fin: moment().format("YYYY-MM-DD"),
    });

    if (res.status === 200) {
      setNotificaciones([...notificaciones, ...res.data]);

      toast({
        title: "¡Alerta!",
        description:
          res.data[0] && res.data[0]?.descripciónNBS
            ? res.data[0]?.descripciónNBS
            : "Revisa las notificaciones",
        status: "warning", // Puedes usar 'success', 'error', 'warning' o 'info'
        duration: 3000, // Duración en milisegundos
        isClosable: true, // Puede cerrarse haciendo clic en la 'X'
      });
    }
    res = await getUsuarios({ id: user.idUsuario });

    if (res.status === 200) {
      setUsuario(res.data[0]);
    }
  };

  useEffect(() => {
    socket.on("nuevoRegistroNotificaciones", () => {
      handleAlerta();
    });

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      socket.off("nuevoRegistroNotificaciones");
    };
  }, []);
};

export default Alertas;
