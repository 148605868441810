import React from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { editarPlanificacion } from "../../helpers/api.helper";

const BtnInactivar = (props) => {
  const { seleccionado, initData, user } = props;
  const toast = useToast();

  const handleInactivar = async () => {
    let res = await editarPlanificacion({
      id: seleccionado.idPlanificacion,
      estado: "inactivo",
    });

    if (res.status === 200) {
      initData();
      toast({
        description: "Inactivado correctamente",
        title: "Exito",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      return toast({
        description: "Error al Inactivar",
        title: "Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <Box>
      <Button
        color="red"
        variant="link"
        size="sm"
        leftIcon={<HiOutlineExclamationCircle />}
        onClick={() => handleInactivar()}
        isDisabled={user.idRol === "usuarioIT"}
      >
        Inactivar
      </Button>
    </Box>
  );
};

export default BtnInactivar;
