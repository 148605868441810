import React, { useState, useEffect } from "react";
import {
  HStack,
  Box,
  IconButton,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Stack,
  Divider,
  FormControl,
  FormLabel,
  useToast,
  CircularProgress,
  Radio,
  RadioGroup,
  Icon,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Center,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  Tooltip,
} from "@chakra-ui/react";
import {
  MdOutlineCreate,
  MdFilterList,
  MdRefresh,
  MdOutlineImportExport,
  MdAddCircle,
  MdCheck,
  MdOutlineClose,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { sortData } from "../../utils/sortData";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import {
  getRegistroEvento,
  getPlanificaciones,
} from "../operaciones/helpers/api.helper";
import EliminarRegistro from "../operaciones/components/curso/eventos/EliminarRegistro";
import DescargarEventos from "../operaciones/components/curso/eventos/DescargaEventos";
import DescargarEventoPDF from "../operaciones/components/curso/eventos/DescargaEvento";
import moment from "moment";
import DatePicker from "react-datepicker";

function arrayBufferToImageUrl(buffer) {
  const blob = new Blob([new Uint8Array(buffer)], { type: "image/png" });
  const imageUrl = URL.createObjectURL(blob);
  return imageUrl;
}

const RegistroEventos = (props) => {
  const { mobile } = props;
  const [registros, setRegistros] = useState([]);
  const [seleccionado, setSeleccionado] = useState();
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [planificacion, setPlanificacion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const navigate = useNavigate();
  const [filterBy, setFilterBy] = useState({
    inicio: "",
    fin: "",
    valoracion: [],
    responsable: [],
  });
  const padLocacion = useParams();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initData = async () => {
    setLoading(true);
    let res;
    if (padLocacion) {
      res = await getRegistroEvento(padLocacion);
    } else {
      res = await getRegistroEvento();
    }

    if (res.status === 200) {
      setRegistros(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const showEventos = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return registros;
    }

    const { inicio, fin, valoracion, responsable } = filterBy;

    return registros.filter((registro) => {
      const fechaRevision = moment(registro.fechaRegistroEventos);
      const cumpleFechaInicio =
        !inicio || fechaRevision.isSameOrAfter(inicio, "day");
      const cumpleFechaFin = !fin || fechaRevision.isSameOrBefore(fin, "day");
      const cumpleValoracion =
        !valoracion.length ||
        valoracion.includes(registro.valoracionRegistroEventos.toLowerCase());
      const cumpleResponsable =
        !responsable.length ||
        responsable.includes(registro.responsableRegistroEventos.nombre);

      return (
        cumpleFechaInicio &&
        cumpleFechaFin &&
        cumpleValoracion &&
        cumpleResponsable
      );
    });
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  const cleanFilter = () => {
    setFilterBy({
      fecha: "",
      valoracion: "",
      responsable: "",
    });
  };

  const handleFilterSelection = (filterName, value) => {
    setFilterBy((prevstate) => ({
      ...prevstate,
      [filterName]: prevstate[filterName].includes(value)
        ? prevstate[filterName].filter((val) => val !== value)
        : [...prevstate[filterName], value],
    }));
    showEventos();
  };

  const nombreUnicos = registros.reduce((nombresUnicos, registro) => {
    if (
      registro.responsableRegistroEventos &&
      !nombresUnicos.includes(registro.responsableRegistroEventos.nombre)
    ) {
      nombresUnicos.push(registro.responsableRegistroEventos.nombre);
    }
    return nombresUnicos;
  }, []);

  const handleImagen = (e) => {
    setImageUrl(arrayBufferToImageUrl(e.fotoRegistroEventos?.data));
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h={"calc(100vh - 130px)"}>
      <HStack
        w="100%"
        bg="brand.fondos_secundario"
        justifyContent="space-between"
      >
        <HStack pl={5} fontWeight="medium">
          <Heading
            fontWeight="medium"
            size="xl"
            fontFamily="fonts.title"
            color="brand.gris_primario"
          >
            Registro de Eventos
          </Heading>
        </HStack>

        <HStack>
          {(user.idRol === "admin" ||
            user.idRol === "coorOp" ||
            user.idRol === "usuarioIT") && (
            <Button
              color={"brand.naranja"}
              variant={"outline"}
              border={"1px solid brand.naranja"}
              size={"sm"}
              _hover={{ color: "white", bg: "brand.naranja" }}
              onClick={() => {
                navigate(`/reportes/registro/nuevo`);
              }}
              leftIcon={<MdAddCircle />}
            >
              Agregar registro
            </Button>
          )}
          <HStack spacing={2} pr={10}>
            {(user.idRol === "admin" ||
              user.idRol === "coorOp" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "userHS" ||
              user.idRol === "gerencia" ||
              user.idRol === "usuarioIT") && (
              <DescargarEventos eventos={showEventos()} type="historial" />
            )}
            <Popover isLazy>
              <PopoverTrigger>
                <IconButton
                  icon={<MdFilterList fontSize="20px" />}
                  bg={"brand.fondos_secundarios"}
                  borderRadius="50%"
                  color={"black"}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  <Tooltip label="Limpiar filtro">
                    <IconButton
                      icon={<MdRefresh />}
                      onClick={cleanFilter}
                      variant="link"
                      justifySelf={"end"}
                    />
                  </Tooltip>
                </PopoverHeader>
                <PopoverBody maxH={"sm"} overflowY={"auto"}>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Por valoracion
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          <HStack>
                            {filterBy.valoracion.includes("leve") && (
                              <MdCheck />
                            )}
                            <ListItem
                              onClick={() => {
                                handleFilterSelection("valoracion", "leve");
                                showEventos();
                              }}
                            >
                              Leve
                            </ListItem>
                          </HStack>
                          <HStack>
                            {filterBy.valoracion.includes("grave") && (
                              <MdCheck />
                            )}
                            <ListItem
                              onClick={() => {
                                handleFilterSelection("valoracion", "grave");
                                showEventos();
                              }}
                            >
                              Grave
                            </ListItem>
                          </HStack>
                          <HStack>
                            {filterBy.valoracion.includes("muy grave") && (
                              <MdCheck />
                            )}
                            <ListItem
                              onClick={() => {
                                handleFilterSelection(
                                  "valoracion",
                                  "muy grave"
                                );
                                showEventos();
                              }}
                            >
                              Muy grave
                            </ListItem>
                          </HStack>
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton fontWeight={"bold"}>
                        Por Responsable
                      </AccordionButton>
                      <AccordionPanel>
                        <List spacing={2} cursor={"pointer"}>
                          {nombreUnicos.map((nombre) => (
                            <HStack key={nombre}>
                              {filterBy.responsable.includes(nombre) && (
                                <MdCheck />
                              )}
                              <List spacing={2} cursor={"pointer"}>
                                <ListItem
                                  onClick={() => {
                                    handleFilterSelection(
                                      "responsable",
                                      nombre
                                    );
                                    showEventos();
                                  }}
                                  style={
                                    filterBy["responsable"].includes("usuario")
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                >
                                  {nombre}
                                </ListItem>
                              </List>
                            </HStack>
                          ))}
                        </List>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <HStack w={"100%"} justifyContent={"space-between"}>
                        <AccordionButton fontWeight={"bold"}>
                          Fecha
                        </AccordionButton>
                        <IconButton
                          size={"12px"}
                          variant={"link"}
                          cursor={"pointer"}
                          icon={<MdOutlineClose />}
                          onClick={() => {
                            setFilterBy((prevstate) => ({
                              ...prevstate,
                              inicio: "",
                              fin: "",
                            }));
                            showEventos();
                          }}
                        />
                      </HStack>
                      <AccordionPanel>
                        <DatePicker
                          selectsRange={true}
                          startDate={filterBy.inicio}
                          endDate={filterBy.fin}
                          dateFormat="dd/MM/yyyy"
                          onChange={(update) => {
                            setFilterBy((prevstate) => ({
                              ...prevstate,
                              inicio: update[0],
                              fin: update[1],
                            }));
                            showEventos();
                          }}
                          inline
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </HStack>
      </HStack>
      <HStack
        w="100%"
        h={"100%"}
        px={3}
        bg="brand.fondos_secundario"
        borderRadius={5}
      >
        <Stack w={"60%"} h="100%" placeSelf="self-start">
          <TableContainer overflowY="auto">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr
                  style={{
                    position: "sticky",
                    top: -5,
                    zIndex: 1,
                    backgroundColor: "#F3F6F9",
                    opacity: 1,
                    marginTop: "-10px",
                  }}
                >
                  <Th></Th>
                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "fechaRegistroEventos",
                          registros
                        );
                        setSortField("fechaRegistroEventos");
                        setSortDirection(direction);
                        setRegistros(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Fecha y Hora
                      {sortDirection === "asc" &&
                        sortField === "fechaRegistroEventos" && <ArrowUpIcon />}
                      {sortDirection === "des" &&
                        sortField === "fechaRegistroEventos" && (
                          <ArrowDownIcon />
                        )}
                    </span>
                    <span className="hover-icon" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>
                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon1").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon1").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "valoracionRegistroEventos",
                          registros
                        );
                        setSortField("valoracionRegistroEventos");
                        setSortDirection(direction);
                        setRegistros(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Valoración
                      {sortDirection === "asc" &&
                        sortField === "valoracionRegistroEventos" && (
                          <ArrowUpIcon />
                        )}
                      {sortDirection === "des" &&
                        sortField === "valoracionRegistroEventos" && (
                          <ArrowDownIcon />
                        )}
                    </span>
                    <span className="hover-icon1" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>
                  <Th
                    _hover={{ cursor: "pointer" }}
                    onMouseEnter={() =>
                      (document.querySelector(".hover-icon3").style.display =
                        "inline-block")
                    }
                    onMouseLeave={() =>
                      (document.querySelector(".hover-icon3").style.display =
                        "none")
                    }
                  >
                    <span
                      className="sortable"
                      onClick={() => {
                        const { sortedData, direction } = sortData(
                          "responsableRegistroEventos.nombre",
                          registros
                        );
                        setSortField("responsableRegistroEventos.nombre");
                        setSortDirection(direction);
                        setRegistros(sortedData);
                      }}
                      _hover={<Icon as={MdOutlineImportExport} />}
                    >
                      Responsable
                      {sortDirection === "asc" &&
                        sortField === "responsableRegistroEventos.nombre" && (
                          <ArrowUpIcon />
                        )}
                      {sortDirection === "des" &&
                        sortField === "responsableRegistroEventos.nombre" && (
                          <ArrowDownIcon />
                        )}
                    </span>
                    <span className="hover-icon3" style={{ display: "none" }}>
                      <MdOutlineImportExport />
                    </span>
                  </Th>
                  {(user.rol === "admin" ||
                    user.rol === "coorOp" ||
                    user.idRol === "usuarioIT") && <Th>Acciones</Th>}
                </Tr>
              </Thead>
              {loading && (
                <Tbody>
                  <Tr>
                    <Td colSpan={6}>{loadingtable()}</Td>
                  </Tr>
                </Tbody>
              )}
              {!loading && registros.length < 1 && (
                <Tbody>
                  <Tr>
                    <Td
                      colSpan={6}
                      textAlign="center"
                      fontWeight="bold"
                      color="brand.gris_primario"
                    >
                      No hay eventos creados
                    </Td>
                  </Tr>
                </Tbody>
              )}
              {!loading && (
                <Tbody textAlign="center">
                  {showEventos().map((registro) => (
                    <Tr
                      key={registro.idRegistroEventos}
                      onClick={() => {
                        setSeleccionado(registro);
                        handleImagen(registro);
                        onOpen();
                      }}
                    >
                      <Td>
                        <RadioGroup value={seleccionado?.idRegistroEventos}>
                          <Radio
                            value={registro.idRegistroEventos}
                            border="1px solid black"
                          />
                        </RadioGroup>
                      </Td>
                      <Td>
                        {moment
                          .utc(registro.fechaRegistroEventos)
                          .format("DD-MM-YYYY HH:mm")}
                      </Td>
                      <Td>{registro.valoracionRegistroEventos}</Td>
                      <Td>
                        {registro.responsableRegistroEventos?.nombre || "--"}
                      </Td>
                      <Td>
                        {(user.idRol === "admin" ||
                          user.idRol === "coorOp" ||
                          user.idRol === "usuarioIT") && (
                          <>
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/reportes/registro/editar/${registro.idRegistroEventos}`
                                )
                              }
                              icon={<MdOutlineCreate fontSize="20px" />}
                              variant="link"
                              isDisabled={user.idRol === "usuarioIT"}
                              _hover={{ color: "brand.naranja" }}
                            />
                            <EliminarRegistro
                              registro={registro}
                              initData={initData}
                              user={user}
                            />
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Stack>
        {seleccionado && mobile && (
          <Card
            bg="brand.layout"
            border="none"
            w={"40%"}
            h="100%"
            boxShadow="none"
            pr={2}
          >
            <CardHeader pb="unset" color="brand.azul_text">
              <HStack>
                <Text fontWeight="semibold" fontSize="24px">
                  Reporte{" "}
                  {moment
                    .utc(seleccionado.fechaRegistroEventos)
                    .format("DD-MM-YYYY HH:mm")}
                </Text>
              </HStack>
            </CardHeader>
            <Divider pt={1} color="#EBEAEA" />
            <CardBody overflow="auto">
              <HStack py={1}>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Tipo de evento
                  </FormLabel>
                  <Text fontSize="sm">{seleccionado.tipoRegistroEventos}</Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Valoración de evento
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.valoracionRegistroEventos}
                  </Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">
                    Descripcion del evento
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.descripcionRegistroEventos}
                  </Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">
                    Acciones inmediatas
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.accionesRegistroEventos}
                  </Text>
                </FormControl>
              </HStack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Foto</FormLabel>
                  <Box bg="black">
                    <Image src={imageUrl} alt="" />
                  </Box>
                </FormControl>
              </HStack>
              <Divider />
              <Stack py={1}>
                <HStack>
                  <FormControl>
                    <FormLabel fontWeight="semibold">Hay mas fotos</FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.masFotosRegistroEventos === true
                        ? "Si"
                        : "No"}
                    </Text>
                  </FormControl>
                  <FormControl alignSelf="start">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Se informo al cliente
                    </FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.informoRegistroEventos === true
                        ? "Si"
                        : "No"}
                    </Text>
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl alignSelf="start">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      Se registro en otro Registro de evento
                    </FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.otroRegistroEventos === true ? "Si" : "No"}
                    </Text>
                  </FormControl>
                  <FormControl alignSelf="start">
                    <FormLabel fontWeight="semibold" placeSelf="start">
                      ¿Donde?
                    </FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.otroDondeRegistroEventos
                        ? seleccionado.otroDondeRegistroEventos
                        : "-"}
                    </Text>
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl>
                    <FormLabel fontWeight="semibold">
                      Se involucro a terceros
                    </FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.actoresRegistroEventos === true
                        ? "Si"
                        : "No"}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight="semibold">¿Quien?</FormLabel>
                    <Text fontSize="sm">
                      {seleccionado.actoresQuienRegistroEventos
                        ? seleccionado.actoresQuienRegistroEventos
                        : "-"}
                    </Text>
                  </FormControl>
                </HStack>
              </Stack>
              <Divider />
              <HStack py={1}>
                <FormControl>
                  <FormLabel fontWeight="semibold">
                    Responsable del ticket
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.responsableAccionRegistroEventos}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Detector del evento
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.detectoRegistroEventos?.nombre || "--"}
                  </Text>
                </FormControl>
                <FormControl alignSelf="start">
                  <FormLabel fontWeight="semibold" placeSelf="start">
                    Responsable del evento
                  </FormLabel>
                  <Text fontSize="sm">
                    {seleccionado.responsableRegistroEventos?.nombre || "--"}
                  </Text>
                </FormControl>
              </HStack>
            </CardBody>
            <Divider color="#EBEAEA" />
            {(user.idRol === "admin" ||
              user.idRol === "coorOp" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "userHS" ||
              user.idRol === "gerencia" ||
              user.idRol === "usuarioIT") && (
              <CardFooter justifyContent="end">
                <DescargarEventoPDF
                  planificacion={planificacion}
                  seleccionado={seleccionado}
                  initData={initData}
                />
              </CardFooter>
            )}
          </Card>
        )}
        {seleccionado && !mobile && (
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <Card
                  bg="brand.layout"
                  border="none"
                  h="lg"
                  boxShadow="none"
                  pr={2}
                >
                  <CardHeader pb="unset" color="brand.azul_text">
                    <HStack>
                      <Text fontWeight="semibold" fontSize="24px">
                        Reporte {seleccionado.fechaAccionRegistroEventos}
                      </Text>
                    </HStack>
                  </CardHeader>
                  <Divider pt={1} color="#EBEAEA" />
                  <CardBody overflow="auto">
                    <HStack py={1}>
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold" placeSelf="start">
                          Tipo de evento
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.tipoRegistroEventos}
                        </Text>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold" placeSelf="start">
                          Valoración de evento
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.valoracionRegistroEventos}
                        </Text>
                      </FormControl>
                    </HStack>
                    <Divider />
                    <HStack py={1}>
                      <FormControl>
                        <FormLabel fontWeight="semibold">
                          Descripcion del evento
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.descripcionRegistroEventos}
                        </Text>
                      </FormControl>
                    </HStack>
                    <Divider />
                    <HStack py={1}>
                      <FormControl>
                        <FormLabel fontWeight="semibold">
                          Acciones inmediatas
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.accionesRegistroEventos}
                        </Text>
                      </FormControl>
                    </HStack>
                    <Divider />
                    <HStack py={1}>
                      <FormControl>
                        <FormLabel fontWeight="semibold">Foto</FormLabel>
                        <Box bg="black">
                          <Image src={imageUrl} alt="" />
                        </Box>
                      </FormControl>
                    </HStack>
                    <Divider />
                    <Stack py={1}>
                      <HStack>
                        <FormControl>
                          <FormLabel fontWeight="semibold">
                            Hay mas fotos
                          </FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.masFotosRegistroEventos === true
                              ? "Si"
                              : "No"}
                          </Text>
                        </FormControl>
                        <FormControl alignSelf="start">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Se informo al cliente
                          </FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.informoRegistroEventos === true
                              ? "Si"
                              : "No"}
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack>
                        <FormControl alignSelf="start">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            Se registro en otro Registro de evento
                          </FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.otroRegistroEventos === true
                              ? "Si"
                              : "No"}
                          </Text>
                        </FormControl>
                        <FormControl alignSelf="start">
                          <FormLabel fontWeight="semibold" placeSelf="start">
                            ¿Donde?
                          </FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.otroDondeRegistroEventos
                              ? seleccionado.otroDondeRegistroEventos
                              : "-"}
                          </Text>
                        </FormControl>
                      </HStack>
                      <HStack>
                        <FormControl>
                          <FormLabel fontWeight="semibold">
                            Se involucro a terceros
                          </FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.actoresRegistroEventos === true
                              ? "Si"
                              : "No"}
                          </Text>
                        </FormControl>
                        <FormControl>
                          <FormLabel fontWeight="semibold">¿Quien?</FormLabel>
                          <Text fontSize="sm">
                            {seleccionado.actoresQuienRegistroEventos
                              ? seleccionado.actoresQuienRegistroEventos
                              : "-"}
                          </Text>
                        </FormControl>
                      </HStack>
                    </Stack>
                    <Divider />
                    <HStack py={1}>
                      <FormControl>
                        <FormLabel fontWeight="semibold">
                          Responsable del ticket
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.responsableAccionRegistroEventos
                            ?.nombre || "--"}
                        </Text>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold" placeSelf="start">
                          Detector del evento
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.detectoRegistroEventos?.nombre || "--"}
                        </Text>
                      </FormControl>
                      <FormControl alignSelf="start">
                        <FormLabel fontWeight="semibold" placeSelf="start">
                          Responsable del evento
                        </FormLabel>
                        <Text fontSize="sm">
                          {seleccionado.responsableRegistroEventos?.nombre ||
                            "--"}
                        </Text>
                      </FormControl>
                    </HStack>
                  </CardBody>
                  <Divider color="#EBEAEA" />
                  {(user.idRol === "admin" ||
                    user.idRol === "coorOp" ||
                    user.idRol === "userMantenimiento" ||
                    user.idRol === "userHS" ||
                    user.idRol === "gerencia" ||
                    user.idRol === "usuarioIT") && (
                    <CardFooter justifyContent="end">
                      <DescargarEventoPDF
                        planificacion={planificacion}
                        seleccionado={seleccionado}
                        initData={initData}
                      />
                    </CardFooter>
                  )}
                </Card>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {!seleccionado && (
          <Center w="xl" justifyContent={"center"}>
            <Text
              fontSize={"14px"}
              color={"gray.400"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              La información se verá una vez <br /> que se seleccione un
              registro
            </Text>
          </Center>
        )}
      </HStack>
    </Stack>
  );
};

export default RegistroEventos;
