import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  HStack,
  Button,
  Select,
  useToast,
  InputGroup,
  IconButton,
  InputRightAddon,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FieldArray, Field } from "formik";
import {
  MdOutlineAdd,
  MdNavigateNext,
  MdNavigateBefore,
  MdClose,
  MdFileDownloadDone,
} from "react-icons/md";
import {
  editarPlanificacion2,
  crearPlanificacion2,
} from "../../helpers/api.helper";
import { useNavigate } from "react-router-dom";
import { handleUsuarioIT } from "../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../utils/Validaciones";

const SegundoPaso = (props) => {
  const {
    activeStep,
    setActiveStep,
    id,
    type,
    onClose,
    initPlanificacion,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    expanded,
    formValues,
    touched,
    errors,
    user,
  } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (values, event) => {
    setLoading(true);

    if (!values.pozos[0].nombrePozo) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "ID de pozo requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    if (!values.etapasDiaMalla) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Etapas por día requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    if (
      (values.targeStandbyPozoGral &&
        validateOnSubmit("text", values.targeStandbyPozoGral)) ||
      (values.etapasDiaMalla &&
        validateOnSubmit("text", values.etapasDiaMalla)) ||
      (values.targetCantidadCamiones &&
        validateOnSubmit("text", values.targetCantidadCamiones))
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Datos incorrectos",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    for (let index = 0; index < values.pozos.length; index++) {
      const element = values.pozos[index].nombrePozo;
      const elementII = values.pozos[index].numeroEtapasPozo;
      if (validateOnSubmit("text", element)) {
        setLoading(false);
        return toast({
          title: "Error",
          description: `El nombre del pozo ${index + 1} es incorrecto`,
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      }
      if (validateOnSubmit("number", elementII)) {
        setLoading(false);
        return toast({
          title: "Error",
          description: `El numero de etapas del pozo ${
            index + 1
          } es incorrecto`,
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      }
    }
    for (let index = 0; index < values.mallas.length; index++) {
      const element = values.mallas[index].tnsArenaSc;
      const elementII = values.mallas[index].scEtapas;
      if (validateOnSubmit("decimal", element)) {
        setLoading(false);
        return toast({
          title: "Error",
          description: `Las tns de la malla ${index + 1} son incorrectas`,
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      }
      if (validateOnSubmit("number", elementII)) {
        setLoading(false);
        return toast({
          title: "Error",
          description: `Los Scs por etapa de la malla ${
            index + 1
          } son incorrectos`,
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      }
    }

    let parsedPozos = values.pozos.map((pozo) => {
      return pozo;
    });
    let parsedMalla = values.mallas.map((malla) => {
      return {
        scEtapas: malla.scEtapas,
        targetTnsDiaM: malla.targetTnsDiaM,
        tipoArenaMalla: malla.tipoArenaMalla,
        tipoMalla: malla.tipoMalla,
        tnsArenaSc: parseFloat(malla.tnsArenaSc),
      };
    });

    let data;

    if (formValues.desMontTransp === true) {
      data = {
        nombrePozo: parsedPozos,
        tipoMalla: parsedMalla,
        etapasTotalPad: values.etapasTotalPad,
        targeStandbyPozoGral: values.targeStandbyPozoGral,
        etapasDiaMalla: values.etapasDiaMalla,
        totalTns: values.totalTns,
        targetCantidadCamiones: values.targetCantidadCamiones,
        estado: "activo",
      };
    } else {
      data = {
        nombrePozo: parsedPozos,
        tipoMalla: parsedMalla,
        etapasTotalPad: values.etapasTotalPad,
        targeStandbyPozoGral: values.targeStandbyPozoGral,
        etapasDiaMalla: values.etapasDiaMalla,
        totalTns: values.totalTns,
        targetCantidadCamiones: values.targetCantidadCamiones,
        estado: "proyectado",
      };
    }

    let res;

    if (type === "editar" || type === "continuar" || type === "reActivar") {
      res = await editarPlanificacion2({
        ...data,
        id: formValues.idPlanificacion,
      });
    } else {
      res = await crearPlanificacion2({ ...data, id: id });
    }

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: `Segundo paso ${
          type === "editar" || type === "reActivar" ? "editado" : "creado"
        } correctamente`,
        duration: 3000,
      });
    } else {
      setLoading(false);
      return toast({
        status: "error",
        isClosable: true,
        title: `Error al ${
          type === "editar" || type === "reActivar" ? "editar" : "crear"
        } el segundo paso`,
        description: res.message,
      });
    }
    if (event === "continuar") {
      setActiveStep(activeStep + 1);
    } else {
      navigate("/operaciones/planificacion");
      if (type === "continuar") {
        initPlanificacion();
      }
    }

    if (type === "continuar" && event === "finalizar") {
      onClose();
    }

    setLoading(false);
  };

  const calcEtapas = (values, index, setFieldValue) => {
    const numeroEtapasPozo = values.pozos[index].numeroEtapasPozo;

    const totalEtapas = values.pozos.reduce((acc, pozo, i) => {
      if (i !== index) {
        return acc + pozo.numeroEtapasPozo;
      }

      return acc;
    }, numeroEtapasPozo);

    setFieldValue("etapasTotalPad", totalEtapas);
  };

  const calcTons = (values, index, setFieldValue) => {
    if (
      values.etapasTotalPad &&
      values.mallas[index].tnsArenaSc &&
      values.mallas[index].scEtapas
    ) {
      const etapasTotalPad = values.etapasTotalPad;

      const sandCubesXEtapa = values.mallas.reduce(
        (acc, val) => acc + val.scEtapas,
        0
      );
      const scEtapasValues = etapasTotalPad * sandCubesXEtapa;

      const tonsXscs = 13.5;

      const totalTons = scEtapasValues * tonsXscs;

      setFieldValue("totalTns", totalTons);
    }
  };

  const calcTonsXdia = (values, index, setFieldValue) => {
    if (
      values.mallas[index].tnsArenaSc &&
      values.mallas[index].scEtapas &&
      values.etapasDiaMalla
    ) {
      const tnsArenaSc = parseFloat(values.mallas[index].tnsArenaSc);
      const scEtapas = values.mallas[index].scEtapas;
      const etapasDiaMalla = values.etapasDiaMalla;

      // Calcular el valor para targetTnsDiaM
      const total = tnsArenaSc * scEtapas * etapasDiaMalla;

      setFieldValue(`mallas[${index}].targetTnsDiaM`, total);
    }
  };

  const mostrarResultado = (numero) => {
    if (numero % 1 === 0) {
      // Si el número es un entero (decimales .00), conviértelo a entero para eliminar los decimales
      return Math.floor(numero);
    } else {
      // Si el número tiene decimales distintos de .00, muéstralo tal cual
      return numero;
    }
  };

  return (
    <Stack px={3}>
      <HStack w="100%" pb={1}>
        <FieldArray name="pozos">
          {({ push, remove }) => (
            <Stack>
              <HStack
                w="100%"
                justifyContent={!expanded ? "space-between" : "unset"}
              >
                <Stack placeSelf={"start"} m={"unset"}>
                  {values.pozos.map((pozo, index) => (
                    <Field
                      key={index}
                      name={`pozos[${index}].nombrePozo`}
                      validate={(value) =>
                        validateField("text", value, `Pozo ${index + 1}`)
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            touched.pozos &&
                            touched.pozos[index]?.nombrePozo &&
                            errors.pozos &&
                            errors.pozos[index]?.nombrePozo
                          }
                          w={"xs"}
                          isRequired
                        >
                          <FormLabel fontWeight="semibold">ID Pozo</FormLabel>
                          <Input
                            {...field}
                            w="2xs"
                            type="text"
                            placeholder="Nombre"
                            name={`pozos[${index}].nombrePozo`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={pozo.nombrePozo}
                          />

                          <FormErrorMessage>
                            {touched.pozos &&
                              touched.pozos[index]?.nombrePozo &&
                              errors.pozos &&
                              errors.pozos[index]?.nombrePozo}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ))}
                </Stack>

                <Stack placeSelf={"start"} m={"unset"}>
                  {values.pozos.map((pozo, index) => (
                    <HStack key={index}>
                      <Field
                        name={`pozos[${index}].numeroEtapasPozo`}
                        validate={(value) => {
                          return validateField(
                            "number",
                            value?.toString(),
                            `Nro. Etapa ${index + 1}`
                          );
                        }}
                      >
                        {({ field }) => (
                          <FormControl
                            isInvalid={
                              touched.pozos &&
                              touched.pozos[index]?.numeroEtapasPozo &&
                              errors.pozos &&
                              errors.pozos[index]?.numeroEtapasPozo
                            }
                            w={"xs"}
                            isRequired
                          >
                            <FormLabel fontWeight="semibold">
                              Nro de Etapas
                            </FormLabel>
                            <Input
                              {...field}
                              w="2xs"
                              type="number"
                              placeholder="Cantidad"
                              name={`pozos[${index}].numeroEtapasPozo`}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                calcEtapas(values, index, setFieldValue);
                              }}
                              value={pozo.numeroEtapasPozo}
                            />

                            <FormErrorMessage>
                              {touched.pozos &&
                                touched.pozos[index]?.numeroEtapasPozo &&
                                errors.pozos &&
                                errors.pozos[index]?.numeroEtapasPozo}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {index !== 0 && (
                        <IconButton
                          placeSelf={"start"}
                          paddingTop={"12px"}
                          fontSize="12px"
                          variant="link"
                          onClick={() => {
                            remove(index);
                            calcEtapas(values, index, setFieldValue);
                          }}
                          isDisabled={values.pozos.length <= 1}
                          icon={<MdClose />}
                        />
                      )}
                    </HStack>
                  ))}
                </Stack>

                <FormControl w="xs">
                  <FormLabel fontWeight="semibold"></FormLabel>
                </FormControl>
              </HStack>
              <Stack alignSelf="start">
                <Button
                  color="brand.naranja"
                  fontSize="12px"
                  variant="link"
                  size="lg"
                  leftIcon={<MdOutlineAdd size={18} color="#FF8200" />}
                  onClick={() => {
                    push({ nombrePozo: "", numeroEtapasPozo: "" });
                  }}
                  textColor="black"
                >
                  Agregar pozo
                </Button>
              </Stack>
            </Stack>
          )}
        </FieldArray>
      </HStack>
      <HStack w="100%" justifyContent={!expanded ? "space-between" : "unset"}>
        <Stack placeSelf={"start"}>
          <Field
            name="targetCantidadCamiones"
            validate={(value) =>
              validateField("number", value, "Cant. camiones")
            }
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors.targetCantidadCamiones &&
                  touched.targetCantidadCamiones
                }
                w={"xs"}
              >
                <FormLabel fontWeight="semibold">
                  Target cant. camiones
                </FormLabel>
                <Input
                  {...field}
                  w="2xs"
                  type="text"
                  placeholder="Número"
                  name="targetCantidadCamiones"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.targetCantidadCamiones}
                />
                <FormErrorMessage>
                  {errors.targetCantidadCamiones}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack placeSelf={"start"}>
          <Field
            name="targeStandbyPozoGral"
            validate={(value) =>
              validateField("number", value, "StandBy Pozo gral.")
            }
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors.targeStandbyPozoGral && touched.targeStandbyPozoGral
                }
                w={"xs"}
              >
                <FormLabel fontWeight="semibold">
                  Target Standby en Pozo gral.
                </FormLabel>

                <InputGroup w="2xs">
                  <Input
                    {...field}
                    type="text"
                    placeholder="Tiempo"
                    name="targeStandbyPozoGral"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.targeStandbyPozoGral}
                  />
                  <InputRightAddon bg="brand.gris_primario" color="white">
                    mins
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage>
                  {errors.targeStandbyPozoGral}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack placeSelf={"start"}>
          <Field
            name="etapasDiaMalla"
            validate={(value) =>
              validateField("number", value, "Etapas por día")
            }
          >
            {({ field }) => (
              <FormControl
                isInvalid={errors.etapasDiaMalla && touched.etapasDiaMalla}
                w={"xs"}
                isRequired
              >
                <FormLabel fontWeight="semibold">Etapas por día</FormLabel>
                <Input
                  {...field}
                  w="2xs"
                  type="number"
                  placeholder="Cantidad"
                  name="etapasDiaMalla"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.etapasDiaMalla}
                />
                <FormErrorMessage>
                  {errors.etapasDiaMalla && touched.etapasDiaMalla}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
      </HStack>
      <HStack justifyContent={!expanded ? "space-between" : "unset"}>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Total de tons.</FormLabel>
          <InputGroup>
            <Input
              w="2xs"
              type="text"
              placeholder="Número"
              name="totalTns"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.totalTns}
              readOnly
              bg="brand.gris_medio"
              _placeholder={{ color: "white" }}
            />
          </InputGroup>
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Etapas total por pad</FormLabel>
          <Input
            w="2xs"
            placeholder="Cantidad"
            name="etapasTotalPad"
            onChange={() => {
              calcTons(values, setFieldValue);
            }}
            value={values.etapasTotalPad}
            readOnly
            bg="brand.gris_medio"
            _placeholder={{ color: "white" }}
          />
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold"></FormLabel>
        </FormControl>
      </HStack>
      <HStack w="100%" justifyContent={!expanded ? "space-between" : "unset"}>
        <FieldArray name="mallas">
          {({ push, remove }) => (
            <Stack>
              <HStack
                w="100%"
                justifyContent={!expanded ? "space-between" : "unset"}
              >
                <FormControl w={"200px"} placeSelf={"start"}>
                  <FormLabel fontWeight="semibold">Malla</FormLabel>
                  <Stack>
                    {values.mallas.map((malla, index) => (
                      <Select
                        key={malla.index}
                        placeholder="Tipo de malla"
                        name={`mallas[${index}].tipoMalla`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mallas[index].tipoMalla}
                        w={"200px"}
                        isDisabled={values.etapasDiaMalla === ""}
                      >
                        <option value="100">100</option>
                        <option value="30/70">30/70</option>
                        <option value="70/140">70/140</option>
                        <option value="50/70">50/70</option>
                        <option value="40/80">40/80</option>
                        <option value="40/70">40/70</option>
                        <option value="30/50">30/50</option>
                        <option value="20/40">20/40</option>
                        <option value="Otros">Otros</option>
                      </Select>
                    ))}
                  </Stack>
                </FormControl>
                <Stack placeSelf={"start"}>
                  <FormLabel fontWeight="semibold" m={"unset"}>
                    Tns Arena por Sandcube
                  </FormLabel>
                  {values.mallas.map((_, index) => (
                    <Field
                      key={index}
                      name={`mallas[${index}].tnsArenaSc`}
                      validate={(value) =>
                        validateField(
                          "decimal",
                          value?.toString(),
                          `Scs en malla ${index + 1}`
                        )
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.mallas && errors?.mallas[index]?.tnsArenaSc
                          }
                          w={"2xs"}
                          isRequired
                        >
                          <InputGroup>
                            <Input
                              {...field}
                              w={"100px"}
                              type="text"
                              maxLength={5} // 2 dígitos + punto + 2 decimales
                              placeholder="Número"
                              name={`mallas[${index}].tnsArenaSc`}
                              onChange={(e) => {
                                const { value } = e.target;
                                // Verifica si ya se han ingresado 2 dígitos y agrega una coma
                                if (
                                  value.length === 2 &&
                                  /^[0-9]{2}$/.test(value)
                                ) {
                                  handleChange({
                                    target: {
                                      value: value + ".",
                                      name: e.target.name,
                                    },
                                  });
                                } else {
                                  handleChange(e);
                                }

                                calcTons(values, index, setFieldValue);
                                calcTonsXdia(values, index, setFieldValue);
                              }}
                              onBlur={(e) => {
                                // Asegura que el valor tenga formato decimal correcto al perder el foco
                                let { value } = e.target;
                                if (value) {
                                  setFieldValue(
                                    `mallas[${index}].tnsArenaSc`,
                                    value
                                  );
                                }
                                handleBlur(e);
                                calcTons(values, index, setFieldValue);
                                calcTonsXdia(values, index, setFieldValue);
                              }}
                              borderTopRightRadius={0}
                              borderBottomRightRadius={0}
                              isDisabled={values.etapasDiaMalla === ""}
                              value={values.mallas[index]?.tnsArenaSc || ""}
                            />
                            <InputRightAddon
                              color="white"
                              bg="brand.gris_primario"
                              isDisabled={values.etapasDiaMalla === ""}
                            >
                              promedio
                            </InputRightAddon>
                          </InputGroup>

                          <FormErrorMessage>
                            {touched.mallas &&
                              touched.mallas[index]?.tnsArenaSc &&
                              errors.mallas &&
                              errors?.mallas[index]?.tnsArenaSc}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ))}
                </Stack>
                <FormControl w={"200px"} placeSelf={"start"}>
                  <FormLabel fontWeight="semibold">Tipo de Arena</FormLabel>
                  <Stack>
                    {values.mallas.map((elemento, index) => (
                      <Select
                        key={elemento.index}
                        name={`mallas[${index}].tipoArenaMalla`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mallas[index].tipoArenaMalla}
                        defaultValue="seca"
                        w={"200px"}
                        isDisabled={values.etapasDiaMalla === ""}
                      >
                        <option value="seca">Seca</option>
                        <option value="humeda">Humeda</option>
                      </Select>
                    ))}
                  </Stack>
                </FormControl>
                <Stack placeSelf={"start"}>
                  <FormLabel fontWeight="semibold" m={"unset"}>
                    Sandcubes por etapas
                  </FormLabel>
                  {values.mallas.map((_, index) => (
                    <Field
                      key={index}
                      name={`mallas[${index}].scEtapas`}
                      validate={(value) =>
                        validateField(
                          "number",
                          value?.toString(),
                          `Scs por etapa ${index + 1}`
                        )
                      }
                    >
                      {({ field }) => (
                        <FormControl
                          isInvalid={
                            errors.mallas && errors?.mallas[index]?.scEtapas
                          }
                          w={"2xs"}
                        >
                          <InputGroup key={index}>
                            <Input
                              {...field}
                              w={"100px"}
                              type="number"
                              placeholder="Número"
                              name={`mallas[${index}].scEtapas`}
                              onChange={(e) => {
                                handleChange(e);
                                calcTons(values, index, setFieldValue);
                                calcTonsXdia(values, index, setFieldValue);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                calcTons(values, index, setFieldValue);
                                calcTonsXdia(values, index, setFieldValue);
                              }}
                              isDisabled={values.etapasDiaMalla === ""}
                              value={values.mallas[index].scEtapas}
                            />
                            <InputRightAddon
                              color="white"
                              bg="brand.gris_primario"
                              isDisabled={values.etapasDiaMalla === ""}
                            >
                              promedio
                            </InputRightAddon>
                          </InputGroup>

                          <FormErrorMessage>
                            {touched.mallas &&
                              touched.mallas[index]?.scEtapas &&
                              errors.mallas &&
                              errors?.mallas[index]?.scEtapas}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ))}
                </Stack>
                <FormControl w={"200px"} placeSelf={"start"}>
                  <FormLabel fontWeight="semibold">
                    Target tons. por día
                  </FormLabel>
                  <Stack>
                    {values.mallas.map((elemento, index) => (
                      <HStack key={elemento.index}>
                        <Input
                          w={index !== 0 ? "200px" : "150px"}
                          type="text"
                          placeholder="Cantidad"
                          name={`mallas[${index}].targetTnsDiaM`}
                          onChange={(e) => {
                            handleChange(e);
                            calcTonsXdia(values, index, setFieldValue);
                          }}
                          onBlur={(e) => {
                            handleChange(e);
                            calcTonsXdia(values, index, setFieldValue);
                          }}
                          value={mostrarResultado(
                            values.mallas[index].targetTnsDiaM
                          )}
                          readOnly
                          bg="brand.gris_medio"
                          _placeholder={{ color: "white" }}
                          isDisabled={values.etapasDiaMalla === ""}
                        />
                        {index !== 0 && (
                          <IconButton
                            key={elemento.index}
                            fontSize="12px"
                            variant="link"
                            onClick={() => remove(index)}
                            isDisabled={values.mallas.length <= 1}
                            icon={<MdClose />}
                          />
                        )}
                      </HStack>
                    ))}
                  </Stack>
                </FormControl>
              </HStack>
              <Stack placeSelf="start">
                <Button
                  color="brand.naranja"
                  placeSelf="self-start"
                  fontSize="12px"
                  variant="link"
                  size="lg"
                  leftIcon={<MdOutlineAdd size={18} color="#FF8200" />}
                  onClick={() => {
                    push({
                      malla: "",
                      sandcubesEtapas: "",
                      tnsArenaSandcube: "",
                      tipoArena: "",
                      targetTnsDiaM: "",
                    });
                  }}
                  isDisabled={values.etapasDiaMalla === ""}
                  textColor="black"
                >
                  Agregar
                </Button>
              </Stack>
            </Stack>
          )}
        </FieldArray>
      </HStack>

      <HStack w="100%" justifyContent="space-between" pr={2}>
        <Button
          fontSize={12}
          letterSpacing={1}
          border="1px solid orange"
          fontWeight="semibold"
          variant="outline"
          color="brand.naranja"
          leftIcon={<MdNavigateBefore fontSize="20px" />}
          onClick={() => setActiveStep(activeStep - 1)}
          alignSelf="end"
          _hover={{ bg: "brand.naranja", color: "white" }}
        >
          Atras
        </Button>
        <Stack>
          <Button
            fontSize={12}
            fontWeight="semibold"
            bg="brand.naranja"
            color="white"
            rightIcon={<MdNavigateNext fontSize="20px" />}
            onClick={() =>
              user.idRol === "usuarioIT"
                ? handleUsuarioIT(setActiveStep, activeStep)
                : handleSubmit(values, "continuar")
            }
            variant="solid"
            form="FormularioPlanificacion"
            isDisabled={loading}
            _hover={{ bg: "white", color: "brand.naranja" }}
          >
            Continuar
          </Button>
          <Button
            color="brand.naranja"
            fontSize={12}
            letterSpacing={1}
            border="1px solid orange"
            variant="outline"
            leftIcon={<MdFileDownloadDone fontSize="20px" />}
            textColor="brand.naranja"
            form="FormularioPlanificacion"
            onClick={() => handleSubmit(values, "finalizar")}
            _hover={{ bg: "brand.naranja", color: "white" }}
            isDisabled={loading || user.idRol === "usuarioIT"}
          >
            Finalizar
          </Button>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default SegundoPaso;
