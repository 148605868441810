import React, { useEffect } from "react";
import { HStack, Box, Text, Avatar } from "@chakra-ui/react";
import {
  MdOutlineSettings,
  MdOutlineViewAgenda,
  MdPersonOutline,
  MdOutlinePublic,
  MdOutlinePersonAddAlt,
  MdOutlineLibraryBooks,
} from "react-icons/md";
import FilterData from "../../../utils/FilterData";
import Notificationes from "./Notificationes";
import Usuario from "./Usuario";
import { useSelector } from "react-redux";
import LogoExpandido from "../../../assets/Brent-Marca-con-bajada-CMYK.png";
import { ReactComponent as Cargas } from "../../../assets/iconos/Sand.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { getPlanificaciones } from "../../operaciones/helpers/api.helper";
import DrawlerNav from "./DrawlerNav";

const MultiTaskBar = (props) => {
  const {
    mobile,
    filter,
    setFilter,
    notificaciones,
    setNotificaciones,
    usuario,
    setUsuario,
    loading,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const btnRef = React.useRef();
  const { user } = useSelector((state) => state.user);

  const biblioteca = [
    {
      tag: "planificacion",
      nombre: "Planificación",
      link: "/operaciones/planificacion",
    },
    {
      tag: "plantas",
      nombre: "Plantas",
      link: "/inventario/planta",
    },
    {
      tag: "autoelevadores",
      nombre: "Autoelevadores",
      link: "/inventario/autoelevadores",
    },
    {
      tag: "historial",
      nombre: "Historial",
      link: "/operaciones/historial",
    },
    {
      tag: "sandvan",
      nombre: "SandVan",
      link: "/inventario/sandvan",
    },
    {
      tag: "sandcubes",
      nombre: "Sandcubes",
      link: "/inventario/sandcubes",
    },
    {
      tag: "sander",
      nombre: "Sander",
      link: "/inventario/sander",
    },
    {
      tag: "empresatransportista",
      nombre: "Empresa transportista",
      link: "/inventario/transportista",
    },
    {
      tag: "camiones",
      nombre: "Camiones",
      link: "/inventario/camiones",
    },
    {
      tag: "padlocacion",
      nombre: "Pad / Locacion",
      link: "/inventario/locacion",
    },
    {
      tag: "usuarios",
      nombre: "Usuarios",
      link: "/usuarios",
    },
    {
      tag: "certificacioncamiones",
      nombre: "Certificacion Camiones (reportes)",
      link: "/reportes/certificacion",
    },
    {
      tag: "revisionentreturno",
      nombre: "Revision entre turno (reportes)",
      link: "/reportes/revision",
    },
    {
      tag: "registrodeeventos",
      nombre: "Registro eventos (reportes)",
      link: "/reportes/registro",
    },
    {
      tag: "resportesdeviajes",
      nombre: "Reporte de viajes (reportes)",
      link: "/reportes/reporte",
    },
    {
      tag: "notificaciones",
      nombre: "Configuracion de notificaciones",
      link: "/administrarNotificaciones",
    },
    {
      tag: "encurso",
      nombre: "Operaciones en Curso",
      link: "/operaciones/curso",
    },
    {
      tag: "descarga",
      nombre: "Planificaciones",
      link: "/operaciones/planificacion",
    },
    {
      tag: "descarga",
      nombre: "Historial",
      link: "/operaciones/historial",
    },
    {
      tag: "descarga",
      nombre: "Reporte de viajes (reportes)",
      link: "/reportes/reporte",
    },
    {
      tag: "descarga",
      nombre: "Revision entre turno (reportes) ",
      link: "/reportes/revision",
    },
    {
      tag: "descarga",
      nombre: "Registro de eventos (reportes)",
      link: "/reportes/registro",
    },
    {
      tag: "crearplanificacion",
      nombre: "Crear Planificación",
      link: "/operaciones/planificacion/nuevo",
    },
    {
      tag: "crearautoelevador",
      nombre: "Crear Autoelevador",
      link: "/inventario/autoelevadores/nuevo",
    },
    {
      tag: "crearempresatrasnportista",
      nombre: "Crear Empresa transportista",
      link: "/inventario/transportista/nuevo",
    },
    {
      tag: "crearcamiones",
      nombre: "Crear Camiones",
      link: "/inventario/camiones/nueva",
    },
    {
      tag: "crearsandcubes",
      nombre: "Crear Sandcube",
      link: "/inventario/sandcubes/nuevo",
    },
    {
      tag: "crearsander",
      nombre: "Crear Sander",
      link: "/inventario/sander/nuevo",
    },
    {
      tag: "crearsandvan",
      nombre: "Crear SandVan",
      link: "/inventario/sandvan/nuevo",
    },
  ];

  const initData = async () => {
    let res;

    res = await getPlanificaciones();

    if (res.status === 200) {
      res.data.forEach((elemento) => {
        biblioteca.push({
          tag: elemento.idPadlocacion.trim().toLowerCase(),
          nombre: elemento.idPadlocacion,
          link: "/operaciones/planificacion",
        });
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <HStack
      bg="brand.fondos_secundarios"
      w={{ base: "4xl", md: "100%" }}
      justifyContent={!mobile ? "space-between" : "end"}
      pr={10}
    >
      {!mobile && (
        <Box pl={3}>
          <DrawlerNav
            Logo={LogoExpandido}
            btnRef={btnRef}
            LogoExpandido={LogoExpandido}
            user={user}
            navigate={navigate}
            location={location}
            Cargas={Cargas}
            MdOutlineViewAgenda={MdOutlineViewAgenda}
            MdOutlineSettings={MdOutlineSettings}
            MdOutlinePersonAddAlt={MdOutlinePersonAddAlt}
            MdOutlineLibraryBooks={MdOutlineLibraryBooks}
            MdOutlinePublic={MdOutlinePublic}
          />
        </Box>
      )}
      <HStack
        w="100%"
        justifyContent={"end"}
        spacing={5}
        pt={1}
        position={"relative"}
      >
        <FilterData
          filter={filter}
          setFilter={setFilter}
          biblioteca={biblioteca}
        />

        <Notificationes
          notificaciones={notificaciones}
          setNotificaciones={setNotificaciones}
          usuario={usuario}
          loading={loading}
          setUsuario={setUsuario}
        />

        {mobile && (
          <Text textTransform="uppercase">
            <Avatar
              borderRadius="50%"
              color="brand.fondos_primarios"
              bg="gray.700"
              icon={<MdPersonOutline color="white" size="30px" />}
            />
          </Text>
        )}
        {mobile && <Text textTransform="uppercase">{user.nombre}</Text>}

        <Usuario />
      </HStack>
    </HStack>
  );
};

export default MultiTaskBar;
