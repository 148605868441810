import { Stack, HStack, Divider, CircularProgress } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Navigate from "./components/Navigate";
import MultiTaskBar from "./components/MultiTaskBar";

const Layout = (props) => {
  const {
    expanded,
    setExpanded,
    mobile,
    filter,
    setFilter,
    notificaciones,
    setNotificaciones,
    usuario,
    setUsuario,
    loading,
  } = props;

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  return (
    <HStack w="100vw">
      {mobile && (
        <Navigate
          mobile={mobile}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}
      {loading && loadingtable()}
      {!loading && (
        <Stack
          bg="brand.fondos_secundarios"
          w="100%"
          h={"100vh"}
          overflowY={"hidden"}
        >
          <MultiTaskBar
            mobile={mobile}
            filter={filter}
            setFilter={setFilter}
            notificaciones={notificaciones}
            setNotificaciones={setNotificaciones}
            expanded={expanded}
            setExpanded={setExpanded}
            usuario={usuario}
            setUsuario={setUsuario}
            loading={loading}
          />
          <Divider bg="blackAlpha.50" w={"100%"} />
          <Outlet expanded={expanded} />
        </Stack>
      )}
    </HStack>
  );
};

export default Layout;
