import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  CircularProgress,
  // Radio,
  // RadioGroup,
  Box,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import moment from "moment";
import { regionalFormattingNumbers } from "../../../utils/regionalFormattingNumbers";
import EditarEtapa from "../../operaciones/components/curso/etapas/EditarEtapa";
import EliminarEtapa from "../../operaciones/components/curso/etapas/EliminarEtapa";

function TablaEtapas(props) {
  const {
    loading,
    sortData,
    sortDirection,
    sortField,
    seleccionado,
    user,
    filteredEtapas,
    setSeleccionado,
    initData,
  } = props;
  return (
    <Box
      w={seleccionado ? "60%" : "100%"}
      flex={1}
      flexGrow={1}
      maxHeight="100%"
      overflow="hidden"
    >
      <TableContainer
        // className="table-container-reporteEtapas"
        maxHeight="calc(100vh - 160px)"
      >
        <Table variant={"simple"} size={"sm"}>
          <Thead
            position="sticky"
            alignContent={"center"}
            top={0}
            left={0}
            zIndex="1"
            display={"block"}
            width={"100%"}
          >
            <Tr>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("padLocacion")}
                textAlign={"left"}
              >
                PAD
                {sortDirection === "asc" && sortField === "padLocacion" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" && sortField === "padLocacion" && (
                  <ArrowDownIcon />
                )}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("numTotalEtapaSC")}
                width={"8%"}
                textAlign={"left"}
              >
                Nro Etapa <br /> (total)
                {sortDirection === "asc" && sortField === "numTotalEtapaSC" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" &&
                  sortField === "numTotalEtapaSC" && <ArrowDownIcon />}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("numPozoEtapaSC")}
                width={"9%"}
                textAlign={"left"}
              >
                Nro Etapa <br /> (pozo)
                {sortDirection === "asc" && sortField === "numPozoEtapaSC" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" && sortField === "numPozoEtapaSC" && (
                  <ArrowDownIcon />
                )}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("pozo.nombrePozo")}
                width={"12.5%"}
                textAlign={"left"}
              >
                ID <br /> Pozo
                {sortDirection === "asc" && sortField === "pozo.nombrePozo" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" &&
                  sortField === "pozo.nombrePozo" && <ArrowDownIcon />}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("etapaWeTSanD")}
                width={"11%"}
                textAlign={"left"}
              >
                Wetsand
                {sortDirection === "asc" && sortField === "etapaWeTSanD" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" && sortField === "etapaWeTSanD" && (
                  <ArrowDownIcon />
                )}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("fechaFinalEtapaSC")}
                width={"12%"}
                textAlign={"left"}
              >
                Fecha
                {sortDirection === "asc" &&
                  sortField === "fechaFinalEtapaSC" && <ArrowUpIcon />}
                {sortDirection === "desc" &&
                  sortField === "fechaFinalEtapaSC" && <ArrowDownIcon />}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("sandCubes.length")}
                width={"7.5%"}
                textAlign={"left"}
              >
                Cant. <br /> SCs.
                {sortDirection === "asc" &&
                  sortField === "sandCubes.length" && <ArrowUpIcon />}
                {sortDirection === "desc" &&
                  sortField === "sandCubes.length" && <ArrowDownIcon />}
              </Th>
              <Th
                padding={0}
                paddingRight={5}
                marging={0}
                _hover={{ cursor: "pointer" }}
                onClick={() => sortData("estado")}
                width={"7%"}
                textAlign={"left"}
              >
                Tns.
                {sortDirection === "asc" && sortField === "estado" && (
                  <ArrowUpIcon />
                )}
                {sortDirection === "desc" && sortField === "estado" && (
                  <ArrowDownIcon />
                )}
              </Th>
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "usuarioIT") && (
                <Th
                  padding={0}
                  marging={0}
                  width={"5%"}
                  textAlign={"left"}
                  paddingRight={"4px"}
                >
                  Acciones
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody
            // textAlign="center"
            alignItems={"center"}
            display={"block"}
            height={"calc(100vh - 200px)"}
            overflowY={"auto"}
          >
            {loading ? (
              <CircularProgress
                isIndeterminate
                color="brand.naranja"
                position={"relative"}
                top={"50%"}
                left={"50%"}
              />
            ) : (
              filteredEtapas.map((etapa) => (
                <Tr
                  key={etapa.idEtapaSC}
                  onClick={() => setSeleccionado(etapa)}
                  w={"100%"}
                >
                  <Td
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    textAlign={"left"}
                    width={"28.72%"}
                  >
                    {etapa.padLocacion || "No informado"}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"8%"}
                  >
                    {etapa.numTotalEtapaSC || "No informado"}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"9%"}
                  >
                    {etapa.numPozoEtapaSC || "No informado"}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"12.5%"}
                  >
                    {etapa.pozo?.nombrePozo || "No informado"}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"11%"}
                  >
                    {etapa.etapaWeTSanD === true ? "SI" : "NO"}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"12%"}
                  >
                    {moment(etapa.fechaFinalEtapaSC).format("DD-MM-YYYY")}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"7.5%"}
                  >
                    {etapa.sandCubes ? etapa.sandCubes.length : 0}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    paddingRight={5}
                    marging={0}
                    width={"6.5%"}
                  >
                    {etapa.sandCubes
                      ? regionalFormattingNumbers({
                          number: etapa.sandCubes.length * 13.5,
                          digits: 2,
                        })
                      : 0}
                  </Td>
                  <Td
                    textAlign={"left"}
                    padding={0}
                    paddingBlock={1.5}
                    marging={0}
                    width={"5%"}
                  >
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "usuarioIT") && (
                      <>
                        <EditarEtapa
                          seleccionado={etapa}
                          initData={initData}
                          user={user}
                        />
                        <EliminarEtapa
                          seleccionado={etapa}
                          initData={initData}
                          user={user}
                        />
                      </>
                    )}
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TablaEtapas;
