import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  IconButton,
  Box,
  Heading,
  Text,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
  Divider,
  Stack,
  Button,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { MdNavigateBefore, MdNavigateNext, MdArrowBack } from "react-icons/md";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPlanificaciones,
  crearRegistroEvento,
  editarRegistroEvento,
  getRegistroEvento,
} from "../../../helpers/api.helper";
import { getUsuarios } from "../../../../usuarios/helpers/api.helper";
import PrimerPasoRegistro from "./PrimerPasoRegistro";
import SegundoPasoRegistro from "./SegundoPasoRegistro";
import { validateOnSubmit } from "../../../../../utils/Validaciones";

const CrearEditarRegistro = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState(true);
  const [planificacion, setPlanificacion] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [seleccionado, setSeleccionado] = useState([]);
  const [imgUpload, setImgUpload] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const padLocacion = useParams();
  const id = useParams();
  const steps = [{ title: "Detalles" }, { title: "Acciones inmediatas" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const initData = async () => {
    let res;
    res = await getPlanificaciones(padLocacion);

    if (res.status === 200) {
      setPlanificacion(res.data[0]);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (type === "editar") {
      res = await getRegistroEvento(id);
      if (res.status === 200) {
        setSeleccionado(res.data[0]);
      } else if (res.status === 400) {
        setLoading(false);
        return toast({
          title: "Error",
          description: " Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    res = await getUsuarios(padLocacion);

    if (res.status === 200) {
      setUsuarios(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  function formatearFechaEditar(e) {
    let year, month, day, hours, minutes;

    if (type === "editar") {
      year = new Date(e).getFullYear();
      month = String(new Date(e).getMonth() + 1).padStart(2, "0");
      day = String(new Date(e).getDate()).padStart(2, "0");
      hours = String(new Date(e).getUTCHours()).padStart(2, "0");
      minutes = String(new Date(e).getMinutes()).slice(-2).padStart(2, "0");

      return year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
    }
  }

  const initialValues =
    type === "editar"
      ? {
          fechaRegistroEventos: moment(
            seleccionado.fechaRegistroEventos
          ).format("YYYY-MM-DDTHH:mm"),
          equipoRegistroEventos: seleccionado.equipoRegistroEventos,
          tipoRegistroEventos: seleccionado.tipoRegistroEventos,
          valoracionRegistroEventos: seleccionado.valoracionRegistroEventos,
          detectoRegistroEventos: seleccionado.detectoRegistroEventos,
          descripcionRegistroEventos: seleccionado.descripcionRegistroEventos,
          fotoRegistroEventos: seleccionado.fotoRegistroEventos,
          masFotosRegistroEventos: seleccionado.masFotosRegistroEventos,
          otroRegistroEventos: seleccionado.otroRegistroEventos,
          otroDondeRegistroEventos: seleccionado.otroDondeRegistroEventos,
          actoresRegistroEventos: seleccionado.actoresRegistroEventos,
          actoresQuienRegistroEventos: seleccionado.actoresQuienRegistroEventos,
          informoRegistroEventos: seleccionado.informoRegistroEventos,
          accionesRegistroEventos: seleccionado.accionesRegistroEventos,
          responsableAccionRegistroEventos:
            seleccionado.responsableAccionRegistroEventos,
          fechaAccionRegistroEventos: formatearFechaEditar(
            seleccionado.fechaAccionRegistroEventos
          ),
          responsableRegistroEventos: seleccionado.responsableRegistroEventos,
          servicioReestRegistroEventos:
            seleccionado.servicioReestRegistroEventos,
          aQuienComunico: seleccionado.aQuienComunico,
          rtaObtenida: seleccionado.rtaObtenida,
          rtaObtenidaCl: seleccionado.rtaObtenidaCl,
        }
      : {
          fechaRegistroEventos: "",
          equipoRegistroEventos: "",
          tipoRegistroEventos: "",
          valoracionRegistroEventos: "",
          detectoRegistroEventos: "",
          descripcionRegistroEventos: "",
          fotoRegistroEventos: "",
          masFotosRegistroEventos: false,
          otroRegistroEventos: false,
          otroDondeRegistroEventos: "",
          actoresRegistroEventos: false,
          actoresQuienRegistroEventos: "",
          informoRegistroEventos: false,
          accionesRegistroEventos: "",
          responsableAccionRegistroEventos: "",
          fechaAccionRegistroEventos: "",
          responsableRegistroEventos: "",
          servicioReestRegistroEventos: false,
          aQuienComunico: "",
          rtaObtenida: "",
          rtaObtenidaCl: "",
        };

  useEffect(() => {
    initData();
  }, []);

  return (
    <VStack w="100%" flex="1" overflowY={"auto"}>
      <HStack pl={5} w="100%" bg="brand.fondos_secundario">
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          {type === "crear" && "Nuevo evento"}
          {type === "editar" && "Edicion de registro"}
        </Heading>
        <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
          de {padLocacion.padLocacion}
        </Text>
      </HStack>
      <HStack w="100%" px={3} justifyContent="space-between">
        <Stepper index={activeStep} colorScheme="orange">
          {steps.map((step, index) => (
            <Step key={step + index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="1">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Text color="brand.azul_text" fontSize={14}>
          {activeStep} de {steps.length} partes
        </Text>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.fechaRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere fecha de registro",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.tipoRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere tipo de evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.valoracionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere valoracion de evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.descripcionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere descripcion del evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.accionesRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere detalle acciones inmediatas",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.responsableAccionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere responsable",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.fechaAccionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere hora del evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.detectoRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere quien detecto el evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.responsableRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere responsable",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }

            const datos =
              type === "editar"
                ? {
                    id: seleccionado.idRegistroEventos,
                    idPlanificacion: seleccionado.idPlanificacion,
                    fechaRegistroEventos: values.fechaRegistroEventos,
                    equipoRegistroEventos: values.equipoRegistroEventos,
                    tipoRegistroEventos: values.tipoRegistroEventos,
                    valoracionRegistroEventos: values.valoracionRegistroEventos,
                    detectoRegistroEventos: values.detectoRegistroEventos,
                    descripcionRegistroEventos:
                      values.descripcionRegistroEventos,
                    masFotosRegistroEventos: values.masFotosRegistroEventos,
                    otroRegistroEventos: values.otroRegistroEventos,
                    otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                    actoresRegistroEventos: values.actoresRegistroEventos,
                    actoresQuienRegistroEventos:
                      values.actoresQuienRegistroEventos,
                    informoRegistroEventos: values.informoRegistroEventos,
                    accionesRegistroEventos: values.accionesRegistroEventos,
                    responsableAccionRegistroEventos:
                      values.responsableAccionRegistroEventos,
                    fechaAccionRegistroEventos:
                      values.fechaAccionRegistroEventos,
                    responsableRegistroEventos:
                      values.responsableRegistroEventos,
                    servicioReestRegistroEventos:
                      values.servicioReestRegistroEventos,
                    aQuienComunico: values.aQuienComunico,
                    rtaObtenida: values.rtaObtenida,
                    rtaObtenidaCl: values.rtaObtenidaCl,
                  }
                : {
                    fechaRegistroEventos: values.fechaRegistroEventos,
                    idPlanificacion: planificacion.idPlanificacion,
                    equipoRegistroEventos: values.equipoRegistroEventos,
                    tipoRegistroEventos: values.tipoRegistroEventos,
                    valoracionRegistroEventos: values.valoracionRegistroEventos,
                    detectoRegistroEventos: values.detectoRegistroEventos,
                    descripcionRegistroEventos:
                      values.descripcionRegistroEventos,
                    masFotosRegistroEventos: values.masFotosRegistroEventos,
                    otroRegistroEventos: values.otroRegistroEventos,
                    otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                    actoresRegistroEventos: values.actoresRegistroEventos,
                    actoresQuienRegistroEventos:
                      values.actoresQuienRegistroEventos,
                    informoRegistroEventos: values.informoRegistroEventos,
                    accionesRegistroEventos: values.accionesRegistroEventos,
                    responsableAccionRegistroEventos:
                      values.responsableAccionRegistroEventos,
                    fechaAccionRegistroEventos:
                      values.fechaAccionRegistroEventos,
                    responsableRegistroEventos:
                      values.responsableRegistroEventos,
                    servicioReestRegistroEventos:
                      values.servicioReestRegistroEventos,
                    aQuienComunico: values.aQuienComunico,
                    rtaObtenida: values.rtaObtenida,
                    rtaObtenidaCl: values.rtaObtenidaCl,
                  };

            let res;
            if (type === "editar") {
              res = await editarRegistroEvento({
                datos: datos,
                fotoRegistroEventos: imgUpload,
              });
            } else {
              res = await crearRegistroEvento({
                datos: datos,
                fotoRegistroEventos: imgUpload,
              });
            }

            if (res.status === 200 || res.status === 404) {
              toast({
                status: "success",
                isClosable: true,
                title: `Registro ${
                  type === "editar" ? "editado" : "creado"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              toast({
                status: "success",
                isClosable: true,
                title: `Registro ${
                  type === "editar" ? "editado" : "creado"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "editar" ? "editar" : "crear"
                } registro`,
                duration: 3000,
              });
            }
            navigate(-1);
            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Stack w="100%" pb={3}>
              <Form name="FormularioRegistroEvento">
                <Divider w="100%" colorScheme="blackAlpha" />
                <br />
                {activeStep === 1 && (
                  <PrimerPasoRegistro
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    values={values}
                    initData={initData}
                    imgUpload={imgUpload}
                    setImgUpload={setImgUpload}
                    errors={errors}
                    touched={touched}
                  />
                )}
                {activeStep === 2 && (
                  <SegundoPasoRegistro
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    values={values}
                    initData={initData}
                    usuarios={usuarios}
                    errors={errors}
                    touched={touched}
                  />
                )}
                {activeStep === 1 && (
                  <HStack px={5} justifyContent="end">
                    <Box>
                      <Button
                        fontSize={12}
                        fontWeight="semibold"
                        bg="brand.naranja"
                        color="white"
                        rightIcon={<MdNavigateNext fontSize="20px" />}
                        variant="solid"
                        onClick={() => {
                          if (
                            validateOnSubmit(
                              "text",
                              values.descripcionRegistroEventos
                            ) ||
                            (values.otroDondeRegistroEventos &&
                              validateOnSubmit(
                                "text",
                                values.otroDondeRegistroEventos
                              )) ||
                            (values.actoresQuienRegistroEventos &&
                              validateOnSubmit(
                                "text",
                                values.actoresQuienRegistroEventos
                              )) ||
                            (values.rtaObtenidaCl &&
                              validateOnSubmit("text", values.rtaObtenidaCl)) ||
                            (values.aQuienComunico &&
                              validateOnSubmit(
                                "text",
                                values.aQuienComunico
                              )) ||
                            (values.rtaObtenida &&
                              validateOnSubmit("text", values.rtaObtenida))
                          ) {
                            setLoading(false);
                            return toast({
                              title: "Error",
                              description: "Datos invalidos",
                              status: "error",
                              duration: 3000,
                              isClosable: true,
                            });
                          } else {
                            setActiveStep(activeStep + 1);
                          }
                        }}
                        isDisabled={loading}
                        _hover={{ bg: "white", color: "brand.naranja" }}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </HStack>
                )}
                {activeStep === 2 && (
                  <HStack w="100%" justifyContent="space-between" p={3}>
                    <Button
                      fontSize={12}
                      fontWeight="semibold"
                      color="brand.naranja"
                      border="1px solid #FF8200"
                      leftIcon={<MdNavigateBefore fontSize="20px" />}
                      variant="outline"
                      onClick={() => setActiveStep(activeStep - 1)}
                      isDisabled={loading}
                      _hover={{ bg: "brand.naranja", color: "white" }}
                    >
                      Atras
                    </Button>
                    <Button
                      fontSize={12}
                      fontWeight="semibold"
                      bg="brand.naranja"
                      type={"submit"}
                      color="white"
                      rightIcon={<MdNavigateNext fontSize="20px" />}
                      variant="solid"
                      form="FormularioRegistroEvento"
                      onClick={handleSubmit}
                      isDisabled={loading || user.idRol === "usuarioIT"}
                      _hover={{ bg: "white", color: "brand.naranja" }}
                    >
                      Finalizar
                    </Button>
                  </HStack>
                )}
              </Form>
            </Stack>
          )}
        </Formik>
      )}
    </VStack>
  );
};

export default CrearEditarRegistro;
