import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Stack,
  HStack,
  Heading,
  Input,
  useToast,
  CircularProgress,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverHeader,
  List,
  ListItem,
  Progress,
  IconButton,
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import {
  MdOutlineGetApp,
  MdFilterList,
  MdRefresh,
  MdCheck,
  MdAutorenew,
  MdArrowBack,
} from "react-icons/md";
import { descargarReportes } from "../operaciones/helpers/api.helper";
import {
  getReportesViajes,
  editarReportesViajes,
  getReportesViajesFiltrados,
  getCategoriasFiltros,
} from "./helpers/api.helper";
import { getCamiones } from "../operaciones/helpers/api.helper";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroll from "react-infinite-scroll-component";
import DisenoTabla from "./components/DisenoTabla";
import moment from "moment";

const ReporteViajes = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingIII, setLoadingIII] = useState(false);
  const [tempDate, setTempDate] = useState({
    inicio: null,
    fin: null,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    statusRV: [],
    inicio: "",
    fin: "",
    padDes: [],
    status2RV: [],
    transportista: [],
    dominio: [],
    interno: [],
  });
  const [reportesViajes, setReportesViajes] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [nombresPad, setNombresPad] = useState([]);
  const [nombresTransportista, setNombresTransportista] = useState([]);
  const [internos, setInternos] = useState([]);
  const [dominios, setDominios] = useState([]);
  const [camiones, setCamiones] = useState([]);
  const [filtroCamiones, setFiltroCamiones] = useState("");
  const [filtroInterno, setFiltroInterno] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPanel, setIsOpenPanel] = useState(true);
  const [fechaFin, setFechaFin] = useState();
  const [camionFin, setCamionFin] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const { padLocacion } = useParams();
  const padDestino = padLocacion;
  const scrollRef = useRef(null);
  const tableRef = useRef(null);

  const initData = async () => {
    setLoading(true);
    try {
      const [camionesRes, reportesRes, categoriasRes] = await Promise.all([
        getCamiones(),
        padLocacion
          ? getReportesViajes({ currentPage: 1, padDestino })
          : getReportesViajes({ currentPage: 1 }),
        getCategoriasFiltros(),
      ]);

      if (camionesRes.status === 200) {
        setCamiones(camionesRes.data);
      }

      if (categoriasRes.status === 200) {
        setNombresPad(categoriasRes.data.nombresPAD);
        setDominios(categoriasRes.data.nombresCamiones);
        setInternos(categoriasRes.data.internoCamiones);
        setNombresTransportista(categoriasRes.data.nombresTransportista);
      }

      if (reportesRes.status === 200) {
        setReportesViajes(reportesRes.data.reportes);
      }
    } catch (error) {
      console.error("Error al cargar los datos:", error);
      return toast({
        title: "Error",
        description: "Error al cargar los datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const preInitData = async () => {
    setLoading(true);

    let res;
    try {
      if (padLocacion) {
        res = await getReportesViajes({ currentPage: 1, padDestino });
      } else {
        res = await getReportesViajes({ currentPage: 1 });
      }

      if (res.status === 200) {
        setReportesViajes(res.data.reportes);
        setCurrentPage(1);
      } else if (res.status === 204) {
        toast({
          status: "info",
          description: "No hay más reportes",
          isClosable: true,
          duration: 2000,
        });
      } else {
        toast({
          status: "error",
          description: "Error al cargar reportes",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      console.error("Error al cargar más datos:", error);
      return toast({
        title: "Error",
        description: "Error al cargar más datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  const handleFilter = async (updatedPage) => {
    setLoadingIII(true);
    try {
      let res;
      let filtroActivo = {};

      if (appliedFilters.dominio.length > 0) {
        filtroActivo.patentes = appliedFilters.dominio;
      }
      if (appliedFilters.interno.length > 0) {
        filtroActivo.interno = appliedFilters.interno;
      }
      if (appliedFilters.transportista.length > 0) {
        filtroActivo.transportista = appliedFilters.transportista;
      }
      if (appliedFilters.status2RV.length > 0) {
        filtroActivo.statusRV2 = appliedFilters.status2RV;
      }
      if (appliedFilters.statusRV.length > 0) {
        filtroActivo.statusRV = appliedFilters.statusRV;
      }
      if (padDestino) {
        filtroActivo.pad = [padDestino];
      }
      if (appliedFilters.padDes.length > 0) {
        filtroActivo.pad = appliedFilters.padDes;
      }
      if (appliedFilters.fin) {
        filtroActivo.fin = appliedFilters.fin;
      }
      if (appliedFilters.inicio) {
        filtroActivo.inicio = appliedFilters.inicio;
      }

      if (Object.keys(filtroActivo).length > 0) {
        if (padLocacion) {
          res = await getReportesViajesFiltrados({
            currentPage: updatedPage,
            padDestino,
            ...filtroActivo,
          });
        } else {
          res = await getReportesViajesFiltrados({
            currentPage: updatedPage,
            ...filtroActivo,
          });
        }
      } else {
        if (padLocacion) {
          res = await getReportesViajes({
            currentPage: updatedPage,
            padDestino,
            ...filtroActivo,
          });
        } else {
          res = await getReportesViajes({
            currentPage: updatedPage,
            ...filtroActivo,
          });
        }
      }

      if (res.status === 200) {
        if (res.data) {
          // Si hay datos en la respuesta, los agregamos al estado existente
          setReportesViajes(res.data.filteredData);
          setCurrentPage(1);
        }

        if (res.data.totalData % 50 === 0 || res.data.length < 50) {
          setHasMore(false);
        }
      } else if (res.status === 204) {
        setReportesViajes([]);
        return toast({
          status: "info",
          description: "No hay reportes con ese filtro",
          isClosable: true,
          duration: 2000,
        });
      } else {
        // Manejar errores de la solicitud
        return toast({
          status: "error",
          description: "Error al cargar reportes",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      // Manejar errores de la solicitud
      console.error("Error al cargar más datos:", error);

      return toast({
        title: "Error",
        description: "Error al cargar más datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingIII(false);
    }
  };

  const handleFilterSelection = (filterName, value) => {
    setAppliedFilters((prevFilters) => {
      // Actualizamos el filtro seleccionado
      const updatedFilters = {
        ...prevFilters,
        [filterName]: prevFilters[filterName].includes(value)
          ? prevFilters[filterName].filter((val) => val !== value)
          : [...prevFilters[filterName], value],
      };

      // Verificamos si todos los filtros están vacíos
      const allFiltersEmpty = Object.values(updatedFilters).every((val) =>
        Array.isArray(val) ? val.length === 0 : val === ""
      );

      // Si todos los filtros están vacíos, ejecutamos una función
      if (allFiltersEmpty) {
        preInitData();
      }

      return updatedFilters;
    });
  };

  const cleanFilter = () => {
    setAppliedFilters({
      statusRV: [],
      inicio: "",
      fin: "",
      padDes: [],
      status2RV: [],
      transportista: [],
      dominio: [],
      interno: [],
    });
    setTempDate({
      inicio: "",
      fin: "",
    });
    setCurrentPage(1);
    setFiltroInterno("");
    setFiltroCamiones("");
    preInitData();
    setIsOpenPanel(true);
  };

  const handleDownload = async (datos) => {
    const {
      statusRV,
      inicio,
      fin,
      padDes,
      status2RV,
      transportista,
      dominio,
      interno,
    } = appliedFilters;
    let data;

    if (
      statusRV.length === 0 &&
      inicio === "" &&
      fin === "" &&
      padDes.length === 0 &&
      status2RV.length === 0 &&
      transportista.length === 0 &&
      dominio.length === 0 &&
      interno.length === 0
    ) {
      setLoadingIII(true);
      let res;
      if (padLocacion) {
        res = await getReportesViajes({ padDestino });
      } else {
        res = await getReportesViajes();
      }
      let reportesTotales = res.data.reportes.map((obj) => {
        return {
          ...obj,
          tsPozoOut: obj.tsPozoOut
            ? moment.utc(obj.tsPozoOut).format("YYYY/MM/DD HH:mm")
            : null,
          tsPlantaIn: obj.tsPlantaIn
            ? moment.utc(obj.tsPlantaIn).format("YYYY/MM/DD HH:mm")
            : null,
          tsPlantaOut: obj.tsPlantaOut
            ? moment.utc(obj.tsPlantaOut).format("YYYY/MM/DD HH:mm")
            : null,
          tsPozoIn: obj.tsPozoIn
            ? moment.utc(obj.tsPozoIn).format("YYYY/MM/DD HH:mm")
            : null,
          eta: obj.eta ? moment.utc(obj.eta).format("YYYY/MM/DD HH:mm") : null,
          fechaRV: obj.tsPozoIn
            ? moment.utc(obj.tsPozoIn).format("YYYY/MM/DD")
            : moment.utc(obj.fechaRV).format("YYYY/MM/DD"),
          cargaTotal: 27,
        };
      });

      data = reportesTotales;
    } else {
      let reportesTotales = datos.map((obj) => {
        obj.cargaTotal = 27;
        return obj;
      });
      data = reportesTotales;
    }

    let response = await descargarReportes({ reportes: data });
    if (response.status === 200) {
      setLoadingIII(false);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Reporte-de-viajes.xlsx`);
      document.body.appendChild(link);
      link.click();
    } else if (response.status === 400) {
      return toast({
        title: "Error al descargar",
        description: "Error al descargar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (response.status === 500) {
      return toast({
        title: "Error",
        description: "Error del servidor ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loadingtable = (evento) => {
    if (evento === "inicial") {
      return (
        <HStack w="100%" placeContent="center">
          <CircularProgress isIndeterminate color="brand.naranja" />
        </HStack>
      );
    }
    if (evento === "tabla") {
      return (
        <HStack w="100%" placeContent="center">
          <Progress size="xs" isIndeterminate />
        </HStack>
      );
    }
  };

  const fetchMoreDataII = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    try {
      let res;
      if (padLocacion) {
        res = await getReportesViajes({
          currentPage: currentPage + 1,
          padDestino,
        });
      } else {
        res = await getReportesViajes({
          currentPage: currentPage + 1,
        });
      }

      if (res.status === 200) {
        if (res.data) {
          setReportesViajes((prevData) => [...prevData, ...res.data.reportes]);
        }

        if (
          res.data.totalAllData - reportesViajes.length === 0 ||
          res.data.reportes.length < 25
        ) {
          setHasMore(false);
          return toast({
            status: "info",
            description: "No hay más reportes",
            isClosable: true,
            duration: 2000,
          });
        } else {
          setCurrentPage(currentPage + 1);
        }
      } else if (res.status === 204) {
        return toast({
          status: "info",
          description: "No hay más reportes",
          isClosable: true,
          duration: 2000,
        });
      } else {
        return toast({
          status: "error",
          description: "Error al cargar reportes",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      console.error("Error al cargar más datos:", error);
      return toast({
        title: "Error",
        description: "Error al cargar más datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, [
    loading,
    currentPage,
    padLocacion,
    padDestino,
    setLoading,
    setReportesViajes,
    setCurrentPage,
    setHasMore,
    setInitialLoading,
    toast,
  ]);

  const applyFiltersAndUpdateData = () => {
    // Asegúrate de que todos los filtros están actualizados y luego realiza la búsqueda
    handleFilter(1); // Actualiza los reportes según los filtros seleccionados
  };

  const fetchFilteredData = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    let filtroActivo = {};

    if (appliedFilters.dominio.length > 0) {
      filtroActivo.patentes = appliedFilters.dominio;
    }
    if (appliedFilters.interno.length > 0) {
      filtroActivo.interno = appliedFilters.interno;
    }
    if (appliedFilters.transportista.length > 0) {
      filtroActivo.transportista = appliedFilters.transportista;
    }
    if (appliedFilters.status2RV.length > 0) {
      filtroActivo.statusRV2 = appliedFilters.status2RV;
    }
    if (appliedFilters.statusRV.length > 0) {
      filtroActivo.statusRV = appliedFilters.statusRV;
    }
    if (padDestino) {
      filtroActivo.pad = [padDestino];
    }
    if (appliedFilters.padDes.length > 0) {
      filtroActivo.pad = appliedFilters.padDes;
    }
    if (appliedFilters.fin) {
      filtroActivo.fin = appliedFilters.fin;
    }
    if (appliedFilters.inicio) {
      filtroActivo.inicio = appliedFilters.inicio;
    }

    try {
      let res;
      if (padLocacion) {
        res = await getReportesViajesFiltrados({
          currentPage: currentPage + 1,
          padDestino,
          ...filtroActivo,
        });
      } else {
        res = await getReportesViajesFiltrados({
          currentPage: currentPage + 1,
          ...filtroActivo,
        });
      }

      if (res.status === 200) {
        if (res.data) {
          setReportesViajes((prevData) => {
            const nuevosReportes = res.data.filteredData.filter(
              (nuevo) =>
                !prevData.some(
                  (prev) => prev.idReporteViaje === nuevo.idReporteViaje
                )
            );
            return [...prevData, ...nuevosReportes];
          });
        }

        if (
          res.data.filteredData.length < 25 ||
          res.data.totalData - reportesViajes.length === 0
        ) {
          setHasMore(false);
          return toast({
            status: "info",
            description: "No hay más reportes",
            isClosable: true,
            duration: 2000,
          });
        } else {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      } else if (res.status === 204) {
        return toast({
          status: "info",
          description: "No hay más reportes",
          isClosable: true,
          duration: 2000,
        });
      }
    } catch (error) {
      console.error("Error al cargar más datos:", error);
      return toast({
        title: "Error",
        description: "Error al cargar más datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, [
    appliedFilters,
    padDestino,
    padLocacion,
    currentPage,
    loading,
    setLoading,
    setInitialLoading,
    setReportesViajes,
    setCurrentPage,
    setHasMore,
    toast,
  ]);

  useEffect(() => {
    if (!initialLoading && scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Desplazar el scroll hacia la parte superior solo una vez
    }
  }, [initialLoading]);

  const handleCierreManual = async (editable, fechaFin, camionFin) => {
    setLoading(true);

    if (!fechaFin) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha Fin requerida",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    if (!camionFin) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Camion requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }

    let res;

    res = await editarReportesViajes({
      id: editable.idReporteViaje,
      camionFinal: camionFin.dominio,
      tsPozoIn: fechaFin,
    });

    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Reporte finalizado correctamente",
        duration: 3000,
      });
      onClose();
      initData();
    } else {
      return toast({
        status: "error",
        isClosable: true,
        title: "Error al finalizar el reporte",
        description: res.data.error.message,
      });
    }

    setLoading(false);
  };

  const onClose = () => {
    setIsOpen(false);
    setFiltroCamiones("");
    setCamionFin();
    setFechaFin();
  };

  useEffect(() => {
    if (tableRef.current) {
      localStorage.setItem("scrollLeft", tableRef.current.scrollLeft);
      localStorage.setItem("scrollTop", tableRef.current.scrollTop);
    }
  }, [tableRef.current?.scrollLeft, tableRef.current?.scrollTop]);

  useEffect(() => {
    initData();

    if (tableRef.current) {
      const savedScrollLeft = localStorage.getItem("scrollLeft");
      const savedScrollTop = localStorage.getItem("scrollTop");
      if (savedScrollLeft !== null) {
        tableRef.current.scrollLeft = parseInt(savedScrollLeft);
      }
      if (savedScrollTop !== null) {
        tableRef.current.scrollTop = parseInt(savedScrollTop);
      }
    }
  }, []);

  return (
    <Stack w="100%" h={"calc(100vh - 130px)"}>
      {loadingIII && loadingtable("inicial")}
      {!loadingIII && (
        <>
          <HStack justifyContent="space-between" px={3}>
            <HStack fontWeight="medium">
              {padLocacion && (
                <Box>
                  <IconButton
                    icon={<MdArrowBack />}
                    variant="link"
                    onClick={() => navigate(-1)}
                    fontSize="24px"
                  />
                </Box>
              )}
              <Heading
                fontWeight="medium"
                size="xl"
                fontFamily="fonts.title"
                color="brand.gris_primario"
              >
                Reporte de viajes
              </Heading>
              {padLocacion && (
                <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
                  en {padLocacion}
                </Text>
              )}
            </HStack>
            <HStack pr={4}>
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "useFacturacion" ||
                user.idRol === "usuarioIT" ||
                user.idRol === "userHS" ||
                user.idRol === "gerencia") && (
                <Tooltip label="Descargar XLSX">
                  <IconButton
                    size="sm"
                    variant="outline"
                    border="1px solid #FF8200"
                    icon={<MdOutlineGetApp fontSize="20px" />}
                    color="brand.naranja"
                    _hover={{ bg: "brand.naranja", color: "white" }}
                    onClick={() => handleDownload(reportesViajes)}
                  />
                </Tooltip>
              )}
              <Popover isLazy>
                <PopoverTrigger>
                  <IconButton
                    icon={<MdFilterList fontSize="20px" />}
                    bg={"brand.fondos_secundarios"}
                    borderRadius="50%"
                    color={"black"}
                  />
                </PopoverTrigger>
                <PopoverContent mr={2}>
                  <PopoverArrow ml={1} />
                  <PopoverHeader>
                    <HStack w={"100%"} justifyContent={"space-between"} pr={2}>
                      <Button
                        size={"sm"}
                        color={"white"}
                        bg={"brand.naranja"}
                        onClick={() => {
                          applyFiltersAndUpdateData();
                        }}
                        isDisabled={
                          loading ||
                          !Object.values(appliedFilters).some((value) => {
                            return Array.isArray(value)
                              ? value.length > 0
                              : value !== "";
                          })
                        }
                      >
                        Filtrar
                      </Button>
                      <Tooltip label="Limpiar filtro">
                        <IconButton
                          icon={<MdRefresh />}
                          onClick={cleanFilter}
                          variant="link"
                          placeSelf={"center"}
                          justifySelf={"center"}
                          isDisabled={loading}
                        />
                      </Tooltip>
                    </HStack>
                  </PopoverHeader>
                  <PopoverBody maxH={"sm"} overflowY={"auto"}>
                    <Accordion w={"100%"} allowToggle>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton fontWeight={"bold"}>
                          Locacion
                        </AccordionButton>
                        <AccordionPanel>
                          <List
                            cursor={loading ? "not-allowed" : "pointer"}
                            _disabled={loading}
                          >
                            <HStack w={"100%"}>
                              {appliedFilters.status2RV.includes("EN POZO") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                style={
                                  appliedFilters["status2RV"].includes(
                                    "EN POZO"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("status2RV", "EN POZO");
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                En pozo
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {appliedFilters.status2RV.includes(
                                "EN TRÁNSITO A POZO"
                              ) && <MdCheck />}
                              <ListItem
                                style={
                                  appliedFilters["status2RV"].includes(
                                    "EN TRÁNSITO A POZO"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "status2RV",
                                    "EN TRÁNSITO A POZO"
                                  );
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                En tránsito a pozo
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {appliedFilters.status2RV.includes(
                                "EN TRÁNSITO A PLANTA"
                              ) && <MdCheck />}
                              <ListItem
                                style={
                                  appliedFilters["status2RV"].includes(
                                    "EN TRÁNSITO A PLANTA"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "status2RV",
                                    "EN TRÁNSITO A PLANTA"
                                  );
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                En tránsito a planta
                              </ListItem>
                            </HStack>
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton fontWeight={"bold"}>
                          Estado
                        </AccordionButton>
                        <AccordionPanel>
                          <List
                            cursor={loading ? "not-allowed" : "pointer"}
                            _disabled={loading}
                          >
                            <HStack w={"100%"}>
                              {appliedFilters.statusRV.includes("En curso") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  appliedFilters["statusRV"].includes(
                                    "En curso"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("statusRV", "En curso");
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                En curso
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {appliedFilters.statusRV.includes(
                                "Finalizado"
                              ) && <MdCheck />}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  appliedFilters["statusRV"].includes(
                                    "Finalizado"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection(
                                    "statusRV",
                                    "Finalizado"
                                  );
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                Finalizado
                              </ListItem>
                            </HStack>
                            <HStack w={"100%"}>
                              {appliedFilters.statusRV.includes("Caducado") && (
                                <MdCheck />
                              )}
                              <ListItem
                                w={"100%"}
                                cursor={"pointer"}
                                style={
                                  appliedFilters["statusRV"].includes(
                                    "Caducado"
                                  )
                                    ? { backgroundColor: "lightgray" }
                                    : {}
                                }
                                onClick={() => {
                                  handleFilterSelection("statusRV", "Caducado");
                                }}
                                pointerEvents={loading ? "none" : "auto"}
                              >
                                Caducado
                              </ListItem>
                            </HStack>
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton fontWeight={"bold"}>
                          PAD Destino
                        </AccordionButton>
                        <AccordionPanel>
                          <List
                            cursor={loading ? "not-allowed" : "pointer"}
                            _disabled={loading}
                          >
                            {nombresPad?.map((element) => (
                              <HStack w={"100%"} key={element}>
                                {appliedFilters.padDes.includes(element) && (
                                  <MdCheck />
                                )}
                                <ListItem
                                  w={"100%"}
                                  style={
                                    appliedFilters["padDes"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("padDes", element);
                                  }}
                                  pointerEvents={loading ? "none" : "auto"}
                                >
                                  {element}
                                </ListItem>
                              </HStack>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton fontWeight={"bold"}>
                          Empresa Transportista
                        </AccordionButton>
                        <AccordionPanel>
                          <List
                            cursor={loading ? "not-allowed" : "pointer"}
                            _disabled={loading}
                          >
                            {nombresTransportista?.map((element) => (
                              <HStack w={"100%"} key={element}>
                                {appliedFilters.transportista.includes(
                                  element
                                ) && <MdCheck />}
                                <ListItem
                                  w={"100%"}
                                  style={
                                    appliedFilters["transportista"].includes(
                                      element
                                    )
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection(
                                      "transportista",
                                      element
                                    );
                                  }}
                                  pointerEvents={loading ? "none" : "auto"}
                                >
                                  {element}
                                </ListItem>
                              </HStack>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton
                          cursor={loading ? "not-allowed" : "pointer"}
                          _disabled={loading}
                        >
                          <Input
                            w="xs"
                            variant="flushed"
                            type="text"
                            placeholder="Camion"
                            style={{ fontWeight: "bold", color: "black" }}
                            onChange={(e) => {
                              const value = e.target.value ?? "";
                              setFiltroCamiones(value.toLowerCase());
                            }}
                            value={filtroCamiones}
                          />
                        </AccordionButton>
                        <AccordionPanel h="100px" overflowY="scroll">
                          {dominios
                            ?.filter((element) =>
                              element
                                .toLowerCase()
                                .includes(filtroCamiones ?? "")
                            )
                            .map((element) => (
                              <HStack w={"100%"} key={element}>
                                {appliedFilters.dominio.includes(element) && (
                                  <MdCheck />
                                )}
                                <option
                                  style={
                                    appliedFilters["dominio"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("dominio", element);
                                  }}
                                >
                                  {element}
                                </option>
                              </HStack>
                            ))}
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <AccordionButton
                          cursor={loading ? "not-allowed" : "pointer"}
                          _disabled={loading}
                        >
                          <Input
                            w="xs"
                            variant="flushed"
                            type="text"
                            placeholder="Interno"
                            style={{ fontWeight: "bold", color: "black" }}
                            onChange={(e) => {
                              const value = e.target.value ?? "";
                              setFiltroInterno(value.toLowerCase());
                            }}
                            value={filtroInterno}
                          />
                        </AccordionButton>
                        <AccordionPanel h="100px" overflowY="scroll">
                          {internos
                            ?.filter((element) =>
                              element
                                .toLowerCase()
                                .includes(filtroInterno ?? "")
                            )
                            .map((element) => (
                              <HStack w={"100%"} key={element}>
                                {appliedFilters.interno.includes(element) && (
                                  <MdCheck />
                                )}
                                <option
                                  style={
                                    appliedFilters["interno"].includes(element)
                                      ? { backgroundColor: "lightgray" }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleFilterSelection("interno", element);
                                  }}
                                >
                                  {element}
                                </option>
                              </HStack>
                            ))}
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem isDisabled={loading}>
                        <HStack w={"100%"} justifyContent={"space-between"}>
                          <AccordionButton
                            fontWeight={"bold"}
                            cursor={loading ? "not-allowed" : "pointer"}
                            opacity={loading ? 0.5 : 1}
                            _disabled={loading}
                          >
                            Fecha
                            {appliedFilters.inicio && appliedFilters.fin && (
                              <Text
                                pl={3}
                                fontWeight={"light"}
                                fz={"12px"}
                              >{`${moment(appliedFilters.inicio[0]).format(
                                "DD/MM/YYYY"
                              )} - ${moment(appliedFilters.fin[0]).format(
                                "DD/MM/YYYY"
                              )}`}</Text>
                            )}
                          </AccordionButton>

                          <IconButton
                            size={"12px"}
                            variant={
                              appliedFilters.inicio && appliedFilters.fin
                                ? "solid"
                                : "link"
                            }
                            backgroundColor={
                              appliedFilters.inicio && appliedFilters.fin
                                ? "brand.naranja"
                                : "none"
                            }
                            color={
                              appliedFilters.inicio && appliedFilters.fin
                                ? "white"
                                : "gray"
                            }
                            cursor={"pointer"}
                            isDisabled={loading || !appliedFilters.inicio}
                            icon={<MdAutorenew />}
                            onClick={() => {
                              setAppliedFilters((prevState) => ({
                                ...prevState,
                                inicio: "", // Reinicia el valor de inicio a una cadena vacía
                                fin: "", // Reinicia el valor de fin a una cadena vacía
                              }));
                              setTempDate({ inicio: null, fin: null });
                              setIsOpenPanel(true);
                            }}
                          />
                        </HStack>
                        {isOpenPanel && (
                          <AccordionPanel
                            style={{ pointerEvents: loading ? "none" : "auto" }}
                            opacity={loading ? 0.5 : 1}
                          >
                            <DatePicker
                              selectsRange={true}
                              startDate={
                                tempDate.inicio || appliedFilters.inicio
                              }
                              endDate={tempDate.fin || appliedFilters.fin}
                              dateFormat="dd/MM/yyyy"
                              disabled={
                                loading ||
                                (appliedFilters.inicio && appliedFilters.fin) // Deshabilita si ya hay dos fechas seleccionadas
                              }
                              onChange={(update) => {
                                const [start, end] = update;
                                setTempDate({
                                  inicio: start,
                                  fin: end,
                                });

                                if (start && end) {
                                  handleFilterSelection("inicio", start);
                                  handleFilterSelection("fin", end);
                                  setIsOpenPanel(false);
                                }
                              }}
                              inline
                            />
                          </AccordionPanel>
                        )}
                      </AccordionItem>
                    </Accordion>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </HStack>
          <>
            {initialLoading && loadingtable("inicial")}
            {!initialLoading && (
              <InfiniteScroll
                dataLength={reportesViajes?.length}
                next={() => {
                  const hasFilters = Object.values(appliedFilters).some(
                    (filter) => {
                      return Array.isArray(filter)
                        ? filter.length > 0
                        : filter !== "";
                    }
                  );

                  if (hasFilters) {
                    fetchFilteredData();
                  } else {
                    fetchMoreDataII();
                  }
                }}
                hasMore={hasMore}
                loader={loadingtable("tabla")}
                scrollableTarget="TBody"
                scrollThreshold={0.9}
                initialScrollY={scrollRef - 1000}
              >
                <DisenoTabla
                  reportesViajes={reportesViajes}
                  user={user}
                  initData={initData}
                  setReportesViajes={setReportesViajes}
                  handleCierreManual={handleCierreManual}
                  camiones={camiones}
                  setCamiones={setCamiones}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  fechaFin={fechaFin}
                  setFechaFin={setFechaFin}
                  camionFin={camionFin}
                  setCamionFin={setCamionFin}
                  filtroCamiones={filtroCamiones}
                  setFiltroCamiones={setFiltroCamiones}
                  onClose={onClose}
                  tableRef={tableRef}
                />
              </InfiniteScroll>
            )}
          </>
          {!loading && reportesViajes.length <= 0 && (
            <Box py={5}>
              <Heading textAlign={"center"} size="xl">
                No hay reportes de viaje
              </Heading>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default ReporteViajes;
