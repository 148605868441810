import React from "react";
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Textarea,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Text,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";
import { Field } from "formik";
import { validateField } from "../../../../../utils/Validaciones";

const PrimerPasoRegistro = (props) => {
  const { handleChange, handleBlur, values, setImgUpload, errors, touched } =
    props;

  return (
    <Stack w="100%" pb={3} px={7}>
      <HStack justifyContent="space-between" pb={3}>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Evento</FormLabel>
          <RadioGroup
            display={"block"}
            name="tipoRegistroEventos"
            value={values.tipoRegistroEventos}
          >
            <HStack>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Calidad"
              >
                Calidad
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Mantenimiento"
              >
                Mantenimiento
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Valoracion de Evento</FormLabel>
          <RadioGroup
            display={"block"}
            name="valoracionRegistroEventos"
            value={values.valoracionRegistroEventos}
          >
            <HStack>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Leve"
              >
                Leve
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Grave"
              >
                Grave
              </Radio>
              <Radio
                display={"block"}
                onChange={handleChange}
                onBlur={handleBlur}
                value="Muy Grave"
              >
                Muy grave
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl w="2xs" isRequired>
          <FormLabel fontWeight="semibold">Fecha/Hora del evento</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaRegistroEventos"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fechaRegistroEventos}
          />
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" pb={3}>
        <FormControl w="2xs">
          <FormLabel fontWeight="semibold">Equipo involucrado</FormLabel>
          <RadioGroup
            display={"block"}
            name="equipoRegistroEventos"
            value={values.equipoRegistroEventos}
          >
            <HStack justifyContent="start">
              <Stack justifyContent="left">
                <Radio
                  display={"inline"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sander"
                >
                  Sander
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandcubes"
                >
                  Sandcubes
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="sandVan"
                >
                  SandVan
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="dosificadora"
                >
                  Dosificadora
                </Radio>
              </Stack>
              <Stack placeSelf="start">
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="generadores"
                >
                  Generadores
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="autoelevador"
                >
                  Autoelevador
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="minipala"
                >
                  Mini Pala
                </Radio>
                <Radio
                  display={"block"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="otro"
                >
                  Ninguno
                </Radio>
              </Stack>
            </HStack>
          </RadioGroup>
        </FormControl>
        <Stack w="lg" placeSelf={"start"}>
          <Field
            name="descripcionRegistroEventos"
            validate={(value) =>
              validateField("textArea", value, "Observaciones")
            }
            w={"100%"}
          >
            {({ field }) => (
              <FormControl
                isInvalid={
                  errors?.descripcionRegistroEventos &&
                  touched?.descripcionRegistroEventos
                }
                isRequired
              >
                <HStack spacing={-1}>
                  <FormLabel> Descripcion de Evento</FormLabel>

                  <Text fontSize={12} color={"gray"}>
                    ¿Qué pasó?¿Cómo pasó?¿Quiénes estuvieron involucrados?
                  </Text>
                </HStack>
                <Card variant="unstyled" bg="brand.fondos_secundarios">
                  <CardBody>
                    <Textarea
                      {...field}
                      placeholder="Descripcion"
                      name="descripcionRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.descripcionRegistroEventos}
                      maxLength="1500"
                      resize="none"
                    />
                  </CardBody>
                  <CardFooter
                    placeSelf="end"
                    color="brand.gris_medio"
                    pr={2}
                    m={"unset"}
                  >
                    {values.descripcionRegistroEventos.length}/1500
                  </CardFooter>
                </Card>
                <FormErrorMessage>
                  {errors?.descripcionRegistroEventos}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Box></Box>
      </HStack>
      <HStack pb={3}>
        <FormControl w="xs" placeSelf="start">
          <FormLabel fontWeight="semibold">Foto</FormLabel>
          <Input
            variant="filled"
            type="file"
            onChange={(e) => setImgUpload(e.target.files[0])}
            accept=".jpeg, .jpg, .png"
            multiple={false}
            _hover="none"
            bg="brand.fondo_secundario"
          />
        </FormControl>
      </HStack>
      <HStack pb={3}>
        <FormLabel w={"sm"}></FormLabel>
        <FormLabel w={"sm"}></FormLabel>
        <FormLabel w={"sm"}>A quién se comunicó?</FormLabel>
      </HStack>
      <HStack>
        <Stack spacing={6} placeSelf={"start"}>
          <Checkbox
            name="masFotosRegistroEventos"
            onChange={handleChange}
            isChecked={values.masFotosRegistroEventos}
          >
            Se disponen más fotos?
          </Checkbox>
          <HStack w={"sm"}>
            <Checkbox
              w={"xs"}
              name="otroRegistroEventos"
              onChange={handleChange}
              isChecked={values.otroRegistroEventos}
            >
              Se registró evento en otro registro?
            </Checkbox>
            <Stack m={"unset"} w={"sm"}>
              <Field
                name="otroDondeRegistroEventos"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={
                      errors.otroDondeRegistroEventos &&
                      touched.otroDondeRegistroEventos
                    }
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="¿Donde?"
                      size="sm"
                      name="otroDondeRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otroDondeRegistroEventos}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.otroDondeRegistroEventos}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
        </Stack>
        <Stack placeSelf={"start"}>
          <HStack w={"sm"} placeSelf={"start"}>
            <Checkbox
              w={"sm"}
              name="actoresRegistroEventos"
              onChange={handleChange}
              isChecked={values.actoresRegistroEventos}
            >
              Involucró otros actores/empresas?
            </Checkbox>
            <Stack m={"unset"} w={"sm"}>
              <Field
                name="actoresQuienRegistroEventos"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={
                      errors.actoresQuienRegistroEventos &&
                      touched.actoresQuienRegistroEventos
                    }
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="¿Quien?"
                      size="sm"
                      name="actoresQuienRegistroEventos"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actoresQuienRegistroEventos}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.actoresQuienRegistroEventos}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
          <HStack w={"sm"} pt={2}>
            <Checkbox
              w={"sm"}
              name="informoRegistroEventos"
              onChange={handleChange}
              isChecked={values.informoRegistroEventos}
            >
              Se informó al cliente?
            </Checkbox>
            <Stack placeSelf={"start"} m={"unset"} w={"sm"}>
              <Field
                name="rtaObtenidaCl"
                validate={(value) => validateField("text", value, "texto")}
              >
                {({ field }) => (
                  <FormControl
                    isInvalid={errors.rtaObtenidaCl && touched.rtaObtenidaCl}
                  >
                    <Input
                      {...field}
                      type="text"
                      placeholder="Respuesta obtenida"
                      size="sm"
                      name="rtaObtenidaCl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rtaObtenidaCl}
                    />
                    <FormErrorMessage fontSize={"10px"}>
                      {errors.rtaObtenidaCl}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
          </HStack>
        </Stack>
        <Stack spacing={6} placeSelf={"start"} pt={2}>
          <Stack w={"sm"}>
            <Field
              name="aQuienComunico"
              validate={(value) => validateField("text", value, "texto")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.aQuienComunico && touched.aQuienComunico}
                >
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    size="sm"
                    name="aQuienComunico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.aQuienComunico}
                    w={"2xs"}
                  />
                  <FormErrorMessage fontSize={"10px"} m={"unset"}>
                    {errors.aQuienComunico}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          <Stack placeSelf={"start"} w={"sm"}>
            <Field
              name="rtaObtenida"
              validate={(value) => validateField("text", value, "texto")}
            >
              {({ field }) => (
                <FormControl
                  isInvalid={errors.rtaObtenida && touched.rtaObtenida}
                >
                  <Input
                    {...field}
                    type="text"
                    placeholder="Respuesta obtenida"
                    size="sm"
                    name="rtaObtenida"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rtaObtenida}
                    w={"2xs"}
                  />
                  <FormErrorMessage fontSize={"10px"}>
                    {errors.rtaObtenida}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default PrimerPasoRegistro;
