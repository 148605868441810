import React, { useEffect, useState } from "react";
import {
  HStack,
  IconButton,
  Box,
  Heading,
  Text,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  crearRegistroEvento,
  editarRegistroEvento,
  getRegistroEvento,
} from "../../operaciones/helpers/api.helper";
import { getUsuarios } from "../../usuarios/helpers/api.helper";
import PrimerPasoRegistroFijo from "./PrimerPasoRegistroFijo";
import SegundoPasoRegistroFijo from "./SegundoPasoRegistroFijo";
import moment from "moment";
import { validateOnSubmit } from "../../../utils/Validaciones";

const CrearEditarRegistroFijo = (props) => {
  const { type, user } = props;
  const [loading, setLoading] = useState([]);
  const [textAccionesInmediatas, setTextAccionesInmediatas] = useState("");
  const [formValues, setFormValues] = useState({
    initialValues: {
      fechaRegistroEventos: "",
      equipoRegistroEventos: "",
      tipoRegistroEventos: "",
      valoracionRegistroEventos: "",
      detectoRegistroEventos: "",
      descripcionRegistroEventos: "",
      fotoRegistroEventos: "",
      masFotosRegistroEventos: false,
      otroRegistroEventos: false,
      otroDondeRegistroEventos: "",
      actoresRegistroEventos: false,
      actoresQuienRegistroEventos: "",
      informoRegistroEventos: false,
      accionesRegistroEventos: "",
      responsableAccionRegistroEventos: "",
      fechaAccionRegistroEventos: "",
      responsableRegistroEventos: "",
      locacionRegistroEvento: "",
      locacionRegistroEventoOtro: "",
      servicioReestRegistroEventos: false,
      aQuienComunico: "",
      rtaObtenida: "",
      rtaObtenidaCl: "",
    },
  });
  const [usuarios, setUsuarios] = useState([]);
  const [imgUpload, setImgUpload] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const steps = [{ title: "Detalles" }, { title: "Acciones inmediatas" }];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const idParam = useParams();

  const initData = async () => {
    setLoading(true);
    let res;
    res = await getUsuarios();

    if (res.status === 200) {
      setUsuarios(res.data);
    } else {
      toast({
        title: "Error",
        description: "Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (type === "editar") {
      res = await getRegistroEvento(idParam);

      if (res.status === 200) {
        setFormValues({
          initialValues: {
            fechaRegistroEventos: moment(
              res.data[0].fechaRegistroEventos
            ).format("YYYY-MM-DDTHH:mm"),
            equipoRegistroEventos: res.data[0].equipoRegistroEventos,
            tipoRegistroEventos: res.data[0].tipoRegistroEventos,
            valoracionRegistroEventos: res.data[0].valoracionRegistroEventos,
            detectoRegistroEventos:
              res.data[0].detectoRegistroEventos.idUsuario,
            descripcionRegistroEventos: res.data[0].descripcionRegistroEventos,
            fotoRegistroEventos: res.data[0].fotoRegistroEventos,
            masFotosRegistroEventos: res.data[0].masFotosRegistroEventos,
            otroRegistroEventos: res.data[0].otroRegistroEventos,
            otroDondeRegistroEventos: res.data[0].otroDondeRegistroEventos,
            actoresRegistroEventos: res.data[0].actoresRegistroEventos,
            actoresQuienRegistroEventos:
              res.data[0].actoresQuienRegistroEventos,
            informoRegistroEventos: res.data[0].informoRegistroEventos,
            accionesRegistroEventos: res.data[0].accionesRegistroEventos,
            responsableAccionRegistroEventos:
              res.data[0].responsableAccionRegistroEventos,
            fechaAccionRegistroEventos: moment(
              res.data[0].fechaAccionRegistroEventos
            ).format("YYYY-MM-DDTHH:mm"),
            responsableRegistroEventos:
              res.data[0].responsableRegistroEventos.idUsuario,
            locacionRegistroEvento: res.data[0].locacionRegistroEvento,
            locacionRegistroEventoOtro: res.data[0].locacionRegistroEventoOtro,
            servicioReestRegistroEventos:
              res.data[0].servicioReestRegistroEventos,
            aQuienComunico: res.data[0].aQuienComunico,
            rtaObtenida: res.data[0].rtaObtenida,
            rtaObtenidaCl: res.data[0].rtaObtenidaCl,
          },
        });
      } else {
        navigate(-1);
        toast({
          title: "Error",
          description: "Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Stack w="100%" h="100%" overflowY={"auto"}>
      <HStack pl={5} w="100%" bg="brand.fondos_secundario">
        <Box>
          <IconButton
            icon={<MdArrowBack />}
            variant="link"
            onClick={() => navigate(-1)}
            fontSize="24px"
          />
        </Box>
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          {type === "crear" && "Nuevo evento"}
          {type === "editar" && "Edicion de registro"}
        </Heading>
      </HStack>
      <HStack w="100%" px={3} justifyContent="space-between">
        <Stepper index={activeStep} colorScheme="orange">
          {steps.map((step) => (
            <Step key={step}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="1">
                <StepTitle>{step.title}</StepTitle>
              </Box>
            </Step>
          ))}
        </Stepper>
        <Text color="brand.azul_text" fontSize={14}>
          {activeStep + 1} de {steps.length} partes
        </Text>
      </HStack>

      {!loading && (
        <Formik
          initialValues={formValues.initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            if (!values.locacionRegistroEvento) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere locación",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            } else if (values.locacionRegistroEvento === "otro") {
              if (!values.locacionRegistroEventoOtro) {
                setLoading(false);
                return toast({
                  title: "Error",
                  description: "Se requiere locación",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }
            if (!values.tipoRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere tipo de evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.valoracionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere valoracion de evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.fechaRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere fecha de registro",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.descripcionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere descripcion del evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (
              !values.accionesRegistroEventos ||
              validateOnSubmit("textArea", values.accionesRegistroEventos)
            ) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere detalle acciones inmediatas",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (
              !values.responsableAccionRegistroEventos ||
              validateOnSubmit(
                "textArea",
                values.responsableAccionRegistroEventos
              )
            ) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere responsable",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.fechaAccionRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere hora del evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.detectoRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere quien detecto el evento",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (!values.responsableRegistroEventos) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Se requiere responsable",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }

            const datos =
              type === "editar"
                ? {
                    id: idParam.id,
                    fechaRegistroEventos: values.fechaRegistroEventos,
                    equipoRegistroEventos: values.equipoRegistroEventos,
                    tipoRegistroEventos: values.tipoRegistroEventos,
                    valoracionRegistroEventos: values.valoracionRegistroEventos,
                    detectoRegistroEventos: values.detectoRegistroEventos,
                    descripcionRegistroEventos:
                      values.descripcionRegistroEventos,
                    masFotosRegistroEventos: values.masFotosRegistroEventos,
                    otroRegistroEventos: values.otroRegistroEventos,
                    otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                    actoresRegistroEventos: values.actoresRegistroEventos,
                    actoresQuienRegistroEventos:
                      values.actoresQuienRegistroEventos,
                    informoRegistroEventos: values.informoRegistroEventos,
                    accionesRegistroEventos: values.accionesRegistroEventos,
                    responsableAccionRegistroEventos:
                      values.responsableAccionRegistroEventos,
                    fechaAccionRegistroEventos:
                      values.fechaAccionRegistroEventos,
                    responsableRegistroEventos:
                      values.responsableRegistroEventos,
                    locacionRegistroEvento: values.locacionRegistroEvento,
                  }
                : {
                    fechaRegistroEventos: values.fechaRegistroEventos,
                    equipoRegistroEventos: values.equipoRegistroEventos,
                    tipoRegistroEventos: values.tipoRegistroEventos,
                    valoracionRegistroEventos: values.valoracionRegistroEventos,
                    detectoRegistroEventos: values.detectoRegistroEventos,
                    descripcionRegistroEventos:
                      values.descripcionRegistroEventos,
                    masFotosRegistroEventos: values.masFotosRegistroEventos,
                    otroRegistroEventos: values.otroRegistroEventos,
                    otroDondeRegistroEventos: values.otroDondeRegistroEventos,
                    actoresRegistroEventos: values.actoresRegistroEventos,
                    actoresQuienRegistroEventos:
                      values.actoresQuienRegistroEventos,
                    informoRegistroEventos: values.informoRegistroEventos,
                    accionesRegistroEventos: values.accionesRegistroEventos,
                    responsableAccionRegistroEventos:
                      values.responsableAccionRegistroEventos,
                    fechaAccionRegistroEventos:
                      values.fechaAccionRegistroEventos,
                    responsableRegistroEventos:
                      values.responsableRegistroEventos,
                    locacionRegistroEvento: values.locacionRegistroEvento,
                  };
            let res;
            if (type === "editar") {
              if (imgUpload) {
                res = await editarRegistroEvento({
                  datos: datos,
                  fotoRegistroEventos: imgUpload,
                });
              } else {
                res = await editarRegistroEvento({
                  datos: datos,
                });
              }
            } else {
              if (imgUpload) {
                res = await crearRegistroEvento({
                  datos: datos,
                  fotoRegistroEventos: imgUpload,
                });
              } else {
                res = await crearRegistroEvento({
                  datos: datos,
                });
              }
            }

            if (res.status === 200) {
              navigate(-1);
              setLoading(false);
              toast({
                status: "success",
                isClosable: true,
                title: `Registro ${
                  type === "editar" ? "editado" : "creado"
                } correctamente`,
                duration: 3000,
              });
            } else if (res.status === 204) {
              navigate(-1);
              setLoading(false);
              toast({
                status: "success",
                isClosable: true,
                title: `Etapa ${
                  type === "editar" ? "editada" : "creada"
                } correctamente`,
                duration: 3000,
              });
              toast({
                status: "warning",
                isClosable: true,
                title: `No hay usuarios activos para notificar`,
                duration: 3000,
              });
            } else {
              setLoading(false);
              return toast({
                status: "error",
                isClosable: true,
                title: `Error al ${
                  type === "editar" ? "editar" : "crear"
                } registro`,
                duration: 3000,
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form id="FormularioRegistroEvento">
              <Stack w="100%" pb={3}>
                <Divider w="100%" colorScheme="blackAlpha" />
                <br />
                {activeStep === 0 && (
                  <PrimerPasoRegistroFijo
                    loading={loading}
                    setLoading={setLoading}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    type={type}
                    imgUpload={imgUpload}
                    setImgUpload={setImgUpload}
                    errors={errors}
                    touched={touched}
                  />
                )}
                {activeStep === 1 && (
                  <SegundoPasoRegistroFijo
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    values={values}
                    setTextAccionesInmediatas={setTextAccionesInmediatas}
                    textAccionesInmediatas={textAccionesInmediatas}
                    usuarios={usuarios}
                    formValues={formValues.initialValues}
                    user={user}
                    errors={errors}
                    touched={touched}
                  />
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Stack>
  );
};

export default CrearEditarRegistroFijo;
