import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Icon,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useDisclosure,
  Img,
  HStack,
  Tooltip,
  Text,
  List,
  ListItem,
  IconButton,
} from "@chakra-ui/react";
import { MdDehaze } from "react-icons/md";

const DrawlerNav = (props) => {
  const {
    btnRef,
    LogoExpandido,
    user,
    navigate,
    location,
    Cargas,
    MdOutlineViewAgenda,
    MdOutlineSettings,
    MdOutlinePersonAddAlt,
    MdOutlineLibraryBooks,
    MdOutlinePublic,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack>
      <IconButton
        icon={<MdDehaze />}
        boxSize={14}
        ref={btnRef}
        onClick={onOpen}
        variant={"ghost"}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Img src={LogoExpandido} />
          </DrawerHeader>
          <DrawerBody>
            <Accordion w="100%">
              {(user.idRol === "admin" ||
                user.idRol === "usuarioBrent" ||
                user.idRol === "coorOp" ||
                user.idRol === "gerencia" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "cliente" ||
                user.idRol === "userHS" ||
                user.idRol === "userFacturacion" ||
                user.idRol === "userOperaciones") && (
                <AccordionItem>
                  <AccordionButton
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    color={
                      location.pathname === "/dashboard" && "brand.naranja"
                    }
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                    borderLeftWidth={
                      location.pathname === "/dashboard" && "5px"
                    }
                    borderLeftColor={
                      location.pathname === "/dashboard" && "brand.naranja"
                    }
                  >
                    <Tooltip label={"Dashboard"}>
                      <HStack
                        spacing={3}
                        pl={location.pathname === "/dashboard" ? -1 : 1}
                      >
                        <Icon
                          as={MdOutlineViewAgenda}
                          color="gray.400"
                          fontSize={"24px"}
                          transform="rotate(90deg)"
                        />
                        <Text>Dashboard</Text>
                      </HStack>
                    </Tooltip>
                  </AccordionButton>
                </AccordionItem>
              )}
              {(user.idRol === "admin" ||
                user.idRol === "usuarioBrent" ||
                user.idRol === "coorOp" ||
                user.idRol === "userHS" ||
                user.idRol === "gerencia" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "userFacturacion" ||
                user.idRol === "userOperaciones") && (
                <AccordionItem>
                  <AccordionButton
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                  >
                    <HStack spacing={2} pl={1}>
                      <Tooltip label={"Operaciones"}>
                        <Icon as={Cargas} boxSize={8} ml={"-5px"} />
                      </Tooltip>
                      <Text>Operaciones</Text>
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel>
                    <List spacing={1}>
                      <ListItem
                        _hover={{ color: "brand.naranja" }}
                        color={
                          location.pathname === "/operaciones/planificacion" &&
                          "brand.naranja"
                        }
                        onClick={() => {
                          navigate("/operaciones/planificacion");
                        }}
                      >
                        Planificaciones
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/operaciones/curso" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/operaciones/curso");
                        }}
                      >
                        En curso
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/operaciones/historial" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/operaciones/historial");
                        }}
                      >
                        Historial
                      </ListItem>
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {(user.idRol === "admin" ||
                user.idRol === "coorOp" ||
                user.idRol === "userOperaciones" ||
                user.idRol === "userMantenimiento") && (
                <AccordionItem>
                  <AccordionButton
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                  >
                    <Tooltip label={"Inventario"}>
                      <HStack spacing={3} pl={1}>
                        <Icon
                          as={MdOutlineSettings}
                          color="gray.400"
                          boxSize={6}
                        />
                        <Text>Inventario</Text>
                      </HStack>
                    </Tooltip>
                  </AccordionButton>
                  <AccordionPanel>
                    <List spacing={1}>
                      <ListItem
                        color={
                          location.pathname === "/inventario/clientes" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/clientes");
                        }}
                      >
                        Clientes
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/locacion" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/locacion");
                        }}
                      >
                        Pad/Locación
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/planta" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/planta");
                        }}
                      >
                        Plantas
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/transportista" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/transportista");
                        }}
                      >
                        Empresas Transportistas
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/camiones" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/camiones");
                        }}
                      >
                        Camiones
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/autoelevadores" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/autoelevadores");
                        }}
                      >
                        Autoelevadores
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/sander" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/sander");
                        }}
                      >
                        Sander
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/sandcubes" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/sandcubes");
                        }}
                      >
                        Sandcubes
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/inventario/sandvan" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/inventario/sandvan");
                        }}
                      >
                        SandVan
                      </ListItem>
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {(user.idRol === "admin" || user.idRol === "usuarioIT") && (
                <AccordionItem>
                  <AccordionButton
                    onClick={() => {
                      navigate("/usuarios");
                    }}
                    color={location.pathname === "/usuarios" && "brand.naranja"}
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                  >
                    <Tooltip label={"Usuarios"}>
                      <HStack
                        spacing={3}
                        pl={location.pathname === "/usuarios" ? -1 : 1}
                      >
                        <Icon
                          as={MdOutlinePersonAddAlt}
                          color="gray.400"
                          boxSize={6}
                        />
                        <Text>Usuarios</Text>
                      </HStack>
                    </Tooltip>
                  </AccordionButton>
                </AccordionItem>
              )}
              {(user.idRol === "admin" ||
                user.idRol === "usuarioBrent" ||
                user.idRol === "coorOp" ||
                user.idRol === "gerencia" ||
                user.idRol === "userHS" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "userFacturacion" ||
                user.idRol === "userOperaciones") && (
                <AccordionItem>
                  <AccordionButton
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                  >
                    <Tooltip label={"Reportes"}>
                      <HStack spacing={3} pl={1}>
                        <Icon
                          as={MdOutlineLibraryBooks}
                          color="gray.400"
                          boxSize={6}
                        />
                        <Text>Reportes</Text>
                      </HStack>
                    </Tooltip>
                  </AccordionButton>

                  <AccordionPanel>
                    <List spacing={1}>
                      <ListItem
                        color={
                          location.pathname === "/reportes/reportetapas" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/reportes/reportetapas");
                        }}
                      >
                        Reporte de etapas
                      </ListItem>
                      <ListItem
                        color={
                          location.pathname === "/reportes/reporte" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/reportes/reporte");
                        }}
                      >
                        Reporte de viajes
                      </ListItem>
                      {(user.idRol === "admin" ||
                        user.idRol === "coorOp" ||
                        user.idRol === "gerencia" ||
                        user.idRol === "userMantenimiento" ||
                        user.idRol === "userOperaciones") && (
                        <ListItem
                          color={
                            location.pathname === "/reportes/revision" &&
                            "brand.naranja"
                          }
                          _hover={{ color: "brand.naranja" }}
                          onClick={() => {
                            navigate("/reportes/revision");
                          }}
                        >
                          Revisión entre turnos
                        </ListItem>
                      )}
                      {(user.idRol === "admin" ||
                        user.idRol === "coorOp" ||
                        user.idRol === "gerencia" ||
                        user.idRol === "userMantenimiento" ||
                        user.idRol === "userHS" ||
                        user.idRol === "userOperaciones") && (
                        <ListItem
                          color={
                            location.pathname === "/reportes/registro" &&
                            "brand.naranja"
                          }
                          _hover={{ color: "brand.naranja" }}
                          onClick={() => {
                            navigate("/reportes/registro");
                          }}
                        >
                          Registro de eventos
                        </ListItem>
                      )}
                      {/*    {(user.idRol === "admin" ||
                        user.idRol === "coorOp" ||
                        user.idRol === "userFacturacion" ||
                        user.idRol === "userHS" ||
                        user.idRol === "gerencia") && (
                        <ListItem
                          color={
                            location.pathname === "/reportes/certificacion" &&
                            "brand.naranja"
                          }
                          _hover={{ color: "brand.naranja" }}
                          onClick={() => {
                            navigate("/reportes/certificacion");
                            
                          }}
                        >
                          Certificación camiones
                        </ListItem>
                      )} */}
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              )}
              {(user.idRol === "admin" ||
                user.idRol === "usuarioBrent" ||
                user.idRol === "coorOp" ||
                user.idRol === "gerencia" ||
                user.idRol === "userHS" ||
                user.idRol === "userMantenimiento" ||
                user.idRol === "cliente" ||
                user.idRol === "userFacturacion" ||
                user.idRol === "userOperaciones") && (
                <AccordionItem>
                  <AccordionButton
                    _expanded={{
                      borderLeftWidth: "5px",
                      borderLeftColor: "brand.naranja",
                    }}
                    color={
                      location.pathname === "/geocercas" && "brand.naranja"
                    }
                    borderLeftWidth={
                      location.pathname === "/geocercas" && "5px"
                    }
                    borderLeftColor={
                      location.pathname === "/geocercas" && "brand.naranja"
                    }
                  >
                    <Tooltip label={"Mapas"}>
                      <HStack spacing={3} pl={1}>
                        <Icon
                          as={MdOutlinePublic}
                          color="gray.400"
                          boxSize={6}
                        />
                        <Text>Mapas</Text>
                      </HStack>
                    </Tooltip>
                  </AccordionButton>
                  <AccordionPanel>
                    <List spacing={1}>
                      {(user.idRol === "admin" || user.idRol === "coorOp") && (
                        <ListItem
                          color={
                            location.pathname === "/geocercas" &&
                            "brand.naranja"
                          }
                          _hover={{ color: "brand.naranja" }}
                          onClick={() => {
                            navigate("/geocercas");
                          }}
                        >
                          Geocercas
                        </ListItem>
                      )}
                      <ListItem
                        color={
                          location.pathname === "/mapaCamiones" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/mapaCamiones");
                        }}
                      >
                        Mapa de camiones
                      </ListItem>
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};

export default DrawlerNav;
