import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Badge,
  Select,
  IconButton,
  Tooltip,
  useToast,
  Flex,
  Text,
} from "@chakra-ui/react";
import { MdEditNote } from "react-icons/md";
import { editarSandcube } from "../../helpers/api.helper";
import { crearSandcubesPlanificacion } from "../../../operaciones/helpers/api.helper";
import { SmallCloseIcon } from "@chakra-ui/icons";

const EdicionMultiple = (props) => {
  const {
    selectedItems,
    planiActivas,
    initData,
    setSelectedItems,
    setSelectAll,
    user,
    handleChange,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nuevoEstado, setNuevoEstado] = useState();
  const [nuevoPAD, setNuevoPAD] = useState();
  const [nuevaMalla, setNuevaMalla] = useState();
  const [nuevaCondicion, setNuevaCondicion] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setLoading(true);
    let plan;
    let dataII;

    let scs = selectedItems.map((scs) => ({ idSandCube: scs.idSandCube }));

    if (nuevoPAD) {
      plan = planiActivas.find((e) => e.idPadlocacion === nuevoPAD);
      dataII = {
        idPlanificacion: plan.idPlanificacion,
        sandcubes: scs,
      };
    }

    let data = selectedItems.map((scs) => ({
      idPlanta: scs.idPlanta,
      idSandCube: scs.idSandCube,
      malla: nuevaMalla ? nuevaMalla : scs.malla,
      nombrePlanta: scs.nombrePlanta,
      nombreSandCube: scs.nombreSandCube,
      padLocacion: nuevoPAD ? nuevoPAD : scs.padLocacion,
      porcentajeSandCube: scs.porcentajeSandCube,
      statusSandCube: nuevoEstado ? nuevoEstado : scs.statusSandCube,
      condicion: nuevaCondicion ? nuevaCondicion : scs.condicion,
      tipoArena: scs.tipoArena,
      toneladasSandCube: scs.toneladasSandCube,
      ubicacionSandCube: scs.ubicacionSandCube,
      usosPAD: scs.usosPAD,
      usosTotales: scs.usosTotales,
      wetsandSC: scs.wetsandSC,
    }));

    if (nuevoPAD) {
      await crearSandcubesPlanificacion(dataII);
    }
    let res = await editarSandcube({ sandCubes: data });
    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Sandcubes editados correctamente",
        duration: 3000,
      });

      onClose();
      initData();
      setSelectedItems([]);
    } else {
      setLoading(false);
      return toast({
        status: "error",
        duration: 3000,
        isClosable: true,
        title: "Error al editar los sandcubes",
        description: res.data.error.message || "Error al editar sandcube",
      });
    }
    setSelectAll(false);
    setLoading(false);
  };

  return (
    <div>
      <Tooltip label="Editar Multiple">
        <IconButton
          onClick={onOpen}
          icon={<MdEditNote fontSize="25px" />}
          variant={"link"}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack
              maxW={"100%"}
              maxH={"200px"}
              overflowY={"auto"}
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(90px, 1fr))"
              gridGap="10px"
            >
              {selectedItems.map((sandcube, index) => (
                <Badge
                  key={index}
                  colorScheme="orange"
                  variant="solid"
                  fontSize="0.8rem"
                  borderRadius={"10px"}
                  p={1}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                  >
                    <Text>{sandcube.nombreSandCube}</Text>

                    <IconButton
                      icon={<SmallCloseIcon />}
                      variant={"link"}
                      size={"1rem"}
                      color={"white"}
                      onClick={() => handleChange(sandcube)}
                    />
                  </Flex>
                </Badge>
              ))}
            </HStack>
          </ModalHeader>

          <ModalBody>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Propiedad</Th>
                    <Th></Th>
                    <Th></Th>
                    <Th>Valor</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td colSpan={2}>STATUS</Td>
                    <Td></Td>

                    <Td>
                      <Select
                        size={"sm"}
                        placeholder="- Estado -"
                        onChange={(e) => setNuevoEstado(e.target.value)}
                      >
                        <option value={"Lleno"}>Lleno</option>
                        <option value={"Vacio"}>Vacio</option>
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>SET</Td>
                    <Td></Td>
                    <Td>
                      <Select
                        size={"sm"}
                        placeholder="- Planificacion -"
                        onChange={(e) => setNuevoPAD(e.target.value)}
                      >
                        {planiActivas.map((plan) => (
                          <option
                            key={plan.idPadlocacion}
                            value={plan.idPadlocacion}
                          >
                            {plan.idPadlocacion}
                          </option>
                        ))}
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>Condicion</Td>
                    <Td></Td>
                    <Td>
                      <Select
                        size={"sm"}
                        placeholder="- Condicion -"
                        onChange={(e) => setNuevaCondicion(e.target.value)}
                      >
                        <option value={"activo"}>Activo</option>
                        <option value={"inactivo"}>Inactivo</option>
                      </Select>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>MALLA</Td>
                    <Td></Td>

                    <Td>
                      <Select
                        placeholder="- Malla -"
                        size={"sm"}
                        onChange={(e) => setNuevaMalla(e.target.value)}
                      >
                        <option value={"100"}>100</option>
                        <option value={"30/70"}>30/70</option>
                        <option value={"70/140"}>70/140</option>
                        <option value={"50/70"}>50/70</option>
                        <option value={"40/80"}>40/80</option>
                        <option value={"40/70"}>40/70</option>
                        <option value={"30/50"}>30/50</option>
                        <option value={"20/40"}>20/40</option>
                        <option value={"Otros"}>Otros</option>
                      </Select>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter justifyContent={"space-between"}>
            <Button colorScheme="red" variant="ghost" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button
              bgColor={"brand.naranja"}
              color={"white"}
              onClick={handleSubmit}
              isDisabled={loading || user.idRol === "usuarioIT"}
            >
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EdicionMultiple;
