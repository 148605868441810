import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { TbCreativeCommonsSa } from "react-icons/tb";

import { useNavigate } from "react-router-dom";

const BtnReActivar = (props) => {
  const navigate = useNavigate();
  const { seleccionado, user } = props;

  const handleReactivar = async () => {
    navigate(
      `/operaciones/planificacion/reActivar/${seleccionado.idPlanificacion}`
    );
  };
  return (
    <Box>
      <Button
        color="green"
        variant="link"
        size="sm"
        leftIcon={<TbCreativeCommonsSa />}
        onClick={() => handleReactivar()}
        isDisabled={user.idRol === "usuarioIT"}
      >
        Re-Activar
      </Button>
    </Box>
  );
};

export default BtnReActivar;
