import React from "react";
import { MdOutlineCreate } from "react-icons/md";
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import CrearEditarEtapas from "./CrearEditarEtapas";

const EditarEtapa = (props) => {
  const { seleccionado, initData, user } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Editar Etapa">
        <IconButton
          onClick={onOpen}
          icon={<MdOutlineCreate fontSize="20px" />}
          letterSpacing={1}
          size="sm"
          isDisabled={user.idRol === "usuarioIT"}
          variant={"link"}
          _hover={{
            color: "brand.naranja",
          }}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton bg="brand.fondo" />
          <ModalBody bg="brand.fondo">
            <CrearEditarEtapas
              type="editar"
              seleccionado={seleccionado}
              initTablaEtapas={initData}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditarEtapa;
