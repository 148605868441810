import React, { useState } from "react";
import {
  HStack,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Input,
  Button,
  Switch,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Field } from "formik";
import { MdArrowDropDown, MdNavigateNext } from "react-icons/md";
import { handleUsuarioIT } from "../../../../../utils/UsuarioIT";
import {
  validateField,
  validateOnSubmit,
} from "../../../../../utils/Validaciones";

const PrimerPasoEtapa = (props) => {
  const {
    planificacion,
    etapasDatos,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
    activeStep,
    setActiveStep,
    usuarios,
    setFormValues,
    user,
    touched,
    errors,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleEtapaPozo = (idPozoEtapa, setFieldValue) => {
    let etapasPozo;
    if (etapasDatos.length > 0) {
      etapasPozo = etapasDatos.find((e) => e.nombrePozo === idPozoEtapa);
    }

    if (etapasPozo) {
      setFieldValue("numPozoEtapaSC", etapasPozo.MaxNumTotalEtapaSC + 1);
      setFieldValue("idPozoEtapa", idPozoEtapa);
    } else {
      setFieldValue("numPozoEtapaSC", 1);
      setFieldValue("idPozoEtapa", idPozoEtapa);
    }
  };
  const toast = useToast();

  const handleAvanzar = () => {
    setLoading(true);
    if (!values.idPozoEtapa) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "ID de pozo requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (
      !values.numPozoEtapaSC ||
      validateOnSubmit("number", values.numPozoEtapaSC)
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Número de etapa por pozo esta vacio o es incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (
      !values.numTotalEtapaSC ||
      validateOnSubmit("number", values.numTotalEtapaSC)
    ) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Número de etapa total esta vacio o es incorrecto",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.fechaInicioEtapaSC) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Fecha inicial requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.supervisor) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Supervisor requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.maquinista) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Maquinista requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.operador1) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Operadores requeridos",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.idSander) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Sander requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else if (!values.idAutoE) {
      setLoading(false);
      return toast({
        title: "Error",
        description: "Autoelevador requerido",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    } else {
      setFormValues({
        initialValues: {
          ...values,
          idPozoEtapa: values.idPozoEtapa,
          fechaInicioEtapaSC: values.fechaInicioEtapaSC,
          fechaFinalEtapaSC: values.fechaFinalEtapaSC,
          numPozoEtapaSC: values.numPozoEtapaSC,
          numTotalEtapaSC: values.numTotalEtapaSC,
          supervisor: values.supervisor,
          operador1: values.operador1,
          operador2: values.operador2,
          maquinista: values.maquinista,
          idSander: values.idSander,
          idAutoE: values.idAutoE,
          etapaWeTSanD: values.etapaWeTSanD,
          limpieza: values.limpieza,
        },
      });
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Stack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="xs" isRequired placeSelf={"start"}>
          <FormLabel fontWeight="semibold">ID Pozo</FormLabel>
          <Select
            placeholder="Seleccione pozo"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="idPozoEtapa"
            onBlur={handleBlur}
            onChange={(e) => handleEtapaPozo(e.target.value, setFieldValue)}
            value={values.idPozoEtapa}
          >
            {planificacion.pozos.map((pozo) => (
              <option key={pozo.idPozo} value={pozo.nombrePozo}>
                {pozo.nombrePozo}
              </option>
            ))}
          </Select>
        </FormControl>
        <Stack placeSelf={"start"}>
          <Field
            name="numPozoEtapaSC"
            validate={(value) =>
              validateField("number", value, "Nro. etapa pozo")
            }
          >
            {({ field }) => (
              <FormControl
                isInvalid={errors.numPozoEtapaSC && touched.numPozoEtapaSC}
                w={"xs"}
                isRequired
              >
                <FormLabel fontWeight="semibold">Nro. Etapa (pozo)</FormLabel>
                <Input
                  {...field}
                  w="2xs"
                  name="numPozoEtapaSC"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={!values.limpieza ? values.numPozoEtapaSC : 0}
                />
                <FormErrorMessage>{errors.numPozoEtapaSC}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack placeSelf={"start"}>
          <Field
            name="numTotalEtapaSC"
            validate={(value) =>
              validateField("number", value, "Nro. etapa pozo")
            }
          >
            {({ field }) => (
              <FormControl
                isInvalid={errors.numTotalEtapaSC && touched.numTotalEtapaSC}
                w={"xs"}
                isRequired
              >
                <FormLabel fontWeight="semibold">Nro. Etapa (total)</FormLabel>
                <Input
                  {...field}
                  w="2xs"
                  name="numTotalEtapaSC"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={!values.limpieza ? values.numTotalEtapaSC : 0}
                />
                <FormErrorMessage>{errors.numTotalEtapaSC}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Fecha y Hora de Inicio</FormLabel>
          <Input
            w="2xs"
            type="datetime-local"
            name="fechaInicioEtapaSC"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.fechaInicioEtapaSC}
          />
        </FormControl>
        <FormControl w="xs">
          {planificacion.dosificadora && (
            <>
              <FormLabel fontWeight="semibold">Wetsand</FormLabel>
              <HStack spacing={5}>
                <Switch
                  onChange={handleChange}
                  isChecked={values.etapaWeTSanD}
                  name="etapaWeTSanD"
                  size={"lg"}
                />
              </HStack>
            </>
          )}
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Etapa limpieza</FormLabel>
          <HStack spacing={5}>
            <Switch
              onChange={handleChange}
              isChecked={values.limpieza}
              name="limpieza"
              size={"lg"}
            />
          </HStack>
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={3}>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Supervisor</FormLabel>
          <Select
            placeholder="Seleccione supervisor"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="supervisor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.supervisor}
          >
            {usuarios
              .filter((e) => e.puesto === "supervisor")
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((user) => (
                <option key={user.codigoUsuario} value={user.nombre}>
                  {user.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Operador</FormLabel>
          <Select
            placeholder="Seleccione operador"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="operador1"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.operador1}
          >
            {usuarios
              .filter((e) => e.puesto === "operador")
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((user) => (
                <option key={user.codigoUsuario} value={user.nombre}>
                  {user.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="xs">
          <FormLabel fontWeight="semibold">Operador</FormLabel>
          <Select
            placeholder="Seleccione operador"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="operador2"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.operador2}
          >
            {usuarios
              .filter((e) => e.puesto === "operador")
              .filter((e) => e.username !== values.operador1)
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((user) => (
                <option key={user.codigoUsuario} value={user.nombre}>
                  {user.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
      </HStack>
      <HStack justifyContent="space-around" pb={7}>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Maquinista</FormLabel>
          <Select
            placeholder="Seleccione maquinista"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="maquinista"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.maquinista}
          >
            {usuarios
              .filter(
                (e) => e.puesto === "maquinista" || e.puesto === "operador"
              )
              .sort((a, b) => {
                // Ordenar alfabéticamente por nombre y apellido
                const nameA = `${a.nombre}`.toUpperCase();
                const nameB = `${b.nombre}`.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((user) => (
                <option key={user.codigoUsuario} value={user.nombre}>
                  {user.nombre}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Sander</FormLabel>
          <Select
            placeholder="Seleccione sander"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="idSander"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.idSander}
          >
            {planificacion.sanderPrincipal && (
              <option value={planificacion.sanderPrincipal.idSander}>
                {planificacion.sanderPrincipal.codigoSander}
              </option>
            )}
            {planificacion.sanderBackup && (
              <option value={planificacion.sanderBackup.idSander}>
                {planificacion.sanderBackup.codigoSander}
              </option>
            )}
          </Select>
        </FormControl>
        <FormControl w="xs" isRequired>
          <FormLabel fontWeight="semibold">Autoelevador</FormLabel>
          <Select
            placeholder="Seleccione autoelevador"
            icon={<MdArrowDropDown />}
            w="2xs"
            type="text"
            name="idAutoE"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.idAutoE}
          >
            {planificacion.autoelevador1 && (
              <option value={planificacion.autoelevador1.idAutoE}>
                {planificacion.autoelevador1.nombreAutoE}
              </option>
            )}
            {planificacion.autoelevador2 && (
              <option value={planificacion.autoelevador2.idAutoE}>
                {planificacion.autoelevador2.nombreAutoE}
              </option>
            )}
          </Select>
        </FormControl>
      </HStack>
      <Box placeSelf="end" px={5}>
        <Button
          fontSize={12}
          fontWeight="semibold"
          bg="brand.naranja"
          color="white"
          rightIcon={<MdNavigateNext fontSize="20px" />}
          variant="solid"
          form="FormularioEtapa"
          onClick={() =>
            user.idRol === "usuarioIT"
              ? handleUsuarioIT(setActiveStep, activeStep)
              : handleAvanzar()
          }
          isDisabled={loading}
          _hover={{ bg: "white", color: "brand.naranja" }}
        >
          Continuar
        </Button>
      </Box>
    </Stack>
  );
};

export default PrimerPasoEtapa;
