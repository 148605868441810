import React, { useState } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { eliminarEtapa } from "../../../helpers/api.helper";
import { MdOutlineDelete } from "react-icons/md";

const EliminarEtapa = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const { seleccionado, initData, user } = props;

  const toast = useToast();

  const handleEliminarEtapa = async () => {
    setLoading(true);
    let res = await eliminarEtapa({
      idEtapaSC: seleccionado.idEtapaSC,
    });
    if (res.status === 200) {
      toast({
        status: "success",
        isClosable: true,
        title: "Etapa eliminada correctamente",
        duration: 3000,
      });
      initData();
      onClose();
    } else {
      toast({
        title: "Error al eliminar Etapa",
        description: res.data,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip label="Eliminar Etapa">
        <IconButton
          variant="link"
          icon={<MdOutlineDelete fontSize="20px" />}
          size="sm"
          onClick={onOpen}
          _hover={{ bg: "brand.naranja", color: "white" }}
        />
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="md"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              color="brand.gris_primario"
              fontSize="lg"
              fontWeight="bold"
            >
              Eliminar Etapa
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro que desea eliminar la Etapa número{" "}
              {seleccionado.numPozoEtapaSC} del{" "}
              {seleccionado.pozo?.nombrePozo || ""} ?
              <br />
              Esta acción no se puede revertir.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} variant="link" onClick={onClose}>
                Cancelar
              </Button>
              <Button
                bg="brand.naranja"
                onClick={handleEliminarEtapa}
                ml={3}
                isDisabled={loading || user.idRol === "usuarioIT"}
                color="white"
              >
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EliminarEtapa;
