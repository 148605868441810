import React, { useState, useEffect } from "react";
import {
  Stack,
  HStack,
  FormControl,
  Text,
  Input,
  Heading,
  Select,
  Radio,
  RadioGroup,
  Box,
  Button,
  IconButton,
  Switch,
  useToast,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdArrowDropDown,
  MdFileDownloadDone,
  MdArrowBack,
  MdOutlineAdd,
  MdDelete,
} from "react-icons/md";
import {
  getNotificaciones,
  editarUsuariosNotificaciones,
  getHabilitados,
  putFiltroRol,
  // getFiltroRol,
  deleteFiltroRol,
} from "../../helpers/api.helper";
import { getUsuarios } from "../../../usuarios/helpers/api.helper";
import FormModalUsuarios from "../FormModalUsuarios";

const FormCrearEditarNotificacion = (props) => {
  const { user } = props;
  const { id } = useParams();
  const idTipoE = parseInt(id);
  const navigate = useNavigate();
  const toast = useToast();
  const [editar, setEditar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosHabilitados, setUsuariosHabilitados] = useState([]);
  const [usuariosOriginales, setUsuariosOriginales] = useState([]);
  // const [filtroRol, setFiltroRol] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [usuariosEditados, setUsuariosEditados] = useState([]);
  const removidos = [];

  const initData = async () => {
    setLoading(true);
    try {
      const [
        usuariosRes,
        notificacionesRes,
        habilitadosRes /* filtroRolRes */,
      ] = await Promise.all([
        getUsuarios(),
        getNotificaciones({ id: idTipoE }),
        getHabilitados({ id: idTipoE }),
        // getFiltroRol({ id: idTipoE }),
      ]);

      if (usuariosRes.status === 200) {
        setUsuarios(usuariosRes.data);
      }

      if (notificacionesRes.status === 200) {
        setEditar(notificacionesRes.data[0]);
      }

      if (habilitadosRes.status === 200) {
        setUsuariosHabilitados(habilitadosRes.data);
      }

      // if (filtroRolRes.status === 200) {
      //   setFiltroRol(filtroRolRes.data);
      // }
    } catch (error) {
      console.error("Error al obtener datos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (usuario) => {
    const usuarioHabilitadoIndex = usuariosHabilitados?.findIndex(
      (u) => u.idUsuario === usuario.idUsuario
    );
    const usuarioEditadoIndex = usuariosEditados?.findIndex(
      (u) => u.idUsuario === usuario.idUsuario
    );

    if (usuarioHabilitadoIndex !== -1) {
      const nuevosUsuariosHabilitados = [...usuariosHabilitados];
      nuevosUsuariosHabilitados[usuarioHabilitadoIndex] = usuario;
      setUsuariosHabilitados(nuevosUsuariosHabilitados);
    } else {
      setUsuariosHabilitados([...usuariosHabilitados, usuario]);
    }

    if (usuarioEditadoIndex !== -1) {
      const nuevosUsuariosEditados = [...usuariosEditados];
      nuevosUsuariosEditados[usuarioEditadoIndex] = usuario;
      setUsuariosEditados(nuevosUsuariosEditados);
    } else {
      setUsuariosEditados([...usuariosEditados, usuario]);
    }
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleRecipients = () => {
    let x;

    if (editar.rolUsuarios) {
      x = editar?.rolUsuarios.map(
        ({
          idFNotificaciones,
          rolUsuario,
          telegram,
          email,
          sandTrucker,
          idTipoE,
        }) => ({
          idFNotificaciones,
          rolUsuario,
          telegram,
          email,
          sandTrucker,
          idTipoE,
        })
      );
    }

    return x;
  };

  const closeModal = () => {
    // Restaurar la información original si se cancela
    setUsuariosHabilitados(usuariosOriginales);
    // Cerrar el modal
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    setUsuariosOriginales([...usuariosHabilitados]);
  }, [isModalOpen]);

  const initialValues = {
    nombreTipoE: editar.nombreTipoE,
    criticidadTipoE: editar.criticidadTipoE,
    destinatarios: handleRecipients(),
  };
  return (
    <Stack
      bg="brand.fondo"
      w="100%"
      h="auto"
      borderRadius={10}
      spacing={7}
      p={3}
    >
      <HStack w="100%" px={3}>
        <IconButton
          icon={<MdArrowBack />}
          variant="link"
          onClick={() => navigate("/administrarNotificaciones")}
          fontSize="24px"
        />
        <Heading fontWeight="medium" py={3}>
          Editar configuración
        </Heading>
      </HStack>
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);

            if (!values.destinatarios[0].rolUsuario) {
              setLoading(false);
              return toast({
                title: "Error",
                description: "Al menos un Rol es requerido",
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }

            try {
              let data = values.destinatarios.map((elemento) => ({
                idFNotificaciones: elemento?.idFNotificaciones || null,
                rolUsuario: elemento.rolUsuario,
                sandTrucker: elemento.sandTrucker,
                email: elemento.email,
                telegram: elemento.telegram,
                filtroOff: elemento.filtroOff,
              }));
              const rolesPresentes = values.destinatarios.map(
                (rol) => rol.rolUsuario
              );

              const usuariosFiltrados = usuariosEditados.filter((usuario) =>
                rolesPresentes.includes(usuario.rolUser)
              );
              let x = usuariosFiltrados.filter(
                (usuario) => usuario.idTipoE === editar.idTipoE
              );
              let putUsuarios = x.map((elemento) => ({
                idUsuario: elemento.idUsuario,
                idTipoE: editar.idTipoE,
                filtroOff: elemento.filtroOff,
              }));
              if (removidos.length > 0) {
                await deleteFiltroRol({
                  roles: removidos,
                });
              }
              const resFiltro = await putFiltroRol({
                roles: data,
                idTipoE: editar.idTipoE,
              });
              const resUser = await editarUsuariosNotificaciones({
                notifications: putUsuarios,
              });

              if (resFiltro.status === 200 && resUser.status === 200) {
                toast({
                  status: "success",
                  isClosable: true,
                  title: `Configuración editada correctamente`,
                  duration: 3000,
                });
                navigate(-1);
              } else {
                throw new Error("Error al editar configuración");
              }
            } catch (error) {
              console.error(error);
              toast({
                status: "error",
                isClosable: true,
                title: `Error al editar configuración`,
                duration: 3000,
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form>
              <Stack w="100%" justifyContent="start" spacing={3}>
                <HStack pb={3} px={3} spacing={5}>
                  <FormControl>
                    <FormLabel>Evento</FormLabel>
                    <Input
                      icon={<MdArrowDropDown />}
                      size="md"
                      name={"nombreTipoE"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly
                      value={`${values.nombreTipoE} ${
                        editar.criticidadTipoE && `- ${editar.criticidadTipoE}`
                      } ${editar.claseTipoE && `- ${editar.claseTipoE}`}`}
                    />
                  </FormControl>

                  <FormControl w="xs">
                    {values.nombreTipoE === "Registro Evento" && (
                      <>
                        <FormLabel>Criticidad</FormLabel>
                        <RadioGroup
                          name="criticidadTipoE"
                          value={values.criticidadTipoE}
                        >
                          <Stack direction="row">
                            <Radio
                              isReadOnly
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.criticidadTipoE}
                            >
                              {values.criticidadTipoE}
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </>
                    )}
                  </FormControl>
                </HStack>
                <FieldArray name="destinatarios">
                  {({ push, remove }) => (
                    <Stack>
                      <Stack>
                        <HStack pb={3} px={3} spacing={5}>
                          <Stack>
                            {values.destinatarios &&
                              values.destinatarios.map(
                                (destinatario, index) => (
                                  <HStack py={2} key={index}>
                                    <Select
                                      icon={<MdArrowDropDown />}
                                      placeholder="Seleccione un rol"
                                      w="xs"
                                      size="sm"
                                      onChange={handleChange}
                                      name={`destinatarios[${index}].rolUsuario`}
                                      onBlur={handleBlur}
                                      value={
                                        values.destinatarios[index].rolUsuario
                                      }
                                    >
                                      <option value={"admin"}>Admin</option>
                                      <option value={"cliente"}>Cliente</option>
                                      <option value={"usuarioBrent"}>
                                        Usuario BRENT
                                      </option>
                                      <option value={"coorOp"}>
                                        Coordinador de Operaciones
                                      </option>
                                      <option value={"userOperaciones"}>
                                        Operaciones
                                      </option>

                                      <option value={"userFacturacion"}>
                                        Facturación
                                      </option>
                                      <option value={"userHS"}>
                                        Usuario de HyS
                                      </option>
                                      <option value={"gerencia"}>
                                        Gerencia
                                      </option>
                                      <option value={"userMantenimiento"}>
                                        Mantenimiento
                                      </option>
                                      <option value={"usuarioIT"}>
                                        Usuario IT
                                      </option>
                                    </Select>
                                    <HStack>
                                      <Switch
                                        onChange={handleChange}
                                        isChecked={
                                          values.destinatarios[index].email
                                        }
                                        name={`destinatarios[${index}].email`}
                                      />
                                      <Text>Email</Text>
                                    </HStack>
                                    <HStack>
                                      <Switch
                                        onChange={handleChange}
                                        isChecked={
                                          values.destinatarios[index].telegram
                                        }
                                        name={`destinatarios[${index}].telegram`}
                                      />
                                      <Text>Telegram</Text>
                                    </HStack>
                                    <HStack>
                                      <Switch
                                        onChange={handleChange}
                                        isChecked={
                                          values.destinatarios[index]
                                            .sandTrucker
                                        }
                                        name={`destinatarios[${index}].sandTrucker`}
                                      />
                                      <Text>SandPro</Text>
                                    </HStack>
                                    <Tooltip label="Remover rol">
                                      <IconButton
                                        variant="link"
                                        size="lg"
                                        icon={
                                          <MdDelete size={18} color={"red"} />
                                        }
                                        onClick={() => {
                                          remove(index);
                                          removidos.push({
                                            ...destinatario,
                                            filtroOff: false,
                                          });
                                        }}
                                        isDisabled={
                                          values.destinatarios.length <= 1
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip label="Lista de usuarios">
                                      <Button
                                        variant={"link"}
                                        onClick={() => openModal(destinatario)}
                                      >
                                        Lista
                                      </Button>
                                    </Tooltip>
                                  </HStack>
                                )
                              )}
                          </Stack>
                        </HStack>
                      </Stack>
                      <Box px={5}>
                        <Button
                          placeSelf={"start"}
                          fontSize="12px"
                          variant="link"
                          size="lg"
                          leftIcon={
                            <MdOutlineAdd size={18} color={"#FF8200"} />
                          }
                          onClick={() => {
                            push({
                              rolUsuario: "",
                              telegram: false,
                              email: false,
                              sandTrucker: false,
                            });
                          }}
                        >
                          Agregar Rol
                        </Button>
                      </Box>
                    </Stack>
                  )}
                </FieldArray>
                <Button
                  placeSelf="end"
                  rightIcon={<MdFileDownloadDone />}
                  bg="brand.naranja"
                  form="formAutoelevador"
                  isDisabled={loading || user.idRol === "usuarioIT"}
                  color="white"
                  size="md"
                  onClick={handleSubmit}
                >
                  Finalizar
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
      {selectedItem && (
        <FormModalUsuarios
          isOpen={isModalOpen}
          onClose={closeModal}
          usuarios={usuarios}
          usuariosHabilitados={usuariosHabilitados}
          handleCheckboxChange={handleCheckboxChange}
          closeModal={closeModal}
          setIsModalOpen={setIsModalOpen}
          selectedItem={selectedItem}
          idTipoE={idTipoE}
        />
      )}
    </Stack>
  );
};

export default FormCrearEditarNotificacion;
